<template>
	<div class="examine-container">
		<ul>
			<li>
				<span class="pageType">{{this.$route.query.type==13?'估值报告列表':'测评报告列表'}}</span>
			</li>
			<li style="width: 60%;">
				<p class="createTime">
					<img src="../../assets/img/examine/icon_audit_title.png" />
					<span>{{data.companyName}}</span>
				</p>
				<p class="payTime">
					<img src="../../assets/img/examine/icon_audit_date.png" />
					<span>支付日期：{{data.payTime}}</span>
				</p>
			</li>
		</ul>
	</div>
</template>

<script>
	import qs from 'qs'
	import axios from 'axios'

	export default {
		data() {
			return {
				data: {
					createTime: 'xxxx年xx月xx日',
					companyName: 'xxxxx',
					payTime: 'xxxx年xx月xx日'
				}
			}
		},
		mounted() {
			$('html,body,#app').css('min-width', '1200px')

			this.getPayBillInfo()
		},
		methods: {
			getPayBillInfo() {
				this.$post(this.domain.valuationProject + 'pay/getPayBillInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.data = res.data.content
					})
			}
		}
	}
</script>

<style scoped lang="less">
	.examine-container {
		width: 100%;

		>ul {
			width: 100%;
			height: 70px;
			background: url(../../assets/img/examine/title.png) center center no-repeat;
			background-size: 100% 100%;
			padding: 0 3%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			li:first-child {
				align-items: center;
			}

			li {
				height: 100%;
				display: flex;
				justify-content: flex-end;

				img {
					height: 18px;
					vertical-align: middle;
				}

				.pageType {
					font-size: 20px;
					font-weight: bold;
					color: #FFFFFF;
				}

				div {
					width: 100%;
				}

				p {
					display: inline-block;
					transform: translateY(calc(50% - 18px));

					span {
						font-size: 14px;
						font-weight: 400;
						color: #FFFFFF;
						margin-left: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

				}

				.createTime span {
					width: 170px;
				}

				.payTime {
					margin-left: 20px;
				}

			}
		}
	}

	@media screen and (max-width:1400px) and (max-height:650px) {
		.examine-container {
			height: 62px;
		}
	}
</style>