<template>
	<div>
		<Header />
		<Carousel loop :height="450" arrow="never" dots="none">
			<CarouselItem>
				<div class="banner">
					<div>
						<p class="englishName">Data Quotient Test</p>
						<p class="productName">数商测试</p>
						<p class="banner_explain">
							数商是对大数据时代人类获得信息能力的一种度量。数商测试是对数据优势、数据实力、数据能力高低的衡量，它不仅衡量通过测量和记录获得数据的行为能力，还衡量用科学高效的方法保存数据、使用数据，从数据中洞察知识和规律，预测未来的能力。
						</p>
						<p class="subheading" style="font-size: 18px;">修炼数商是智能时代的潮流。快来看看你的数商有多高？</p>
					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<div class="mainContent">
			<div class="introductoryQuestion">
				<p>本套测试题引自涂子沛老师的《数商》一书</p>
				<p>测试题分为两大板块：一是关于数据价值观、数据思维和日常习惯的测试，二是关于现代数据科学知识和技能的测试，但为了计分方便两类题目并没有按类别和重要性的次序排列。</p>
				<p>共34题，需要时间为30-40分钟。全部题目没有复杂的计算，如果涉及计算，可以用估算的方式从选项中找出正确答案。</p>
			</div>

			<div id="xqBox">
				<div class="topics" v-for="(item,index) in softIndexProblem">
					<p>{{item.contentName}}</p>
					<div class="row" v-for="(topic, t) in item.contentDetails">
						<p class="quotientProblem">
							<span>{{topic.contval}}</span>
							<span>{{topic.content}}</span>
						</p>
						<div>
							<ul>
								<li v-for="(answer, a) in topic.options"
									:class="[answer.chooseFlag=='1'?'active':'',topic.isSelect?'multiSelect':'']"
									:ref="answer.chooseFlag=='1'?'selected':'Selecte'" :optionId="answer.id"
									:questionId="answer.questionId"
									:style="{'background':answer.chooseFlag=='1'?hexToRgb($store.state.user.subjectColor.mainColor, '0.1'):'#fff'}"
									@mouseover="changeBackgroundColor($event)" @mouseout="resetBackgroundColor($event)"
									@click="selectTopic(index,t, a)">


									<i class="iconfont icon-duigou"></i>
									<label>{{answer.content}}</label>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>




			<button class="buttonFill save" @click="save">立即提交</button>

		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '../../common/header/flow_header.vue'
	import Footer from '../../common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'

	import qs from 'qs'
	import axios from 'axios'

	import {
		Modal,
		Input,
		DIV,
		Switch
	} from 'iview'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp
		},
		data() {
			return {
				isSave: false,
				softIndexProblem: [],
				selectedQuestion: [],
				total: 0
			}
		},
		mounted() {
			this.getBusinessModel()
		},
		methods: {
			changeBackgroundColor(e) {
				e.currentTarget.style.background = this.hexToRgb(this.$store.state.user.subjectColor.mainColor, '0.1');
			},
			resetBackgroundColor(e) {
				if (e.currentTarget.classList.contains('active')) {
					e.currentTarget.style.background = this.hexToRgb(this.$store.state.user.subjectColor.mainColor, '0.1');
				} else {
					e.currentTarget.style.background = 'white'
				}
			},
			selectTopic(i, t, j) {
				this.isSave = false


				if (this.softIndexProblem[i].contentDetails[t].isSelect) {
					if (this.softIndexProblem[i].contentDetails[t].options[j].chooseFlag == 1) {
						this.$set(this.softIndexProblem[i].contentDetails[t].options[j], 'chooseFlag', '0')
					} else {
						this.$set(this.softIndexProblem[i].contentDetails[t].options[j], 'chooseFlag', '1')
					}
				} else {
					this.softIndexProblem[i].contentDetails[t].options.map((el, b) => {
						if (j == b) this.$set(this.softIndexProblem[i].contentDetails[t].options[j], 'chooseFlag', '1')
						else el.chooseFlag = 0
					})
				}


				$('.quotientProblem').removeClass('no-choose-red')

				this.$forceUpdate()

			},
			getBusinessModel() {
				this.$post(this.domain.dataRealization + 'businessModel/getBusinessModel', qs.stringify({
						businessId: ''
					}))
					.then((res) => {
						this.softIndexProblem = res.data.content

						this.total = 0;
						for (var i = 0; i < this.softIndexProblem.length; i++) {
							this.total += this.softIndexProblem[i].contentDetails.length;
						}

						// 循环条数
						var cont = 0
						for (var i = 0; i < res.data.content.length; i++) {
							for (var k = 0; k < res.data.content[i].contentDetails.length; k++) {
								this.$set(res.data.content[i].contentDetails[k], 'contval', cont += 1)
							}
						}
					});
			},
			save() {
				var selectedQuestion = this.$refs.selected ? this.$refs.selected : [];
				let hasError = false;

				$(".row").each(function() {
					if ($(this).find(".active").length <= 0) {
						hasError = true;
						return false; // 立即退出循环
					}
				});

				if (hasError) {
					Modal.warning({
						title: '提示',
						content: '所有题皆为必填项',
						onOk: () => {
							$(".row").each(function() {
								if ($(this).find(".active").length <= 0) {
									console.log($(this))
									window.scrollTo({
										top: $(this).find("ul")[0].offsetTop - 140,
										behavior: 'smooth'
									});
									$(this).find('.quotientProblem').addClass('no-choose-red');
									return false
								}
							});
						}
					});
				} else {
					for (var i = 0; i < selectedQuestion.length; i++) {
						this.selectedQuestion.push({
							optionId: selectedQuestion[i].getAttribute('optionId'),
							questionId: selectedQuestion[i].getAttribute('questionId')
						})
					}
					this.saveTopic()
				}

			},
			saveTopic() {
				this.$post(this.domain.dataRealization + 'businessModel/saveBusinessModel', qs.stringify({
					softJArray: JSON.stringify(this.selectedQuestion),
					businessId: ''
				})).then(res => {
					this.$router.push({
						name: 'quotientTestResults',
						query: {
							id: res.data.content
						}
					})
				})

			}

		}


	}
</script>

<style scoped lang="less">
	.banner {
		background: url(../../assets/img/numberQuotient/banner.png) center center no-repeat;
	}
	
	body[theme='#d9001b'] {
		.banner {
			background: url(../../assets/img/numberQuotient/bannerRed.jpg) center center no-repeat;
		}
	}

	.no-choose-red {
		span:last-child {
			color: red !important;
		}
	}

	.introductoryQuestion {
		width: 100%;
		background: #FFFFFF;
		box-shadow: 0px 0px 13px 0px rgba(98, 136, 200, 0.23);
		padding: 28px 40px;
		margin: 74px auto 40px;

		p:first-child {
			font-size: 16px;
			color: #282828;
			margin-bottom: 20px;
		}

		p:not(:first-child) {
			font-size: 14px;
			color: #282828;
			line-height: 24px;
		}
	}

	.topics {
		background: #FFFFFF;
		box-shadow: 0px 0px 13px 0px rgba(98, 136, 200, 0.23);
		padding-bottom: 30px;
		margin-bottom: 40px;

		>P {
			font-size: 18px;
			color: #282828;
			line-height: 24px;
			padding: 25px 40px 10px;
			border-bottom: 1px solid #CACACA;
		}

		>div {
			.quotientProblem {
				margin-top: 34px;
				padding: 0 36px;
				display: flex;

				span:first-child {
					width: 34px;
					height: 34px;
					line-height: 34px;
					background: var(--mainColor);
					border-radius: 50%;
					text-align: center;
					font-size: 18px;
					font-weight: bold;
					color: #fff;
					display: inline-block;
					margin-right: 23px;
				}

				span:last-child {
					width: calc(100% - 57px);
					font-size: 16px;
					font-weight: bold;
					color: #282828;
					line-height: 24px;
					position: relative;
					top: 3px;
				}
			}

			.quotientProblem+div {

				ul {
					padding: 0 36px 0 74px;

					li {
						height: 32px;
						line-height: 32px;
						padding: 0 50px;
						margin-top: 15px;
						cursor: pointer;
						border-radius: 16px;
						position: relative;
					}

					li:not(.multiSelect) {
						label {
							font-size: 14px;
							color: #282828;
							display: flex;
							align-items: center;
							cursor: pointer;
						}

						label::before {
							content: '';
							width: 16px;
							height: 16px;
							border-radius: 50%;
							border: 2px solid var(--mainColor);
							display: inline-block;
							margin-right: 14px;
						}

						i {
							display: none;
						}
					}

					li.multiSelect {
						i {
							display: none;
							color: var(--mainColor);
							font-size: 12px;
							font-weight: bold;
							position: absolute;
							top: 1px;
							left: 52px;
						}

						label {
							font-size: 14px;
							color: #282828;
							display: flex;
							align-items: center;
							cursor: pointer;
						}

						label::before {
							content: '';
							width: 16px;
							height: 16px;
							border-radius: 4px;
							border: 2px solid var(--mainColor);
							display: inline-block;
							margin-right: 14px;
						}
					}

					li.active {
						border-radius: 16px;
					}

					li.active:not(.multiSelect) {
						label {
							color: var(--mainColor);
						}

						label::before {
							background-color: var(--mainColor);
							background-clip: content-box;
							padding: 3px;
							width: 17px;
							height: 17px;
							border-color: var(--mainColor);
						}
					}

					li.active.multiSelect {
						i {
							display: block;
						}

						label {
							color: var(--mainColor);
						}
					}

				}

			}

		}
	}
</style>