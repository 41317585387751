<template>
	<div>
		<table ref="table" class="table" border="0" style="border-collapse: collapse;">
			<thead>
				<tr class="noInput">
					<th>项目</th>
					<th v-for="(item,index) in year" :key="index - 1">{{item}}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="trSeven">（1）重新计量设定受益计划变动额</td>
					<td v-for="(item,index) in year" :key="index + 32">
						{{judgingCondition('remeasureBenefitAmount',index)?method.formateNum(profitTableInfo.remeasureBenefitAmount[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trSeven">（2）权益法下不能转损益的其他综合收益</td>
					<td v-for="(item,index) in year" :key="index + 33">
						{{judgingCondition('notTransferOci',index)?method.formateNum(profitTableInfo.notTransferOci[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trSeven">（3）其他权益工具投资公允价值变动</td>
					<td v-for="(item,index) in year" :key="index + 34">
						{{judgingCondition('otherEquityFairValue',index)?method.formateNum(profitTableInfo.otherEquityFairValue[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trSeven">（4）企业自身信用风险公允价值变动</td>
					<td v-for="(item,index) in year" :key="index + 35">
						{{judgingCondition('enterpriseCreditRiskFairValue',index)?method.formateNum(profitTableInfo.enterpriseCreditRiskFairValue[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trSix">2.将重分类进损益的其他综合收益</td>
					<td v-for="(item,index) in year" :key="index + 36">
						{{judgingCondition('reclassifiedOci',index)?method.formateNum(profitTableInfo.reclassifiedOci[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trSeven">（1）权益法下可转损益的其他综合收益</td>
					<td v-for="(item,index) in year" :key="index + 37">
						{{judgingCondition('transferOci',index)?method.formateNum(profitTableInfo.transferOci[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trSeven">（2）其他债权投资公允价值变动</td>
					<td v-for="(item,index) in year" :key="index + 38">
						{{judgingCondition('otherClaimsFairValue',index)?method.formateNum(profitTableInfo.otherClaimsFairValue[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trSeven">（3）金融资产重分类计入其他综合收益的金额</td>
					<td v-for="(item,index) in year" :key="index + 39">
						{{judgingCondition('financialAssetsOciAmount',index)?method.formateNum(profitTableInfo.financialAssetsOciAmount[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trSeven">（4）其他债权投资信用减值准备</td>
					<td v-for="(item,index) in year" :key="index + 40">
						{{judgingCondition('otherClaimsCreditImpairment',index)?method.formateNum(profitTableInfo.otherClaimsCreditImpairment[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trSeven">（5）现金流量套期储备</td>
					<td v-for="(item,index) in year" :key="index + 41">
						{{judgingCondition('cashFlowHedgingReserve',index)?method.formateNum(profitTableInfo.cashFlowHedgingReserve[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trSeven">（6）外币财务报表折算差额</td>
					<td v-for="(item,index) in year" :key="index + 42">
						{{judgingCondition('foreignCurrencyBalance',index)?method.formateNum(profitTableInfo.foreignCurrencyBalance[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">（二）归属于少数股东的其他综合收益的税后净额</td>
					<td v-for="(item,index) in year" :key="index + 43">
						{{judgingCondition('minorityStockOciNetTax',index)?method.formateNum(profitTableInfo.minorityStockOciNetTax[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trOne">六、综合收益总额</td>
					<td v-for="(item,index) in year" :key="index + 44">
						{{judgingCondition('totalComprehensiveIncome',index)?method.formateNum(profitTableInfo.totalComprehensiveIncome[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trTwo">（一）归属于母公司所有者的综合收益总额</td>
					<td v-for="(item,index) in year" :key="index + 45">
						{{judgingCondition('parentCompanyTci',index)?method.formateNum(profitTableInfo.parentCompanyTci[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trTwo">（一）归属于少数股东的综合收益总额</td>
					<td v-for="(item,index) in year" :key="index + 46">
						{{judgingCondition('minorityStockTci',index)?method.formateNum(profitTableInfo.minorityStockTci[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trOne">七、每股收益</td>
					<td v-for="(item,index) in year" :key="index + 47"></td>
				</tr>
				<tr>
					<td class="trTwo">（一）基本每股收益</td>
					<td v-for="(item,index) in year" :key="index + 45">
						{{judgingCondition('basicEps',index)?method.formateNum(profitTableInfo.basicEps[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">（二）稀释每股收益</td>
					<td v-for="(item,index) in year" :key="index + 46">
						{{judgingCondition('dilutedEps',index)?method.formateNum(profitTableInfo.dilutedEps[index],2):'-'}}
					</td>
				</tr>


			</tbody>
		</table>
	</div>
</template>
<script>
	export default ({
		props: [
			'profitTableInfo', 'year', 'versions','color'
		],
		data() {
			return {
				dateFormate: "12月31日"
			}
		},
		mounted() {
			if (this.versions == 1) {
				this.dateFormate = "";
			} else if (this.versions == 'trace') {
				this.dateFormate = "年12月31日"
			}
			
			this.changeThemeColor(this.color)
			
		},
		methods: {
			judgingCondition: function(key, i, index) {
				if (this.profitTableInfo[key][i] === 0) {
					return true
				} else if (this.profitTableInfo[key][i] != 0 && this.profitTableInfo[key][i] != '-' && this.profitTableInfo[
						key][i]) {
					return true
				} else {
					return false
				}
			},
			changeThemeColor(newColor) {
				this.$refs.table.style.setProperty('--color', newColor);
			}

		}
	})
</script>
<style scoped lang="less">
	.titleBox {
		margin: 130px 0 0 0;
		padding: 0px 110px;
		display: flex;
		justify-content: space-between;
	}

	.titleBox span {
		line-height: 100%;
		font-size: 20px;
	}

	.titleBox .title {
		text-align: center;
		font-size: 24px;
		font-weight: 400;
		color: #282828;
		margin: 130px 0 0 0;
		line-height: 100%;
	}

	table {
		--color: #1459F4;
		
		width: 100%;
		text-align: center;
		border-collapse: collapse;
		margin: 28px auto 0;
		border: 1px solid var(--color);
	}

	table tr th {
		height: 50px;
		background: var(--color);
		font-size: 18px;
		color: #fff;
	}
	
	table th:not(:last-child) {
		border-right: 1px solid #fff;
	}

	table tr td {
		width: 20%;
		height: 43px;
		border: 1px solid var(--color);
		font-size: 14px;
		color: #282828;
	}

	table>tbody>tr:first-of-type>td {
		border-top: none;
	}

	table>tbody>tr>td:first-of-type {
		width: 40%;
		text-align: left;
		padding-right: 10px;
	}

	.total {
		background: rgba(20, 22, 244, 0.1);
		font-weight: bold;
	}

	.trOne {
		padding-left: 40px;
		font-weight: bold;
	}

	.trTwo {
		padding-left: 70px;
	}

	.trThree {
		padding-left: 101px;
	}

	.trFour {
		padding-left: 131px;
	}

	.trFive {
		padding-left: 178px;
	}

	.trSix {
		padding-left: 116px;
	}

	.trSeven {
		padding-left: 122px;
	}

	.title {
		margin: 0 !important;
	}
	
	table>tbody>tr{
		td:not(:first-child){
			text-align: right;
			padding-right: 38px;
		}
	}
</style>