<template>
	<div :id="id"></div>
</template>

<script>
	import * as echarts from 'echarts'

	export default {
		props: [
			'chartData', 'id', 'yAxisName', 'chartColor', 'titleText'
		],
		data() {
			return {


			}
		},
		mounted() {
			this.initChart()

		},
		methods: {
			initChart() {
				var this_ = this;
				var fontSize12 = 14;
				var chartRotate = 30;
				var dataxAxis = this.chartData.name;
				var datayAxis = this.chartData.value;
				var chartGrid = {
					left: '8%',
					right: '12%',
					bottom: '10%',
					top: '14%',
					containLabel: true
				}

				var difference = this_.method.setFinancialAnalysisChartPoor(dataxAxis, datayAxis),
					barWidth = 35,
					yAxisParams = this_.method.setHasBackgroundData(datayAxis);
				var barData = []
				var bottomHalfRing = this_.method.pictorialBarBottom(datayAxis, 17)
				datayAxis.forEach(function(item, i) {
					barData.push(item > 0 ? yAxisParams.max : item == 0 ? 0 : yAxisParams.min)
					bottomHalfRing[i].symbol = 'image://' + require('@/assets/img/professionalReport/semiRing'+(i + 1) + '.svg')
				})

				var option = {
					tooltip: {
						trigger: 'none'
					},
					grid: chartGrid,
					animation: false,
					title: {
						show: true,
						text: this.titleText,
						textStyle: {
							color: '#282828',
							align: 'center',
							fontSize: 16
						},
						left: 'center',
						top: 'top'
					},
					xAxis: [{
						type: "category",
						data: dataxAxis,
						axisTick: {
							alignWithLabel: true,
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#1459F4"
							}
						},
						axisLabel: {
							color: "#282828",
							fontSize: fontSize12,
							lineHeight: 15,
							interval: 0,
							rotate: chartRotate,
							margin: 20
						}
					}],
					yAxis: {
						type: "value",
						name: this.yAxisName,
						max: yAxisParams.max,
						min: yAxisParams.min,
						interval: yAxisParams.interval,
						nameTextStyle: { // 设置名称样式
							color: '#282828', // 文字颜色
							fontSize: fontSize12, // 字体大小
							padding: [0, 0, 10, 0]
						},
						axisLine: {
							show: true, //轴线
							lineStyle: {
								color: "#1459F4"
							}
						},
						axisLabel: {
							fontSize: fontSize12,
							color: "#282828"
						},
						splitLine: {
							show: false //网格线
						}
					},
					series: [{
						name: "数据上椭圆",
						type: 'pictorialBar',
						symbolSize: [barWidth, 16],
						symbolPosition: 'end',
						z: 12,
						label: {
							show: true,
							position: 'top',
							fontSize: fontSize12,
							color: '#282828',
							padding: [0, 0, 5, 0],
							formatter: function(params, index) {
								return this_.method.formateNum(datayAxis[params.dataIndex].toString());
							}
						},
						itemStyle: {
							color(params) {
								var colorList = this_.chartColor.topColor
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: this_.method.pictorialBarTop(datayAxis, 8)
					}, {
						name: '下椭圆',
						type: 'pictorialBar',
						symbolSize: [barWidth, 16],
						z: 12,
						itemStyle: {
							color(params) {
								var colorList = this_.chartColor.bottomColor
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: this_.method.pictorialBarBottom(datayAxis, 8)
					}, {
						name: '底部半环',
						type: 'pictorialBar',
						symbolSize: [50, 32],
						z: 12,
						emphasis: {
							disabled: true
						},
						data: bottomHalfRing
					}, {
						name: '真实数据',
						type: 'bar',
						barWidth: barWidth,
						z: 5,
						barCategoryGap: 10,
						itemStyle: {
							color(params) {
								var colorList = this_.method.setGradientColor(datayAxis, this_.chartColor.lightColor, this_
									.chartColor.darkColor)
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: datayAxis,
						markLine: {
							lineStyle: {
								color: '#282828',
							},
							label: {
								show: false,
							},
							data: [
								[{
										coord: difference.coordOne,
										lineStyle: {
											color: '#282828',
											type: 'solid'
										},
										symbolSize: [0, 0],
									},
									{
										coord: difference.coordTwo,
										lineStyle: {
											color: '#282828',
											type: 'solid'
										},
										label: {
											show: true,
											position: 'middle',
											color: '#282828', //设置了文本的颜色会把文本周围的白边去掉
											formatter: function(params) {
												var value = difference.markLineValue.toFixed(2);
												return value;
											}
										},
									}
								]
							]
						}
					}, {
						name: '背景数据',
						type: 'bar',
						barWidth: barWidth,
						barGap: '-100%', // barGap值为-100%时可以使柱子重叠
						itemStyle: {
							color(params) {
								var colorList = this_.chartColor.backgroundColor
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: barData
					}, {
						name: "背景top顶",
						type: 'pictorialBar',
						symbolSize: [barWidth, 16],
						symbolPosition: 'end',
						z: 10,
						itemStyle: {
							color(params) {
								var colorList = this_.chartColor.maxColor
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: this_.method.pictorialBarTop(barData, 8)

					}, {
						type: 'line',
						markLine: {
							symbolSize: [0, 0],
							silent: true,
							lineStyle: {
								color: '#282828',
							},
							label: {
								show: false
							},
							data: [
								difference.axisOne,
								difference.axisTwo,
							]
						}
					}]
				};
				var myChart = echarts.init(document.getElementById(this.id));
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option, false);

			}

		}

	}
</script>

<style>
</style>