<template>
	<div class="report-container">
		<div class="tools">
			<button @click="exportPDF">导出PDF</button>
		</div>

		<div id="report-page">
			<!-- 封面 -->
			<div class="page" id="page-home">
				<div class="logoBox">
					<img v-if="pageInfo.firstPageImage" :src="pageInfo.firstPageImage" alt="公司logo" />
				</div>
				<div class="page-main">
					<p class="companyName">{{pageInfo.baseInfo.companyName}}</p>
					<h5>估值报告书</h5>
					<p class="reportNumber">报告编号：{{pageInfo.reportNumber}}</p>
					<h6>（初创期）</h6>
				</div>
				<div class="powerInterpretation">
					<span></span>
					<p>{{pageInfo.channelName}}保留对此报告的最终解释权</p>
					<div class="date">{{pageInfo.time ? replaceAll(pageInfo.time) : ''}}</div>
				</div>
			</div>
			<!--盖章页-->
			<div class="page page-stamp" id="page-stamp">
				<!--内容-->
				<div class="page-content">
					<p class="stampNew">
						<i>依据《企业会计准则第22号--金融供给确认和计量》、《企业会计准则第39号—公允价值计量》、《私募投资基金非上市股权投资估值指引》、《中小微企业估值规范》、《中华人民共和国公司法》、《中华人民共和国证券法》等政策规章，基于国际通用的股权估值模型、自研的估值指标体系及Ai估值技术，贵公司股东全部权益在估值基准日（</i>
						<i class="reportBaseDate">{{method.reportFormatDate(pageInfo.reportBaseDate)}}</i>
						<i>）的市场价值为（人民币）：</i>
					</p>
					<div class="detailResultNew">
						<h4 v-text="resultFormate(pageInfo.value.minValue, pageInfo.value.maxValue)">0.00</h4>
					</div>
					<div class="stamptime">
						<p>{{pageInfo.channelName}}</p>
						<p>{{pageInfo.time ? replaceAll(pageInfo.time) : ''}}</p>
					</div>
				</div>
			</div>
			<!--目录页-->
			<div class="page page-catalogue" id="page-catalogue">
				<h2>目录</h2>
				<div class="generalize">
					<div class="catalogue-category">
						<span>一、企业概况</span>
						<span class="catalogue-pagination">01</span>
					</div>
					<ul>
						<li>
							<div class="second-level-directory">
								<span>1. 基本信息</span>
								<span class="catalogue-pagination">01</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>2. 主营业务</span>
								<span class="catalogue-pagination">01</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>3. 产品情况</span>
								<span class="catalogue-pagination">01</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>4. 创始人团队</span>
								<span class="catalogue-pagination">01</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>5. 拟融资情况</span>
								<span class="catalogue-pagination">01</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>6. 股权结构</span>
								<span class="catalogue-pagination">01</span>
							</div>
						</li>
					</ul>
					<div class="catalogue-category">
						<span>二、估值图谱</span>
						<span class="catalogue-pagination">02</span>
					</div>
					<ul>
						<li>
							<div class="second-level-directory">
								<span>1. 行业环境</span>
								<span class="catalogue-pagination">02</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>2. 管理团队</span>
								<span class="catalogue-pagination">03</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>3. 商业模式</span>
								<span class="catalogue-pagination">03</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>4. 产品竞争力</span>
								<span class="catalogue-pagination">03</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>5. 风险管理</span>
								<span class="catalogue-pagination">04</span>
							</div>
						</li>
					</ul>
					<div class="catalogue-category">
						<span>三、盈利预测</span>
						<span class="catalogue-pagination">05</span>
					</div>
					<ul>
						<li>
							<div class="second-level-directory">
								<span>1. 企业支出预测</span>
								<span class="catalogue-pagination">05</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>2. 企业收入预测</span>
								<span class="catalogue-pagination">05</span>
							</div>
						</li>
					</ul>
					<div class="catalogue-category">
						<span>四、估值结果</span>
						<span class="catalogue-pagination">06</span>
					</div>
					<div class="catalogue-category">
						<span>五、特别事项说明和瑕疵事项</span>
						<span class="catalogue-pagination">07</span>
					</div>
					<div class="catalogue-category">
						<span>六、估值报告使用限制说明</span>
						<span class="catalogue-pagination">07</span>
					</div>
					<div class="catalogue-category">
						<span>七、估值报告解释权</span>
						<span class="catalogue-pagination">07</span>
					</div>
					<div class="catalogue-category">
						<span>八、附件</span>
						<span class="catalogue-pagination">08</span>
					</div>
					<div class="catalogue-category stockIntroduceCatalogue" v-if="pageInfo.introImage">
						<span>九、股交所简介</span>
						<span class="catalogue-pagination">09</span>
					</div>
					<div class="catalogue-category" style="border-bottom: 2px solid #1459F4;">
						<span class="toTen">{{pageInfo.introImage?'十':'九'}}、免责声明</span>
						<span class="catalogue-pagination isAnd_">{{pageInfo.introImage?10:'09'}}</span>
					</div>
				</div>
			</div>
			<!-- 基本信息 -->
			<div class="page" id="page-baseInfo">
				<div class="page-content">
					<p class="authorizationDes">
						受{{pageInfo.baseInfo.companyName}}（以下简称“{{pageInfo.baseInfo.companyShort}}”或“公司”）的委托，北京心流慧估科技有限公司（以下简称“心流慧估”）依据相关法律法规和国际公认的估值方法，对{{pageInfo.baseInfo.companyName}}股东全部权益在估值基准日的市场价值进行了评估。
					</p>

					<p class="broadCategories">一、企业概况</p>
					<p class="broadHeading">（一）基本信息</p>

					<div class="baseInfoCont baseInfoContFlex">
						<ul>
							<li>
								<span>企业名称：</span>
								<span class="companyName" v-text="pageInfo.baseInfo.companyName"></span>
							</li>
							<li>
								<span>所属行业：</span>
								<span class="industry" v-text="pageInfo.baseInfo.newIndustryName"></span>
							</li>
							<li>
								<span>企业所在地：</span>
								<span class="companyLocation" v-text="pageInfo.baseInfo.area ? pageInfo.baseInfo.area:'无数据'">无数据</span>
							</li>
							<li>
								<span>注册资本：</span>
								<span class="companyValuation" v-text="valueFormate(pageInfo.baseInfo.regCapital)"></span>
							</li>
							<li>
								<span>统一社会信用代码：</span>
								<span class="socialCreditCode"
									v-text="pageInfo.baseInfo.creditCode ? pageInfo.baseInfo.creditCode:'无数据'"></span>
							</li>
							<li>
								<span>法定代表人：</span>
								<span class="corporateRepresentative"
									v-text="pageInfo.baseInfo.legalPeople ? pageInfo.baseInfo.legalPeople:'无数据'"></span>
							</li>
							<li>
								<span>邮箱：</span>
								<span class="Email" v-text="pageInfo.baseInfo.email ? pageInfo.baseInfo.email:'无数据'"></span>
							</li>
							<li>
								<span>成立日期：</span>
								<span class="establishmentDate"
									v-text="pageInfo.baseInfo.establishDate ? dateFormate(pageInfo.baseInfo.establishDate): '无数据'"></span>
							</li>
							<li>
								<span>企业价值（自评）：</span>
								<span class="companyValuation" v-text="valueFormate(pageInfo.baseInfo.selfValue)"></span>
							</li>
							<li>
								<span>估值目的：</span>
								<span v-text="pageInfo.baseInfo.valuationPurpose ? pageInfo.baseInfo.valuationPurpose:'无数据'"></span>
							</li>
						</ul>
					</div>

					<div>
						<p class="broadHeading">（二）主营业务</p>
						<p class="bussinessDesc text secondLevelText">
							<i>
								{{!pageInfo.bussinessDesc?'无数据':pageInfo.bussinessDesc.length>100?pageInfo.bussinessDesc.substring(0, 100) + '...' :pageInfo.bussinessDesc}}
							</i>
						</p>
					</div>

					<div class="productAndGroup">
						<div>
							<p class="broadHeading">（三）产品情况</p>
							<ul>
								<li>
									<span>产品阶段：</span>
									<span class="productStage">{{pageInfo.product.productStage}}</span>
								</li>
								<li>
									<span>产品类型：</span>
									<span class="productType" v-text="pageInfo.product.productType"></span>
								</li>
								<li>
									<span>客户反馈：</span>
									<span class="clientFeelback" v-text="pageInfo.product.customerReaction"></span>
								</li>
							</ul>
						</div>
						<div>
							<p class="broadHeading">（四）创始人团队</p>
							<ul>
								<li>
									<span>管理经验：</span>
									<span
										class="managerialExperience">{{pageInfo.team.managerExper?pageInfo.team.managerExper:'无数据'}}</span>
								</li>
								<li>
									<span>相关行业经验：</span>
									<span
										class="industryExperience">{{pageInfo.team.industryExper ? pageInfo.team.industryExper : '无数据'}}</span>
								</li>
								<li>
									<span>创始人学历：</span>
									<span
										class="originatorEducation">{{pageInfo.baseInfo.founderEdu || pageInfo.baseInfo.founderEdu == ''?pageInfo.baseInfo.founderEdu:pageInfo.team.education}}</span>
								</li>
							</ul>
						</div>
					</div>
					<div>
						<p class="broadHeading">（五）拟融资情况</p>
						<div class="baseInfoCont baseInfoContFlex">
							<ul>
								<li>
									<span>拟融资额度：</span>
									<span class="financingLimit"
										v-text="pageInfo.finance.finance ? this.method.formateNum(pageInfo.finance.finance,2)+'万元' : '无数据'"></span>
								</li>
								<li>
									<span>计划出让股权：</span>
									<span class="concessionStock"
										v-text="pageInfo.finance.ratio ? pageInfo.finance.ratio + '%' : '无数据'"></span>
								</li>
							</ul>
						</div>
					</div>
					<div>
						<p class="broadHeading">（六） 股权结构</p>
						<div class="equityStructure">
							<img class="equityStructureBase" src="../../assets/img/professionalReport/equityStructureBase.jpg"
								alt="股权结构">
							<Draw3DPieCanvas :id="'stockStructurePie'" :chartData="pageInfo.holderRatio" size='400'
								v-if="initStatus" />
						</div>
					</div>

				</div>
				<div class="pageNum">
					<p>///</p><span>1</span>
				</div>
			</div>
			<div class="page" id="page-atlas">
				<div class="page-content">
					<p class="broadCategories">二、估值图谱</p>
					<div style="margin-bottom: 100px;">
						<div class="valueResultTitle">
							<img src="../../assets/img/professionalReport/valuationGraphIcon.png" class="icon_">
							<span>估值图谱</span>
						</div>
						<div class="radar">
							<img src="../../assets/img/professionalReport/radarBase.jpg" alt="底座">
							<DrawRadarCanvas2 v-if="initStatus" :chartData="pageInfo.question" :id="'flowDiagnose_BaseInfo'"
								title="估值图谱" />
						</div>
					</div>

					<div class="industryContent">
						<p class="broadHeading">（一）行业环境</p>
						<ul class="softModule threeModule">
							<li v-for="(item,i) in pageInfo.industry" :key="i">
								<p class="softValue">{{item.value}}分</p>
								<p class="softTitle">{{item.name}}</p>
							</li>
						</ul>
						<div class="softDescribe">
							<p v-for="(item,index) in industryStatements" :key="index + 1">{{item}}</p>
						</div>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>2</span>
				</div>
			</div>
			<div class="page" id="page-teamManage">
				<div class="page-content">
					<div class="teamManageContent">
						<p class="broadHeading">（二）管理团队</p>
						<ul class="softModule twoModule">
							<li v-for="(item,i) in pageInfo.competitivePower" :key="i">
								<p class="softValue">{{item.value}}分</p>
								<p class="softTitle">{{item.name}}</p>
							</li>
						</ul>
						<div class="softDescribe">
							<p v-for="(item,index) in teamManageStatements" :key="index + 1">{{item}}</p>
						</div>
					</div>
					<div class="technologyContent">
						<p class="broadHeading">（三）商业模式</p>
						<ul class="softModule twoModule">
							<li v-for="(item,i) in pageInfo.teamManage" :key="i">
								<p class="softValue">{{item.value}}分</p>
								<p class="softTitle">{{item.name}}</p>
							</li>
						</ul>
						<div class="softDescribe">
							<p v-for="(item,index) in businessStatements" :key="index + 1">{{item}}</p>
						</div>
					</div>

					<div class="productContent">
						<p class="broadHeading">（四）产品竞争力</p>
						<ul class="softModule fourModule">
							<li v-for="(item,i) in pageInfo.technology" :key="i">
								<p class="softValue">{{item.value}}分</p>
								<p class="softTitle">{{item.name}}</p>
							</li>
						</ul>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>3</span>
				</div>
			</div>
			<div class="page" id="page-product">
				<div class="page-content">
					<div class="productContent">
						<div class="softDescribe">
							<p v-for="(item,index) in technologyStatements" :key="index + 1">{{item}}</p>
						</div>
					</div>
					<div class="ventureManageContent">
						<p class="broadHeading">（五）风险管理</p>
						<ul class="softModule twoModule">
							<li v-for="(item,i) in pageInfo.ventureManage" :key="i">
								<p class="softValue">{{item.value}}分</p>
								<p class="softTitle">{{item.name}}</p>
							</li>
						</ul>
						<div class="softDescribe">
							<p v-for="(item,index) in ventureManageStatements" :key="index + 1">{{item}}</p>
						</div>
					</div>

				</div>
				<div class="pageNum">
					<p>///</p><span>4</span>
				</div>
			</div>
			<div class="page" id="page-forecast">
				<div class="page-content">
					<p class="broadCategories">三、盈利预测</p>
					<div>
						<p class="broadHeading">（一） 企业支出预测</p>
						<div class="spendingForecast">
							<img src="../../assets/img/startup/edge.svg" alt="边缘">
							<div class="keyVisual">
								<ul>
									<li v-for="(item,i) in pageInfo.costExpenditure" :class="{'rollingOver':(i+1) % 2===0}">
										<div class="expenditureItemIcon">
											<img :src="require('@/assets/img/startup/expenditureItemIcon'+(i+1)+'.png')" alt="">
										</div>
										<div class="expenditureItemText">
											<p>{{item.key}}</p>
											<p>{{Number(item.value).toFixed(2)}}%</p>
										</div>
									</li>
								</ul>
							</div>
							<img class="rollingOver" src="../../assets/img/startup/edge.svg" alt="边缘">
						</div>
						<ul class="result-explain-container">
							<li>
								<span>注：</span>
								<span class="expcalculate_according" v-if="initStatus">
									企业支出预测反映了企业在未来一年内对其支出的预测配比情况，其中，{{pageInfo.costExpenditure[0].key+'、'+pageInfo.costExpenditure[1].key+'、'+pageInfo.costExpenditure[2].key+'、'+pageInfo.costExpenditure[3].key+'、'+pageInfo.costExpenditure[4].key+'、及'+pageInfo.costExpenditure[5].key+'分别占比为：'+Number(pageInfo.costExpenditure[0].value).toFixed(2)+'%、'+Number(pageInfo.costExpenditure[1].value).toFixed(2)+'%、'+Number(pageInfo.costExpenditure[2].value).toFixed(2)+'%、'+Number(pageInfo.costExpenditure[3].value).toFixed(2)+'%、'+Number(pageInfo.costExpenditure[4].value).toFixed(2)+'%、'+Number(pageInfo.costExpenditure[5].value).toFixed(2)+'%。'}}
								</span>
							</li>
						</ul>

					</div>
					<div style="margin-top: 38px;">
						<p class="broadHeading">（二） 企业收入预测</p>

						<div class="forecastChart">
							<img src="../../assets/img/professionalReport/forecastBase.png" alt="底座" style="bottom:37px;">
							<DoubleCoordinateColumnLine :id="'companyIncomForcastChart'" v-if="initStatus" :chartData="startUpIncome"
								:year="startUpIncome.year" :chartColor="chartColor" />
						</div>
						<ul class="result-explain-container">
							<li>
								<span>注：</span>
								<span class="calculate_accordings" v-if="initStatus">{{pageInfo.startUpIncome.incomeDes}}</span>
							</li>
						</ul>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>5</span>
				</div>
			</div>
			<div class="page" id="page-valuationResult">
				<div class="page-content">
					<p class="broadCategories">四、估值结果</p>
					<div class="startupValuationResult">
						<div class="numberBox">
							<div v-for="(item, index) in valueResultNumber" :key="index + 1" class="locationUnit">
								<p class="unit" v-if="item.val==12" style="margin-right: 12px;">
									{{method.technologyNum(pageInfo.minValue).unit}}
								</p>
								<img :src="require('@/assets/img/figure/NSNumber/'+item.val+'.png')"
									:style="{marginTop:item.top,width:(item.val=='11'?'5px':item.val=='12'?'20px':item.val=='13'?'6px':'46px')}"
									alt="估值结果数字">
							</div>
							<p class="unit">{{method.technologyNum(pageInfo.maxValue).unit}}</p>
						</div>
					</div>
					<div class="cyberlook">
						<ul class="computingMethod">
							<li>
								<div>
									<div>
										<img src="../../assets/img/startup/priceMethod.png" alt="最近融资价格法">
										<p style="width: 100px;">最近融资价格法</p>
									</div>
								</div>
							</li>
							<li>
								<div>
									<p>估值结果</p>
								</div>
							</li>
							<li>
								<div>
									<div>
										<img src="../../assets/img/startup/flowIndex.png" alt="指数倍数修正区间">
										<p>指数倍数修正区间</p>
									</div>
								</div>
							</li>
						</ul>
						<div class="flowIndex">
							<p>指数倍数修正区间</p>
						</div>
						<ul>
							<li>
								<p>行业环境</p>
								<div></div>
							</li>
							<li style="top: 17px;">
								<p>管理团队</p>
								<div></div>
							</li>
							<li style="top: 23px;">
								<p>商业模式</p>
								<div></div>
							</li>
							<li style="top: 18px;">
								<p>产品竞争力</p>
								<div></div>
							</li>
							<li style="top: 1px;">
								<p>风险管理</p>
								<div></div>
							</li>
						</ul>
					</div>
					<p class="reportingBaseDate">报告基准日：{{pageInfo.reportBaseDate.replaceAll("-", ".")}}</p>

					<div style="position: relative;top: -40px;">
						<p class="textNoBottom">
							<i>系统采用先例交易法与初创企业估值图谱相结合的评价体系，充分考虑新兴行业特性，结合企业自身实际情况，科学客观地反映了企业价值。</i>
						</p>
						<p class="textNoBottom">
							<i>依据《私募投资基金非上市股权投资估值指引（试行）》，在估计非上市股权的公允价值时，通常使用的市场法包括参考最近融资价格法（先例交易法）、市场乘数法、行业指标法。由于初创企业通常尚未产生稳定的收入或利润，但融资活动一般比较频繁，因此本估值报告采用最近融资价格法（先例交易法）。</i>
						</p>
						<p class="textNoBottom">
							<i>
								最近融资价格法（先例交易法）是指选取主营业务相同或相似的交易案例，获取并分析可比公司近年的买卖、收购及合并案例资料，在与被估值单位比较分析的基础上，采用软性指标对估值结果进行修正，最终确定估值对象价值的具体方法。</i>
						</p>
						<p class="textNoBottom">
							<i>采用最近融资价格法（先例交易法）对交易案例进行调整时，主要业务指标包括但不限于有代表性的行业环境、管理团队、商业模式、产品竞争力、风险管理等，在选择主要业务指标时，考虑被投资企业所处行业特点及其自身的特点，选择最能反映被投资企业价值变化的业务指标。</i>
						</p>
					</div>

				</div>
				<div class="pageNum">
					<p>///</p><span>6</span>
				</div>
			</div>
			<div class="page" id="page-specialEvent">
				<div class="page-content">
					<div>
						<p class="broadCategories">五、特别事项说明和瑕疵事项</p>
						<p class="textNoBottom"><i>特别事项可能对估值结论产生影响，估值报告使用者应予以关注。</i></p>
						<p class="textNoBottom">
							<i>
								（一）在本报告所述估值目的下对估值对象进行价值测算，并不涉及到对该估值目的所对应的估值购买行为做出任何判断。估值工作很大程度上，依赖于被估值企业提供的有关资料。因此，估值工作是以被估值企业提供的财务数据、预测财务信息及审核人员核实信息时企业答复的真实、合法为前提。
							</i>
						</p>
						<p class="text stairText">
							<i>（二）本报告仅为{{pageInfo.baseInfo.companyName}}提供内部参考，不应当被认为是对估值企业可实现价格的保证。如果在实际交易中产生纠纷，{{pageInfo.channelName}}不承担任何法律责任。</i>
						</p>
					</div>
					<div>
						<p class="broadCategories">六、估值报告使用限制说明</p>
						<p class="textNoBottom"><i>（一）估值报告的估值结论仅为本报告所列明的估值目的和用途而服务。</i></p>
						<p class="textNoBottom"><i>（二）除非事前征得估值机构书面同意，估值报告的内容不得被摘抄、引用或披露于公开媒体，法律、法规规定以及相关当事方另有约定的除外。</i>
						</p>
						<p class="textNoBottom"><i>（三）本报告必须完整使用方为有效，对仅使用报告中部分内容导致的可能的损失，本公司不承担责任。</i></p>
						<p class="text stairText">
							<i>（四）估值报告使用者应当正确理解估值结论。估值结论不等同于估值对象可实现价格，估值结论不应当被认为是对估值对象可实现价格的保证。</i>
						</p>
					</div>
					<div>
						<p class="broadCategories">七、估值报告解释权</p>
						<p class="text"><i>本估值报告意思表达解释权属于出具报告的估值机构，除国家法律、法规有明确的特殊规定外，其他任何单位和部门均无权做出书面解释。</i></p>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>7</span>
				</div>
			</div>
			<div class="page" id="page-computational">
				<div class="page-content">
					<div class="accessoryNew">
						<span>附件</span>
						<div class="title">表1 企业支出预测表</div>
						<span></span>
					</div>
					<table class="table startUpForecastForm" id="expenditureForecastForm" border="1"
						style="border-collapse: collapse;margin-bottom: 112px;">
						<thead>
							<tr class="tableT">
								<th style="width: 50%;">项目</th>
								<th style="width: 50%;">预计支出比例</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item,i) in pageInfo.costExpenditure">
								<td>{{item.key}}</td>
								<td>{{item.value}}%</td>
							</tr>
							<tr data-class="totalFixedLiabilities">
								<td class="total">总计</td>
								<td class="total">100%</td>
							</tr>
						</tbody>
					</table>


					<div class="accessoryNew">
						<span>附件2</span>
						<div class="title">表2 企业收入预测表</div>
						<span></span>
					</div>
					<table class="table startUpForecastForm" id="revenueForecastForm" border="0"
						style="border-collapse: collapse;">
						<thead>
							<tr class="tableT">
								<th style="width: 33%;">年度</th>
								<th style="width: 33%;">{{startUpIncome.columnarCategory}}</th>
								<th style="width: 33%;">销售收入（万元）</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item,i) in startUpIncome.columnar">
								<td>{{startUpIncome.year[i]}}</td>
								<td>{{item}}</td>
								<td>{{startUpIncome.line[i].toFixed(2)}}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="pageNum">
					<p>///</p><span>8</span>
				</div>
			</div>
			<div class="page stockIntroduce" id="stockIntroduce" v-if="pageInfo.introImage">
				<div>
					<img style="width:100%;" :src="pageInfo.introImage" alt="简介">
				</div>
				<div class="pageNum">
					<span>9</span>
				</div>
			</div>

			<div class="page" id="page-statement">
				<div class="page-content">
					<Statement />
				</div>
				<div class="pageNum">
					<p>///</p><span class="isAnd_">{{pageInfo.introImage?10:9}}</span>
				</div>
			</div>
			<div class="page valueBackCoverImg" id="page-flow">
				<div class="page-content">
					<ContactWay :channelName="pageInfo.channelName" />
				</div>
			</div>




		</div>


		<div id="loading">
			<div class='base'>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
			</div>
		</div>
	</div>
</template>

<script>
	import qs from 'qs'
	import axios from 'axios'
	import PdfOnload from '@/utils/pdf'
	import tsconfig from '@/assets/js/tsconfig.json'

	import Draw3DPieCanvas from '@/common/echarts/draw3DPieCanvas.vue'
	import DrawRadarCanvas2 from '@/common/echarts/drawRadarCanvas2.vue'
	import DoubleCoordinateColumnLine from '@/common/echarts/doubleCoordinateColumnLine.vue'
	import Statement from '@/components/backCover/statement'
	import ContactWay from '@/components/backCover/contactWay'

	export default {
		props: {
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			url: {
				type: String
			},
			typeName: String

		},
		components: {
			PdfOnload,
			Draw3DPieCanvas,
			DrawRadarCanvas2,
			DoubleCoordinateColumnLine,
			Statement,
			ContactWay

		},
		data() {
			return {
				pdfArr: [],
				pdfIndex: 0,
				pageInfo: {
					reportBaseDate: '',
					baseInfo: {
						companyName: "",
						establishDate: ""
					},
					product: {
						productStage: '',
						productType: '',
						customerReaction: ''
					},
					team: {},
					finance: {},
					year: [],
					absoluteValuation: {
						ggm: ""
					},
					description: {
						quesStatement: '',
						financeStatement: ''
					},
					value: {
						minValue: '',
						maxValue: ''
					},
					holderRatio: [],
					costExpenditure: []
				},
				industryStatements: [], //行业环境
				teamManageStatements: [], //管理团队
				businessStatements: [], //商业模式
				technologyStatements: [], //产品竞争力
				ventureManageStatements: [], //风险管理
				startUpIncome: {
					columnar: [], //柱状
					line: [], //折线
					year: [],
					columnarUnit: '个',
					lineUnit: '万元',
					columnarCategory: '销售量（个）',
					lineCategory: '销售收入'
				},
				chartColor: {
					lightColorEight: ['#84AAFF', '#B9E4FF', '#D3BEFF', '#77A7FF', '#FFA8F3', '#BBFCFF', '#FEAD77', '#FFCE84'],
					darkColorEight: ['#2164FC', '#0091FF', '#9157FF', '#4385FF', '#FF64E9', '#4AF5FF', '#FF7F29', '#FCA521'],
					topColorEight: ['#6F9CFF', '#94D6FE', '#C9AEFF', '#6CA0FF', '#FF8FEF', '#91F9FF', '#FFA061', '#FFC66F'],
					bottomColorEight: ['#1459F4', '#008AF3', '#8848FF', '#3179FF', '#FF4CE6', '#00F1FF', '#FC7821', '#F49A14'],
				},
				valueResultNumber: []


			}
		},
		computed: {
			initStatus: function() {
				if (this.pageInfo.baseInfo.companyName) {
					return true;
				} else {
					return false;
				}
			}
		},
		mounted() {
			this.initPage()
		},
		methods: {
			exportPDF() {
				document.getElementById("loading").style.display = "block";
				var title = this.pageInfo.baseInfo.companyName + this.typeName + '报告.pdf'
				var pageArr = document.getElementsByClassName("page");
				for (var i = 0; i < pageArr.length; i++) {
					this.pdfArr.push(pageArr[i].id)
				}
				PdfOnload.getPdf(this.pdfArr, this.pdfIndex, title);

			},
			getIndex(array, keyToFind) {
				var index = array.findIndex(item => item.key === keyToFind)
				return index != -1 ? array[index].value : ''
			},
			initPage() {
				var that = this;
				this.$post(this.url + "valuationReport/showInitialReport", qs.stringify({
					reportId: this.$route.query.reportId ? this.$route.query.reportId : ""
				})).then(res => {
					var data = res.data;
					if (data.code == 200 && data.content) {
						that.pageInfo = data.content;

						// 股权
						var holderArr = [];
						var stockholders = Object.keys(this.pageInfo.holderRatio)

						for (var i = 0; i < stockholders.length; i++) {
							holderArr.push({
								name: stockholders[i],
								y: Number(that.pageInfo.holderRatio[stockholders[i]]),
							})
						};
						that.pageInfo.holderRatio = holderArr;



						// 行业环境
						for (var key in that.pageInfo.industry) { //得分
							that.industryStatements.push(that.pageInfo.industry[key].description);
						}
						for (var key in that.pageInfo.competitivePower) { //得分
							that.teamManageStatements.push(that.pageInfo.competitivePower[key].description);
						}
						// 商业模式
						for (var key in that.pageInfo.teamManage) { //得分
							that.businessStatements.push(that.pageInfo.teamManage[key].description);
						}
						// 产品竞争力
						for (var key in that.pageInfo.technology) { //得分
							that.technologyStatements.push(that.pageInfo.technology[key].description);
						}
						// 风险管理
						for (var key in that.pageInfo.ventureManage) { //得分
							that.ventureManageStatements.push(that.pageInfo.ventureManage[key].description);
						}

						// 企业支出预测

						// 企业收入预测
						this.pageInfo.startUpIncome.incomeList.map((item, i) => {
							this.startUpIncome.columnar.push(item.salesVolume)
							this.startUpIncome.line.push(item.salesIncome ? item.salesIncome : 0)
							this.startUpIncome.year.push(i == 0 ? item.year + '年' : item.year + 'E')

							if (item.salesVolume > 9999) {
								this.startUpIncome.columnarUnit = "万个"
							}

						})

						if (this.startUpIncome.columnarUnit == '万个') {
							this.startUpIncome.columnar.map(item => {
								item = item / 10000
							})

							this.startUpIncome.columnarCategory = this.pageInfo.startUpIncome.incomeList[0].incomeType == 1 ?
								'销售量（万个）' : '用户数（万个）'
						} else {
							this.startUpIncome.columnarCategory = this.pageInfo.startUpIncome.incomeList[0].incomeType == 1 ?
								'销售量（个）' : '用户数（个）'
						}

						// 设置估值结果页特殊数字
						var minArr = this.method.formateNum(that.pageInfo.value.minValue).split('');
						var maxArr = this.method.formateNum(that.pageInfo.value.maxValue).split('');
						minArr.push('12')
						var newArr = minArr.concat(maxArr);
						this.specialNumeric(newArr, "valueResultNumber")



					}

				})


			},
			// 日期格式化
			dateFormate: function(date) {
				if (date) {
					var dataStr = date.split("-");
					var newDataStr = dataStr[0] + "年" + dataStr[1] + "月" + dataStr[2] + "日";
					return newDataStr;
				}
			},
			// 估值结果格式化
			resultFormate: function(min, max) {
				var val = '';
				if (min) {
					return this.method.valuationResultConversion(min, max)
				} else {
					return '无数据'
				}
			},
			// 
			valueFormate: function(val) {
				if (val) {
					return this.method.resultConversion(val)
				} else {
					return '无数据'
				}
			},
			/*页脚日期格式转换*/
			replaceAll: function(val) {
				if (val) {
					return val.replace(new RegExp("-", "gm"), ".");
				}

			},
			specialNumeric: function(data, key, minus) { //minus==1 代表正数   minus==2代表负数
				console.log(data)
				if (minus == 2) {
					this[key].push({
						val: '12',
						top: "0px"
					})
				}
				data.forEach((val, i) => {
					if (val == ".") {
						this[key].push({
							val: "11",
							top: "62px",
						})
					} else if (val == ",") {
						this[key].push({
							val: "13",
							top: '55px',
						})
					} else {
						this[key].push({
							val: val,
							top: "0px"
						})
					}
				})
			},





		}

	}
</script>

<style scoped src="../../assets/css/reportValuation/professionReportNew.css"></style>
<style scoped lang="less">

</style>