<template>
	<div class="reportPage webReport">
		
		<SpecialtyReport :stage="stage" :type="type" :url="url" :typeName="typeName" />

	</div>
</template>

<script>
	import SpecialtyReport from '@/components/reportPage/specialtyValuation.vue'
	import '@/assets/css/reportCommon.css'

	export default {
		components: {
			SpecialtyReport
		},
		data() {
			return {
				url: this.domain.ajaxUrl + this.domain.systemName + this.domain.projectValuationName,
				stage: '2',
				type: '2',
				typeName: '成长期专业估值'

			}
		},
		mounted() {

		},
		methods: {
			

		}


	}
</script>

<style>
	
</style>