import qs from 'qs'
import axios from 'axios'

import {
	mapActions,
	mapMutations
} from 'vuex'

import {
	Modal,
	Input,
	DIV,
	Switch
} from 'iview'

export default {
	data() {
		return {
			isShow: false
		}
	},
	methods: {
		...mapActions([
			'getsAvatar',

		]),
		getInfo(setupType) {
			this.$Spin.show();
			this.$post(this.domain.dataRealization + 'pageSetup/getPageSetup', qs.stringify({
					channelCode: window.channel,
					setupType: setupType
				}))
				.then((res) => {
					var this_ = this
					this.pageSetup = JSON.parse(res.data.content.setupContent)
					
					// setTimeout(function(){
						this_.isShow = true
						this_.$Spin.hide();
					// },3000)
				

				});
		},
		openPdf(url) { //打开pdf
			window.open(url)
		},
		hexToRgb(hex, a) {
			// 去除 # 符号
			var color = hex.replace("#", "");

			// 分割 RGB 三原色部分
			var r = parseInt(color.substring(0, 2), 16);
			var g = parseInt(color.substring(2, 4), 16);
			var b = parseInt(color.substring(4, 6), 16);

			return `rgba(${r}, ${g}, ${b},${a})`;
		},
		handleMaxSize(file) {
			this.$Message.warning('上传的图片不能超过2M')
		},
		setUpAvatar(res) { //更改头像
			const headPortrait = this.domain.testUrl + this.domain.dataRealization + res.content.url
			this.getsAvatar({
				headPortrait
			})

			this.saveHeaderImg(res.content.url)
		},
		saveHeaderImg(imgPath) { //保存头像
			this.$post(this.domain.dataRealization + 'dataCustomer/saveHeaderImg', qs.stringify({
					headerImg: imgPath
				}))
				.then((res) => {

				});
		},
		getCaptcha(value) { //发送短信验证码
			const phone = value.trim()
			if (phone) {
				// 获取验证码
				this.$post(this.domain.dataRealization + 'secure/sendPhoneCodSecure', qs.stringify({
						phoneNum: value
					}))
					.then((res) => {
						res = res.data
						if (res.code == 200) {
							this.cooling = 60
							let coolingInter = setInterval(() => {
								if (this.cooling > 0) {
									this.cooling--
								} else {
									clearInterval(coolingInter)
								}
							}, 1000)
							// const xPhone = phone.slice(0, 3) + 'xxxx' + phone.slice(7)
							// this.$Notice.success({
							// 	title: '验证码',
							// 	desc: `验证码已发送至${xPhone}`,
							// 	duration: 5
							// })
						} else {
							this.$Message.error(res.message)
						}
					});
			} else {
				this.$Message.warning('请输入手机号')
			}

		},
		alert(text, empty, key) {
			Modal.warning({
				title: '提示',
				content: text,
				onOk: () => {
					if (empty) {
						this.authInfo[key] = ''
					}
					console.log('确认按钮被点击');
				}
			});
		},
		handleMaxSize(file) {
			this.$Message.warning('上传的图片不能超过2M')
		},
		upLogo(res, key) {
			this.data[key] = this.domain.testUrl + this.domain.dataRealization + res.content.url
		}



	}
}
