<template>
	<div :id="id"></div>
</template>

<script>
	import * as echarts from 'echarts'

	export default {
		props: [
			'chartData', 'id', 'chartColor'
		],
		data() {
			return {



			}
		},
		mounted() {
			this.initChart()

		},
		methods: {
			initChart() {
				var this_ = this
				var fontSize12 = 14;
				var barWidth = 40;
				var chartGrid = {
					left: '8%',
					right: '12%',
					bottom: '10%',
					top: '14%',
					containLabel: true
				}


				var option = {
					title: {
						show: true,
						text: '现金流趋势',
						textStyle: {
							color: '#282828',
							align: 'center'
						},
						left: 'center',
						top: 'top'
					},
					tooltip: {
						show: false,
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'none', // 默认为直线，可选为：'line' | 'shadow'
						},
						formatter: function(params) {
							// 只展示柱子对应的内容，把顶部底部的 tooltip 过滤掉
							return params.reduce((pre, i) => {
								if (i.componentSubType === "line") {
									i.marker = i.marker.replace(/\[object Object\]/, i.color);
									i.value =
										`<span style="flex: 1; text-align: right; margin-left: 16px;">${this_.method.formateNum(i.value.toString())}</span>`;
									const current =
										`<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.seriesName} ${i.value}</div>`;
									return `${pre}${current}`;
								}
								if (i.componentSubType === 'bar') {
									i.marker = i.marker.replace(/\[object Object\]/, i.color.colorStops[1].color);
									i.value =
										`<span style="flex: 1; text-align: right; margin-left: 16px;">${this_.method.formateNum(i.value.toString())}</span>`;
									const current =
										`<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.seriesName} ${i.value}</div>`;
									return `${pre}${current}`;
								}
								return pre;
							}, '');
						},
					},
					grid: chartGrid,
					legend: {
						data: [{
							name: 'FCFF',
							width: 20,
							itemWidth: 20,
							height: 12,
							itemStyle: {
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 1,
									y2: 0,
									colorStops: [{
										offset: 0,
										color: '#1432F4' // 0% 处的颜色
									}, {
										offset: 1,
										color: '#B1C7FB' // 100% 处的颜色
									}],
									global: false // 缺省为 false
								}
							}
						}, {
							name: 'FCFF增长率'
						}],
						left: '30%',
						bottom: '0',
						itemGap: 50
					},
					xAxis: {
						type: 'category',
						data: this.chartData.dataxAxis,
						axisTick: {
							show: false
						},
						axisLabel: {
							fontSize: fontSize12,
							color: '#282828',
							margin: 15
						},
						axisLine: {
							lineStyle: {
								color: '#282828'
							}
						}
					},
					yAxis: [{
							type: 'value',
							name: '人民币万元',
							nameTextStyle: { // 设置名称样式
								color: '#282828', // 文字颜色
								fontSize: fontSize12, // 字体大小
								padding: [0, 0, 0, -50]
							},
							axisLabel: {
								color: '#282828'
							},
							axisLine: {
								show: true,
								lineStyle: {
									color: '#282828'
								}
							},
						},
						{
							type: 'value',
							name: '%',
							splitLine: {
								show: false
							},
							axisLabel: {
								color: '#282828'
							},
							axisLine: {
								show: true,
								lineStyle: {
									color: '#282828'
								}
							}
						},
					],
					series: [{
							name: 'FCFF',
							data: this.chartData.columnar,
							type: 'bar',
							stack: 'FCFF',
							barMaxWidth: barWidth,
							label: {
								show: true,
								position: 'top',
								color: 'black',
								formatter: function(p) {
									return this_.method.formateNum(p.value.toString());
								}
							},
							itemStyle: {
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 1,
									y2: 0,
									colorStops: [{
										offset: 0,
										color: '#1432F4' // 0% 处的颜色
									}, {
										offset: 1,
										color: '#B1C7FB' // 100% 处的颜色
									}],
									global: false // 缺省为 false
								}
							}
						},
						{
							name: 'FCFF增长率',
							z: 15,
							data: this.chartData.line,
							type: 'line',
							yAxisIndex: 1,
							label: {
								show: true,
								position: [-30, -15],
								color: 'black',
								formatter: function(p) {
									return Number(p.value).toFixed(2);
								}
							},
							itemStyle: {
								color: "#EC6B1E",
							},
							symbol: 'circle', //设置此项则拐点为实心圆
							symbolSize: 8, //拐点圆的大小
						},
					],
				};
				var myChart = echarts.init(document.getElementById(this.id));
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);

			}


		}

	}
</script>

<style>
</style>