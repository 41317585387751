<template>
	<div>
		<table class="table liaTableInfo" border="0" style="border-collapse: collapse;">
			<thead>
				<tr class="noInput">
					<th style="width: 31%;">项目</th>
					<th style="width: 23%;" v-for="(item,index) in year" :key="index - 1">
						{{type==7?item: item.substring(0,4)+'-12-31'}}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="trOne">流动负债：</td>
					<td v-for="(item,index) in year" :key="index + 1"></td>
				</tr>
				<tr>
					<td class="trTwo">短期借款</td>
					<td v-for="(item,index) in year" :key="index + 2">
						{{judgingCondition('shortLoan',index)?method.formateNum(liaTableInfo.shortLoan[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">交易性金融负债</td>
					<td v-for="(item,index) in year" :key="index + 3">
						{{judgingCondition('transactionLiabilities',index)?method.formateNum(liaTableInfo.transactionLiabilities[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">衍生金融负债</td>
					<td v-for="(item,index) in year" :key="index + 4">
						{{judgingCondition('derivativeLiabilities',index)?method.formateNum(liaTableInfo.derivativeLiabilities[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">应付票据</td>
					<td v-for="(item,index) in year" :key="index + 5">
						{{judgingCondition('notesPayable',index)?method.formateNum(liaTableInfo.notesPayable[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">应付账款</td>
					<td v-for="(item,index) in year" :key="index + 6">
						{{judgingCondition('accountsPayable',index)?method.formateNum(liaTableInfo.accountsPayable[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">预收款项</td>
					<td v-for="(item,index) in year" :key="index + 7">
						{{judgingCondition('advanceReceipt',index)?method.formateNum(liaTableInfo.advanceReceipt[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">合同负债</td>
					<td v-for="(item,index) in year" :key="index + 8">
						{{judgingCondition('contractualLiabilities',index)?method.formateNum(liaTableInfo.contractualLiabilities[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">应付职工薪酬</td>
					<td v-for="(item,index) in year" :key="index + 9">
						{{judgingCondition('payrollPayable',index)?method.formateNum(liaTableInfo.payrollPayable[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">应交税费</td>
					<td v-for="(item,index) in year" :key="index + 10">
						{{judgingCondition('taxPayable',index)?method.formateNum(liaTableInfo.taxPayable[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">其他应付款</td>
					<td v-for="(item,index) in year" :key="index + 11">
						{{judgingCondition('otherPayables',index)?method.formateNum(liaTableInfo.otherPayables[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">持有待售负债</td>
					<td v-for="(item,index) in year" :key="index + 12">
						{{judgingCondition('saleForLiabilities',index)?method.formateNum(liaTableInfo.saleForLiabilities[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">一年内到期的非流动负债</td>
					<td v-for="(item,index) in year" :key="index + 13">
						{{judgingCondition('illiquidLiabilitiesOne',index)?method.formateNum(liaTableInfo.illiquidLiabilitiesOne[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">其他流动负债</td>
					<td v-for="(item,index) in year" :key="index + 14">
						{{judgingCondition('otherNonLiabilities',index)?method.formateNum(liaTableInfo.otherNonLiabilities[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trOne">流动负债合计</td>
					<td v-for="(item,index) in year" :key="index + 15">
						{{judgingCondition('totalNonLiabilities',index)?method.formateNum(liaTableInfo.totalNonLiabilities[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trOne">非流动负债：</td>
					<td v-for="(item,index) in year" :key="index + 16"></td>
				</tr>
				<tr>
					<td class="trTwo">长期借款</td>
					<td v-for="(item,index) in year" :key="index + 17">
						{{judgingCondition('longLoan',index)?method.formateNum(liaTableInfo.longLoan[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">应付债券</td>
					<td v-for="(item,index) in year" :key="index + 18">
						{{judgingCondition('bondsPayable',index)?method.formateNum(liaTableInfo.bondsPayable[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trThree">其中：优先股</td>
					<td v-for="(item,index) in year" :key="index + 19">
						{{judgingCondition('bondsPreferredStock',index)?method.formateNum(liaTableInfo.bondsPreferredStock[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trFour">永续债</td>
					<td v-for="(item,index) in year" :key="index + 20">
						{{judgingCondition('bondsPerpetuaDebt',index)?method.formateNum(liaTableInfo.bondsPerpetuaDebt[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">租赁负债</td>
					<td v-for="(item,index) in year" :key="index + 21">
						{{judgingCondition('leaseLiabilities',index)?method.formateNum(liaTableInfo.leaseLiabilities[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">长期应付款</td>
					<td v-for="(item,index) in year" :key="index + 22">
						{{judgingCondition('longPayment',index)?method.formateNum(liaTableInfo.longPayment[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">预计负债</td>
					<td v-for="(item,index) in year" :key="index + 23">
						{{judgingCondition('estimatedLiabilities',index)?method.formateNum(liaTableInfo.estimatedLiabilities[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">递延收益</td>
					<td v-for="(item,index) in year" :key="index + 24">
						{{judgingCondition('deferredIncome',index)?method.formateNum(liaTableInfo.deferredIncome[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">递延所得税负债</td>
					<td v-for="(item,index) in year" :key="index + 25">
						{{judgingCondition('deferredLiability',index)?method.formateNum(liaTableInfo.deferredLiability[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">其他非流动负债</td>
					<td v-for="(item,index) in year" :key="index + 26">
						{{judgingCondition('otherFixedLiabilities',index)?method.formateNum(liaTableInfo.otherFixedLiabilities[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trOne">非流动负债合计</td>
					<td v-for="(item,index) in year" :key="index + 27">
						{{judgingCondition('totalFixedLiabilities',index)?method.formateNum(liaTableInfo.totalFixedLiabilities[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trOne">负债总计</td>
					<td v-for="(item,index) in year" :key="index + 28">
						{{judgingCondition('totalLiabilities',index)?method.formateNum(liaTableInfo.totalLiabilities[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trOne">所有者权益（或股东权益）：</td>
					<td v-for="(item,index) in year" :key="index + 29"></td>
				</tr>
				<tr>
					<td class="trTwo">实收资本（或股本）</td>
					<td v-for="(item,index) in year" :key="index + 31">
						{{judgingCondition('paidUpCapital',index)?method.formateNum(liaTableInfo.paidUpCapital[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">其他权益工具</td>
					<td v-for="(item,index) in year" :key="index + 32">
						{{judgingCondition('otherEquity',index)?method.formateNum(liaTableInfo.otherEquity[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trThree">其中：优先股</td>
					<td v-for="(item,index) in year" :key="index + 33">
						{{judgingCondition('equityPreferredStock',index)?method.formateNum(liaTableInfo.equityPreferredStock[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trFour">永续债</td>
					<td v-for="(item,index) in year" :key="index + 34">
						{{judgingCondition('equityPerpetuaDebt',index)?method.formateNum(liaTableInfo.equityPerpetuaDebt[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">资本公积</td>
					<td v-for="(item,index) in year" :key="index + 35">
						{{judgingCondition('capitalReserve',index)?method.formateNum(liaTableInfo.capitalReserve[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">减：库存股</td>
					<td v-for="(item,index) in year" :key="index + 36">
						{{judgingCondition('treasuryShare',index)?method.formateNum(liaTableInfo.treasuryShare[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">其他综合收益</td>
					<td v-for="(item,index) in year" :key="index + 37">
						{{judgingCondition('otherIncome',index)?method.formateNum(liaTableInfo.otherIncome[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">专项储备</td>
					<td v-for="(item,index) in year" :key="index + 38">
						{{judgingCondition('appropriativeReserve',index)?method.formateNum(liaTableInfo.appropriativeReserve[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">盈余公积</td>
					<td v-for="(item,index) in year" :key="index + 39">
						{{judgingCondition('earnedSurplus',index)?method.formateNum(liaTableInfo.earnedSurplus[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">未分配利润</td>
					<td v-for="(item,index) in year" :key="index + 40">
						{{judgingCondition('undistributedProfit',index)?method.formateNum(liaTableInfo.undistributedProfit[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trTwo">归属于母公司所有者权益合计</td>
					<td v-for="(item,index) in year" :key="index + 41">
						{{judgingCondition('totalParentEquity',index)?method.formateNum(liaTableInfo.totalParentEquity[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trOne">少数股东权益</td>
					<td v-for="(item,index) in year" :key="index + 42">
						{{judgingCondition('minorityEquity',index)?method.formateNum(liaTableInfo.minorityEquity[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trOne">所有者权益（或股东权益）合计</td>
					<td v-for="(item,index) in year" :key="index + 43">
						{{judgingCondition('totalOwnershipInterest',index)?method.formateNum(liaTableInfo.totalOwnershipInterest[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trOne">负债和所有者权益（或股东权益）总计</td>
					<td v-for="(item,index) in year" :key="index + 44">
						{{judgingCondition('totalLiabilitiesEquity',index)?method.formateNum(liaTableInfo.totalLiabilitiesEquity[index],2):'-'}}
					</td>
				</tr>

			</tbody>
		</table>
	</div>
</template>
<script>
	export default ({
		props: {
			liaTableInfo: Object,
			year: Array,
			versions: [String, Number],
			stage: {
				type: [String, Number],
				required: false
			},
			type: {
				type: [String, Number],
				required: false
			}

		},
		data() {
			return {
				dateFormate: "12月31日"
			}
		},
		mounted() {
			if (this.versions == 1) {
				this.dateFormate = "";
			} else if (this.versions == 'trace') {
				this.dateFormate = "年12月31日"
			}
		},
		methods: {
			judgingCondition: function(key, i, index) {
				if (this.liaTableInfo[key][i] === 0) {
					return true
				} else if (this.liaTableInfo[key][i] != 0 && this.liaTableInfo[key][i] != '-' && this.liaTableInfo[key][i]) {
					return true
				} else {
					return false
				}
			},

		}
	})
</script>
<style scoped lang="less">
	.titleBox {
		margin: 130px 0 0 0;
		padding: 0px 110px;
		display: flex;
		justify-content: space-between;
	}

	.titleBox span {
		line-height: 100%;
		font-size: 20px;
	}

	.titleBox .title {
		text-align: center;
		font-size: 24px;
		font-weight: 400;
		color: #282828;
		margin: 130px 0 0 0;
		line-height: 100%;
	}

	table {
		width: 100%;
		text-align: center;
		border-collapse: collapse;
		margin: 28px auto 0;
		border: 1px solid #000;
	}

	table tr th {
		height: 50px;
		border: 1px solid #000;
		background: #0478fc;
		font-size: 18px;
		color: #fff;
	}

	table tr td {
		height: 33px;
		border: 1px solid #000;
		font-size: 14px;
		color: #282828;
	}

	table>tbody>tr:first-of-type>td {
		border-top: none;
	}

	table>tbody>tr>td:first-child {
		text-align: left;
	}

	.total {
		// background: rgba(20, 22, 244, 0.1);
		font-weight: bold;
	}

	.trOne {
		padding-left: 40px;
		padding-right: 10px;
		font-weight: bold;
	}

	.trTwo {
		padding-left: 57px;
	}

	.trThree {
		padding-left: 84px;
	}

	.trFour {
		padding-left: 125px;
	}

	.title {
		margin: 0 !important;
	}

	table>tbody>tr:not(:first-child) {
		td:not(:first-child) {
			text-align: right;
			padding-right: 38px;
		}
	}
</style>