<template>
	<div :id="id"></div>
</template>

<script>
	import * as echarts from 'echarts'

	export default {
		props: [
			'chartData', 'id'
		],
		data() {
			return {



			}
		},
		mounted() {
			this.initChart()

		},
		methods: {
			initChart() {
				var this_ = this
				var fontSize12 = 14;
				var barWidth = 40;
				var series = [];
				var leftColor1 = ['#29A3FF', '#4AF5FF', '#9863FE', '#5A94FF'],
					rightColor1 = ['#0091FF', '#00F1FF', '#8848FF', '#4385FF'],
					cubeTop1 = ['#4BB1FE', '#74F2FA', '#B089FF', '#77A7FF'];

				var lightColor1 = ['#CCEAFF', '#E2F9FA', '#D9C6FB', '#BAB8FF']
				var darkColor1 = ['#0260EE', '#00C8FF', '#A748FF', '#6843FF']

				var chartGrid = {
					left: '8%',
					right: '12%',
					bottom: '10%',
					top: '14%',
					containLabel: true
				}

				var data = this.chartData.data

				data.map((el, i) => {
					series.push({
						name: el.name,
						type: 'bar',
						barMaxWidth: barWidth,
						stack: '总量',
						label: {
							show: true,
							color: '#282828',
							position: 'inside',
							formatter: function(p) {
								var index = p.dataIndex;
								return this_.method.formateNum(el.value[index]);
							}
						},
						itemStyle: {
							color(params) {
								var index = params.dataIndex;
								return params.color = new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
									offset: 0,
									color: darkColor1[i]
								}, {
									offset: 1,
									color: lightColor1[i]
								}])
							}

						},
						data: el.value,
						z: 10,
					})


				})

				var option = {
					title: {
						show: true,
						text: '现金流预测',
						textStyle: {
							color: '#282828',
							align: 'center'
						},
						left: 'center',
						top: 'top'
					},
					animation: false,
					grid: chartGrid,
					tooltip: {
						show: false,
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'none', // 默认为直线，可选为：'line' | 'shadow'
						},
						formatter(params) {
							// 只展示柱子对应的内容，把顶部底部的 tooltip 过滤掉
							return params.reduce((pre, i) => {
								// console.log(i)
								if (i.componentSubType === "line") {
									i.marker = i.marker.replace(/\[object Object\]/, i.color);
									i.value =
										`<span style="flex: 1; text-align: right; margin-left: 16px;">${(isNaN(i.value) ? '0.00' : this_.method.formateNum(i.value.toString(), 2))}</span>`;
									const current =
										`<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.seriesName} ${i.value}</div>`;
									return `${pre}${current}`;
								}
								if (i.componentSubType === 'bar') {
									i.marker = i.marker.replace(/\[object Object\]/, i.color
										.colorStops[1].color);
									i.value =
										`<span style="flex: 1; text-align: right; margin-left: 16px;">${(isNaN(i.value) ? '0.00' : this_.method.formateNum(i.value.toString(), 2))}</span>`;
									const current =
										`<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.seriesName} ${i.value}</div>`;
									return `${pre}${current}`;
								}
								return pre;
							}, '');
						}
					},
					legend: {
						selectedMode: false,
						data: [{
							name: '净利润',
							width: 20,
							itemWidth: 20,
							height: 12,
							itemStyle: {
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 1,
									y2: 0,
									colorStops: [{
										offset: 0,
										color: darkColor1[0] // 0% 处的颜色
									}, {
										offset: 1,
										color: lightColor1[0] // 100% 处的颜色
									}],
									global: false // 缺省为 false
								}
							}
						}, {
							name: '折旧及摊销',
							width: 20,
							itemWidth: 20,
							height: 12,
							itemStyle: {
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 1,
									y2: 0,
									colorStops: [{
										offset: 0,
										color: darkColor1[1] // 0% 处的颜色
									}, {
										offset: 1,
										color: lightColor1[1] // 100% 处的颜色
									}],
									global: false // 缺省为 false
								}
							}
						}, {
							name: '资本性支出',
							width: 20,
							itemWidth: 20,
							height: 12,
							itemStyle: {
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 1,
									y2: 0,
									colorStops: [{
										offset: 0,
										color: darkColor1[2] // 0% 处的颜色
									}, {
										offset: 1,
										color: lightColor1[2] // 100% 处的颜色
									}],
									global: false // 缺省为 false
								}
							}
						}, {
							name: '营运资本增加',
							width: 20,
							itemWidth: 20,
							height: 12,
							itemStyle: {
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 1,
									y2: 0,
									colorStops: [{
										offset: 0,
										color: darkColor1[3] // 0% 处的颜色
									}, {
										offset: 1,
										color: lightColor1[3] // 100% 处的颜色
									}],
									global: false // 缺省为 false
								}
							}
						}],
						bottom: 0,
						textStyle: {
							fontSize: fontSize12
						}

					},
					xAxis: {
						type: 'category',
						data: this.chartData.dataxAxis,
						axisTick: {
							show: false
						},
						axisLabel: {
							fontSize: fontSize12,
							color: '#282828',
							margin: 15
						},
						axisLine: {
							lineStyle: {
								color: '#282828'
							}
						}
					},
					yAxis: {
						type: 'value',
						name: '人民币万元',
						nameTextStyle: { // 设置名称样式
							color: '#282828', // 文字颜色
							fontSize: fontSize12, // 字体大小
							padding: [0, 0, 0, -50]
						},
						splitLine: {
							show: false
						},
						axisLabel: {
							color: '#282828'
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#282828'
							}
						},
					},
					series: series
				};
				var myChart = echarts.getInstanceByDom(document.getElementById(this.id));
				if (myChart == undefined) {
					myChart = echarts.init(document.getElementById(this.id));
				}
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);


			}


		}

	}
</script>

<style>
</style>