<template>
	<div :id="id"></div>
</template>

<script>
	import * as echarts from 'echarts'

	export default {
		props: [
			'chartData', 'id', 'chartColor', 'newline'
		],
		data() {
			return {
				chartName: [],
				chartValue: []


			}
		},
		mounted() {
			for (var i in this.chartData) {
				this.chartName.push(this.setName(this.chartData[i].name));
				this.chartValue.push(this.chartData[i].value);
			};

			this.chartName = this.chartName.reverse()
			this.chartValue = this.chartValue.reverse()

			this.initChart()
		},
		methods: {
			setName(data) {
				var str = ''
				switch (data) {
					case "EBITDA":
						str = '息税折旧摊销前利润'
						break;
					case "市盈率":
						str = "P/E";
						break;
					case "市净率":
						str = "P/B";
						break;
					case "市销率":
						str = "P/S";
						break;
					case "市现率":
						str = "EV/EBITDA";
						break;
					case "企业价值/息税折旧摊销前利润":
						str = "EV/EBITDA";
						break;

					default:
						str = data
				}

				return str
			},
			initChart() {
				var this_ = this
				var fontSize12 = 14;
				
				switch (this.id) {
					case "barOne":
						var yAxisName = "万元";
						break;
					case "barThree":
						var yAxisName = "万元";
						break;
					case "barTwo":
						var yAxisName = "倍";
						break;
				}

				var barWidth = 30;

				var option = {
					tooltip: {
						trigger: 'none'
					},
					grid: { // 控制图的大小，调整下面这些值就可以，
						left: '2%',
						right: '13%',
						bottom: '10%',
						top: '12%',
						containLabel: true
					},
					xAxis: [{
						type: "value",
						nameTextStyle: { // 设置名称样式
							color: '#282828', // 文字颜色
							fontSize: fontSize12, // 字体大小
							padding: [0, 0, 10, 0]
						},
						axisTick: {
							alignWithLabel: true,
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#1459F4"
							}
						},
						axisLabel: {
							color: "#282828",
							fontSize: fontSize12,
							lineHeight: 20
						},
						splitLine: {
							show: false //网格线
						}

					}],
					yAxis: {
						type: "category",
						nameTextStyle: { // 设置名称样式
							color: '#282828', // 文字颜色
							fontSize: fontSize12, // 字体大小
							padding: [0, 0, 0, -50]
						},
						data: this.chartName,
						axisLabel: {
							color: "#282828",
							fontSize: fontSize12,
							lineHeight: 20,
							margin: 20,
							formatter: function(params) {
								var str = ""; // 最终拼接成的字符串
								var paramsLen = params.length; // 获取每项文字的个数
								var len = this_.newline; // 每行能显示的字的个数（根据实际情况自己设置）
								var rowNumber = Math.ceil(paramsLen / len); // 换行的话，需要显示几行，向上取整
								if (paramsLen > len) { //大于设定的len就换行，不大于就不变化
									for (var i = 0; i < rowNumber; i++) {
										var temp = ""; // 表示每一次截取的字符串
										var start = i * len; // 开始截取的位置
										var end = start + len; // 结束截取的位置
										if (i == rowNumber - 1) { // 最后一次不换行
											temp = params.substring(start, paramsLen);
										} else { // 每一次拼接字符串并换行
											temp = params.substring(start, end) + "\n";
										}
										str += temp; // 最终拼成的字符串
									}
								} else { // 给新的字符串赋值
									str = params;
								}
								return str; //返回字符串
							},

						},
						axisTick: {
							alignWithLabel: true,
						},
						axisLine: {
							show: true, //轴线
							lineStyle: {
								color: "#1459F4"
							}
						},
						splitLine: {
							show: false //网格线
						}
					},
					series: [{
						name: "数据上椭圆",
						type: 'pictorialBar',
						symbolSize: [12, barWidth],
						symbolPosition: 'end',
						z: 12,
						label: {
							show: true,
							position: 'inside',
							fontSize: fontSize12,
							color: '#282828',
							padding: [0, 0, 5, 0],
							formatter: function(params, index) {
								return this_.method.formateNum(params.value);
							}
						},
						itemStyle: {
							color(params) {
								var colorList = this_.chartColor.topColor
								var index = params.dataIndex;
								return params.color = colorList.slice(0, 4).reverse()[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: this.method.pictorialBarTop(this.chartValue, 6, true)
					}, {
						name: '下椭圆',
						type: 'pictorialBar',
						symbolSize: [12, barWidth],
						symbolOffset: [-6, 0],
						symbolPosition: 'start',
						z: 12,
						itemStyle: {
							color(params) {
								var colorList = this_.chartColor.bottomColor
								var index = params.dataIndex;
								return params.color = colorList.slice(0, 4).reverse()[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: this.method.pictorialBarBottom(this.chartValue, 6, true)
					}, {
						type: 'bar',
						barWidth: barWidth,
						z: 11,
						barCategoryGap: 10,
						itemStyle: {
							color(params) {
								var colorList = this_.method.setGradientColor(this_.chartValue, this_.chartColor.darkColor, this_
									.chartColor
									.lightColor, true)
								var index = params.dataIndex;
								return params.color = colorList.slice(0, 4).reverse()[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: this.chartValue,
					}]
				};
				var myChart = echarts.init(document.getElementById(this.id));
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);

			}


		}

	}
</script>

<style>
</style>