<template>
	<div>
		<table class="table" border="0" style="border-collapse: collapse;">
			<thead>
				<tr class="noInput">
					<th style="width: 38%;">项目</th>
					<th style="width: 21%;" v-for="(item,index) in year" :key="index - 1">
						{{type==7?item: item.substring(0,4)+'年度'}}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr class="total">
					<td class="trOne">一、营业收入</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{judgingCondition('income',index)?method.formateNum(profitTableInfo.income[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">减：营业成本</td>
					<td v-for="(item,index) in year" :key="index + 2">
						{{judgingCondition('cost',index)?method.formateNum(profitTableInfo.cost[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trThree">税金及附加</td>
					<td v-for="(item,index) in year" :key="index + 3">
						{{judgingCondition('taxAdditional',index)?method.formateNum(profitTableInfo.taxAdditional[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trThree">销售费用</td>
					<td v-for="(item,index) in year" :key="index + 4">
						{{judgingCondition('saleCost',index)?method.formateNum(profitTableInfo.saleCost[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trThree">管理费用</td>
					<td v-for="(item,index) in year" :key="index + 5">
						{{judgingCondition('manageCost',index)?method.formateNum(profitTableInfo.manageCost[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trThree">研发费用</td>
					<td v-for="(item,index) in year" :key="index + 6">
						{{judgingCondition('researchCost',index)?method.formateNum(profitTableInfo.researchCost[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trThree">财务费用</td>
					<td v-for="(item,index) in year" :key="index + 7">
						{{judgingCondition('financeCost',index)?method.formateNum(profitTableInfo.financeCost[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trFour">其中：利息支出</td>
					<td v-for="(item,index) in year" :key="index + 8">
						{{judgingCondition('interestCost',index)?method.formateNum(profitTableInfo.interestCost[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trFive">利息收入</td>
					<td v-for="(item,index) in year" :key="index + 9">
						{{judgingCondition('interestIncome',index)?method.formateNum(profitTableInfo.interestIncome[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">加：其他收益</td>
					<td v-for="(item,index) in year" :key="index + 10">
						{{judgingCondition('otherBenefits',index)?method.formateNum(profitTableInfo.otherBenefits[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trThree">投资收益(损失以"-"号填列)</td>
					<td v-for="(item,index) in year" :key="index + 11">
						{{judgingCondition('investmentIncome',index)?method.formateNum(profitTableInfo.investmentIncome[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trFour">其中：对联营企业和合营企业的投资收益</td>
					<td v-for="(item,index) in year" :key="index + 12">
						{{judgingCondition('enterpriseIncome',index)?method.formateNum(profitTableInfo.enterpriseIncome[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trFive">以摊余成本计量的金融资产终止确认收益（损失以"-"号填列）</td>
					<td v-for="(item,index) in year" :key="index + 13">
						{{judgingCondition('financialAssetsBenefits',index)?method.formateNum(profitTableInfo.financialAssetsBenefits[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trThree">净敞口套期收益（损失以"-"号填列）</td>
					<td v-for="(item,index) in year" :key="index + 14">
						{{judgingCondition('netExposureHedgeIncome',index)?method.formateNum(profitTableInfo.netExposureHedgeIncome[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trThree">公允价值变动收益（损失以"-"号填列）</td>
					<td v-for="(item,index) in year" :key="index + 14">
						{{judgingCondition('fairValueIncome',index)?method.formateNum(profitTableInfo.fairValueIncome[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trThree">信用减值损失（损失以"-"号填列）</td>
					<td v-for="(item,index) in year" :key="index + 14">
						{{judgingCondition('creditImpairmentLoss',index)?method.formateNum(profitTableInfo.creditImpairmentLoss[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trThree">资产减值损失（损失以"-"号填列）</td>
					<td v-for="(item,index) in year" :key="index + 15">
						{{judgingCondition('assetLoss',index)?method.formateNum(profitTableInfo.assetLoss[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trThree">资产处置收益（损失以"-"号填列）</td>
					<td v-for="(item,index) in year" :key="index + 16">
						{{judgingCondition('assetDisposalIncome',index)?method.formateNum(profitTableInfo.assetDisposalIncome[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trOne">二、营业利润（亏损以"-"号填列）</td>
					<td v-for="(item,index) in year" :key="index + 17">
						{{judgingCondition('businessProfit',index)?method.formateNum(profitTableInfo.businessProfit[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">加：营业外收入</td>
					<td v-for="(item,index) in year" :key="index + 18">
						{{judgingCondition('outsideIncome',index)?method.formateNum(profitTableInfo.outsideIncome[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">减：营业外支出</td>
					<td v-for="(item,index) in year" :key="index + 19">
						{{judgingCondition('outsideExpenditure',index)?method.formateNum(profitTableInfo.outsideExpenditure[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trOne">三、利润总额（亏损总额以"-"号填列）</td>
					<td v-for="(item,index) in year" :key="index + 20">
						{{judgingCondition('totalProfit',index)?method.formateNum(profitTableInfo.totalProfit[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">减：所得税费用</td>
					<td v-for="(item,index) in year" :key="index + 21">
						{{judgingCondition('incomeTax',index)?method.formateNum(profitTableInfo.incomeTax[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trOne">四、净利润（净亏损以"-"号填列）</td>
					<td v-for="(item,index) in year" :key="index + 22">
						{{judgingCondition('netProfit',index)?method.formateNum(profitTableInfo.netProfit[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">（一） 按经营持续性分类</td>
					<td v-for="(item,index) in year" :key="index + 23"></td>
				</tr>
				<tr>
					<td class="trSix">1.持续经营净利润（净亏损以"-"号填列）</td>
					<td v-for="(item,index) in year" :key="index + 24">
						{{judgingCondition('goingConcernNetProfit',index)?method.formateNum(profitTableInfo.goingConcernNetProfit[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trSix">2.终止经营净利润（净亏损以"-"号填列）</td>
					<td v-for="(item,index) in year" :key="index + 25">
						{{judgingCondition('discontinuedOperationsNetProfit',index)?method.formateNum(profitTableInfo.discontinuedOperationsNetProfit[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">（二） 按所有权归属分类</td>
					<td v-for="(item,index) in year" :key="index + 26"></td>
				</tr>
				<tr>
					<td class="trSix">1.归属于母公司股东的净利润（净亏损以"-"号填列）</td>
					<td v-for="(item,index) in year" :key="index + 27">
						{{judgingCondition('parentCompanyNetProfit',index)?method.formateNum(profitTableInfo.parentCompanyNetProfit[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trSix">2.少数股东损益（净亏损以"-"号填列）</td>
					<td v-for="(item,index) in year" :key="index + 28">
						{{judgingCondition('minorityStockLegalRight',index)?method.formateNum(profitTableInfo.minorityStockLegalRight[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trOne">五、其他综合收益的税后净额</td>
					<td v-for="(item,index) in year" :key="index + 29">
						{{judgingCondition('ociNetTax',index)?method.formateNum(profitTableInfo.ociNetTax[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trTwo">（一）归属于母公司所有者的其他综合收益的税后净额</td>
					<td v-for="(item,index) in year" :key="index + 30">
						{{judgingCondition('parentCompanyOciNetTax',index)?method.formateNum(profitTableInfo.parentCompanyOciNetTax[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trSix">1.不能重分类进损益的其他综合收益</td>
					<td v-for="(item,index) in year" :key="index + 31">
						{{judgingCondition('notReclassifiedOci',index)?method.formateNum(profitTableInfo.notReclassifiedOci[index],2):'-'}}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
	export default ({
		props: {
			profitTableInfo: Object,
			year: Array,
			versions: [String, Number],
			stage: {
				type: [String, Number],
				required: false
			},
			type: {
				type: [String, Number],
				required: false
			}

		},
		data() {
			return {
				dateFormate: "12月31日"
			}
		},
		mounted() {
			if (this.versions == 1) {
				this.dateFormate = "";
			} else if (this.versions == 'trace') {
				this.dateFormate = "年12月31日"
			}
		},
		methods: {
			judgingCondition: function(key, i, index) {
				if (this.profitTableInfo[key][i] === 0) {
					return true
				} else if (this.profitTableInfo[key][i] != 0 && this.profitTableInfo[key][i] != '-' && this.profitTableInfo[
						key][i]) {
					return true
				} else {
					return false
				}
			},

		}
	})
</script>
<style scoped lang="less">
	.titleBox {
		margin: 130px 0 0 0;
		padding: 0px 110px;
		display: flex;
		justify-content: space-between;
	}

	.titleBox span {
		line-height: 100%;
		font-size: 20px;
	}

	.titleBox .title {
		text-align: center;
		font-size: 24px;
		font-weight: 400;
		color: #282828;
		margin: 130px 0 0 0;
		line-height: 100%;
	}

	table {
		width: 100%;
		text-align: center;
		border-collapse: collapse;
		margin: 28px auto 0;
		border: 1px solid #000;
	}

	table tr th {
		height: 50px;
		border: 1px solid #000;
		background: #0478fc;
		font-size: 18px;
		color: #fff;
	}

	table tr td {
		height: 43px;
		border: 1px solid #000;
		font-size: 14px;
		color: #282828;
	}

	table>tbody>tr:first-of-type>td {
		border-top: none;
	}

	table>tbody>tr>td:first-of-type {
		text-align: left;
		padding-right: 10px;
	}

	.total {
		// background: rgba(20, 22, 244, 0.1);
		font-weight: bold;
	}

	.trOne {
		padding-left: 40px;
		font-weight: bold;
	}

	.trTwo {
		padding-left: 70px;
	}

	.trThree {
		padding-left: 101px;
	}

	.trFour {
		padding-left: 131px;
	}

	.trFive {
		padding-left: 178px;
	}

	.trSix {
		padding-left: 116px;
	}

	.trSeven {
		padding-left: 122px;
	}

	.title {
		margin: 0 !important;
	}

	table>tbody>tr {
		td:not(:first-child) {
			text-align: right;
			padding-right: 38px;
		}
	}
</style>