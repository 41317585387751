<template>
	<div id="main">
		<Header />
		<Banner />

		<div class="mainContent">
			<MyCenterMenu ref="menu" :activeName="$route.meta.name" @getName="getName" />

			<div class="userinfo">
				<Search :titleInfo="searchTitle" :url="domain.dataRealization+'productOrder/getProductOrderList'"
					:parameter="parameter" :allStatus="allStatus" @getList="getList" />

				<div v-if="records.length>0">
					<ul class="boxhead">
						<li style="width: 58%;">订单详情</li>
						<li style="width: 7%;">数量</li>
						<li style="width: 13%;">金额</li>
						<li style="width: 12%;">状态</li>
						<li style="width: 10%;">操作</li>
					</ul>
					<ul class="orderDataList">
						<li v-for="(item,index) in records" :key="index"
							:style="{'border':'1px solid '+hexToRgb($store.state.user.subjectColor.mainColor, '0.5'),'boxShadow':'0px 0px 13px 0px '+hexToRgb($store.state.user.subjectColor.mainColor, '0.1')}">
							<p class="orderTime"
								:style="{'background':hexToRgb($store.state.user.subjectColor.mainColor, '0.1'),'borderBottom':'1px solid '+hexToRgb($store.state.user.subjectColor.mainColor, '0.5')}">
								<span>{{item.createTime}}</span>
								<span>订单编号：{{item.id}}</span>
							</p>
							<div>
								<div style="width: 58%;"
									:style="{'borderRight':'1px solid '+hexToRgb($store.state.user.subjectColor.mainColor, '0.5'),'display':'flex','alignItems':'center'}">
									<img :src="item.logo" class="orderLogo" />
									<div>
										<p>{{item.productName}}</p>
										<p>{{item.companyName}}</p>
									</div>
								</div>
								<div style="width: 7%;"
									:style="{'borderRight':'1px solid '+hexToRgb($store.state.user.subjectColor.mainColor, '0.5')}">
									x{{item.num}}</div>
								<div style="width: 13%;"
									:style="{'borderRight':'1px solid '+hexToRgb($store.state.user.subjectColor.mainColor, '0.5')}">
									{{item.discuss?'面议':'￥'+method.formateNum(item.price,2)+'/次'}}
								</div>
								<div style="width: 12%;"
									:style="{'borderRight':'1px solid '+hexToRgb($store.state.user.subjectColor.mainColor, '0.5')}">
									<button class="waitConfirmation" v-if="item.orderType==0">等待确认</button>
									<button class="confirmed" v-if="item.orderType==1">已确认</button>
									<button class="completed" v-if="item.orderType==2">已完成</button>
									<button class="fackOf" v-if="item.orderType==3">交易关闭</button>
								</div>
								<div style="width: 10%;">
									<div>
										<button class="examine" @click="checkOrder(item.id)">查看详情</button>
										<button class="cancelOrder" v-if="item.orderType==0"
											@click="confirmOrder(item.id,index,3)">取消订单</button>
										<button class="orderCompletion" v-if="item.orderType==1"
											@click="confirmOrder(item.id,index,2)">订单完成</button>
									</div>
								</div>
							</div>
						</li>
					</ul>

					<Page :current="currentPageNumber" :total="total" :page-size="pageSize" prev-text="上一页" next-text="下一页"
						@on-change="pageChange" />

				</div>
				<div v-else class="nothing">
					<img v-if="$store.state.user.subjectColor.mainColor=='#d9001b'"
						:src="require('@/assets/img/img_nothing_red.png')" alt="暂无信息" />
					<img v-else-if="$store.state.user.subjectColor.mainColor=='#00bfbf'"
						:src="require('@/assets/img/img_nothing_green.png')" alt="暂无信息" />
					<img v-else-if="$store.state.user.subjectColor.mainColor=='#f59a23'"
						:src="require('@/assets/img/img_nothing_orange.png')" alt="暂无信息" />
					<img v-else :src="require('@/assets/img/img_nothing.png')" alt="暂无信息" />
					<p>暂无信息</p>
				</div>


			</div>
		</div>


		<Modal v-model="modal1" width="600">
			<template #header>
				<span style="color:#282828;font-size: 20px;font-weight: bold;">取消订单</span>
			</template>
			<div style="text-align:center">
				<img :src="require('@/assets/img/myCenter/succeed.png')" />
				<p style="font-size: 18px;font-weight: bold;color: #282828;margin-top: 29px;">下单取消成功</p>
			</div>
			<template #footer>
				<button @click="cancelSuccessfully"
					style="width: 160px;height: 40px;background: #1459F4;border-radius: 6px;font-size: 14px;color: #fff;margin-bottom:20px;cursor: pointer;">我的订单</button>
			</template>
		</Modal>

		<Modal v-model="modal2" width="600">
			<template #header>
				<span style="color:#282828;font-size: 20px;font-weight: bold;">取消订单</span>
			</template>
			<div style="text-align:center">
				<img :src="require('@/assets/img/myCenter/cancel.png')" />
				<p style="font-size: 18px;color: #282828;margin-top: 29px;">确定要取消订单吗？</p>
			</div>
			<template #footer>
				<button @click="cancelOrder"
					style="width: 160px;height: 40px;background: #1459F4;border-radius: 6px;font-size: 14px;color: #fff;margin-bottom:20px;cursor: pointer;margin-right: 40px;">确定</button>
				<button @click="close"
					style="width: 160px;height: 40px;background: #fff;border-radius: 6px;font-size: 14px;color: #B3B3B3;margin-bottom:20px;cursor: pointer;border: 1px solid #B3B3B3;">取消</button>
			</template>
		</Modal>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '@/views/publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import MyCenterMenu from '@/components/myCenterMenu/index.vue'
	import Banner from '@/views/myCenter/banner.vue'
	import Search from '@/components/listSearch/index.vue'
	import qs from 'qs'
	import axios from 'axios'

	import '../personalCenter.less'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			MyCenterMenu,
			Banner,
			Search,
		},
		data() {
			return {
				searchTitle: {},
				name: '',
				records: [],
				currentPageNumber: 1, //当前页码
				total: 0, //总页数
				pageSize: 4, //每页条数
				status: '',
				parameter: {
					pageNum: 1,
					pageSize: 4,
					status: '',
					name: '',
					orderFlag: 1 //（买家：1，卖家：2）
				},
				allStatus: [{ //订单状态  0：等待确认，1：已确认，2：已完成，3：已取消（交易关闭）
					state: '全部状态',
					statusCode: ''
				}, {
					state: '等待确认',
					statusCode: 0
				}, {
					state: '已确认',
					statusCode: 1
				}, {
					state: '已完成',
					statusCode: 2
				}, {
					state: '交易关闭',
					statusCode: 3
				}],
				myAlert: false,
				modal1: false,
				modal2: false,
				cancellationOrder: ''

			}
		},
		mounted() {
			if (this.$route.params.num) {
				this.currentPageNumber = Number(this.$route.params.num)
			}

			this.findDataProduct(this.currentPageNumber)
		},
		methods: {
			getName(data) { //获取搜索栏的信息
				console.log(data)
				this.searchTitle = data
			},
			getList(data) { //获取列表信息
				this.records = data.records
				this.name = data.name
				this.status = data.status
				this.total = data.total
			},
			pageChange(data) { //切换页数
				console.log(data)
				this.currentPageNumber = data
				this.findDataProduct(data)
			},
			findDataProduct(pageNum) { //订单状态  0：等待确认，1：已确认，2：已完成，3：已取消（交易关闭）
				this.$post(this.domain.dataRealization + 'productOrder/getProductOrderList', qs.stringify({
						pageNum: pageNum,
						pageSize: this.pageSize,
						status: this.status,
						name: this.name,
						orderFlag: 1, //1:买家 0：卖家
						channelFlag: '0' // 0:个人，1：渠道
					}))
					.then((res) => {
						this.records = res.data.content.records
						this.total = res.data.content.total
					});
			},
			confirmOrder(id, i, orderType) { //确认订单
				if (orderType == 3) {
					this.modal2 = true
					this.cancellationOrder = i
				} else {
					this.$post(this.domain.dataRealization + 'productOrder/updateProductOrder', qs.stringify({
							orderId: id,
							orderType: orderType
						}))
						.then((res) => {
							if (res.data.code == 200) {
								this.$Message.success('操作成功')
								this.records[i].orderType = orderType
							}
						});
				}

			},
			cancelOrder() {
				this.$post(this.domain.dataRealization + 'productOrder/updateProductOrder', qs.stringify({
						orderId: this.records[this.cancellationOrder].id,
						orderType: 3
					}))
					.then((res) => {
						if (res.data.code == 200) {
							this.modal1 = true
							this.modal2 = false
						}
					});
			},
			checkOrder(id) {
				this.$router.push({
					name: 'orderDetails',
					query: {
						id: id + '-' + this.currentPageNumber
					}
				})
			},
			close() {
				this.modal2 = false
			},
			cancelSuccessfully() {
				this.modal1 = false
				this.records[this.cancellationOrder].orderType = 3
			}


		}
	}
</script>

<style scoped lang="less">
	.mainContent {
		margin-top: 75px;
		display: flex;
		justify-content: space-between;
	}

	/deep/ .ivu-icon-ios-close:before {
		font-size: 40px;
		font-weight: bold;
		color: #555555;
	}

	/deep/ .ivu-modal-close .ivu-icon-ios-close {
		top: -1px;
	}

	/deep/ .ivu-modal-footer {
		text-align: center;
	}

	/deep/ .ivu-modal-header {
		border: none;
	}

	/deep/ .ivu-modal-header::after {
		content: '';
		display: block;
		width: 100%;
		margin: 0 auto;
		height: 1px;
		background: #B3B3B3;
		margin-top: 18px;
	}
</style>