<template>
	<div :id="id"></div>
</template>
<script>
	import * as echarts from 'echarts/lib/echarts.js'


	export default ({
		props: {
			chartData: Object,
			id: String,
			chartColor: {
				type: Object,
				required: false
			}

		},
		data() {
			return {
				chartName: [],
				chartValue: []


			}
		},
		mounted() {
			this.initChart()
		},
		methods: {
			initChart: function() {
				this.chart = echarts.init(document.getElementById(this.id));
				this.setOptions(this.id)
			},
			setOptions: function(id, dataxAxis, data) {
				var this_ = this
				var barWidth = 50;


				var option = {
					legend: {
						data: [{
							name: this.chartData.columnarCategory,
							icon: 'image://' + require('@/assets/img/startup/forecastIcon.png')
						}, {
							name: '销售收入'
						}],
						left: '40%',
						bottom: '0',
						itemGap: 50
					},
					tooltip: {
						trigger: 'axis',
						confine: true,
						axisPointer: {
							type: 'none'
						},
						textStyle: {
							fontSize: 12,
							fontWeight: 500
						},
						formatter: function(params) {
							// 只展示柱子对应的内容，把顶部底部的 tooltip 过滤掉
							return params.reduce((pre, i) => {
								if (i.componentSubType === "line") {
									i.marker = i.marker.replace(/\[object Object\]/, i.color);
									i.value =
										`<span style="flex: 1; text-align: right; margin-left: 16px;">${this_.method.formateNum(i.value.toString(),2)}</span>`;
									const current =
										`<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.seriesName} ${i.value}</div>`;
									return `${pre}${current}`;
								}
								if (i.componentSubType === 'bar') {
									i.marker = i.marker.replace(/\[object Object\]/, i.color.colorStops[1].color);
									i.value =
										`<span style="flex: 1; text-align: right; margin-left: 16px;">${this_.method.formateNum(i.value.toString(),2)}</span>`;
									const current =
										`<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.seriesName} ${i.value}</div>`;
									return `${pre}${current}`;
								}
								return pre;
							}, '');
						}
					},
					grid: {
						left: '8%',
						right: '6%',
						bottom: '15%',
						top: '14%',
						containLabel: true
					},
					animation: false,
					xAxis: {
						type: 'category',
						data: this.chartData.year,
						axisLine: {
							lineStyle: {
								color: '#000'
							}
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							color: '#585D63',
							fontSize: 16, // 字体大小
							margin: 20
						},
					},
					yAxis: [{
						axisLine: {
							lineStyle: {
								color: '#111111'
							},
							show: true
						},
						name: this.chartData.columnarUnit,
						nameTextStyle: { // 设置名称样式
							color: '#282828', // 文字颜色
							fontSize: 16, // 字体大小
						},
						axisTick: {
							show: true
						},
						axisLabel: {
							color: '#111',
							fontSize: 16
						},
						splitLine: {
							show: false
						}
					}, {
						axisLine: {
							lineStyle: {
								color: '#111111'
							},
							show: true
						},
						name: '人民币' + this.chartData.lineUnit,
						nameTextStyle: { // 设置名称样式
							color: '#282828', // 文字颜色
							fontSize: 16, // 字体大小
							padding: [0, -52, 0, 0]
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							color: '#111',
							fontSize: '16'
						},
						splitLine: {
							lineStyle: {
								color: '#E1E1E1'
							}
						},

					}],
					series: [{
							name: this.chartData.columnarCategory,
							type: 'bar',
							barWidth: barWidth,
							barCategoryGap: 12,
							data: this.chartData.columnar,
							itemStyle: {
								color(params) {
									var colorList = this_.method.setGradientColor(this_.chartData.columnar, this_.chartColor
										.lightColorEight, this_.chartColor.darkColorEight)
									var index = params.dataIndex;
									return params.color = colorList[0]
								}
							}
						},
						{
							name: '上椭圆',
							type: 'pictorialBar',
							symbolSize: [barWidth, 16],
							// 这个属性很重要，直接决定了顶部跟柱子是否契合
							z: 12,
							label: {
								show: true,
								position: 'top',
								fontSize: 16,
								color: '#282828',
								padding: [0, 0, 7, 0],
								formatter: function(params, index) {
									return this_.method.formateNum(this_.chartData.columnar[params.dataIndex].toString());
								}
							},
							itemStyle: {
								color(params) {
									var colorList = this_.chartColor.topColorEight
									var index = params.dataIndex;
									return params.color = colorList[0]
								}
							},
							symbolPosition: 'end',
							// 要给成武汉这两个柱子的值，这是圆柱顶部，值不给对高度会对不上
							data: this_.method.pictorialBarTop(this_.chartData.columnar, 8)
						},
						{
							name: '下椭圆',
							type: 'pictorialBar',
							symbolSize: [barWidth, 16],
							z: 12,
							itemStyle: {
								color(params) {
									var colorList = this_.chartColor.bottomColorEight
									var index = params.dataIndex;
									return params.color = colorList[0]
								}
							},
							emphasis: {
								disabled: true
							},
							data: this_.method.pictorialBarBottom(this_.chartData.columnar, 8)
						},
						{
							name: this.chartData.lineCategory,
							type: 'line',
							z: 12,
							data: this.chartData.line,
							symbol: 'circle', //设定为实心点
							symbolSize: 6, //设定实心点的大小
							yAxisIndex: 1, //选择纵坐标的第几个
							label: {
								show: true,
								fontSize: 14,
								color: '#111',
								position: [-50, 15], //在上方显示
								formatter: function(p) {
									return this_.method.formateNum(p.value.toString(), 2);
								}
							},
							itemStyle: {
								color: "#EC6B1F"
							},
							textStyle: {
								shadowBlur: 0,
								shadowColor: 'transparent'
							}
						}

					]
				}
				var myChart = echarts.getInstanceByDom(document.getElementById(id));
				if (myChart == undefined) {
					myChart = echarts.init(document.getElementById(id));
				}
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
			},
			pictorialBar2: function(data) {
				var arr = [];
				data.forEach(function(ele, i) {
					if (ele < 0) {
						arr[i] = {
							value: ele,
							symbolOffset: [0, 12],
							symbolPosition: 'end'
						}
					} else {
						arr[i] = {
							value: ele,
							symbolOffset: [0, -12],
							symbolPosition: 'end'
						}
					}
				});
				return arr
			}
		}
	})
</script>
<style scoped>
</style>