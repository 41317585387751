<template>
	<div>
		<table class="table" border="0" style="border-collapse: collapse;">
			<thead>
				<tr class="noInput">
					<th>项目</th>
					<th v-for="(item,index) in year" :key="index - 1">
						{{type==7||type==11?item: item.substring(0,4)+'-12-31'}}
					</th>
				</tr>
			</thead>
			<tbody v-if="type==11||type==7">
				<tr>
					<td class="trTwo">总资产净利率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.totalAssetsReturnOn[index]?method.formateNum(financeRatio.totalAssetsReturnOn[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">销售费用率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.saleCostRatio[index]?method.formateNum(financeRatio.saleCostRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">管理费用率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.manageCostRatio[index]?method.formateNum(financeRatio.manageCostRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">研发费用率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.researchCostRatio[index]?method.formateNum(financeRatio.researchCostRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">毛利率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.grossProfitRatio[index]?method.formateNum(financeRatio.grossProfitRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">净利润率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.netProfitRatio[index]?method.formateNum(financeRatio.netProfitRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">主营业务收入增长率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.incomeRatio[index]?method.formateNum(financeRatio.incomeRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">利润总额增长率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.totalProfitRatio[index]?method.formateNum(financeRatio.totalProfitRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">净资产收益率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.netAssetsRatio[index]?method.formateNum(financeRatio.netAssetsRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">资产负债率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.liabilitiesRatio[index]?method.formateNum(financeRatio.liabilitiesRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">流动比率</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.curAssetsRatio[index]?method.formateNum(financeRatio.curAssetsRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">速动比率</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.quickRatio[index]?method.formateNum(financeRatio.quickRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">EBITl利息保障倍数</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.ebitMulRatio[index]?method.formateNum(financeRatio.ebitMulRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">应收账款周转率</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.receivableRatio[index]?method.formateNum(financeRatio.receivableRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">总资产周转率</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.totalAssetsTurnover[index]?method.formateNum(financeRatio.totalAssetsTurnover[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">存货周转率</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.inventoryRatio[index]?method.formateNum(financeRatio.inventoryRatio[index],2):'-'}}
					</td>
				</tr>

			</tbody>
			<tbody v-else>
				<tr>
					<td class="trTwo">资产负债率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.liabilitiesRatio[index]?method.formateNum(financeRatio.liabilitiesRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">流动比率</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.curAssetsRatio[index]?method.formateNum(financeRatio.curAssetsRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">EBITl利息保障倍数</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.ebitMulRatio[index]?method.formateNum(financeRatio.ebitMulRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">毛利率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.grossProfitRatio[index]?method.formateNum(financeRatio.grossProfitRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">净利润率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.netProfitRatio[index]?method.formateNum(financeRatio.netProfitRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">主营业务收入增长率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.incomeRatio[index]?method.formateNum(financeRatio.incomeRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">净资产收益率（%）</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.netAssetsRatio[index]?method.formateNum(financeRatio.netAssetsRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">应收账款周转率</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.receivableRatio[index]?method.formateNum(financeRatio.receivableRatio[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">存货周转率</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{financeRatio.inventoryRatio[index]?method.formateNum(financeRatio.inventoryRatio[index],2):'-'}}
					</td>
				</tr>


			</tbody>
		</table>
	</div>
</template>
<script>
	export default ({
		props: {
			financeRatio: Object,
			year: Array,
			versions: [String, Number],
			stage: {
				type: [String, Number],
				required: false
			},
			type: {
				type: [String, Number],
				required: false
			}

		},
		data() {
			return {
				dateFormate: "12月31日"
			}
		},
		mounted() {
			if (this.versions == 1) {
				this.dateFormate = "";
			} else if (this.versions == 'trace') {
				this.dateFormate = "年12月31日"
			}
		},
		methods: {

		}
	})
</script>
<style scoped lang="less">
	table {
		width: 100%;
		text-align: center;
		border-collapse: collapse;
		margin: 28px auto 0;
		border: 1px solid #000;
	}

	table tr th {
		height: 50px;
		border: 1px solid #000;
		background: #0478fc;
		font-size: 18px;
		color: #fff;
	}

	table tr td {
		width: 25%;
		height: 43px;
		border: 1px solid #000;
		font-size: 14px;
		color: #282828;
	}

	table>tbody>tr:first-of-type>td {
		border-top: none;
	}

	table>tbody>tr>td:first-of-type {
		text-align: left;
		padding-right: 10px;
	}

	.total {
		background: rgba(20, 22, 244, 0.1);
	}

	.trOne {
		padding-left: 40px;
		font-weight: bold;
	}

	.trTwo {
		padding-left: 30px;
	}

	.trThree {
		padding-left: 101px;
	}

	.trFour {
		padding-left: 131px;
	}

	.trFive {
		padding-left: 178px;
	}

	.trSix {
		padding-left: 116px;
	}

	.trSeven {
		padding-left: 122px;
	}

	.title {
		margin: 0 !important;
	}

	table>tbody>tr {
		td:not(:first-child) {
			text-align: right;
			padding-right: 38px;
		}
	}
</style>