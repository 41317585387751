<template>
	<div :id="id"></div>
</template>

<script>
	import * as echarts from 'echarts'

	export default {
		props: [
			'chartData', 'id', 'yAxisName', 'chartColor', 'titleText', 'rect'
		],
		data() {
			return {



			}
		},
		mounted() {
			this.initChart()

		},
		methods: {
			initChart() {
				var this_ = this;
				var fontSize12 = 14;
				var chartRotate = 30;
				var reversexAxis = this.chartData.name;
				var reverseData = this.chartData.value;
				var datayAxis = this.chartData.value;
				var chartGrid = {
					left: '8%',
					right: '12%',
					bottom: '10%',
					top: '14%',
					containLabel: true
				}

				var difference = this_.method.setFinancialAnalysisChartPoor(reversexAxis, reverseData),
					barWidth = 40,
					yAxisParams = this_.method.setHasBackgroundData(datayAxis);
				var backgroundData = []

				reverseData.forEach(function(item, i) {
					backgroundData.push({
						value: item >= 0 ? yAxisParams.max : yAxisParams.min
					})
				})

				var series = [{
					type: 'bar',
					barWidth: barWidth,
					z: '-1',
					barGap: '-100%', // barGap值为-100%时可以使柱子重叠
					itemStyle: {
						color(params) {
							var colorList = this_.chartColor.backgroundColor
							var index = params.dataIndex;
							return params.color = colorList[index]
						}
					},
					emphasis: {
						disabled: true
					},
					data: backgroundData
				}, {
					type: 'bar',
					barWidth: barWidth,
					label: {
						show: true,
						position: "top",
						padding: [0, 0, 7, 0],
						fontSize: fontSize12,
						color: '#282828',
						formatter: function(params, index) {
							return this_.method.formateNum(reverseData[params.dataIndex], 2);
						}
					},
					itemStyle: {
						color(params) {
							var colorList = this_.chartColor.bottomColor
							var index = params.dataIndex;
							return params.color = colorList[index]
						}
					},
					emphasis: {
						disabled: true
					},
					data: reverseData,
					markLine: {
						lineStyle: {
							color: '#282828',
						},
						label: {
							show: false,
						},
						data: [
							[{
									coord: difference.coordOne,
									lineStyle: {
										color: '#282828',
										type: 'solid'
									},
									symbolSize: [0, 0],
								},
								{
									coord: difference.coordTwo,
									lineStyle: {
										color: '#282828',
										type: 'solid'
									},
									label: {
										show: true,
										position: 'middle',
										color: '#282828', //设置了文本的颜色会把文本周围的白边去掉
										formatter: function(params) {
											var value = difference.markLineValue.toFixed(2);
											return value;
										}
									},
								}
							]
						]
					}
				}, {
					type: 'line',
					markLine: {
						symbolSize: [0, 0],
						silent: true,
						lineStyle: {
							color: '#282828',
						},
						label: {
							show: false
						},
						data: [
							difference.axisOne,
							difference.axisTwo,
						]
					}
				}]


				if (this.rect) {
					series.push({
						name: "",
						symbol: 'rect',
						z: 10,
						type: 'pictorialBar',
						symbolSize: [50, 6],
						symbolPosition: 'end',
						itemStyle: {
							color(params) {
								var colorList = this_.chartColor.topColor
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: reverseData
					})
				}


				var option = {
					tooltip: {
						trigger: 'none'
					},
					animation: false,
					grid: chartGrid,
					title: {
						show: true,
						text: this.titleText,
						textStyle: {
							color: '#282828',
							align: 'center',
							fontSize: 16
						},
						left: 'center',
						top: 'top'
					},
					xAxis: [{
						type: "category",
						data: reversexAxis,
						axisTick: {
							show: false,
							alignWithLabel: true,
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#1459F4"
							}
						},
						axisLabel: {
							color: "#282828",
							fontSize: fontSize12,
							lineHeight: 15,
							interval: 0,
							rotate: chartRotate,
							margin: 10
						},
						splitLine: {
							show: false //网格线
						}
					}],
					yAxis: {
						type: "value",
						name: this.yAxisName,
						max: yAxisParams.max,
						min: yAxisParams.min,
						interval: yAxisParams.interval,
						nameTextStyle: { // 设置名称样式
							color: '#282828', // 文字颜色
							fontSize: fontSize12, // 字体大小
							padding: [0, 0, 10, 0]
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true, //轴线
							lineStyle: {
								color: "#1459F4"
							}
						},
						axisLabel: {
							fontSize: fontSize12,
							color: "#282828",
							lineHeight: 20,
							margin: 20
						},
						splitLine: {
							show: false //网格线
						}
					},
					series: series
				};
				var myChart = echarts.init(document.getElementById(this.id));
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);


			}

		}

	}
</script>

<style>
</style>