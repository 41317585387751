<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="newViewPage"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<examineTitle />
			<div class="examineContent">
				<div>
					<examineNav />
				</div>
				<div class="newViewInfo">
					<div class="fillinMain">
						<img class="leftTop" :src="require('@/assets/img/dataValutaion/leftTop.png')" />
						<img class="leftBottom" :src="require('@/assets/img/dataValutaion/leftBottom.png')" />
						<img class="rightTop" :src="require('@/assets/img/dataValutaion/rightTop.png')" />
						<img class="rightBottom" :src="require('@/assets/img/dataValutaion/rightBottom.png')" />
						<div class="fillinContent">
							<p class="examineTitle">资产负债表</p>
							<div class="financeTabulation">
								<p class="unit">单位：元</p>
								<div>
									<ul>
										<i></i>
										<li>
											<span>项目</span>
											<span
												v-for="(item,i) in yearList">{{item}}</span>
										</li>
										<li>
											<span>应收账款</span>
											<span v-for="(item,i) in yearList">
												<input type="number" />
												<span class="showInput">
													{{judgingCondition('receivables',i)?method.formateNum(liabilities.receivables[i],2):'-'}}
												</span>
											</span>
										</li>
										<li>
											<span>存货</span>
											<span v-for="(item,i) in yearList">
												<input type="number" />
												<span class="showInput">
													{{judgingCondition('inventory',i)?method.formateNum(liabilities.inventory[i],2):'-'}}
												</span>
											</span>
										</li>
										<li>
											<span>流动资产合计</span>
											<span v-for="(item,i) in yearList">
												<input type="number" />
												<span class="showInput">
													{{judgingCondition('totalCurrentAssets',i)?method.formateNum(liabilities.totalCurrentAssets[i],2):'-'}}
												</span>
											</span>
										</li>
										<li>
											<span>无形资产（不含土地使用权、矿业权、水域使用权）</span>
											<span v-for="(item,i) in yearList">
												<input type="number" />
												<span class="showInput">
													{{judgingCondition('netWorthCombination',i)?method.formateNum(liabilities.netWorthCombination[i],2):'-'}}
												</span>
											</span>
										</li>
										<!-- <li>
											<span class="trTwo">其中：数据资源</span>
											<span v-for="(item,i) in yearList">
												<input type="number" />
												<span class="showInput">
													{{judgingCondition('dataResources',i)?method.formateNum(liabilities.dataResources[i],2):'-'}}
												</span>
											</span>
										</li> -->
										<li>
											<span>非流动资产合计</span>
											<span v-for="(item,i) in yearList">
												<input type="number" />
												<span class="showInput">
													{{judgingCondition('totalNonCurrentAssets',i)?method.formateNum(liabilities.totalNonCurrentAssets[i],2):'-'}}
												</span>
											</span>
										</li>
										<li>
											<span>资产总计</span>
											<span v-for="(item,i) in yearList">
												<input type="number" />
												<span class="showInput">
													{{judgingCondition('totalAssets',i)?method.formateNum(liabilities.totalAssets[i],2):'-'}}
												</span>
											</span>
										</li>
										<li>
											<span>流动负债合计</span>
											<span v-for="(item,i) in yearList">
												<input type="number" />
												<span class="showInput">
													{{judgingCondition('totalNonLiabilities',i)?method.formateNum(liabilities.totalNonLiabilities[i],2):'-'}}
												</span>
											</span>
										</li>
										<li>
											<span>非流动负债合计</span>
											<span v-for="(item,i) in yearList">
												<input type="number" />
												<span class="showInput">
													{{judgingCondition('totalFixedLiabilities',i)?method.formateNum(liabilities.totalFixedLiabilities[i],2):'-'}}
												</span>
											</span>
										</li>
										<li>
											<span>负债合计</span>
											<span v-for="(item,i) in yearList">
												<input type="number" />
												<span class="showInput">
													{{judgingCondition('totalLiabilities',i)?method.formateNum(liabilities.totalLiabilities[i],2):'-'}}
												</span>
											</span>
										</li>
										<li>
											<span>所有者权益(或股东权益)合计</span>
											<span v-for="(item,i) in yearList">
												<input type="number" />
												<span class="showInput">
													{{judgingCondition('totalOwnershipInterest',i)?method.formateNum(liabilities.totalOwnershipInterest[i],2):'-'}}
												</span>
											</span>
										</li>
										<i></i>
									</ul>
								</div>
							</div>

							<p class="line"></p>

							<p class="examineTitle">利润表</p>
							<div class="financeTabulation">
								<p class="unit">单位：元</p>
								<div>
									<ul>
										<i></i>
										<li>
											<span>项目</span>
											<span
												v-for="(item,i) in profitYear">{{item}}</span>
										</li>
										<li>
											<span>营业收入</span>
											<span v-for="(item,i) in yearList">
												<input type="number">
												<span class="showInput">
													{{profit.income[i]!=''?method.formateNum(profit.income[i],2):'-'}}
												</span>
											</span>
										</li>
										<li>
											<span>营业成本</span>
											<span v-for="(item,i) in yearList">
												<input type="number">
												<span class="showInput">
													{{profit.cost[i]!=''?method.formateNum(profit.cost[i],2):'-'}}
												</span>
											</span>
										</li>
										<li>
											<span>利息费用</span>
											<span v-for="(item,i) in yearList">
												<input type="number">
												<span class="showInput">
													{{profit.interestCost[i]!=''?method.formateNum(profit.interestCost[i],2):'-'}}
												</span>
											</span>
										</li>
										<li>
											<span>利润总额</span>
											<span v-for="(item,i) in yearList">
												<input type="number">
												<span class="showInput">
													{{profit.totalProfit[i]!=''?method.formateNum(profit.totalProfit[i],2):'-'}}
												</span>
											</span>
										</li>
										<li>
											<span>毛利润</span>
											<span v-for="(item,i) in yearList">
												<input type="number">
												<span class="showInput">
													{{profit.grossProfit[i]!=''?method.formateNum(profit.grossProfit[i],2):'-'}}
												</span>
											</span>
										</li>
										<li>
											<span>所得税</span>
											<span v-for="(item,i) in yearList">
												<input type="number">
												<span class="showInput">
													{{profit.incomeTax[i]!=''?method.formateNum(profit.incomeTax[i],2):'-'}}
												</span>
											</span>
										</li>
										<li>
											<span>净利润</span>
											<span v-for="(item,i) in yearList">
												<input type="number">
												<span class="showInput">
													{{profit.netProfit[i]!=''?method.formateNum(profit.netProfit[i],2):'-'}}
												</span>
											</span>
										</li>

										<i></i>
									</ul>
								</div>

							</div>

						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import examineTitle from '@/common/fillin/examineTitle.vue'
	import examineNav from '../../nav/examineNav.vue'
	import common from '@/views/dataValuation/common'
	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [common],
		components: {
			Header,
			examineTitle,
			examineNav
		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				type: '15',
				yearList: ['xxxx年', 'xxxx年'],
				profitYear: ['xxxx年', 'xxxx年'],
				id: '',
				isCacheValue: '',
				liabilities: {
					receivables: [],
					inventory: [],
					totalCurrentAssets: [],
					totalNonCurrentAssets: [],
					totalAssets: [],
					totalNonLiabilities: [],
					totalFixedLiabilities: [],
					totalLiabilities: [],
					totalOwnershipInterest: [],
					netWorthCombination: [], //组合无形资产
					dataResources: [] //数据资源
				},
				profit: {
					income: [],
					cost: [],
					interestCost: [],
					totalProfit: [],
					grossProfit: [],
					incomeTax: [],
					netProfit: []
				},
				dataResource: [{
					valuationReportId: "",
					resourceName: '',
					originalValue: '', //原值
					netValue: '', //净值
					resDescribe: '' //数据资源介绍
				}],
				totalNetWorth: '',
				createTime: ''


			}
		},
		mounted() {
			this.getBalanceSheet()
		
		},
		methods: {
			judgingCondition: function(key, i, index) {
				if (this.liabilities[key][i] === 0) {
					return true
				} else if (this.liabilities[key][i] != 0 && this.liabilities[key][i] != '-' && this.liabilities[key][i]) {
					return true
				} else {
					return false
				}
			},
			getBalanceSheet() {
				var this_ = this
				this.$post(this.domain.valuationProject + 'auditInfoShow/getDataFinancialValue', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.$Spin.hide();
						this.$Spin.hide();
						var liabilitiesData = JSON.parse(res.data.content.liabilities.liabilities)
						var liabilitiesKey = Object.keys(this.liabilities); //获取资产负债表对象的 key
						
						this.yearList = liabilitiesData.year ? liabilitiesData.year : res.data.content.liabilities.year
						
						if (this.yearList.length == 2) {
							this.yearList.push(this.getLastDayOfPreviousMonthInChinese(res.data.content.liabilities.createTime.split(
								' ')[0]))
						}


						var liabilitiesKey = Object.keys(this.liabilities); //获取资产负债表对象的 key
						var returnliabilitiesKey = Object.keys(JSON.parse(res.data.content.liabilities
							.liabilities)); //后台数据返回的资产负债表对象的 key
						liabilitiesKey.forEach((elem, index) => {
							returnliabilitiesKey.forEach((item, i) => {
								this_.yearList.forEach((yearItem, y) => {
									if (elem == item) {
										this_.liabilities[elem][y] = JSON.parse(res.data.content.liabilities.liabilities)[elem]
											[y];
									} else if (!this_.liabilities[elem]) {
										if (this_.yearList.length == 2) {
											this_.liabilities[elem] = ['', '']
										}
									}
								})
							})
						})


						var profitData = JSON.parse(res.data.content.profit.profit)
						var profitKey = Object.keys(this.profit); //获取利润表对象的 key
						var returnProfit = Object.keys(JSON.parse(res.data.content.profit.profit)) //后台数据返回的利润表对象的 key
						
						this.profitYear = profitData.year ? profitData.year : res.data.content.profit.year
						
						if (this.profitYear.length == 2) {
							this.profitYear.push(this.getLastDayOfPreviousMonthInChinese(res.data.content.liabilities.createTime
								.split(' ')[0]))
						}
						
						profitKey.forEach((elem, index) => {
							returnProfit.forEach((item, i) => {
								this_.profitYear.forEach((yearItem, y) => {
									if (elem == item) {
										this_.profit[elem][y] = JSON.parse(res.data.content.profit.profit)[elem][y] ? JSON
											.parse(res.data.content.profit.profit)[elem][y] : '';
									} else if (!this_.profit[elem]) {
										if (this_.profitYear.length == 2) {
											this_.profit[elem] = ['', '']
										} else if (this_.profitYear.length == 3) {
											this_.profit[elem] = ['', '', '']
										}
									}
								})
							})
						})



					})
			},
			getData() {
				this.$post(this.domain.valuationProject + 'auditInfoShow/getDataFinancialFutureValue', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then(res => {
						this.createTime = res.data.content.createTime
						if (res.data.content.dataResources) {
							this.dataResource = res.data.content.dataResources
							this.setTotal()
						}

					})
			},
			setTotal() {
				var totalNum = 0;
				for (var k = 0; k < this.dataResource.length; k++) {
					console.log(this.dataResource[k].netValue)
					totalNum += Number(this.dataResource[k].netValue)
					this.totalNetWorth = Number(totalNum)
				}
			},



		}
	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";
</style>