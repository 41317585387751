<template>
	<div class="report-container">
		<div class="tools">
			<button @click="exportPDF">导出PDF</button>
		</div>

		<div id="report-page">
			<!-- 封面 -->
			<div class="page" id="page-home">
				<div class="logoBox">
					<img v-if="pageInfo.firstPageImage" :src="pageInfo.firstPageImage" alt="公司logo" />
				</div>
				<div class="page-main">
					<p class="companyName">{{pageInfo.baseInfo.companyName}}</p>
					<h5>估值报告书</h5>
					<p class="reportNumber">报告编号：{{pageInfo.reportNumber}}</p>
					<h6>{{type==2?'（专业版）':'（并购版）'}}</h6>
				</div>
				<div class="powerInterpretation">
					<span></span>
					<p>{{pageInfo.channelName}}保留对此报告的最终解释权</p>
					<div class="date">{{pageInfo.createTime ? replaceAll(pageInfo.createTime) : ''}}</div>
				</div>
			</div>
			<!--盖章页-->
			<div class="page page-stamp" id="page-stamp">
				<!--内容-->
				<div class="page-content">
					<p class="stampNew">
						<i>依据《企业会计准则第22号--金融供给确认和计量》、《企业会计准则第39号—公允价值计量》、《私募投资基金非上市股权投资估值指引》、《中小微企业估值规范》、《中华人民共和国公司法》、《中华人民共和国证券法》等政策规章，基于国际通用的股权估值模型、自研的估值指标体系及Ai估值技术，贵公司股东全部权益在估值基准日（</i>
						<i class="reportBaseDate">{{method.reportFormatDate(pageInfo.reportBaseDate)}}</i>
						<i>）的市场价值为（人民币）：</i>
					</p>
					<div class="detailResultNew">
						<h4 v-text="resultFormate(pageInfo.minValue, pageInfo.maxValue)">0.00</h4>
					</div>
					<div class="stamptime">
						<p>{{pageInfo.channelName}}</p>
						<p v-text="pageInfo.updateTime ? dateFormate(pageInfo.updateTime) : dateFormate(pageInfo.createTime)"></p>
					</div>
				</div>
			</div>
			<!--目录页-->
			<div class="page page-catalogue" id="page-catalogue">
				<h2>目录</h2>
				<div class="generalize">
					<div class="catalogue-category">
						<span>一、企业概况</span>
						<span class="catalogue-pagination">01</span>
					</div>
					<ul>
						<li>
							<div class="second-level-directory">
								<span>1. 基本信息</span>
								<span class="catalogue-pagination">01</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>2. 股权结构</span>
								<span class="catalogue-pagination">01</span>
							</div>
						</li>
					</ul>
					<div class="catalogue-category">
						<span>二、估值对象、估值范围、估值基准日和价值类型</span>
						<span class="catalogue-pagination">02</span>
					</div>
					<ul>
						<li>
							<div class="second-level-directory">
								<span>1. 估值对象</span>
								<span class="catalogue-pagination">02</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>2. 估值范围</span>
								<span class="catalogue-pagination">02</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>3. 估值基准日</span>
								<span class="catalogue-pagination">02</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>4. 价值类型</span>
								<span class="catalogue-pagination">02</span>
							</div>
						</li>
					</ul>
					<div class="catalogue-category">
						<span>三、估值方法选择</span>
						<span class="catalogue-pagination">02</span>
					</div>
					<ul>
						<li>
							<div class="second-level-directory">
								<span>1. 相对估值法</span>
								<span class="catalogue-pagination">02</span>
							</div>
							<div class="three-level-directory">
								<p>
									<span>1.1 方法简介</span>
									<span class="catalogue-pagination">02</span>
								</p>
								<p>
									<span>1.2 估值思路</span>
									<span class="catalogue-pagination">02</span>
								</p>
								<p>
									<span>1.3 估值结果</span>
									<span class="catalogue-pagination">03</span>
								</p>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>2. 绝对估值法</span>
								<span class="catalogue-pagination">05</span>
							</div>
							<div class="three-level-directory">
								<p>
									<span>2.1 方法简介</span>
									<span class="catalogue-pagination">05</span>
								</p>
								<p>
									<span>2.2 估值思路</span>
									<span class="catalogue-pagination">05</span>
								</p>
								<p>
									<span>2.3 估值结果</span>
									<span class="catalogue-pagination">06</span>
								</p>
							</div>
						</li>
					</ul>
					<div class="catalogue-category">
						<span>四、软性指标调整系数</span>
						<span class="catalogue-pagination">08</span>
					</div>
					<ul>
						<li>
							<div class="second-level-directory">
								<span>1. 行业</span>
								<span class="catalogue-pagination">09</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>2. 产品</span>
								<span class="catalogue-pagination">09</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>3. 团队</span>
								<span class="catalogue-pagination">09</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>4. 技术</span>
								<span class="catalogue-pagination">10</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>5. 风控</span>
								<span class="catalogue-pagination">10</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>6. 财务</span>
								<span class="catalogue-pagination">11</span>
							</div>
						</li>
					</ul>
					<div class="catalogue-category">
						<span>五、财务分析</span>
						<span class="catalogue-pagination">12</span>
					</div>
					<ul>
						<li>
							<div class="second-level-directory">
								<span>1. 营业收入预测</span>
								<span class="catalogue-pagination">12</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>2. 净利润预测</span>
								<span class="catalogue-pagination">12</span>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="page page-catalogue" id="page-catalogue2">
				<div class="generalize" style="margin-top: 110px;">
					<ul style="border-top: none;">
						<li>
							<div class="second-level-directory">
								<span>3. 偿债能力</span>
								<span class="catalogue-pagination">13</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>4. 盈利能力</span>
								<span class="catalogue-pagination">14</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>5. 成长能力</span>
								<span class="catalogue-pagination">15</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>6. 营运能力</span>
								<span class="catalogue-pagination">16</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>7. 杜邦分析</span>
								<span class="catalogue-pagination">17</span>
							</div>
						</li>
					</ul>
					<div class="catalogue-category">
						<span>六、估值假设</span>
						<span class="catalogue-pagination">18</span>
					</div>
					<ul>
						<li>
							<div class="second-level-directory">
								<span>1. 基本假设</span>
								<span class="catalogue-pagination">18</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>2. 一般假设</span>
								<span class="catalogue-pagination">18</span>
							</div>
						</li>
						<li>
							<div class="second-level-directory">
								<span>3. 特殊假设</span>
								<span class="catalogue-pagination">18</span>
							</div>
						</li>
					</ul>
					<div class="catalogue-category">
						<span>七、估值结果</span>
						<span class="catalogue-pagination">19</span>
					</div>
					<div class="catalogue-category">
						<span>八、特别事项说明和瑕疵事项</span>
						<span class="catalogue-pagination">20</span>
					</div>
					<div class="catalogue-category">
						<span>九、估值报告使用限制说明</span>
						<span class="catalogue-pagination">20</span>
					</div>
					<div class="catalogue-category">
						<span>十、估值报告解释权</span>
						<span class="catalogue-pagination">20</span>
					</div>
					<div class="catalogue-category">
						<span>十一、附件</span>
						<span class="catalogue-pagination">21</span>
					</div>
					<div class="catalogue-category stockIntroduceCatalogue" v-if="pageInfo.introImage">
						<span>十二、股交所简介</span>
						<span class="catalogue-pagination">26</span>
					</div>
					<div class="catalogue-category" style="border-bottom: 2px solid #1459F4;">
						<span class="toTen">{{pageInfo.introImage?'十三':'十二'}}、关于心流</span>
						<span class="catalogue-pagination isAnd_">{{pageInfo.introImage?27:26}}</span>
					</div>
				</div>
			</div>
			<!-- 基本信息 -->
			<div class="page" id="page-baseInfo">
				<div class="page-content">
					<p class="authorizationDes">
						受{{pageInfo.baseInfo.companyName}}（以下简称“{{pageInfo.baseInfo.companyShort}}”或“公司”）的委托，北京心流慧估科技有限公司（以下简称“心流慧估”）依据相关法律法规和国际公认的估值方法，对{{pageInfo.baseInfo.companyName}}股东全部权益在估值基准日的市场价值进行了评估。
					</p>

					<p class="broadCategories">一、企业概况</p>
					<p class="broadHeading">（一）基本信息</p>
					<div class="baseInfoCont">
						<ul>
							<li>
								<span>公司名称：</span>
								<span class="companyName" v-text="pageInfo.baseInfo.companyName"></span>
							</li>
							<li>
								<span>企业所在地：</span>
								<span class="companyLocation" v-text="pageInfo.baseInfo.area ? pageInfo.baseInfo.area:'无数据'">无数据</span>
							</li>
							<li>
								<span>成立日期：</span>
								<span class="establishmentDate"
									v-text="pageInfo.baseInfo.establishDate ? dateFormate(pageInfo.baseInfo.establishDate): '无数据'"></span>
							</li>
							<li>
								<span>法定代表人：</span>
								<span class="corporateRepresentative"
									v-text="pageInfo.baseInfo.legalPeople ? pageInfo.baseInfo.legalPeople:'无数据'"></span>
							</li>
							<li>
								<span>注册资本：</span>
								<span class="companyValuation" v-text="valueFormate(pageInfo.baseInfo.regCapital)"></span>
							</li>
							<li>
								<span>统一社会信用代码：</span>
								<span class="socialCreditCode"
									v-text="pageInfo.baseInfo.creditCode ? pageInfo.baseInfo.creditCode:'无数据'"></span>
							</li>
							<li>
								<span>拟上市地点：</span>
								<span>{{getIndex(proposedListingVenue,pageInfo.baseInfo.position)}}</span>
							</li>
							<li>
								<span>所属行业：</span>
								<span class="industry"
									v-text="pageInfo.baseInfo.industryName+'-'+pageInfo.baseInfo.industryChildName"></span>
							</li>
							<li>
								<span>估值目的：</span>
								<span class="valuationPurpose"
									v-text="pageInfo.baseInfo.valuationPurpose ? pageInfo.baseInfo.valuationPurpose : '无数据'"></span>
							</li>
							<li>
								<span>邮箱：</span>
								<span class="Email" v-text="pageInfo.baseInfo.email ? pageInfo.baseInfo.email:'无数据'"></span>
							</li>
							<li>
								<span>主营业务：</span>
								<span class="bussinessDesc" style="width: 88%;">
									{{!pageInfo.baseInfo.bussinessDesc?'无数据':pageInfo.baseInfo.bussinessDesc.length>100?pageInfo.baseInfo.bussinessDesc.substring(0, 100) + '...' :pageInfo.baseInfo.bussinessDesc}}
								</span>
							</li>
						</ul>
					</div>

					<p class="broadHeading">（二） 股权结构</p>

					<div class="equityStructure">
						<img class="equityStructureBase" src="../../assets/img/professionalReport/equityStructureBase.jpg"
							alt="股权结构">
						<Draw3DPieCanvas :id="'stockStructurePie'" :chartData="pageInfo.baseInfo.holderRation" size='400'
							v-if="initStatus" />
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>1</span>
				</div>
			</div>
			<div class="page" id="page-valuationObject">
				<div class="page-content">
					<p class="broadCategories">二、估值对象、估值范围和估值基准日</p>
					<div>
						<p class="broadHeading">（一） 估值对象</p>
						<p class="text secondLevelText">
							<i>本次估值对象为{{pageInfo.baseInfo.companyShort}}</i>
							<i>在估值基准日</i><i
								class="reportBaseDate">{{method.reportFormatDate(pageInfo.reportBaseDate)}}</i><i>股东全部权益价值。</i>
						</p>
					</div>
					<div>
						<p class="broadHeading">（二） 估值范围</p>
						<p class="text secondLevelText">
							<i>本次估值范围为</i><i class="companyShort">{{pageInfo.baseInfo.companyShort}}</i><i>截至估值基准日</i><i
								class="reportBaseDate">{{method.reportFormatDate(pageInfo.reportBaseDate)}}</i><i>股东全部权益价值涉及的资产和负债。估值所依据数据均取自于</i><i
								class="companyShort">{{pageInfo.baseInfo.companyShort}}</i><i>提供的财务报表和相关资料及数据。</i>
						</p>
					</div>
					<div>
						<p class="broadHeading">（三）估值基准日</p>
						<p class="text secondLevelText">
							<i>估值基准日是</i><i
								class="reportBaseDate">{{method.reportFormatDate(pageInfo.reportBaseDate)}}</i><i>。估值基准日在考虑经济行为的实现、会计核算期等因素后确定。估值基准日的确定对估值结果的影响符合常规情况，无特别影响因素。本次估值的取价标准为估值基准日有效的价格标准。</i>
						</p>
					</div>
					<div>
						<p class="broadHeading">（四）价值类型</p>
						<p class="textNoBottom">
							<i>本次估值对估值的市场条件、估值对象的使用条件无特别限制和要求，故选择市场价值作为估值结论的价值类型。</i>
						</p>
						<p class="text stairText">
							<i>市场价值是指自愿买方和自愿卖方在各自理性行事且未受任何强迫的情况下，估值对象在估值基准日进行正常公平交易的价值估计数额。</i>
						</p>
					</div>
					<div>
						<p class="broadCategories" style="margin-bottom: 24px;">三、估值方法</p>
						<p class="textNoBottom"><i>本次估值采用相对估值法和绝对估值法进行估值。具体估值方法分析如下：</i></p>
						<p class="textNoBottom">
							<i>相对估值法是系统根据“上市公司比较法”（Guideline Public Company
								Method，GPCM），以可比公司在市场上的当前定价为基础，估值计算出的企业价值，进而计算公司的股东全部权益价值。</i>
						</p>
						<p class="text secondLevelText">
							<i>绝对估值法是系统采用DCF（Discount Cash Flow）中FCFF（Free Cash Flow to Firm）模型和GGM（Gorden Growth
								Model）模型相结合，对企业自由现金流及终值使用WACC进行折现，估算出企业价值，进而计算公司的股东全部权益价值。</i>
						</p>

						<div>
							<p class="broadHeading">（一）相对估值法</p>
							<p class="secondaryHeading">1、方法简介</p>
							<p class="text secondLevelText">
								<i>相对估值法是指获取并分析可比上市公司的经营和财务数据，计算适当的价值比率，在与被估值单位比较分析的基础上，确定被估值对象价值的具体方法。相对估值法中的可比公司应当是公开市场上正常交易的上市公司，估值结论应当考虑流动性对被估值对象价值的影响。</i>
							</p>
							<p class="secondaryHeading">2、估值思路</p>
							<p class="secondaryHeadingBrackets">（1）计算过程</p>

							<p class="textNoBottom">
								<i>
									本次估值选取常用的市盈率（P/E）、市净率（P/B）、市销率（P/S）和企业价值/息税折旧摊销前利润（EV/EBITDA）四个指标，结合企业所处行业特性针对四种估值模型的计算结果进行权重配比，公式如下：
								</i>
							</p>
						</div>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>2</span>
				</div>
			</div>
			<div class="page" id="page-calculationStep">
				<div class="page-content">
					<p class="text secondLevelText">
						<i class="companyShort">{{pageInfo.baseInfo.companyShort}}</i>
						<i>股东全部权益价值=[</i>
						<i class="companyShort">{{pageInfo.baseInfo.companyShort}}</i>
						<i class="updateTime">{{updateTime}}</i><i>预期净利润×P/E×权重+</i>
						<i class="companyShort">{{pageInfo.baseInfo.companyShort}}</i>
						<i class="updateTime">{{updateTime}}</i><i>预期净资产×P/B×权重+</i>
						<i class="companyShort">{{pageInfo.baseInfo.companyShort}}</i>
						<i class="updateTime">{{updateTime}}</i><i>预期收入×P/S×权重 +（</i>
						<i class="companyShort">{{pageInfo.baseInfo.companyShort}}</i>
						<i class="updateTime">{{updateTime}}</i><i>预期息税折旧摊销前利润×EV/EBITDA+货币资金-带息债务）× 权重] ×（1-缺乏流动性折扣）</i>
					</p>
					<p class="secondaryHeadingBrackets">（2）计算步骤</p>
					<div>
						<p class="threeLevelHeading">① 选择可比企业</p>
						<p class="fourLevelHeading">a.选择目标资本市场</p>
						<p class="text threeLevelText">
							<i class="companyShort">{{pageInfo.baseInfo.companyShort}}</i><i>主营业务为</i>
							<i class="mainBusiness">{{pageInfo.baseInfo.bussinessDesc}}</i>
							<i>企业所处阶段为</i>
							<i class="period">{{pageInfo.period}}</i>
							<i>企业，</i>
							<i
								class="registeredCapitalNation">{{registeredCapitalNation+getIndex(proposedListingVenue,pageInfo.baseInfo.position)}}</i>
							<i class="targetCapitalMarket">
								{{pageInfo.baseInfo.position == '5'?'属非公开发行的资本市场，流动性较弱，在选择目标资本市场时对标新三板市场':'是相对成熟、公开的资本市场，因此在选择目标资本市场时'}}
							</i>
							<i>，优先选择被估值单位所属行业。</i>
						</p>
					</div>
					<div>
						<p class="fourLevelHeading">b.选择可比企业</p>
						<p class="textNoBottom">
							<i>根据被估值单位的主营业务，搜集</i>
							<i
								class="regionalEquity">{{pageInfo.baseInfo.position == '5'?'新三板':getIndex(proposedListingVenue,pageInfo.baseInfo.position)}}</i>
							<i>市场上市公司的公开信息，选择与被估值单位业务类别接近、业务范围相当的</i>
							<i
								class="regionalEquity_">{{pageInfo.baseInfo.position == '5'?'新三板':getIndex(proposedListingVenue,pageInfo.baseInfo.position)}}</i>
							<i>上市公司，对其进行相关统计分析后作为可比公司筛选范围。</i>
						</p>
						<p class="text threeLevelText">
							<i>在可比公司筛选范围内进行适当筛选，以确定合适的可比企业。本次选择考虑的因素主要是业务构成、企业规模、品牌类别及影响等因素。</i>
						</p>
					</div>
					<div>
						<p class="threeLevelHeading">② 分析选择价值比率</p>
						<p class="text threeLevelText">
							<i>主要是将可比企业和被估值单位的财务报表进行分析调整，使调整后的财务报表具有可比性，并选择合适的价值比率。主要分析调整事项如下：</i>
						</p>
						<p class="fourLevelHeading">a.被估值单位和可比企业财务报表编制基础的差异；</p>
						<p class="fourLevelHeading">b.选择、计算、调整价值比率。</p>
						<p class="textNoBottom">
							<i>本次估值采用的价值比率有市盈率（P/E）、市净率（P/B）、市销率（P/S）、企业价值/息税折旧摊销前利润（EV/EBITDA）。</i>
						</p>
						<p class="text threeLevelText">
							<i>对比被估值单位和可比企业，对被估值单位的财务报表进行分析调整。通过对其多角度量化分析、比较，根据同一资本市场内同行业上市公司市值与对应市场乘数法结果的回归分析，对其所处行业市场乘数法之适用性进行优先次序排序。对</i><i
								class="registeredCapital">{{getIndex(proposedListingVenue,pageInfo.baseInfo.position)}}</i>
							<i>上市公司市值及市场乘数法结果进行指标测算，对误差结果进行总结、归纳，整理出现有的估值指标优先次序排序。而后，采用层次分析法，对各指标根据其优先顺序进行权重赋值，形成对应资本市场的市场乘数法行业权重赋值表。</i>
						</p>
					</div>
					<div>
						<p class="threeLevelHeading">③ 缺乏流动性折扣</p>
						<p class="text secondLevelText">
							<i>由于可比公司为上市公司、被估值单位为非上市公司，因此计算被估值单位股东全部权益价值时需考虑缺乏流动性折扣，即在考虑缺乏流动性折扣前的市场价值的基础上，扣除缺乏流动性折扣，得出被估值单位的股东全部权益价值。</i>
						</p>
					</div>
					<p class="secondaryHeading">3、估值结果</p>
					<div class="page-tip-container">
						<ul>
							<li>
								<p class="tipStrOne">（1）{{relativeText}}</p>
							</li>
							<li>
								<p>（2）相对估值法是系统根据“上市公司比较法”（Guideline Public Company
									Method，GPCM），以可比公司于估值基准日在市场上的定价为基础，计算公司的股东全部权益价值。</p>
							</li>
							<li class="relativeValuationTip" v-if="pageInfo.baseInfo.bussinessDesc.length <= 70">
								<p>（3）市盈率、市净率、市销率、企业价值/息税折旧摊销前利润所取数值为系统对A股、新三板、港股、纳斯达克等板块数据进行分行业整理、计算得出，根据企业所处行业分类选取。其他资料详见附件。
								</p>
							</li>
						</ul>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>3</span>
				</div>
			</div>
			<div class="page" id="page-relativeValuation">
				<div class="page-content">
					<div class="page-tip-container">
						<ul>
							<li class="relativeValuationTip_" v-if="pageInfo.baseInfo.bussinessDesc.length > 70">
								<p>（3）市盈率、市净率、市销率、企业价值/息税折旧摊销前利润所取数值为系统对A股、新三板、港股、纳斯达克等板块数据进行分行业整理、计算得出，根据企业所处行业分类选取。其他资料详见附件。
								</p>
							</li>
							<li>
								<p>（4）当市盈率倍数法、市净率倍数法、市销率倍数法、企业价值/息税折旧摊销前利润倍数法的估值结果为负值或空值，则该估值指标被视为异常值不参与计算。</p>
							</li>
						</ul>
					</div>


					<div class="relavtive-container">
						<div class="relavtive-left">
							<div class="relavtive-one">
								<p>单位：人民币万元</p>
								<img src="../../assets/img/professionalReport/relativeValuationBase.png" alt="底座">
								<RelativeValuationHorizontal v-if="initStatus" id="barOne" class="div-canvas"
									:chartData="pageInfo.relativeValuation.baseInfo" :chartColor="chartColor" newline="5" />
							</div>
							<div class="relavtive-two">
								<p>单位：倍</p>
								<img src="../../assets/img/professionalReport/relativeValuationBase.png" alt="底座">
								<RelativeValuationHorizontal v-if="initStatus" id="barTwo" class="div-canvas"
									:chartData="pageInfo.relativeValuation.valuationMul" :chartColor="chartColor" newline="9" />
							</div>
						</div>
						<div class="relavtive-mid-container">
							<div class="relavtive-mid"></div>
							<div class="amount"></div>
						</div>
						<div class="relavtive-right">
							<div>
								<p>相对估值</p>
								<div class="relavtive-three">
									<p>单位：人民币万元</p>
									<img src="../../assets/img/professionalReport/relativeValuationBase.png" alt="底座">
									<RelativeValuationVertical v-if="initStatus" id="barThree" class="div-canvas"
										:chartData="pageInfo.relativeValuation.valuationValue" :chartColor="chartColor" />
								</div>
							</div>
						</div>
					</div>
					<img class="downArrow" src="../../assets/img/professionalReport/relativeValuationBottom.png" alt="">
					<div class="valuationResults"
						:style="{'background': 'url('+require('@/assets/img/professionalReport/valuationResults.png')+') center center no-repeat'}">
						<div
							:style="{'background': 'url('+require('@/assets/img/professionalReport/valuationResultsData.png')+') center center no-repeat'}">
							<p>估值结果：</p>
							<div class="relativevaluationResult valuationResultNum">
								<img v-for="(item,i) in relativeValuationValueImg" :key="i"
									:src="require('@/assets/img/professionalReport/'+item.val+'.png')" :style="{marginTop:item.top}"
									alt="估值结果数字">
								<span>{{method.technologyNum(pageInfo.relativeValuationValue).unit}}</span>
							</div>
						</div>
					</div>

				</div>
				<div class="pageNum">
					<p>///</p><span>4</span>
				</div>
			</div>
			<div class="page" id="page-absoluteValuationFormula">
				<div class="page-content">
					<p class="broadHeading">（二）绝对估值法</p>
					<div>
						<p class="secondaryHeading">1、方法简介</p>
						<p class="textNoBottom">
							<i>绝对估值法选用企业自由现金流模型，即经济收益流是公司全部投资资本（全部股东权益和有息债务）产生的现金流。以未来若干年度内的企业自由现金流量作为依据，采用适当折现率折现后加总计算得出营业性资产价值，然后再加上溢余资产价值、非经营性资产价值，减去有息债务、非经营性负债得出股东全部权益价值。</i>
						</p>
						<p class="textNoBottom">
							<i>现金流量折现法的描述具体如下：</i>
						</p>
						<p class="text secondLevelText">
							<i>股东全部权益价值=企业整体价值－付息债务价值</i>
						</p>
					</div>
					<div>
						<p class="secondaryHeading">2、估值思路</p>
						<p class="secondaryHeadingBrackets">（1）企业整体价值</p>
						<p class="textNoBottom"><i>企业整体价值是指股东全部权益价值和付息债务价值之和。根据被估值单位的资产配置和使用情况，企业整体价值的计算公式如下：</i></p>
						<p class="text threeLevelText">
							<i>企业整体价值=经营性资产价值+溢余资产价值+非经营性资产价值-非经营性负债价值+长期股权投资价值</i>
						</p>
					</div>
					<div>
						<p class="threeLevelHeading">① 经营性资产价值</p>
						<p class="text threeLevelText">
							<i>经营性资产是指与被估值单位生产经营相关的，估值基准日后企业自由现金流量预测所涉及的资产与负债。经营性资产价值的计算公式如下：</i>
						</p>
						<img class="computationalFormula" src="../../assets/img/professionalReport/valueOperatingAssets.png"
							alt="经营性资产价值的计算公式" style=" width: 289px;">
						<p class="textNoBottom"><i>其中：P：估值基准日的企业经营性资产价值；</i></p>
						<p class="textNoBottom thereinto">
							<i class="cornerMark subscriptI">F</i><i>：估值基准日后第i年预期的企业自由现金流量；</i>
						</p>
						<p class="textNoBottom thereinto">
							<i class="cornerMark subscriptN">F</i><i>：预测期末年预期的企业自由现金流量；</i>
						</p>
						<p class="textNoBottom thereinto">
							<i>r：折现率（此处为加权平均资本成本WACC）；</i>
						</p>
						<p class="textNoBottom thereinto">
							<i>n：预测期；</i>
						</p>
						<p class="textNoBottom thereinto">
							<i>i：预测期第i年；</i>
						</p>
						<p class="text thereinto threeLevelText">
							<i>g：永续期增长率。</i>
						</p>
						<p class="textNoBottom"><i>其中，企业自由现金流量计算公式为：</i></p>
						<p class="text threeLevelText"><i>企业自由现金流量=息前税后净利润+折旧与摊销-资本性支出-营运资金增加额</i></p>
						<p class="text threeLevelText"><i>其中，折现率（加权平均资本成本,WACC）计算公式为：</i></p>
						<img class="computationalFormula" src="../../assets/img/professionalReport/waccFormula.png" alt="折现率计算公式"
							style="width: 352px;">

						<p class="textNoBottom">
							<i>其中：</i><i class="cornerMark subscriptKe">K</i><i>：权益资本成本；</i>
						</p>
						<p class="textNoBottom thereinto">
							<i class="cornerMark subscriptkd">K</i><i>：付息债务资本成本；</i>
						</p>
						<p class="textNoBottom thereinto">
							<i>E：权益的市场价值；</i>
						</p>
						<p class="textNoBottom thereinto">
							<i>D：付息债务的市场价值；</i>
						</p>
						<p class="text thereinto threeLevelText">
							<i>t：所得税率。</i>
						</p>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>5</span>
				</div>
			</div>
			<div class="page" id="page-absoluteValuationFormula2">
				<div class="page-content">
					<p class="text threeLevelText"><i>其中，权益资本成本采用资本资产定价模型(CAPM)计算。计算公式为：</i></p>
					<img class="computationalFormula" src="../../assets/img/professionalReport/capm.png" alt="折现率计算公式"
						style="width: 240px;">
					<p class="textNoBottom">
						<i>其中：</i><img src="../../assets/img/professionalReport/rf.svg"
							style="width: 17px;position: relative;top: 8px;left: -2px;"><i>：无风险利率；</i>
					</p>
					<p class="textNoBottom thereinto">
						<i>MRP：市场风险溢价；</i>
					</p>
					<p class="textNoBottom thereinto">
						<i>β：权益的系统风险系数；</i>
					</p>
					<p class="text thereinto threeLevelText">
						<img src="../../assets/img/professionalReport/rc.svg"
							style="width: 17px;position: relative;top: 8px;left: -2px;margin-left: 40px;"><i></i><i>：企业特定风险调整系数。</i>
					</p>

					<div>
						<p class="threeLevelHeading">② 溢余资产价值</p>
						<p class="text threeLevelText"><i>溢余资产是指估值基准日超过企业生产经营所需，估值基准日后企业自由现金流量预测不涉及的资产。</i></p>
						<p class="threeLevelHeading">③ 非经营性资产、负债价值</p>
						<p class="text threeLevelText"><i>非经营性资产、负债是指与被估值单位生产经营无关的，估值基准日后企业自由现金流量预测不涉及的资产与负债。</i></p>
						<p class="threeLevelHeading">④ 长期股权投资价值</p>
						<p class="text threeLevelText"><i>长期股权投资是指被估值单位估值基准日时已形成的对外股权投资。</i></p>
						<p class="secondaryHeadingBrackets">（2）付息债务价值</p>
						<p class="text threeLevelText"><i>付息债务是指估值基准日被估值单位需要支付利息的负债。</i></p>
						<p class="secondaryHeadingBrackets">（3）收益期及预测期的确定</p>
						<p class="threeLevelHeading">① 收益期的确定</p>
						<p class="text threeLevelText">
							<i>
								由于估值基准日被估值单位经营正常，没有对影响企业继续经营的核心资产的使用年限进行限定和对企业生产经营期限、投资者所有权期限等进行限定，或者上述限定可以解除，并可以通过延续方式永续使用。故本报告假设被估值单位估值基准日后永续经营，相应的收益期为无限期。
							</i>
						</p>
						<p class="threeLevelHeading">② 预测期的确定</p>
						<p class="text secondLevelText">
							<i>由于企业近期的收益可以相对合理地预测，而远期收益预测的合理性相对较差，按照通常惯例，将企业的收益期划分为明确预测期和永续期两个阶段。经过综合分析，预计被估值单位于</i>
							<i>{{updateYear}}</i><i>达到稳定经营状态，故明确预测期确定为</i>
							<i>{{yearSection}}</i><i>以后为永续期。</i>
						</p>
						<p class="secondaryHeading">3、估值结果</p>
						<div class="page-tip-container">
							<ul>
								<li>
									<p>（1）绝对估值法：系统采用DCF（Discount Cash Flow）中FCFF（Free Cash Flow to Firm）模型和GGM（Gorden
										Growth Model）模型相结合，对企业无杠杆自由现金流及终值进行折现，继而对企业进行价值评估。</p>
								</li>
								<li>
									<p>（2）在对企业终值测算时，系统采用GGM（Gorden Growth Model）模型。</p>
								</li>
								<li>
									<p>（3）WACC（加权平均资本成本）是系统综合考虑企业所在行业特性，杠杆比率，股权成本，债务成本等因素后，计算出的总资本成本。</p>
								</li>
								<li>
									<p>（4）当FCFF计算估值结果为负值时，则该估值方法不适用。</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>6</span>
				</div>
			</div>
			<div class="page" id="page-absoluteValuation">
				<div class="page-content">
					<div class="table_area">
						<div>
							<img src="../../assets/img/professionalReport/relativeValuationBase.png" class="relativeValuationBase"
								alt="底座">
							<DrawPileBar2 v-if="initStatus" id="cashFlowforecast" class="cash-flow" :chartData="cashFlowForecast" />
						</div>
						<div class="cash-flow center-arrow">
							<img src="../../assets/img/professionalReport/relativeValuationRight.png" alt="线上估值-心流" />
						</div>
						<div>
							<img src="../../assets/img/professionalReport/relativeValuationBase.png" class="relativeValuationBase"
								alt="底座">
							<DrawBarLine v-if="initStatus" id="cashFlowTrend" class="cash-flow" :chartData="cashFlowTrend"
								:chartColor="chartColor" />
						</div>
					</div>
					<div class="dashes-line">
						<img src="../../assets/img/professionalReport/dottedPicX.png" alt="虚线">
						<div class="arrow-down">
							<p>
								WACC<br><span>{{pageInfo.absoluteValuation.wacc ? (pageInfo.absoluteValuation.wacc * 100).toFixed(2) + "%" : '0%'}}</span>
							</p>
						</div>
					</div>

					<div class="valuationResults"
						:style="{background: 'url('+require('@/assets/img/professionalReport/absoluteValuation.png')+') center center no-repeat'}">
						<div
							:style="{background: 'url('+require('@/assets/img/professionalReport/absoluteValuationData.png')+') center center no-repeat'}">
							<p>估值结果：</p>
							<div class="absoluteValuationResult valuationResultNum">
								<img v-for="(item,i) in absoluteValuationValueImg" :key="i"
									:src="require('@/assets/img/professionalReport/'+item.val+'.png')" :style="{marginTop:item.top}"
									alt="估值结果数字">
								<span>{{method.technologyNum(pageInfo.absoluteValuation.ggm).unit}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>7</span>
				</div>
			</div>
			<div class="page" id="page-atlas">
				<div class="page-content">
					<p class="broadCategories">四、软性指标调整系数</p>
					<p class="text stairText">
						<i>
							对企业的非财务指标进行评价打分，主要包括企业行业环境、产品竞争力、技术实力、核心团队情况、风险管理能力及财务分析指标，形成修正指数倍数区间，作为估值结果的修正调整系数。
						</i>
					</p>
					<p style="font-size: 30px;font-weight: bold;color: #282828;margin-bottom: 38px;">软性指标的评价得分具体如下：</p>

					<div>
						<div class="valueResultTitle">
							<img src="../../assets/img/professionalReport/valuationGraphIcon.png" class="icon_">
							<span>估值图谱</span>
						</div>
						<div class="radar">
							<img src="../../assets/img/professionalReport/radarBase.jpg" alt="底座">
							<DrawRadarCanvas2 :chartData="pageInfo.question" id="flowDiagnose_BaseInfo" v-if="initStatus" />
						</div>
					</div>

					<div style="margin-top: 30px;">
						<div class="valueResultTitle">
							<img src="../../assets/img/professionalReport/financialAnalysisIcon.png" class="icon_">
							<span>财务分析</span>
						</div>
						<div class="radar">
							<img src="../../assets/img/professionalReport/radarBase.jpg" alt="底座" style="top: 392px;">
							<div id="financeAnalyze_BaseInfo"></div>
							<DrawRadarCanvas2 :chartData="pageInfo.financial" id="financeAnalyze_BaseInfo" v-if="initStatus" />
						</div>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>8</span>
				</div>
			</div>
			<div class="page" id="page-industry">
				<div class="page-content">
					<div class="industryContent">
						<p class="broadHeading softCategory">（一）行业</p>
						<ul class="softModule twoModule">
							<li v-for="(item,i) in pageInfo.industry" :key="i">
								<p class="softValue">{{item.value}}分</p>
								<p class="softTitle">{{item.name}}</p>
							</li>
						</ul>
						<div class="softDescribe">
							<p v-for="(item,index) in industryStatements" :key="index + 1">{{item}}</p>
						</div>
					</div>
					<div class="productContent">
						<p class="broadHeading softCategory">（二）产品</p>
						<ul class="softModule threeModule">
							<li v-for="(item,i) in pageInfo.competitivePower" :key="i">
								<p class="softValue">{{item.value}}分</p>
								<p class="softTitle">{{item.name}}</p>
							</li>
						</ul>
						<div class="softDescribe">
							<p v-for="(item,index) in competitivePowerStatements" :key="index + 1">{{item}}</p>
						</div>
					</div>
					<div class="teamManageContent">
						<p class="broadHeading softCategory">（三）团队</p>
						<ul class="softModule twoModule">
							<li v-for="(item,i) in pageInfo.teamManage" :key="i">
								<p class="softValue">{{item.value}}分</p>
								<p class="softTitle">{{item.name}}</p>
							</li>
						</ul>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>9</span>
				</div>
			</div>
			<div class="page" id="page-technology">
				<div class="page-content">
					<div class="teamManageContent">
						<div class="softDescribe">
							<p v-for="(item,index) in teamManageStatements" :key="index + 1">{{item}}</p>
						</div>
					</div>
					<div class="technologyContent">
						<p class="broadHeading softCategory">（四）技术</p>
						<ul class="softModule twoModule">
							<li v-for="(item,i) in pageInfo.technology" :key="i">
								<p class="softValue">{{item.value}}分</p>
								<p class="softTitle">{{item.name}}</p>
							</li>
						</ul>
						<div class="softDescribe">
							<p v-for="(item,index) in technologyStatements" :key="index + 1">{{item}}</p>
						</div>
					</div>
					<div class="ventureManageContent">
						<p class="broadHeading softCategory">（五）风控</p>
						<ul class="softModule twoModule">
							<li v-for="(item,i) in pageInfo.ventureManage" :key="i">
								<p class="softValue">{{item.value}}分</p>
								<p class="softTitle">{{item.name}}</p>
							</li>
						</ul>
						<div class="softDescribe">
							<p v-for="(item,index) in ventureManageStatements" :key="index + 1">{{item}}</p>
						</div>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>10</span>
				</div>
			</div>
			<div class="page" id="page-financialCondition">
				<div class="page-content">
					<p class="broadHeading">（六）财务</p>
					<ul class="financialCondition" v-if="initStatus">
						<li v-for="(item,i) in financialFormate(pageInfo.financial)">
							<div class="proportion" :style="{background:'url('+item.url+') center center no-repeat'}">
								<p :style="{color:item.color}">{{item.value}}</p>
							</div>
							<div class="conditionDescription">
								<p class="conditionTitle" :style="{color:chartColor.bottomColor[i]}">{{item.name}}</p>
								<p class="conditionDes">{{item.description}}</p>
							</div>
						</li>
					</ul>
				</div>
				<div class="pageNum">
					<p>///</p><span>11</span>
				</div>
			</div>
			<div class="page" id="page-forecast">
				<div class="page-content">
					<p class="broadCategories">五、财务分析</p>
					<p class="broadHeading">（一）营业收入预测</p>
					<div class="forecastChart">
						<img src="../../assets/img/professionalReport/forecastBase.png" alt="底座">
						<DrawBarProfitForecast v-if="initStatus" id="incomeForecast" :chartData="pageInfo.incomeForecast"
							:chartColor="chartColor" yAxisName="人民币万元" />

					</div>

					<p class="broadHeading">（二）净利润预测</p>
					<div class="forecastChart">
						<img src="../../assets/img/professionalReport/forecastBase.png" alt="底座">
						<ForecastCube v-if="initStatus" id="netProfitForecast" :chartData="pageInfo.netProfitForecast"
							:chartColor="chartColor" yAxisName="人民币万元" />
					</div>
					<div class="forecastExplain">
						<p>
							<img src="../../assets/img/professionalReport/forecastExplain.png" alt="预测说明">
							<span>说明</span>
						</p>
						<p>根据企业提供的历史财务数据，结合同行业财务经营状况，对企业未来五年营业收入、净利润进行预测。</p>
					</div>

				</div>
				<div class="pageNum">
					<p>///</p><span>12</span>
				</div>
			</div>
			<div class="page" id="page-liabilitiesRatio">
				<div class="page-content">
					<p class="broadHeading">（三）偿债能力</p>

					<!--资产负债表-->
					<div class="sovency asset-liability-ratio">
						<div class="sovency_table">
							<img src="../../assets/img/professionalReport/relativeValuationBase.png" class="relativeValuationBase"
								alt="底座">
							<StereoscopicHistogram v-if="initStatus" id="liabilitiesRatio" :chartData="pageInfo.liabilitiesRatio"
								titleText="资产负债率" yAxisName="%" :chartColor="chartColor" :rect="true" />
						</div>
						<div class="sovency_explain">
							<div class="grade">
								<img class="battery" :src="liabilitiesRatioStatementSrc" alt="诊断报告中的偿债分析之资产负债表" />
								<p v-if="initStatus" v-text="pageInfo.liabilitiesRatio.batteryLevel"
									:style="pageInfo.liabilitiesRatio.batteryLevel == '20%'?'color:#FF383D;':''"></p>
							</div>
							<div class="explainText">
								<p>{{liabilitiesRatioStatement}}</p>
								<p v-for="(item,index) in liabilitiesRatioStatementArr" :key="index + 6">{{item}}</p>
							</div>
						</div>
					</div>

					<!--流动比率-->
					<div class="sovency liquidity-ratio">
						<div class="sovency_table">
							<img src="../../assets/img/professionalReport/relativeValuationBase.png" class="relativeValuationBase"
								alt="底座">
							<StereoscopicHistogram v-if="initStatus" id="flowRation" :chartData="pageInfo.flowRation" titleText="流动比率"
								yAxisName="" :chartColor="chartColor" :rect="true" />
						</div>
						<div class="sovency_explain">
							<div class="grade">
								<img class="battery" :src="flowRationStatementSrc" alt="诊断报告中的偿债分析之流动比率" />
								<p v-if="initStatus" v-text="pageInfo.flowRation.batteryLevel"
									:style="pageInfo.flowRation.batteryLevel=='20%'?'color:#FF383D;':''"></p>
							</div>
							<div class="explainText">
								<p>{{flowRationStatement}}</p>
								<p v-for="(item,index) in flowRationStatementArr" :key="index + 5">{{item}}</p>
							</div>
						</div>
					</div>

					<!--EBIT利息保障倍数-->
					<div class="sovency interest-cover">
						<div class="sovency_table">
							<img src="../../assets/img/professionalReport/relativeValuationBase.png" class="relativeValuationBase"
								alt="底座">
							<StereoscopicHistogram v-if="initStatus" id="ebitMultiple" :chartData="pageInfo.ebitMultiple"
								titleText="息税前利润利息保障倍数" yAxisName="" :chartColor="chartColor" :rect="true" />
						</div>
						<div class="sovency_explain">
							<div class="grade" v-if="ebitMultipleGradeStatus">
								<img class="battery" :src="ebitMultipleStatementSrc" alt="诊断报告中的偿债分析之EBIT利息保障倍数" />
								<p v-if="initStatus" v-text="pageInfo.ebitMultiple.batteryLevel"
									:style="pageInfo.ebitMultiple.batteryLevel=='20%'?'color:#FF383D;':''"></p>
							</div>
							<div class="explainText">
								<p>{{ebitMultipleStatement}}</p>
								<p v-for="(item,index) in ebitMultipleStatementArr" :key="index + 7">{{item}}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>13</span>
				</div>
			</div>
			<div class="page" id="page-payoffProfile">
				<div class="page-content">
					<p class="broadHeading">（四）盈利能力</p>
					<!--毛利率-->
					<div class="sovency gross-profit-ratio">
						<div class="sovency_table">
							<img src="../../assets/img/professionalReport/relativeValuationBase.png" class="relativeValuationBase"
								alt="底座">
							<StereoscopicHistogram v-if="initStatus" id="grossProfitRatio" :chartData="pageInfo.grossProfitRatio"
								titleText="毛利率" yAxisName="%" :chartColor="chartColor" :rect="false" />
						</div>
						<div class="sovency_explain">
							<div class="grade">
								<img class="battery" :src="grossProfitRatioStatementSrc" alt="诊断报告中的盈利分析之毛利率" />
								<p v-if="initStatus" v-text="pageInfo.grossProfitRatio.batteryLevel"
									:style="pageInfo.grossProfitRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
							</div>
							<div class="explainText">
								<p>{{grossProfitRatioStatement}}</p>
								<p v-for="(item,index) in grossProfitRatioStatementArr" :key="index + 7">{{item}}</p>
							</div>
						</div>
					</div>

					<!--净利润率-->
					<div class="sovency net-profit-ratio">
						<div class="sovency_table">
							<img src="../../assets/img/professionalReport/relativeValuationBase.png" class="relativeValuationBase"
								alt="底座">
							<StereoscopicHistogram v-if="initStatus" id="netProfiltRatio" :chartData="pageInfo.netProfiltRatio"
								titleText="净利润率" yAxisName="%" :chartColor="chartColor" :rect="false" />
						</div>
						<div class="sovency_explain">
							<div class="grade">
								<img class="battery" :src="netProfiltRatioStatementSrc" alt="诊断报告中的盈利分析之净利润率" />
								<p v-if="initStatus" v-text="pageInfo.netProfiltRatio.batteryLevel"
									:style="pageInfo.netProfiltRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
							</div>
							<div class="explainText">
								<p>{{netProfiltRatioStatement}}</p>
								<p v-for="(item,index) in netProfiltRatioStatementArr" :key="index + 8">{{item}}</p>
							</div>
						</div>
					</div>

				</div>
				<div class="pageNum">
					<p>///</p><span>14</span>
				</div>
			</div>
			<div class="page" id="page-growthAnalysis">
				<div class="page-content">
					<p class="broadHeading">（五）成长能力</p>
					<!--主营业务收入增长率-->
					<div class="sovency mainAdd-ratio">
						<div class="sovency_table">
							<img src="../../assets/img/professionalReport/relativeValuationBase.png" class="relativeValuationBase"
								alt="底座">
							<StereoscopicHistogram2 v-if="initStatus" id="mainIncomeRatio" :chartData="pageInfo.mainIncomeRatio"
								titleText="主营业务收入增长率" yAxisName="%" :chartColor="chartColor" :borderRadius="true" />
						</div>
						<div class="sovency_explain">
							<div class="grade">
								<img class="battery" :src="mainIncomeRatioStatementSrc" alt="诊断报告中的成长分析之主营业务收入增长率" />
								<p v-if="initStatus" v-text="pageInfo.mainIncomeRatio.batteryLevel"
									:style="pageInfo.mainIncomeRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
							</div>
							<div class="explainText">
								<p>{{mainIncomeRatioStatement}}</p>
								<p v-for="(item,index) in mainIncomeRatioStatementArr" :key="index + 9">{{item}}</p>
							</div>
						</div>
					</div>
					<!--净资产收益率-->
					<div class="sovency net-asset-ratio">
						<div class="sovency_table">
							<img src="../../assets/img/professionalReport/relativeValuationBase.png" class="relativeValuationBase"
								alt="底座">
							<StereoscopicHistogram2 v-if="initStatus" id="netAssetRatio" :chartData="pageInfo.netAssetRatio"
								titleText="净资产收益率" yAxisName="%" :chartColor="chartColor" :borderRadius="true" />
						</div>
						<div class="sovency_explain">
							<div class="grade">
								<img class="battery" :src="netAssetRatioStatementSrc" alt="诊断报告中的成长分析之净资产收益率" />
								<p v-if="initStatus" v-text="pageInfo.netAssetRatio.batteryLevel"
									:style="pageInfo.netAssetRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
							</div>
							<div class="explainText">
								<p>{{netAssetRatioStatement}}</p>
								<p v-for="(item,index) in netAssetRatioStatementArr" :key="index + 10">{{item}}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>15</span>
				</div>
			</div>
			<div class="page" id="page-operationalAnalysis">
				<div class="page-content">
					<p class="broadHeading">（六）营运能力</p>
					<!--应收账款周转率-->
					<div class="sovency account-turnover-rate">
						<div class="sovency_table">
							<img src="../../assets/img/professionalReport/relativeValuationBase.png" class="relativeValuationBase"
								alt="底座">
							<StereoscopicHistogram3 v-if="initStatus" id="receivablesTurnoverRatio" :chartColor="chartColor"
								:chartData="pageInfo.receivablesTurnoverRatio" titleText="应收账款周转率" yAxisName="" />
						</div>
						<div class="sovency_explain">
							<div class="grade" v-if="receivablesTurnoverRatioGradeStatus">
								<img class="battery" :src="receivablesTurnoverRatioStatementSrc" alt="诊断报告中的营运分析之应收账款周转率" />
								<p v-if="initStatus" v-text="pageInfo.receivablesTurnoverRatio.batteryLevel"
									:style="pageInfo.receivablesTurnoverRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
							</div>
							<div class="explainText">
								<p>{{receivablesTurnoverRatioStatement}}</p>
								<p v-for="(item,index) in receivablesTurnoverRatioStatementArr" :key="index + 11">{{item}}</p>
							</div>
						</div>
					</div>
					<!--存货周转率-->
					<div class="sovency inventory-turnover-rate">
						<div class="sovency_table">
							<img src="../../assets/img/professionalReport/relativeValuationBase.png" class="relativeValuationBase"
								alt="底座">
							<StereoscopicHistogram3 v-if="initStatus" id="inventoryTurnoverRatio" :chartColor="chartColor"
								:chartData="pageInfo.inventoryTurnoverRatio" titleText="存货周转率" yAxisName="%" />
						</div>
						<div class="sovency_explain">
							<div class="grade" v-if="inventoryTurnoverRatioGradeStatus">
								<img class="battery" :src="inventoryTurnoverRatioStatementSrc" alt="诊断报告中的营运分析之存货周转率" />
								<p v-if="initStatus" v-text="pageInfo.inventoryTurnoverRatio.batteryLevel"
									:style="pageInfo.inventoryTurnoverRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
							</div>
							<div class="explainText">
								<p>{{inventoryTurnoverRatioStatement}}</p>
								<p v-for="(item,index) in inventoryTurnoverRatioStatementArr" :key="index + 12">{{item}}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>16</span>
				</div>
			</div>
			<div class="page page-dupontAnalyzeNew pvda" id="page-dupontAnalyze">
				<div class="page-content">
					<p class="broadHeading">（七）杜邦分析</p>
					<DupontAnalyze2 v-if="initStatus" :versions="0" :dupontData="pageInfo.dubangAnalysis" />
				</div>
				<div class="pageNum">
					<p>///</p><span>17</span>
				</div>
			</div>
			<div class="page" id="page-valuationAssumption">
				<div class="page-content">
					<p class="broadCategories">六、估值假设</p>
					<p style="font-size: 30px;font-weight: bold;color: #282828;margin-bottom: 38px;">本估值报告假设如下：</p>
					<div>
						<p class="broadHeading">（一） 基本假设</p>
						<p class="textNoBottom">
							<i>
								1、公开市场假设：公开市场是指充分发达与完善的市场条件，是一个有自愿的买者和卖者的竞争性市场，在这个市场上，买者和卖者的地位是平等的，彼此都有获取足够市场信息的机会和时间，买卖双方的交易行为都是自愿的、理智的而非强制的或不受限制的条件下进行的；
							</i>
						</p>
						<p class="textNoBottom">
							<i>
								2、持续使用假设：该假设首先设定标的公司资产正处于使用状态，包括正在使用中的资产和备用的资产；其次根据有关数据和信息，推断这些处于使用状态的资产还将继续使用下去。持续使用假设既说明了被估值资产所面临的市场条件或市场环境，同时又着重说明了资产的存续状态；
							</i>
						</p>
						<p class="text secondLevelText">
							<i>
								3、持续经营假设：即假设被估值单位以现有资产、资源条件为基础，在可预见的将来不会因为各种原因而停止营业，而是合法地持续不断地经营下去。
							</i>
						</p>
					</div>
					<div>
						<p class="broadHeading">（二） 一般假设</p>
						<p class="textNoBottom"><i>1、假设估值基准日后国家和地区的政治、经济和社会环境无重大变化；</i></p>
						<p class="textNoBottom"><i>2、假设估值基准日后国家宏观经济政策、产业政策和区域发展政策无重大变化；</i></p>
						<p class="textNoBottom"><i>3、除已知的变化外，假设与被估值单位相关的利率、汇率、赋税基准及税率、政策性征收费用等在估值基准日后不发生重大变化；</i></p>
						<p class="textNoBottom"><i>4、假设估值基准日后被估值单位的管理层是负责的、稳定的，且有能力担当其职务；</i></p>
						<p class="textNoBottom"><i>5、假设被估值单位完全遵守所有相关的法律法规；</i></p>
						<p class="text secondLevelText"><i>6、假设估值基准日后无不可抗力对被估值单位造成重大不利影响。</i></p>
					</div>
					<div>
						<p class="broadHeading">（三） 特殊假设</p>
						<p class="textNoBottom"><i>1、假设估值基准日后被估值单位采用的会计政策和编写本估值报告时所采用的会计政策在重要方面保持一致；</i></p>
						<p class="textNoBottom"><i>2、假设估值基准日后被估值单位在现有管理方式和管理水平的基础上，经营范围、方式与目前保持一致；</i></p>
						<p class="textNoBottom"><i>3、假设估值基准日后被估值单位的现金流入为平均流入，现金流出为平均流出；</i></p>
						<p class="textNoBottom"><i>4、假设被估值单位的业务内容、业务规模按照其预测情况执行且持续经营；</i></p>
						<p class="textNoBottom"><i>5、假设预测期内被估值单位核心管理人员和技术人员队伍相对稳定，不会出现影响企业发展和收益实现的重大变动事项；</i></p>
						<p class="textNoBottom"><i>6、假设本次经济行为实施后，被估值单位主营业务及服务、经营模式、盈利模式等在现行预知的情况下不会发生重大变化；</i></p>
						<p class="textNoBottom"><i>本估值报告估值结论在上述假设条件下，在估值基准日时成立。</i></p>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>18</span>
				</div>
			</div>
			<div class="page" id="page-valuationResult">
				<div class="page-content">
					<p class="broadCategories">七、估值结果<i style="font-size: 20px;position: relative;">（人民币）</i></p>
					<div class="valuationResult">
						<div class="valuationResultData">
							<span class="min">{{method.formateNum(method.technologyNum(pageInfo.minValue).data)}}</span>
							<span class="unit minUnit">{{method.technologyNum(pageInfo.minValue).unit}}</span>
							<span> ~ </span>
							<span class="max">{{method.formateNum(method.technologyNum(pageInfo.maxValue).data)}}</span>
							<span class="unit maxUnit">{{method.technologyNum(pageInfo.maxValue).unit}}</span>

						</div>
						<div class="valuationResultBase">
							<p>估值结果</p>
						</div>
						<ul>
							<li>
								<p>相对估值法估值结果</p>
							</li>
							<li>
								<p>绝对估值法估值结果</p>
							</li>
							<li>
								<p>流动性折价比率</p>
							</li>
							<li>
								<p>心流指数倍数修正区间</p>
							</li>
						</ul>

					</div>
					<p style="font-size: 20px;color: #282828;">企业最终的估值结果为综合考虑企业相对估值结果、绝对估值结果、流动性折价因素、修正指数倍数后得出。</p>
					<ul class="result-explain-container">
						<li>
							<span>注：</span>
							<span>1、</span>
							<span>流动性折价率综合考虑了非上市企业股权转售时的流动性风险，系统根据不同行业制定差异性的流动性折价率；</span>
						</li>
						<li>
							<span></span>
							<span>2、</span>
							<span>修正指数倍数区间是由影响企业价值的内外因素通过量化指标综合打分得出，包括软性指标和财务指标分析两部分，具体参照心流图谱和财务分析部分。</span>
						</li>
					</ul>
				</div>
				<div class="pageNum">
					<p>///</p><span>19</span>
				</div>
			</div>
			<div class="page" id="page-specialEvent">
				<div class="page-content">
					<div>
						<p class="broadCategories">八、特别事项说明和瑕疵事项</p>
						<p class="textNoBottom"><i>特别事项可能对估值结论产生影响，估值报告使用者应予以关注。</i></p>
						<p class="textNoBottom">
							<i>
								（一）在本报告所述估值目的下对估值对象进行价值测算，并不涉及到对该估值目的所对应的估值购买行为做出任何判断。估值工作很大程度上，依赖于被估值企业提供的有关资料。因此，估值工作是以被估值企业提供的财务数据、预测财务信息及审核人员核实信息时企业答复的真实、合法为前提。
							</i>
						</p>
						<p class="text stairText">
							<i>（二）本报告仅为{{pageInfo.baseInfo.companyName}}提供内部参考，不应当被认为是对估值企业可实现价格的保证。如果在实际交易中产生纠纷，{{pageInfo.channelName}}不承担任何法律责任。</i>
						</p>
					</div>
					<div>
						<p class="broadCategories">九、估值报告使用限制说明</p>
						<p class="textNoBottom"><i>（一）估值报告的估值结论仅为本报告所列明的估值目的和用途而服务。</i></p>
						<p class="textNoBottom"><i>（二）除非事前征得估值机构书面同意，估值报告的内容不得被摘抄、引用或披露于公开媒体，法律、法规规定以及相关当事方另有约定的除外。</i>
						</p>
						<p class="textNoBottom"><i>（三）本报告必须完整使用方为有效，对仅使用报告中部分内容导致的可能的损失，本公司不承担责任。</i></p>
						<p class="text stairText">
							<i>（四）估值报告使用者应当正确理解估值结论。估值结论不等同于估值对象可实现价格，估值结论不应当被认为是对估值对象可实现价格的保证。</i>
						</p>
					</div>
					<div>
						<p class="broadCategories">十、估值报告解释权</p>
						<p class="text"><i>本估值报告意思表达解释权属于出具报告的估值机构，除国家法律、法规有明确的特殊规定外，其他任何单位和部门均无权做出书面解释。</i></p>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>20</span>
				</div>
			</div>
			<div class="page" id="page-accessory_1">
				<div class="page-content">
					<p class="broadCategories">十一、附件</p>

					<div class="accessoryNew">
						<span>附件1</span>
						<div class="title">{{pageInfo.baseInfo.companyName}}资产负债表</div>
						<span>单位：人民币元</span>
					</div>

					<LibTableSpecialty1 :liaTableInfo="liabilities" v-if="initStatus" :year="pageInfo.liaTableInfo.year"
						:versions="0" color="#1459F4" />

				</div>
				<div class="pageNum">
					<p>///</p><span>21</span>
				</div>
			</div>
			<div class="page" id="page-accessory_2">
				<div class="page-content">
					<div class="accessoryNew">
						<span>附件1</span>
						<div class="title">{{pageInfo.baseInfo.companyName}}资产负债表（续表）</div>
						<span>单位：人民币元</span>
					</div>

					<LibTableSpecialty2 :liaTableInfo="liabilities" v-if="initStatus" :year="pageInfo.liaTableInfo.year"
						:versions="0" color="#1459F4" />

				</div>
				<div class="pageNum">
					<p>///</p><span>22</span>
				</div>
			</div>
			<div class="page" id="page-accessory_3">
				<div class="page-content">
					<div class="accessoryNew">
						<span>附件2</span>
						<div class="title">{{pageInfo.baseInfo.companyName}}利润表</div>
						<span>单位：人民币元</span>
					</div>
					<ProfitTableSpecialty :profitTableInfo="profitTableInfo" v-if="initStatus"
						:year="pageInfo.profitTableInfo.year" :versions="0" color="#1459F4" />

				</div>
				<div class="pageNum">
					<p>///</p><span>23</span>
				</div>
			</div>
			<div class="page" id="page-accessory_3">
				<div class="page-content">
					<div class="accessoryNew">
						<span>附件2</span>
						<div class="title">{{pageInfo.baseInfo.companyName}}利润表（续表）</div>
						<span>单位：人民币元</span>
					</div>
					<ProfitTableSpecialty2 :profitTableInfo="profitTableInfo" v-if="initStatus"
						:year="pageInfo.profitTableInfo.year" :versions="0" color="#1459F4" />

					<p class="accessoryTitle" style="margin-top: 60px;">
						<i>附件3</i>
						<i>{{pageInfo.baseInfo.companyName}}财务比率表</i>
						<i></i>
					</p>

					<FinancialRatios :financeRatio="financeRatio" v-if="initStatus" :year="financeRatio.year.slice(-2)"
						:versions="0" color="#1459F4" />

				</div>
				<div class="pageNum">
					<p>///</p><span>24</span>
				</div>
			</div>
			<div class="page" id="page-computational">
				<div class="page-content">
					<div class="accessoryNew">
						<span>附件4</span>
						<div class="title">计算公式</div>
						<span></span>
					</div>
					<div class="formula-container formulaNew">
						<h3>1、估值方法公式</h3>
						<ul>
							<li>
								<span></span>
								<div class="formula-tit">
									<h4>FCFF计算公式:</h4>
									<p>FCFF=净利润+税后利息费用+折旧及摊销-营运资金增加额-资本性支出。系统通过该公式计算企业自由现金流。</p>
								</div>
							</li>
							<li>
								<span></span>
								<div class="formula-tit">
									<h4>WACC(加权平均资本成本)计算公式:</h4>
									<p>
										WACC=(债务/总资本)×债务成本×(1-企业所得税税率)+(资产净值/总资本)×股权成本。系统深入研究A股、新三板等板块市场风险、行业风险，整理统计出行业资本结构、股权成本、债权成本等，系统将根据企业所填信息，自动选取对应行业WACC数值。
									</p>
								</div>
							</li>
							<li>
								<span></span>
								<div class="formula-tit">
									<h4>GGM(Gorden Growth Model)</h4>
									<h4><img src="../../assets/img/professionalReport/formulary.png" /></h4>
									<p>系统根据该公式计算该模型下的企业终值，并加以折现，计算企业现值。</p>
								</div>
							</li>
							<li>
								<span></span>
								<div class="formula-tit">
									<h4>市盈率倍数法计算公式:</h4>
									<p>股东全部权益价值=行业市盈率中值*企业净利润（预测）。系统将企业按照证监会发布的最新上市公司行业分类指引进行分类，行业市盈率中值以证监会公开的上市公司行业市盈率中值为准。
									</p>
								</div>
							</li>
							<li>
								<span></span>
								<div class="formula-tit">
									<h4>市净率倍数法计算公式:</h4>
									<p>股东全部权益价值=行业市净率中值*企业净资产（预测）。系统将企业按照证监会发布的最新上市公司行业分类指引进行分类，行业市净率中值以证监会公开的上市公司行业市净率中值为准。
									</p>
								</div>
							</li>
							<li>
								<span></span>
								<div class="formula-tit">
									<h4>市销率倍数法计算公式:</h4>
									<p>股东全部权益价值=行业市销率中值*企业营业收入（预测）。系统将企业按照证监会发布的最新上市公司行业分类指引进行分类，行业市销率中值以证监会公开的上市公司行业市销率中值为准。
									</p>
								</div>
							</li>
							<li>
								<span></span>
								<div class="formula-tit">
									<h4>企业价值/息税折旧摊销前利润倍数法计算公式：</h4>
									<p>
										企业价值=行业企业价值/息税折旧摊销前利润中值×企业EBITDA（预测）+货币资金-带息债务。系统将企业按照证监会发布的最新上市公司行业分类指引进行分类，行业企业价值/息税折旧摊销前利润中值以证监会公开的上市公司行业企业价值/息税折旧摊销前利润中值为准。
									</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="formula-container formulaNew">
						<h3>2、财务指标公式</h3>
						<ul>
							<li>
								<span></span>
								<p>资产负债率=负债合计/资产总计×100%</p>
							</li>
							<li>
								<span></span>
								<p>流动比率=流动资产合计/流动负债合计</p>
							</li>
							<li>
								<span></span>
								<p>息税前利润利息保障倍数=息税前利润/利息费用</p>
							</li>
							<li>
								<span></span>
								<p>毛利率=（营业收入-营业成本）/营业收入×100%</p>
							</li>
							<li>
								<span></span>
								<p>净利润率=净利润/营业收入×100%</p>
							</li>
							<li>
								<span></span>
								<p>主营业务收入增长率=（本年度营业收入-上年度营业收入）/上年度营业收入×100%</p>
							</li>
							<li>
								<span></span>
								<p>净资产收益率=净利润×2/（本年度期初净资产+本年度期末净资产）×100%</p>
							</li>
							<li>
								<span></span>
								<p>应收账款周转率=营业收入/平均应收账款</p>
							</li>
							<li>
								<span></span>
								<p>存货周转率=营业成本/平均存货</p>
							</li>
						</ul>
					</div>

				</div>
				<div class="pageNum">
					<p>///</p><span>25</span>
				</div>
			</div>
			<div class="page stockIntroduce" id="stockIntroduce" v-if="pageInfo.introImage">
				<div>
					<h2 class="classHeading" style="position: absolute;top: 45px;left: 110px;"></h2>
					<img style="width:100%;" :src="pageInfo.introImage" alt="简介">
				</div>
				<div class="pageNum">
					<span>26</span>
				</div>
			</div>
			<div class="page" id="page-statement">
				<div class="page-content">
					<Statement />
				</div>
				<div class="pageNum">
					<p>///</p><span class="isAnd_">{{pageInfo.introImage?27:26}}</span>
				</div>
			</div>
			<div class="page valueBackCoverImg" id="page-flow">
				<div class="page-content">
					<ContactWay :channelName="pageInfo.channelName" />
				</div>
			</div>
			









		</div>


		<div id="loading">
			<div class='base'>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
			</div>
		</div>
	</div>
</template>

<script>
	import qs from 'qs'
	import axios from 'axios'
	import PdfOnload from '@/utils/pdf'
	import tsconfig from '@/assets/js/tsconfig.json'

	import Draw3DPieCanvas from '@/common/echarts/draw3DPieCanvas.vue'
	import RelativeValuationHorizontal from '@/common/echarts/relativeValuationHorizontal.vue'
	import RelativeValuationVertical from '@/common/echarts/relativeValuationVertical.vue'
	import DrawPileBar2 from '@/common/echarts/drawPileBar2.vue'
	import DrawBarLine from '@/common/echarts/drawBarLine.vue'
	import DrawRadarCanvas2 from '@/common/echarts/drawRadarCanvas2.vue'
	import DrawBarProfitForecast from '@/common/echarts/drawBarProfitForecast.vue'
	import ForecastCube from '@/common/echarts/forecastCube.vue'
	import StereoscopicHistogram from '@/common/echarts/stereoscopicHistogram.vue'
	import StereoscopicHistogram2 from '@/common/echarts/stereoscopicHistogram2.vue'
	import StereoscopicHistogram3 from '@/common/echarts/stereoscopicHistogram3.vue'
	import DupontAnalyze2 from '@/components/reportComponent/dupontAnalyze2.vue'
	import LibTableSpecialty1 from '@/components/reportComponent/LibTableSpecialty1.vue'
	import LibTableSpecialty2 from '@/components/reportComponent/LibTableSpecialty2.vue'
	import ProfitTableSpecialty from '@/components/reportComponent/profitTableSpecialty.vue'
	import ProfitTableSpecialty2 from '@/components/reportComponent/profitTableSpecialty2.vue'
	import FinancialRatios from '@/components/reportComponent/financialRatios.vue'
	import Statement from '@/components/backCover/statement'
	import ContactWay from '@/components/backCover/contactWay'


	export default {
		props: {
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			url: {
				type: String
			},
			typeName: String

		},
		components: {
			PdfOnload,
			Draw3DPieCanvas,
			RelativeValuationHorizontal,
			RelativeValuationVertical,
			DrawPileBar2,
			DrawBarLine,
			DrawRadarCanvas2,
			DrawBarProfitForecast,
			ForecastCube,
			StereoscopicHistogram,
			StereoscopicHistogram2,
			StereoscopicHistogram3,
			DupontAnalyze2,
			LibTableSpecialty1,
			LibTableSpecialty2,
			ProfitTableSpecialty,
			ProfitTableSpecialty2,
			FinancialRatios,
			Statement,
			ContactWay

		},
		data() {
			return {
				pdfArr: [],
				pdfIndex: 0,
				proposedListingVenue: tsconfig.base.onTheMarketxinz,
				pageInfo: {
					reportBaseDate: '',
					baseInfo: {
						companyName: "",
						establishDate: "",
						bussinessDesc: ''
					},
					year: [],
					absoluteValuation: {
						ggm: ""
					},
					description: {
						quesStatement: '',
						financeStatement: ''
					},

				},
				cashFlowForecast: {}, //现金流预测
				cashFlowTrend: {}, //现金流趋势
				updateTime: '',
				registeredCapitalNation: '',
				// 相对估值
				relativeText: "", // 图三
				relativeValuationValueImg: [],
				absoluteValuationValueImg: [],
				updateYear: '',
				yearSection: '',
				nowYear: "",
				scoreText: "",
				industryStatements: [], //行业描述
				competitivePowerStatements: [], //产品描述
				teamManageStatements: [], //团队描述
				technologyStatements: [], //技术描述
				ventureManageStatements: [], //风控描述
				// 资产负债率
				liabilitiesRatioStatement: "",
				liabilitiesRatioStatementArr: [],
				liabilitiesRatioStatementSrc: require("@/assets/img/reportValuation/dianchi-100.svg"),
				// 流动比率
				flowRationStatement: "",
				flowRationStatementArr: [],
				flowRationStatementSrc: require("@/assets/img/reportValuation/dianchi-100.svg"),
				// EBIT利息保障倍数
				ebitMultipleStatement: "",
				ebitMultipleStatementArr: [],
				ebitMultipleStatementSrc: require("@/assets/img/reportValuation/dianchi-100.svg"),
				ebitMultipleGradeStatus: 1,
				// 毛利率
				grossProfitRatioStatement: "",
				grossProfitRatioStatementArr: [],
				grossProfitRatioStatementSrc: require("@/assets/img/reportValuation/dianchi-100.svg"),
				// 净利润率
				netProfiltRatioStatement: "",
				netProfiltRatioStatementArr: [],
				netProfiltRatioStatementSrc: require("@/assets/img/reportValuation/dianchi-100.svg"),
				// 主营业务收入增长率
				mainIncomeRatioStatement: "",
				mainIncomeRatioStatementArr: [],
				mainIncomeRatioStatementSrc: require("@/assets/img/reportValuation/dianchi-100.svg"),
				// 净资产收益率
				netAssetRatioStatement: "",
				netAssetRatioStatementArr: [],
				netAssetRatioStatementSrc: require("@/assets/img/reportValuation/dianchi-100.svg"),
				// 应收账款周转率
				receivablesTurnoverRatioStatement: "",
				receivablesTurnoverRatioStatementArr: [],
				receivablesTurnoverRatioStatementSrc: require("@/assets/img/reportValuation/dianchi-100.svg"),
				receivablesTurnoverRatioGradeStatus: 1,
				// 存货周转率
				inventoryTurnoverRatioStatement: "",
				inventoryTurnoverRatioStatementArr: [],
				inventoryTurnoverRatioStatementSrc: require("@/assets/img/reportValuation/dianchi-100.svg"),
				inventoryTurnoverRatioGradeStatus: 1,
				liabilities: {
					monetaryCapital: [],
					tradingAssets: [],
					derivativeAssets: [],
					billReceivable: [],
					receivables: [],
					accountReceivable: [],
					prepayment: [],
					otherReceivables: [],
					inventory: [],
					contractualAssets: [],
					saleForAssets: [],
					illiquidAssetsOne: [],
					otherCurrentAssets: [],
					totalCurrentAssets: [],
					debtInvestment: [],
					otherDebtInvestment: [],
					longAeceivables: [],
					longInvestment: [],
					otherEquityInvestment: [],
					otherIlliquidAssets: [],
					investmentProperty: [],
					fixedAssets: [],
					constructionProjects: [],
					productiveAsset: [],
					oilAndGasAssets: [],
					rightAssets: [],
					netWorth: [],
					developExpense: [],
					goodwill: [],
					longExpenses: [],
					deferredAssets: [],
					otherNonCurrentAssets: [],
					totalNonCurrentAssets: [],
					totalAssets: [],
					shortLoan: [],
					transactionLiabilities: [],
					derivativeLiabilities: [],
					notesPayable: [],
					accountsPayable: [],
					advanceReceipt: [],
					contractualLiabilities: [],
					payrollPayable: [],
					taxPayable: [],
					otherPayables: [],
					saleForLiabilities: [],
					illiquidLiabilitiesOne: [],
					otherNonLiabilities: [],
					totalNonLiabilities: [],
					longLoan: [],
					bondsPayable: [],
					bondsPreferredStock: [],
					bondsPerpetuaDebt: [],
					leaseLiabilities: [],
					longPayment: [],
					estimatedLiabilities: [],
					deferredIncome: [],
					deferredLiability: [],
					otherFixedLiabilities: [],
					totalFixedLiabilities: [],
					totalLiabilities: [],
					paidUpCapital: [],
					otherEquity: [],
					equityPreferredStock: [],
					equityPerpetuaDebt: [],
					capitalReserve: [],
					treasuryShare: [],
					otherIncome: [],
					appropriativeReserve: [],
					earnedSurplus: [],
					undistributedProfit: [],
					totalParentEquity: [],
					minorityEquity: [],
					totalOwnershipInterest: [],
					totalLiabilitiesEquity: []

				},
				profitTableInfo: {
					income: [],
					cost: [],
					taxAdditional: [],
					saleCost: [],
					manageCost: [],
					researchCost: [],
					financeCost: [],
					interestCost: [],
					interestIncome: [],
					otherBenefits: [],
					investmentIncome: [],
					enterpriseIncome: [],
					financialAssetsBenefits: [],
					netExposureHedgeIncome: [],
					fairValueIncome: [],
					creditImpairmentLoss: [],
					assetLoss: [],
					assetDisposalIncome: [],
					businessProfit: [],
					outsideIncome: [],
					outsideExpenditure: [],
					totalProfit: [],
					incomeTax: [],
					netProfit: [],
					goingConcernNetProfit: [],
					discontinuedOperationsNetProfit: [],
					parentCompanyNetProfit: [],
					minorityStockLegalRight: [],
					totalComprehensiveIncome: [],
					parentCompanyTci: [],
					minorityStockTci: [],
					ociNetTax: [],
					parentCompanyOciNetTax: [],
					notReclassifiedOci: [],
					remeasureBenefitAmount: [],
					notTransferOci: [],
					otherEquityFairValue: [],
					enterpriseCreditRiskFairValue: [],
					reclassifiedOci: [],
					transferOci: [],
					otherClaimsFairValue: [],
					financialAssetsOciAmount: [],
					otherClaimsCreditImpairment: [],
					cashFlowHedgingReserve: [],
					foreignCurrencyBalance: [],
					minorityStockOciNetTax: [],
					basicEps: [],
					dilutedEps: []

				},
				financeRatio: {
					liabilitiesRatio: ['', ''],
					curAssetsRatio: ['', ''],
					ebitMulRatio: ['', ''],
					grossProfitRatio: ['', ''],
					netProfitRatio: ['', ''],
					incomeRatio: ['', ''],
					netAssetsRatio: ['', ''],
					receivableRatio: ['', ''],
					inventoryRatio: ['', '']
				},
				chartColor: {
					lightColor: ['#84AAFF', '#B9E4FF', '#D3BEFF', '#FFA8F3', '#BBFCFF', '#FEAD77'],
					darkColor: ['#2164FC', '#0091FF', '#9157FF', '#FF64E9', '#4AF5FF', '#FF7F29'],
					topColor: ['#6F9CFF', '#94D6FE', '#C9AEFF', '#FF8FEF', '#91F9FF', '#FFA061'],
					bottomColor: ['#1459F4', '#008AF3', '#8848FF', '#FF4CE6', '#00F1FF', '#FC7821'],
					leftColor: ['#2D6CFA', '#29A3FF', '#9863FE', '#FFCE84', '#4AF5FF', '#FF8A3B'],
					rightColor: ['#1459F4', '#0091FF', '#8848FF', '#FCA521', '#00F1FF', '#FC7821'],
					cubeTop: ['#3E79FF', '#4BB1FE', '#B089FF', '#FFC66F', '#74F2FA', '#FFA061'],
					backgroundColor: ['#D9E4FD', '#D6EDFF', '#ECE2FF', '#FFE6FB', '#D6FDFF', '#FEE9DB'],
					maxColor: ['#AEC8FF', '#B8E4FF', '#DFD1FA', '#FFD1F9', '#BDFBFF', '#FFC9A5'],
					cubeBackgroundLeft: ['rgba(45, 108, 250, .28)', 'rgba(41, 163, 255, .28)', 'rgba(152, 99, 254, .28)',
						'rgba(255, 133, 238, .28)', 'rgba(74, 245, 255, .28)', 'rgba(255, 138, 59, .28)'
					],
					cubeBackgroundRight: ['rgba(20, 89, 244, .28)', 'rgba(0, 145, 255, .28)', 'rgba(136, 72, 255, .28)',
						'rgba(255, 100, 233, .28)', 'rgba(0, 241, 255, .28)', 'rgba(252, 120, 33, .28)'
					],
					cubeBackgroundTop: ['#8AAEFF', '#CDE9FF', '#E9DEFF', '#FFE1FB', '#D8FCFE', '#FFDCC4'],
					lightColorEight: ['#84AAFF', '#B9E4FF', '#D3BEFF', '#77A7FF', '#FFA8F3', '#BBFCFF', '#FEAD77', '#FFCE84'],
					darkColorEight: ['#2164FC', '#0091FF', '#9157FF', '#4385FF', '#FF64E9', '#4AF5FF', '#FF7F29', '#FCA521'],
					topColorEight: ['#6F9CFF', '#94D6FE', '#C9AEFF', '#6CA0FF', '#FF8FEF', '#91F9FF', '#FFA061', '#FFC66F'],
					bottomColorEight: ['#1459F4', '#008AF3', '#8848FF', '#3179FF', '#FF4CE6', '#00F1FF', '#FC7821', '#F49A14'],
					backgroundColorEight: ['#D9E4FD', '#D6EDFF', '#ECE2FF', '#E0E3FF', '#FFE6FB', '#D6FDFF', '#FEE9DB', '#FFEFD6'],
					maxColorEight: ['#AEC8FF', '#B8E4FF', '#DFD1FA', '#D1D5FA', '#FFD1F9', '#BDFBFF', '#FFC9A5', '#FFDFAE'],
					cubeBackgroundLeftEight: ['rgba(45, 108, 250, .28)', 'rgba(41, 163, 255, .28)', 'rgba(152, 99, 254, .28)',
						'#C8CDFC', 'rgba(255, 133, 238, .28)', 'rgba(74, 245, 255, .28)', 'rgba(255, 138, 59, .28)', '#FFEBCC'
					],
					cubeBackgroundRightEight: ['rgba(20, 89, 244, .28)', 'rgba(0, 145, 255, .28)', 'rgba(136, 72, 255, .28)',
						'#C2C8FC', 'rgba(255, 100, 233, .28)', 'rgba(0, 241, 255, .28)', 'rgba(252, 120, 33, .28)', '#FFE6C1'
					],
					cubeBackgroundTopEight: ['#8AAEFF', '#CDE9FF', '#E9DEFF', '#CFD3FC', '#FFE1FB', '#D8FCFE', '#FFDCC4',
						'#FFEDD2'
					],
					leftColorEight: ['#2D6CFA', '#29A3FF', '#9863FE', '#5A94FF', '#FF85EE', '#4AF5FF', '#FF8A3B', '#FDB64A'],
					rightColorEight: ['#1459F4', '#0091FF', '#8848FF', '#4385FF', '#FF64E9', '#00F1FF', '#FC7821', '#FCA521'],
					cubeTopEight: ['#3E79FF', '#4BB1FE', '#B089FF', '#77A7FF', '#FF94F0', '#74F2FA', '#FFA061', '#FDBE5E'],

				}


			}
		},
		computed: {
			initStatus: function() {
				if (this.pageInfo.baseInfo.companyName) {
					return true;
				} else {
					return false;
				}
			}
		},
		mounted() {
			this.initPage()
		},
		methods: {
			exportPDF() {
				document.getElementById("loading").style.display = "block";
				var title = this.pageInfo.baseInfo.companyName + this.typeName + '报告.pdf'
				var pageArr = document.getElementsByClassName("page");
				for (var i = 0; i < pageArr.length; i++) {
					this.pdfArr.push(pageArr[i].id)
				}
				PdfOnload.getPdf(this.pdfArr, this.pdfIndex, title);

			},
			getIndex(array, keyToFind) {
				var index = array.findIndex(item => item.key === keyToFind)
				return index != -1 ? array[index].value : ''
			},
			initPage() {
				var that = this;
				this.$post(this.url + "valuationReport/qryValuationReport", qs.stringify({
					reportId: this.$route.query.reportId ? this.$route.query.reportId : ""
				})).then(res => {
					var data = res.data;
					if (data.code == 200 && data.content) {
						that.pageInfo = data.content;
						that.pageInfo.description = JSON.parse(data.content.description)
						this.updateTime = that.pageInfo.updateTime.split('-')[0] + '年'

						if (that.pageInfo.baseInfo.position == '2') { //2：纳斯达克在美国       3：港股在香港
							that.registeredCapitalNation = '在美国，'
						} else if (that.pageInfo.baseInfo.position == '3') {
							that.registeredCapitalNation = '在香港，'
						} else {
							that.registeredCapitalNation = '在中国境内，'
						}


						// 股权
						var holderArr = [];
						for (var i in JSON.parse(that.pageInfo.baseInfo.holderRation)) {
							holderArr.push({
								name: i,
								y: JSON.parse(that.pageInfo.baseInfo.holderRation)[i],
							})
						};

						that.pageInfo.baseInfo.holderRation = holderArr;


						// 图谱
						that.pageInfo.question = JSON.parse(that.pageInfo.question);
						// 财务分析
						that.pageInfo.financial = JSON.parse(that.pageInfo.financial);

						// 相对估值
						if (that.pageInfo.relativeValuation) {
							that.pageInfo.relativeValuation = JSON.parse(that.pageInfo.relativeValuation);
							var relativeValueKeys = "";
							var relativeValues = "";
							for (var i = 0; i < that.pageInfo.relativeValuation.valuationValue
								.length; i++) {
								if (i != that.pageInfo.relativeValuation.valuationValue.length - 1) {
									relativeValueKeys += that.pageInfo.relativeValuation.valuationValue[i]
										.name + "、";
									relativeValues += this.method.formateNum(that.pageInfo
										.relativeValuation.valuationValue[i].value, 2) + "万元、";
								} else {
									relativeValues += this.method.formateNum(that.pageInfo
											.relativeValuation.valuationValue[i].value, 2) +
										"万元，经调整后，最终相对估值法的股东全部权益价值为" + that.valueFormate(that.pageInfo
											.relativeValuationValue);
									relativeValueKeys += that.pageInfo.relativeValuation.valuationValue[i]
										.name + "对股东全部权益的估值结果分别为：";
								}
							}
							that.relativeText = relativeValueKeys + relativeValues;

							var relativeValuationValue = this.method.formateNum(this.method.technologyNum(this.pageInfo
								.relativeValuationValue).data)
							this.relativeValuationValueImg = this.method.groupSpecialNumeric(relativeValuationValue.split(''), (
								this.pageInfo.relativeValuationValue >= 0 ? 1 : 2), '40px')

						};

						// 绝对估值
						if (that.pageInfo.absoluteValuation) {
							that.pageInfo.absoluteValuation = JSON.parse(that.pageInfo.absoluteValuation);
							that.nowYear = parseInt(that.pageInfo.createTime.split("-")[0]);

							var thisYear = Number(that.pageInfo.forecastYear);
							var dataxAxis = [thisYear + "E", thisYear + 1 + "E", thisYear + 2 + "E", thisYear + 3 + "E", thisYear +
								4 + "E"
							];
							//左侧柱状堆叠图
							var cashFlowData = [{
								name: '净利润',
								value: that.pageInfo.absoluteValuation.pillar.netProfit
							}, {
								name: '折旧及摊销',
								value: that.pageInfo.absoluteValuation.pillar.ebitda
							}, {
								name: '资本性支出',
								value: that.pageInfo.absoluteValuation.pillar.capex
							}, {
								name: '营运资本增加',
								value: that.pageInfo.absoluteValuation.pillar.workingCapital
							}]

							that.cashFlowForecast = {
								data: cashFlowData,
								dataxAxis: dataxAxis
							}

							var dataSeriesOne = that.pageInfo.absoluteValuation.polyline.ncft;
							var dataSeriesTwo = [];
							for (var i = 0; i < that.pageInfo.absoluteValuation.polyline.ncftPercentArr.length; i++) {
								dataSeriesTwo.push(that.pageInfo.absoluteValuation.polyline.ncftPercentArr[i])
							}

							that.cashFlowTrend = {
								columnar: dataSeriesOne,
								line: dataSeriesTwo,
								dataxAxis: dataxAxis
							}

							var absoluteValuationValue = this.method.formateNum(this.method.technologyNum(this.pageInfo
								.absoluteValuation.ggm).data)
							this.absoluteValuationValueImg = this.method.groupSpecialNumeric(absoluteValuationValue.split(''), (
								this.pageInfo.absoluteValuation.ggm >= 0 ? 1 : 2), '40px')

						}
						// 分值说明
						var scoreText = "";
						for (var i = 0; i < that.pageInfo.question.length; i++) {
							if (i != that.pageInfo.question.length - 1) {
								scoreText += that.pageInfo.question[i].name + that.pageInfo.question[i]
									.value + '分、'
							} else {
								scoreText += that.pageInfo.question[i].name + that.pageInfo.question[i]
									.value + '分。'
							}
						}
						that.scoreText =
							'估值图谱是以产品为核心，根据影响企业估值的内外因素形成的企业评价体系，具体包括行业、产品、团队、技术和风控五方面。五项指标分别对应不同的问题，根据企业的答题情况结合相应的打分规则最终得出的五项指标的得分，如图谱所示：' +
							scoreText;

						// 行业
						if (that.pageInfo.industry) {
							that.pageInfo.industry = JSON.parse(that.pageInfo.industry);
							for (var i = 0; i < that.pageInfo.industry.length; i++) {
								that.industryStatements.push(that.pageInfo.industry[i].description);
							}
						};

						// 产品
						if (that.pageInfo.competitivePower) {
							that.pageInfo.competitivePower = JSON.parse(that.pageInfo.competitivePower);
							for (var i = 0; i < that.pageInfo.competitivePower.length; i++) {
								that.competitivePowerStatements.push(that.pageInfo.competitivePower[i]
									.description);
							}
						};
						// 团队
						if (that.pageInfo.teamManage) {
							that.pageInfo.teamManage = JSON.parse(that.pageInfo.teamManage);
							for (var i = 0; i < that.pageInfo.teamManage.length; i++) {
								that.teamManageStatements.push(that.pageInfo.teamManage[i].description);
							}
						};
						// 技术
						if (that.pageInfo.technology) {
							that.pageInfo.technology = JSON.parse(that.pageInfo.technology);
							for (var i = 0; i < that.pageInfo.technology.length; i++) {
								that.technologyStatements.push(that.pageInfo.technology[i].description);
							}
						};
						// 风控
						if (that.pageInfo.ventureManage) {
							that.pageInfo.ventureManage = JSON.parse(that.pageInfo.ventureManage);
							for (var i = 0; i < that.pageInfo.ventureManage.length; i++) {
								that.ventureManageStatements.push(that.pageInfo.ventureManage[i].description);
							}
						};

						// 营业收入预测
						if (that.pageInfo.incomeForecast) {
							that.pageInfo.incomeForecast = JSON.parse(that.pageInfo.incomeForecast);
						};
						// 净利润预测
						if (that.pageInfo.netProfitForecast) {
							that.pageInfo.netProfitForecast = JSON.parse(that.pageInfo.netProfitForecast);

							that.updateYear = that.pageInfo.netProfitForecast.yearArray[that.pageInfo.netProfitForecast.yearArray
								.length - 1] + '年'

							that.yearSection = that.pageInfo.updateTime.split('-')[0] + '年-' + that.updateYear + '，' + that
								.updateYear
						};

						// 资产负债率
						if (that.pageInfo.liabilitiesRatio) {
							that.pageInfo.liabilitiesRatio = JSON.parse(that.pageInfo.liabilitiesRatio);
							that.pageInfo.liabilitiesRatio.name.push("");
							// 语句
							var liabilitiesRatioAssetArr = that.pageInfo.liabilitiesRatio.description
								.split("|");
							that.liabilitiesRatioStatement = liabilitiesRatioAssetArr[0];
							that.liabilitiesRatioStatementArr = liabilitiesRatioAssetArr.splice(1);
							that.liabilitiesRatioStatementSrc = that.srcFormate(that.pageInfo
								.liabilitiesRatio.batteryLevel);

							console.log('pp--------', that.pageInfo.liabilitiesRatio)

						}
						// 流动比率
						if (that.pageInfo.flowRation) {
							that.pageInfo.flowRation = JSON.parse(that.pageInfo.flowRation);
							that.pageInfo.flowRation.name.push("");
							// 语句
							var flowRationAssetArr = that.pageInfo.flowRation.description.split("|");
							that.flowRationStatement = flowRationAssetArr[0];
							that.flowRationStatementArr = flowRationAssetArr.splice(1);
							that.flowRationStatementSrc = that.srcFormate(that.pageInfo.flowRation
								.batteryLevel);
						}
						// EBIT利息保障倍数
						if (that.pageInfo.ebitMultiple) {
							that.pageInfo.ebitMultiple = JSON.parse(that.pageInfo.ebitMultiple);
							that.pageInfo.ebitMultiple.name.push("");
							var interestCost = JSON.parse(that.pageInfo.profitTableInfo).interestCost;
							if (interestCost[1] == "") {
								that.ebitMultipleGradeStatus = 0;
							} else {
								that.ebitMultipleGradeStatus = 1;
							}
							// 语句
							var ebitMultipleAssetArr = that.pageInfo.ebitMultiple.description.split("|");
							that.ebitMultipleStatement = ebitMultipleAssetArr[0];
							that.ebitMultipleStatementArr = ebitMultipleAssetArr.splice(1);
							that.ebitMultipleStatementSrc = that.srcFormate(that.pageInfo.ebitMultiple
								.batteryLevel);
						}

						// 毛利率
						if (that.pageInfo.grossProfitRatio) {
							that.pageInfo.grossProfitRatio = JSON.parse(that.pageInfo.grossProfitRatio);
							that.pageInfo.grossProfitRatio.name.push("");
							// 语句
							var grossProfitRatioAssetArr = that.pageInfo.grossProfitRatio.description
								.split("|");
							that.grossProfitRatioStatement = grossProfitRatioAssetArr[0];
							that.grossProfitRatioStatementArr = grossProfitRatioAssetArr.splice(1);
							that.grossProfitRatioStatementSrc = that.srcFormate(that.pageInfo
								.grossProfitRatio.batteryLevel);
						}
						// 净利润率
						if (that.pageInfo.netProfiltRatio) {
							that.pageInfo.netProfiltRatio = JSON.parse(that.pageInfo.netProfiltRatio);
							that.pageInfo.netProfiltRatio.name.push("");
							// 语句
							var netProfiltRatioAssetArr = that.pageInfo.netProfiltRatio.description.split(
								"|");
							that.netProfiltRatioStatement = netProfiltRatioAssetArr[0];
							that.netProfiltRatioStatementArr = netProfiltRatioAssetArr.splice(1);
							that.netProfiltRatioStatementSrc = that.srcFormate(that.pageInfo
								.netProfiltRatio.batteryLevel);
						}
						// 主营业务收入增长率
						if (that.pageInfo.mainIncomeRatio) {
							that.pageInfo.mainIncomeRatio = JSON.parse(that.pageInfo.mainIncomeRatio);
							that.pageInfo.mainIncomeRatio.name.push("");
							// 语句
							var mainIncomeRatioAssetArr = that.pageInfo.mainIncomeRatio.description.split(
								"|");
							that.mainIncomeRatioStatement = mainIncomeRatioAssetArr[0];
							that.mainIncomeRatioStatementArr = mainIncomeRatioAssetArr.splice(1);
							that.mainIncomeRatioStatementSrc = that.srcFormate(that.pageInfo
								.mainIncomeRatio.batteryLevel);
						}
						// 净资产收益率
						if (that.pageInfo.netAssetRatio) {
							that.pageInfo.netAssetRatio = JSON.parse(that.pageInfo.netAssetRatio);
							that.pageInfo.netAssetRatio.name.push("");
							// 语句
							var netAssetRatioAssetArr = that.pageInfo.netAssetRatio.description.split("|");
							that.netAssetRatioStatement = netAssetRatioAssetArr[0];
							that.netAssetRatioStatementArr = netAssetRatioAssetArr.splice(1);
							that.netAssetRatioStatementSrc = that.srcFormate(that.pageInfo.netAssetRatio
								.batteryLevel);
						}
						// 应收账款周转率
						if (that.pageInfo.receivablesTurnoverRatio) {
							that.pageInfo.receivablesTurnoverRatio = JSON.parse(that.pageInfo
								.receivablesTurnoverRatio);
							that.pageInfo.receivablesTurnoverRatio.name.push("");
							var receivables = JSON.parse(that.pageInfo.liaTableInfo).receivables;
							if (receivables[1] == "") {
								that.receivablesTurnoverRatioGradeStatus = 0;
							} else {
								that.receivablesTurnoverRatioGradeStatus = 1;
							}
							// 语句
							var receivablesTurnoverRatioAssetArr = that.pageInfo.receivablesTurnoverRatio
								.description.split("|");
							that.receivablesTurnoverRatioStatement = receivablesTurnoverRatioAssetArr[0];
							that.receivablesTurnoverRatioStatementArr = receivablesTurnoverRatioAssetArr
								.splice(1);
							that.receivablesTurnoverRatioStatementSrc = that.srcFormate(that.pageInfo
								.receivablesTurnoverRatio.batteryLevel);
						}
						// 存货周转率
						if (that.pageInfo.inventoryTurnoverRatio) {
							that.pageInfo.inventoryTurnoverRatio = JSON.parse(that.pageInfo
								.inventoryTurnoverRatio);
							that.pageInfo.inventoryTurnoverRatio.name.push("");
							var inventory = JSON.parse(that.pageInfo.liaTableInfo).inventory
							if (inventory[1] == "") {
								that.inventoryTurnoverRatioGradeStatus = 0;
							} else {
								that.inventoryTurnoverRatioGradeStatus = 1;
							}
							// 语句
							var inventoryTurnoverRatioAssetArr = that.pageInfo.inventoryTurnoverRatio
								.description.split("|");
							that.inventoryTurnoverRatioStatement = inventoryTurnoverRatioAssetArr[0];
							that.inventoryTurnoverRatioStatementArr = inventoryTurnoverRatioAssetArr
								.splice(1);
							that.inventoryTurnoverRatioStatementSrc = that.srcFormate(that.pageInfo
								.inventoryTurnoverRatio.batteryLevel);
						};

						// 杜邦分析
						if (that.pageInfo.dubangAnalysis) {
							that.pageInfo.dubangAnalysis = JSON.parse(that.pageInfo.dubangAnalysis);
						}

						// 财务分析
						that.pageInfo.financial[0].color = "#0478fc";
						that.pageInfo.financial[1].color = "#FD865A";
						that.pageInfo.financial[2].color = "#775BFF";
						that.pageInfo.financial[3].color = "#00BF8C";

						// 资产负债表
						this.pageInfo.liaTableInfo = JSON.parse(this.pageInfo.liaTableInfo)
						var liabilitiesKey = Object.keys(this.liabilities); //获取资产负债表对象的 key
						var returnliabilitiesKey = Object.keys(this.pageInfo.liaTableInfo); //后台数据返回的资产负债表对象的 key

						liabilitiesKey.forEach((elem, index) => {
							returnliabilitiesKey.forEach((item, i) => {
								that.pageInfo.liaTableInfo.year.forEach((yearItem, y) => {
									if (elem == item) {
										that.liabilities[elem][y] = that.pageInfo.liaTableInfo[elem][y];
									} else if (!that.pageInfo.liaTableInfo[elem]) {
										if (that.pageInfo.liaTableInfo.year.length == 2) {
											that.liabilities[elem] = ['', '']
										} else if (that.pageInfo.liaTableInfo.year.length == 3) {
											that.liabilities[elem] = ['', '', '']
										}
									}
								})
							})
						})

						// 利润表
						this.pageInfo.profitTableInfo = JSON.parse(this.pageInfo.profitTableInfo)
						var profitKey = Object.keys(this.profitTableInfo); //获取利润表对象的 key
						var returnProfitKey = Object.keys(this.pageInfo.profitTableInfo); //后台数据返回的资产负债表对象的 key

						profitKey.forEach((elem, index) => {
							returnProfitKey.forEach((item, i) => {
								that.pageInfo.year.forEach((yearItem, y) => {
									if (elem == item) {
										that.profitTableInfo[elem][y] = that.pageInfo.profitTableInfo[elem][y];
									} else if (!that.pageInfo.profitTableInfo[elem]) {
										if (that.pageInfo.year.length == 2) {
											that.profitTableInfo[elem] = ['', '']
										} else if (that.pageInfo.year.length == 3) {
											that.profitTableInfo[elem] = ['', '', '']
										}
									}
								})
							})
						})

						console.log('利润--', this.profitTableInfo)

						// 财务比率表
						that.financeRatio = JSON.parse(that.pageInfo.financeRatio);


					}

				})
			},
			// 日期格式化
			dateFormate: function(date) {
				if (date) {
					var dataStr = date.split("-");
					var newDataStr = dataStr[0] + "年" + dataStr[1] + "月" + dataStr[2] + "日";
					return newDataStr;
				}
			},
			// 估值结果格式化
			resultFormate: function(min, max) {
				var val = '';
				if (min) {
					return this.method.valuationResultConversion(min, max)
				} else {
					return '无数据'
				}
			},
			// 
			valueFormate: function(val) {
				if (val) {
					return this.method.resultConversion(val)
				} else {
					return '无数据'
				}
			},
			/*页脚日期格式转换*/
			replaceAll: function(val) {
				if (val) {
					return val.replace(new RegExp("-", "gm"), ".");
				}

			},
			srcFormate: function(val) {
				if (val == "20%") {
					return require("@/assets/img/professionalReport/dianchi-20.png")
				} else if (val == "40%") {
					return require("@/assets/img/professionalReport/dianchi-40.png")
				} else if (val == "60%") {
					return require("@/assets/img/professionalReport/dianchi-60.png")
				} else if (val == "80%") {
					return require("@/assets/img/professionalReport/dianchi-80.png")
				} else if (val == "100%") {
					return require("@/assets/img/professionalReport/dianchi-100.png")
				}
			},
			financialFormate(arr) { // name description value
				var key = '';
				for (var i = 0; i < arr.length; i++) {
					if (arr[i].name == '偿债能力') {
						key = 'debtPayingAbility';
						if (60 >= arr[i].value && arr[i].value > 0) {
							arr[i].color = this.chartColor.bottomColor[0];
						} else {
							arr[i].color = '#fff';
						}
					} else if (arr[i].name == '盈利能力') {
						key = 'profitability';
						if (60 >= arr[i].value && arr[i].value > 0) {
							arr[i].color = this.chartColor.bottomColor[1];
						} else {
							arr[i].color = '#fff';
						}
					} else if (arr[i].name == '成长能力') {
						key = 'growthAbility';
						if (60 >= arr[i].value && arr[i].value > 0) {
							arr[i].color = this.chartColor.bottomColor[2];
						} else {
							arr[i].color = '#fff';
						}
					} else if (arr[i].name == '营运能力') {
						key = 'operationCapacity';
						if (60 >= arr[i].value && arr[i].value > 0) {
							arr[i].color = this.chartColor.bottomColor[3];
						} else {
							arr[i].color = '#fff';
						}
					}

					if (10 >= arr[i].value && arr[i].value >= 0) {
						arr[i].url = require('@/assets/img/professionalReport/financialExamination/' + key + '1.png')
					} else if (20 >= arr[i].value && arr[i].value >= 11) {
						arr[i].url = require('@/assets/img/professionalReport/financialExamination/' + key + '2.png')
					} else if (30 >= arr[i].value && arr[i].value >= 21) {
						arr[i].url = require('@/assets/img/professionalReport/financialExamination/' + key + '3.png')
					} else if (40 >= arr[i].value && arr[i].value >= 31) {
						arr[i].url = require('@/assets/img/professionalReport/financialExamination/' + key + '4.png')
					} else if (50 >= arr[i].value && arr[i].value >= 41) {
						arr[i].url = require('@/assets/img/professionalReport/financialExamination/' + key + '5.png')
					} else if (60 >= arr[i].value && arr[i].value >= 51) {
						arr[i].url = require('@/assets/img/professionalReport/financialExamination/' + key + '6.png')
					} else if (70 >= arr[i].value && arr[i].value >= 61) {
						arr[i].url = require('@/assets/img/professionalReport/financialExamination/' + key + '7.png')
					} else if (80 >= arr[i].value && arr[i].value >= 71) {
						arr[i].url = require('@/assets/img/professionalReport/financialExamination/' + key + '8.png')
					} else if (90 >= arr[i].value && arr[i].value >= 81) {
						arr[i].url = require('@/assets/img/professionalReport/financialExamination/' + key + '9.png')
					} else if (100 >= arr[i].value && arr[i].value >= 91) {
						arr[i].url = require('@/assets/img/professionalReport/financialExamination/' + key + '10.png')
					}
				}
				return arr;
			}


		}


	}
</script>


<style scoped src="../../assets/css/reportValuation/professionReportNew.css"></style>
<style scoped lang="less">

</style>