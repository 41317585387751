<template>
	<div>
		<div class="newCategory">数据资源贡献程度</div>
		<ul class="contribution">
			<i></i>
			<li>
				<div>序号</div>
				<div style="width: 22%;">无形资产类型</div>
				<div style="width: 19%;">是否有相关资产</div>
				<div class="mark" style="width: 53%;">
					<p>按资产对企业收入贡献影响程度的大小对资产重要性进行打分<i
							style="font-size: 12px;display: inline;font-weight: bold;">(1分为非常不重要，10分为非常重要)</i>
					</p>
					<p style="font-size: 12px;"><!-- 1分为非常重要，10分为非常不重要 --></p>
				</div>
			</li>
			<li v-for="(item,i) in contributionList[1].contribution">
				<div>{{i+1}}</div>
				<div style="width: 22%;" v-html="item.assetTypeName"></div>
				<div style="width: 19%;">
					<div v-if="i==0" class="are">是</div>
					<Select v-model="item.hasAnyAsset" style="width: 100px;" v-else @on-select="event => handleChange(event, i)">
						<Option :value="1">是</Option>
						<Option :value="0">否</Option>
					</Select>

				</div>
				<div class="mark" style="width: 53%;" v-if="item.hasAnyAsset==1">
					<p v-for="f in 10"
						:class="['score',f==item.contributionDegree?'select':'',f<item.contributionDegree?'background':'']"
						@click="setScore(1,i,f)">
						<i>{{f}}分</i>
						<span></span>
						<i v-if="f==1||f==10">{{f}}分</i>
					</p>
				</div>
				<div v-else class="noScoring" style="width: 53%;">此项不需要打分</div>
			</li>
			<i></i>
		</ul>


		<div class="newCategory" style="margin-top: 51px;">无形资产贡献程度</div>
		<ul class="contribution">
			<i></i>
			<li>
				<div>序号</div>
				<div>资产类型</div>
				<div class="mark">
					<p>按资产对企业收入贡献影响程度的大小对资产重要性进行打分</p>
					<p style="font-size: 12px;">(1分为非常不重要，10分为非常重要)</p>
				</div>
			</li>
			<li v-for="(item,i) in contributionList[0].contribution">
				<div>{{i+1}}</div>
				<div>{{item.assetTypeName}}</div>
				<div class="mark">
					<p v-for="f in 10"
						:class="['score',f==item.contributionDegree?'select':'',f<item.contributionDegree?'background':'']"
						@click="setScore(0,i,f)">
						<i>{{f}}分</i>
						<span></span>
						<i v-if="f==1||f==10">{{f}}分</i>
					</p>
				</div>
			</li>
			<i></i>
		</ul>



	</div>
</template>

<script>
	import common from '@/views/dataValuation/common'
	import qs from 'qs'
	import axios from 'axios';

	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		mixins: [common],
		props: {
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},
			url: {
				type: String
			},
			jumpAddress: String, //保存之后的跳转地址


		},
		data() {
			return {
				contributionType: 2,
				iacontributionType: 1, // 贡献类型（1：无形资产， 2：数据资源）
				contributionList: [{
					contribution: [{ //contributionDegree：得分    hasAnyAsset：是否有相关资产（0：没有，1：有）
						assetTypeName: '货币资金',
						hasAnyAsset: 1,
						contributionDegree: 0,
						contributionType: 1
					}, {
						assetTypeName: '固定资产',
						hasAnyAsset: 1,
						contributionDegree: 0,
						contributionType: 1
					}, {
						assetTypeName: '无形资产',
						hasAnyAsset: 1,
						contributionDegree: 0,
						contributionType: 1
					}],
					contributionType: 1
				}, {
					contribution: [{ //contributionDegree：得分    hasAnyAsset：是否有相关资产（0：没有，1：有）
						assetTypeName: '数据资源',
						hasAnyAsset: 1,
						contributionDegree: 0,
						contributionType: 2
					}, {
						assetTypeName: '专利权（如发明专利、实用新型专利、外观设计专利）',
						hasAnyAsset: 1,
						contributionDegree: 0,
						contributionType: 2
					}, {
						assetTypeName: '专有技术',
						hasAnyAsset: 1,
						contributionDegree: 0,
						contributionType: 2
					}, {
						assetTypeName: '著作权（如计算机软件著作权、作品著作权等）',
						hasAnyAsset: 1,
						contributionDegree: 0,
						contributionType: 2
					}, {
						assetTypeName: '商标权',
						hasAnyAsset: 1,
						contributionDegree: 0,
						contributionType: 2
					}, {
						assetTypeName: '特许经营权',
						hasAnyAsset: 1,
						contributionDegree: 0,
						contributionType: 2
					}, {
						assetTypeName: '<i>其他（如销售网络、<div class="assetTypeName"><i>客户关系</i><span class="desc-container"><span></span><div class="desc-main"><p>指通过掌握的客户资源和信息，可以与已经存在的客户保持经常性接触、建立业务关系，持续产生预期收益。</p><span><span></div></span></div>、<div class="assetTypeName"><i>合同权益</i><span class="desc-container"><span></span><div class="desc-main"><p>指在企业与供应商签订的保证或优先提供原材料/半成品/外协加工/服务的协议，该协议可以规定固定价格，也可以没有固定价格。</p><span><span></div></span></div>、域名等）</i>',
						hasAnyAsset: 1,
						contributionDegree: 0,
						contributionType: 2
					}],
					contributionType: 2
				}],
				selected: false


			}
		},
		mounted() {
			this.getContribution()
			this.getIaContribution()
			this.pageModify('false');

		},
		methods: {
			...mapMutations(['pageModify']),
			getContribution() {
				this.$post(this.url + 'dataContribution/getContribution', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						contributionType: this.contributionType // 贡献类型（1：无形资产， 2：数据资源）
					}))
					.then((res) => {

						if (this.$store.state.user.checkStatus.dataContribution) {
							this.selected = true
						}

						if (res.data.content.length > 0) {
							this.contributionList[1].contribution.forEach(itemA => {
								const itemB = res.data.content.find(itemB => itemB.assetTypeName === itemA.assetTypeName);
								if (itemB) {
									itemA.contributionDegree = itemB.contributionDegree;
									itemA.hasAnyAsset = itemB.hasAnyAsset
									itemA.id = itemB.id
								}
							})
						}

					})

			},
			getIaContribution() {
				this.$post(this.url + 'dataContribution/getContribution', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						contributionType: this.iacontributionType
					}))
					.then((res) => {
						if (this.$store.state.user.checkStatus.iAContribution) {
							this.selected = true
						}

						if (res.data.content.length > 0) {
							this.contributionList[0].contribution.forEach(itemA => {
								const itemB = res.data.content.find(itemB => itemB.assetTypeName === itemA.assetTypeName);
								if (itemB) {
									itemA.contributionDegree = itemB.contributionDegree;
									itemA.id = itemB.id
								}
							})
						}

					})
			},
			setScore(j, i, f) {
				this.contributionList[j].contribution[i].contributionDegree = f
				this.selected = true //代表已选择了贡献程度
				this.pageModify('true');
			},
			handleChange(value, i) {
				this.contributionList[1].contribution[i].hasAnyAsset = value
			},
			save() {
				var shouldBreak = false; // 标志变量

				for (var i = 0; i < this.contributionList[1].contribution.length; i++) {
					if (this.contributionList[1].contribution[i].contributionDegree > 0 && this.contributionList[1].contribution[0]
						.contributionDegree == 0) {
						this.showDialogBar(true, 'notEmpty-info', '请对数据资源进行打分')
						shouldBreak = true;
						break;
					}
				}

				if (shouldBreak) {
					return false
				}

				if (this.selected && this.contributionList[1].contribution[0].contributionDegree == 0) {
					this.showDialogBar(true, 'notEmpty-info', '请对数据资源贡献程度进行打分')
					return false
				}

				var contributionDegree = []
				for (var i = 0; i < this.contributionList[0].contribution.length; i++) {
					if (this.contributionList[0].contribution[i].contributionDegree > 0) {
						contributionDegree.push(this.contributionList[0].contribution[i])
					}
				}

				if (contributionDegree.length > 0) {
					var unselected = this.findMissingObjects(this.contributionList[0].contribution, contributionDegree)
					if (unselected.length > 0) {
						this.showDialogBar(true, 'notEmpty-info', '请对' + unselected[0].assetTypeName + '进行打分')
						return false;
					}
				}else{
					if(this.type==13){
						this.showDialogBar(true, 'notEmpty-info', '请对' + this.contributionList[0].contribution[0].assetTypeName + '进行打分')
						return false;
					}
				}

				// console.log(this.contributionList)


				this.$post(this.url + 'dataContribution/saveContributionList', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						contributionList: JSON.stringify(this.contributionList),
						reportType: this.type
					}))
					.then((res) => {
						this.$router.push({
							name: this.jumpAddress,
							query: {
								reportId: res.data.content
							}
						})

					})


			},
			findMissingObjects(arr1, arr2) {
				const missing = [];
				arr1.forEach(obj1 => {
					if (!arr2.some(obj2 => obj2.assetTypeName == obj1.assetTypeName)) {
						missing.push(obj1);
					}
				});
				return missing;
			},
			clickCacheTrueMaskJump() {
				this.hideDialogBar()
			},
			clickCacheMaskJump() {
				this.hideDialogBar()
			},
			closeMaskInfoJump() {
				this.hideDialogBar()
			}



		}



	}
</script>

<style scoped lang="less">
	/deep/ .assetTypeName {
		display: inline-block;
		position: relative;
		top: -1px;

		.desc-container>span {
			width: 10px;
			height: 10px;
			top: -9px;
			left: 2px;
		}

		.desc-main {
			top: 0;
			left: -15px;
		}
	}
</style>