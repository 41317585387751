<template>
	<div :id="id"></div>
</template>

<script>
	import * as echarts from 'echarts'

	export default {
		props: [
			'chartData', 'id', 'chartColor'
		],
		data() {
			return {
				chartName: [],
				chartValue: []


			}
		},
		mounted() {
			for (var i in this.chartData) {
				this.chartName.push(this.setName(this.chartData[i].name));
				this.chartValue.push(this.chartData[i].value);
			};


			this.initChart()
		},
		methods: {
			setName(data) {
				var str = ''
				switch (data) {
					case "市盈率":
						str = "P/E倍数法";
						break;
					case "市净率":
						str = "P/B倍数法";
						break;
					case "市销率":
						str = "P/S倍数法";
						break;
					case "市现率":
						str = "EV/EBITDA倍数法";
						break;
					case "市盈率倍数法":
						str = "P/E倍数法";
						break;
					case "市净率倍数法":
						str = "P/B倍数法";
						break;
					case "市销率倍数法":
						str = "P/S倍数法";
						break;
					case "企业价值/息税折旧摊销前利润倍数法":
						str = "EV/EBITDA倍数法";
						break;

					default:
						str = data
				}

				return str
			},
			initChart() {
				var this_ = this
				var fontSize12 = 14;

				var barWidth = 30;

				var option = {
					tooltip: {
						trigger: 'none'
					},
					grid: { // 控制图的大小，调整下面这些值就可以，
						left: '8%',
						right: '0',
						bottom: '5%',
						top: '14%',
						containLabel: true
					},
					xAxis: [{
						type: "category",
						data: this.chartName,
						axisTick: {
							alignWithLabel: true,
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#1459F4"
							}
						},
						axisLabel: {
							color: "#282828",
							interval: 0,
							fontSize: fontSize12,
							lineHeight: 16,
							margin: 10,
							formatter: function(params) {
								var str = ""; // 最终拼接成的字符串
								if (params == 'P/E倍数法') {
									str = 'P/E' + "\n" + '倍数法'
								} else if (params == 'P/B倍数法') {
									str = 'P/B' + "\n" + '倍数法'
								} else if (params == 'P/S倍数法') {
									str = 'P/S' + "\n" + '倍数法'
								} else if (params == 'EV/EBITDA倍数法') {
									str = 'EV/EBITDA' + "\n" + '倍数法'
								}
								return str;
							}

						}
					}],
					yAxis: {
						type: "value",
						nameTextStyle: { // 设置名称样式
							color: '#282828', // 文字颜色
							fontSize: fontSize12, // 字体大小
							padding: [0, 0, 0, -86]
						},
						axisLine: {
							show: true, //轴线
							lineStyle: {
								color: "#1459F4"
							}
						},
						axisLabel: {
							fontSize: fontSize12,
							color: "#282828"
						},
						splitLine: {
							show: false //网格线
						}
					},
					series: [{
						name: "数据上椭圆",
						type: 'pictorialBar',
						symbolSize: [barWidth, 12],
						symbolPosition: 'end',
						z: 12,
						label: {
							show: true,
							position: 'top',
							fontSize: fontSize12,
							color: '#282828',
							padding: [0, 0, 5, 0],
							formatter: function(params, index) {
								return this_.method.formateNum(params.value);
							}
						},
						itemStyle: {
							color(params) {
								var colorList = this_.chartColor.topColor
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: this_.method.pictorialBarTop(this_.chartValue, 6)
					}, {
						name: '下椭圆',
						type: 'pictorialBar',
						symbolSize: [barWidth, 12],
						// symbolOffset: [0, 8],
						z: 12,
						itemStyle: {
							color(params) {
								var colorList = this_.chartColor.bottomColor
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: this_.method.pictorialBarBottom(this_.chartValue, 6)
					}, {
						type: 'bar',
						barWidth: barWidth,
						z: 11,
						// barGap:'30%',
						barCategoryGap: 10,
						itemStyle: {
							color(params) {
								var colorList = this_.method.setGradientColor(this_.chartValue, this_.chartColor.lightColor, this_
									.chartColor.darkColor)
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: this_.chartValue
					}]
				};
				var myChart = echarts.init(document.getElementById(this.id));
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);

			}


		}

	}
</script>

<style>
</style>