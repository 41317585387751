<template>
	<div id="main">
		<Header />
		<Banner />

		<div class="mainContent">
			<MyCenterMenu ref="menu" :activeName="$route.meta.name" @getName="getName" />

			<div class="userinfo">
				<Search :titleInfo="searchTitle" :url="domain.dataRealization+'dataProduct/getDataProductList'"
					:parameter="parameter" :allStatus="allStatus" @getList="getList" />

				<div v-if="records.length>0">
					<ul class="boxhead">
						<li style="width: 24%;">数据产品名称</li>
						<li style="width: 23%;">产品类型</li>
						<li style="width: 16%;">发布时间</li>
						<li style="width: 10%;">收费价格</li>
						<li style="width: 14%;">状态</li>
						<li style="width: 13%;">操作</li>
					</ul>
					<ul class="dataList">
						<li v-for="(item,index) in records" :key="index">
							<div style="width: 24%;">{{item.name}}</div>
							<div style="width: 23%;">{{item.typeName}}</div>
							<div style="width: 16%;">{{item.createTime}}</div>
							<div style="width: 10%;">{{item.discuss?'面议':item.price?'¥'+method.formateNum(item.price,2):''}}</div>
							<div style="width: 14%;">
								<p v-if="item.status==3">
									<img src="../../assets/img/myCenter/status1.png" />
									<span class="status" style="color: #029E06;">在售</span>
								</p>
								<p v-else-if="item.status==4">
									<img src="../../assets/img/myCenter/status4.png" />
									<span class="status" style="color: #DF483C;">下架</span>
								</p>
								<p v-else-if="item.status==2">
									<img src="../../assets/img/myCenter/status3.png" />
									<span class="status" style="color: #FF8827;">审核中</span>
								</p>
								<p v-else-if="item.status==0">
									<img src="../../assets/img/myCenter/status2.png" />
									<span class="status" style="color: #C9312D;">审核未通过</span>
								</p>
							</div>
							<div style="width: 13%;">
								<button class="modification" v-if="item.status!=2" @click="amend(item.id,index)">修改</button>
								<button class="examine" v-if="item.status!=0" @click="amend(item.id,index,1)">查看信息</button>
								<button class="delete" v-if="item.status==0" @click="deleteProduct(item.id)">删除</button>
								<button class="soldout" v-if="item.status==3" @click="updateDataProduct(item.id,4,index)">下架</button>
								<button class="putaway" v-if="item.status==4" @click="updateDataProduct(item.id,3,index)">上架</button>
							</div>
						</li>

					</ul>

					<Page :current="currentPageNumber" :total="total" :page-size="pageSize" prev-text="上一页" next-text="下一页"
						@on-change="pageChange" />
				</div>
				<div v-else class="nothing">
					<img v-if="$store.state.user.subjectColor.mainColor=='#d9001b'"
						:src="require('@/assets/img/img_nothing_red.png')" alt="暂无信息" />
					<img v-else-if="$store.state.user.subjectColor.mainColor=='#00bfbf'"
						:src="require('@/assets/img/img_nothing_green.png')" alt="暂无信息" />
					<img v-else-if="$store.state.user.subjectColor.mainColor=='#f59a23'"
						:src="require('@/assets/img/img_nothing_orange.png')" alt="暂无信息" />
					<img v-else :src="require('@/assets/img/img_nothing.png')" alt="暂无信息" />
					<p>暂无信息</p>
				</div>


			</div>
		</div>


		<Modal v-model="myAlert" class-name="vertical-center-modal del" @on-ok="ok()">
			<img class='warning' src="../../assets/img/remind-01@2x.png">
			<div>
				<p>确定要删除吗？</p>
				<p>删除后无法恢复这条记录</p>
			</div>
		</Modal>

		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import MyCenterMenu from '@/components/myCenterMenu/index.vue'
	import Banner from '@/views/myCenter/banner.vue'
	import Search from '@/components/listSearch/index.vue'
	import qs from 'qs'
	import axios from 'axios'

	import './personalCenter.less'

	import {
		mapActions,
		mapMutations
	} from 'vuex'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			MyCenterMenu,
			Banner,
			Search,

		},
		data() {
			return {
				searchTitle: {},
				name: '',
				records: [],
				currentPageNumber: 1, //当前页码
				total: 0, //总页数
				pageSize: 5, //每页条数
				status: '',
				parameter: {
					pageNum: 1,
					pageSize: 5,
					status: '',
					name: ''
				},
				allStatus: [{ //1:审核通过，0：审核未通过，2：审核中   3：上架（在售）  4：下架
					state: '全部状态',
					statusCode: ''
				}, {
					state: '审核中',
					statusCode: 2
				}, {
					state: '在售',
					statusCode: 3
				}, {
					state: '下架',
					statusCode: 4
				}, {
					state: '审核未通过',
					statusCode: 0
				}],
				myAlert: false,
				productId: '' //要删除产品的id

			}
		},
		mounted() {
			this.findDataProduct(1)

		},
		methods: {
			...mapActions([
				'getProductInfo'
			]),
			getName(data) { //获取搜索栏的信息
				console.log(data)
				this.searchTitle = data
			},
			getList(data) { //获取列表信息
				this.records = data.records
				this.name = data.name
				this.status = data.status
				this.total = data.total
			},
			pageChange(data) { //切换页数
				console.log(data)
				this.findDataProduct(data)
			},
			findDataProduct(pageNum) { //1:审核通过，0：审核未通过，2：未审核 3：上架 4：下架
				this.$post(this.domain.dataRealization + 'dataProduct/getDataProductList', qs.stringify({
						pageNum: pageNum,
						pageSize: this.pageSize,
						status: this.status,
						name: this.name,
						channelFlag:'0'  //0:个人，1：渠道
					}))
					.then((res) => {
						console.log(res.data)
						this.records = res.data.content.records
						this.total = res.data.content.total
					});
			},
			deleteProduct(id) { //删除
				this.myAlert = true
				this.productId = id
			},
			ok() {
				this.$post(this.domain.dataRealization + 'dataProduct/deleteDataProduct', qs.stringify({
						productId: this.productId
					}))
					.then((res) => {
						console.log(res.data)
						this.myAlert = false
						this.findDataProduct(1)
					});
			},
			updateDataProduct(id, status, i) { //上架、下架  1:审核通过，0：审核未通过，2：未审核 3：上架 4：下架
				this.$post(this.domain.dataRealization + 'dataProduct/updateDataProduct', qs.stringify({
						productId: id,
						status: status
					}))
					.then((res) => {
						if (res.data.code == 200) {
							this.records[i].status = status
							this.$Message.success(status == 3 ? '上架成功' : '下架成功')
						}
					});
			},
			amend(id, i, readonly) { //修改、readonly==1是查看信息
				if (readonly == 1) {
					this.$router.push({
						path: 'releaseProduct',
						query: {
							id: id,
							readonly: readonly
						}
					})
				} else {
					this.$router.push({
						path: 'releaseProduct',
						query: {
							id: id
						}
					})
				}

				const data = this.records[i]
				this.getProductInfo({
					data
				})
			}


		}
	}
</script>

<style scoped lang="less">
	.mainContent {
		margin-top: 75px;
		display: flex;
		justify-content: space-between;
	}
</style>