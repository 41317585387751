<template>
	<div>
		<!-- <p class="unit">单位：元</p> -->

		<!-- <div class="yearLimit" style="margin-bottom: 20px;">
			<label for="netWorth">
				无形资产账面净值
				<i style="font-size: 12px;">({{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}})</i>
				<span class="reRed">*</span>
			</label>
			<div class="baseRight">
				<input type="number" id="netWorth" autocomplete="off" v-model="netWorth"
					@blur="displayThousandths($event,netWorth,'netWorth')" @wheel="method.disableWheel($event)">
				<span class="showInput" @click="inputFocus($event)">
					{{netWorth!=''?this.method.formateNum(netWorth,2):''}}
				</span>
				<span class="measure">元</span>
			</div>
		</div> -->

		<div v-for="(bus,index) in singlenessBusiness" class="serviceLine">
			<img class="close" v-if="index!=0" :src="require('@/assets/img/finance/close.png')" alt="关闭"
				@click="delBusiness(index)" />
			<div class="yearLimit businessName">
				<label for="businessName">业务名称<span class="reRed">*</span></label>
				<div class="baseRight">
					<input type="text" id="businessName" autocomplete="off" placeholder="请输入业务名称" v-model="bus.businessName"
						@blur="change">
				</div>
			</div>
			<div class="yearLimit" style="margin-bottom: 20px;">
				<label for="totalYear">预计未来收益年限<span class="reRed">*</span></label>
				<div class="baseRight">
					<input type="number" id="totalYear" autocomplete="off" v-model="bus.serviceLife"
						@blur="changeYear(bus.serviceLife)" @wheel="method.disableWheel($event)">
					<span class="measure">年</span>
				</div>
				<p v-text="'（2年≤年限≤10年）'"></p>
			</div>


			<div class="resourceRditBox resourceFrame specialty" v-if="bus.isShowRedact">
				<i></i>
				<ul>
					<li>
						<label for="companyName">数据资源名称<span class="reRed">*</span></label>
						<div class="baseRight empty">
							<input type="text" id="companyName" class="required" data-value='false' v-model="bus.redact.resourceName"
								@focus="focus($event)" @change="change" autocomplete="off" maxlength="30">
						</div>
					</li>
					<li>
						<label>估值基准日数据资源账面原值</label>
						<div class="baseRight">
							<input type="number" autocomplete="off" v-model="bus.redact.originalValue"
								@blur="displayThousandths($event,bus.redact.originalValue,'originalValue')" @focus="focus($event)"
								@change="change" @wheel="method.disableWheel($event)">
							<div class="showInput" @click="inputFocus($event)">
								{{bus.redact.originalValue?method.formateNum(bus.redact.originalValue,2):''}}
							</div>
							<span class="measure">元</span>
						</div>
					</li>
					<li>
						<label>估值基准日数据资源账面净值<span class="reRed">*</span></label>
						<div class="baseRight">
							<input type="number" autocomplete="off" v-model="bus.redact.netValue"
								@blur="displayThousandths($event,bus.redact.netValue,'netValue')" @focus="focus($event)"
								@change="change" @wheel="method.disableWheel($event)">
							<div class="showInput" @click="inputFocus($event)">
								{{bus.redact.netValue?method.formateNum(bus.redact.netValue,2):''}}
							</div>
							<span class="measure">元</span>
						</div>
					</li>
					<li>
						<label>数据类型（多选）<span class="reRed">*</span></label>
						<div class="baseRight">
							<div class="dataType">
								<p v-for="(type,d) in dataType" :key="'dataType'+d">
									<input type="checkbox" :id="'dataType'+d+'_'+index"
										:checked="bus.redact.dataResourceType&&bus.redact.dataResourceType.indexOf(type) != -1?'checked':''"
										@change="setDataType(bus.redact,type,index)" />
									<label :for="'dataType'+d+'_'+index">{{type}}</label>
								</p>
							</div>
						</div>
					</li>
					<li>
						<label>数据资源介绍<span class="reRed">*</span></label>
						<div class="baseRight text-area-container">
							<Input v-model="bus.redact.resDescribe" type="textarea" :maxlength="maxLength"
								placeholder="请简要描述数据资源产品/服务情况，包括不限于其功能、特点、使用场景和潜在用途等方面。" />
							<span
								class="char-count">{{bus.redact.resDescribe? bus.redact.resDescribe.length:0 }}/{{ maxLength }}</span>
						</div>
					</li>
				</ul>

				<div class="saveBox2">
					<span data-isClick="false" @click="cancelRedact('',index)">取消</span>
					<span data-isClick="false" @click="addSave('',index)">确定</span>
				</div>

				<i></i>
			</div>

			<div class="resourceList specialtyResourceList">
				<p class="addBtn" v-if="!bus.isShowRedact" @click="addSet(index)">添加</p>
				<ul>
					<li v-for="(item,i) in bus.dataResources">

						<div class="resourceRditBox listEditing resourceFrame specialty" ref="editData">
							<i></i>
							<ul>
								<li>
									<label for="companyName">数据资源名称<span class="reRed">*</span></label>
									<div class="baseRight empty">
										<input type="text" id="companyName" class="required" data-value='false'
											v-model="bus.redact.resourceName" @focus="focus($event)" @change="change" autocomplete="off"
											maxlength="30">
									</div>
								</li>
								<li>
									<label>估值基准日数据资源账面原值</label>
									<div class="baseRight">
										<input type="number" autocomplete="off" v-model="bus.redact.originalValue"
											@blur="displayThousandths($event,bus.redact.originalValue,'originalValue')" @focus="focus($event)"
											@change="change" @wheel="method.disableWheel($event)">
										<div class="showInput" @click="inputFocus($event)">
											{{bus.redact.originalValue?method.formateNum(bus.redact.originalValue,2):''}}
										</div>
										<span class="measure">元</span>
									</div>
								</li>
								<li>
									<label>估值基准日数据资源账面净值<span class="reRed">*</span></label>
									<div class="baseRight">
										<input type="number" autocomplete="off" v-model="bus.redact.netValue"
											@blur="displayThousandths($event,bus.redact.netValue,'netValue')" @focus="focus($event)"
											@change="change" @wheel="method.disableWheel($event)">
										<div class="showInput" @click="inputFocus($event)">
											{{bus.redact.netValue?method.formateNum(bus.redact.netValue,2):''}}
										</div>
										<span class="measure">元</span>
									</div>
								</li>
								<li>
									<label>数据类型（多选）<span class="reRed">*</span></label>
									<div class="baseRight">
										<div class="dataType">
											<p v-for="(type,d) in dataType" :key="'dataType_'+d+index+i">
												<input type="checkbox" :id="'dataType'+d+'_'+index+'_'+i"
													:checked="item.dataResourceType&&item.dataResourceType.indexOf(type) != -1?'checked':''"
													@click="setDataType(bus.redact,type,index)" />
												<label :for="'dataType'+d+'_'+index+'_'+i">{{type}}</label>
											</p>
										</div>
									</div>
								</li>
								<li>
									<label>数据资源介绍<span class="reRed">*</span></label>
									<div class="baseRight text-area-container">
										<Input v-model="bus.redact.resDescribe" type="textarea" :maxlength="maxLength"
											placeholder="请简要描述数据资源产品/服务情况，包括不限于其功能、特点、使用场景和潜在用途等方面。" />
										<span
											class="char-count">{{ bus.redact.resDescribe? bus.redact.resDescribe.length:0 }}/{{ maxLength }}</span>
									</div>
								</li>
							</ul>

							<div class="saveBox2">
								<span data-isClick="false" @click="cancelRedact(i,index)">取消</span>
								<span data-isClick="false" @click="addSave(i,index)">确定</span>
							</div>

							<i></i>
						</div>

						<div class="detailedDisplay resourceFrame">
							<i></i>
							<p class="operate">
								<span @click="edit(i,index)">编辑</span>
								<span @click="del(i,index)">删除</span>
							</p>
							<div>
								<span>数据资源名称：</span>
								<span>{{item.resourceName}}</span>
							</div>

							<div>
								<p>
									<span>估值基准日数据资源账面原值：</span>
									<span>{{item.originalValue?method.formateNum(item.originalValue,2)+'元':''}}</span>
								</p>
								<p>
									<span>估值基准日数据资源账面净值：</span>
									<span>{{method.formateNum(item.netValue,2)}}元</span>
								</p>
							</div>
							<div>
								<span>数据类型：</span>
								<div style="width: 80%;">
									<i v-for="(type,d) in item.dataResourceType">{{d==item.dataResourceType.length-1? type:type+'、'}}</i>
								</div>
							</div>
							<div>
								<span>数据资源介绍：</span>
								<span>{{item.resDescribe}}</span>
							</div>
							<i></i>
						</div>
					</li>

				</ul>


			</div>

		</div>


		<p class="line"></p>
		<p class="addBusinessType" @click="addBusinessType">+ 添加业务类型</p>

		<div class="totalNetWorth" v-if="this.productSum[2].value">
			<span>数据资源账面净值合计</span>
			<span>{{productSum[2].value?method.formateNum(productSum[2].value,2):''}}</span>
		</div>


		<Modal v-model="model" class-name="vertical-center-data" :mask-closable="false" @on-ok="submitDialog">
			<img class='warning' src="../../../../assets/img/fillin/unsave.png">
			<p>{{delName=='business'?'确定删除该业务线的数据资源吗?': '确定是否删除？'}}</p>
		</Modal>

	</div>
</template>

<script>
	import common from '@/views/dataValuation/common'
	import qs from 'qs'
	import axios from 'axios';

	import {
		mapState,
		mapMutations
	} from 'vuex'

	import {
		merge
	} from 'lodash'

	export default {
		mixins: [common],
		props: {
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},
			url: String,
			jumpAddress: String //保存之后的跳转地址

		},
		data() {
			return {
				year: new Date().getFullYear(),
				model: false,
				createTime: '',
				netWorth: '', //无形资产
				iaBookValue: '', //会计政策统计表中无形资产账面净值总计
				dataType: ['数字', '图片', '音频', '视频', '文字', '其他'],
				singlenessBusiness: [{
					seq: 1,
					valuationReportId: "",
					businessName: "", //业务线名称
					serviceLife: "", //年限
					isShowRedact: true, //是否展示编辑框
					redact: {
						resourceName: '',
						originalValue: '', //原值
						netValue: '', //净值
						resDescribe: '', //数据资源介绍
						dataResourceType: [], //数据类型
						newType: []
					},
					dataResources: [
						// 	{
						// 	name: '',
						// 	originalValue: '', //原值
						// 	netValue: '' //净值
						// }
					]
				}],
				productSum: [{
					value: ''
				}, {
					value: ''
				}, {
					value: ''
				}],
				isSave: false,
				businessIdx: 0,
				resourcesIdx: 0,
				resourceType: 1, // 0：单一  1：多种
				delName: '',
				hint: false,
				maxLength: 200, //介绍最大长度


			}
		},
		mounted() {
			this.getDataResources()
			this.pageModify('false');
		},
		methods: {
			...mapMutations(['pageModify']),
			setDataType(obj, type, index) {
				const typeArry = JSON.parse(JSON.stringify(obj.dataResourceType))
				const newType = []

				if (typeArry.indexOf(type) == -1) {
					typeArry.push(type)
				} else {
					typeArry.splice(typeArry.indexOf(type), 1)
				}

				this.dataType.map((item, i) => {
					typeArry.map((genre, g) => {
						if (item == genre) {
							newType.push(item)
						}
					})
				})

				this.$set(obj, 'dataResourceType', newType)

				console.log('mmm----', obj)
				console.log('第几个----', index)
			},
			focus(e) {
				e.currentTarget.setAttribute("placeholder", "")
			},
			inputFocus(e) {
				this.isSave = false
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'inline-block';
				e.currentTarget.previousSibling.focus();
			},
			changeYear(value) {
				this.isSave = false
				if (value > 10 || value < 2) {
					this.showDialogBar(true, 'notEmpty', '年限不正确')
				}

			},
			change() {
				this.isSave = false
				this.pageModify('true');
			},
			summation() { //计算合计
				var originalValue = 0 //原值总和
				var netValue = 0 //净值总和

				this.singlenessBusiness.map((el, s) => {
					el.dataResources.map((item, k) => {
						originalValue += Number(item.originalValue)
						netValue += Number(item.netValue)
					})
				})

				this.productSum[1].value = originalValue
				this.productSum[2].value = netValue
			},
			displayThousandths: function(e, val, key, i, j) {
				this.isSave = false
				e.currentTarget.setAttribute("placeholder", "请输入")
				var y = String(val).indexOf(".") + 1; //获取小数点的位置
				var count = String(val).length - y; //获取小数点后的个数

				if (val.length < 20) {
					console.log('符合')

				} else {
					console.log('走l')

					this.singlenessBusiness[i].dataResources[j][key] = ''

					this.$Message.error({
						background: true,
						content: '请输入合理数据'
					});
					return false;
				}

				this.summation()

				e.currentTarget.nextSibling.style.display = 'inline-block';

				//调用缓存共用方法
				// if (!this.$route.query.reportId || (this.$route.query.reportId && this.isCacheValue == '0')) {
				// 	this.method.caching("financialBookValue", JSON.stringify(this.data), this.$route.query.type, '', this)
				// }
			},
			addBusinessType() {
				this.isSave = false
				this.singlenessBusiness.push({
					seq: this.singlenessBusiness.length + 1,
					valuationReportId: "",
					businessName: "", //业务线名称
					serviceLife: "", //年限
					dataResources: [],
					isShowRedact: true, //是否展示编辑框
					redact: {
						resourceName: '',
						originalValue: '', //原值
						netValue: '', //净值
						resDescribe: '', //数据资源介绍
						dataResourceType: [], //数据类型
						newType: []
					}
				})
			},
			del(i, j) {
				this.model = true
				this.businessIdx = j
				this.resourcesIdx = i
				this.delName = 'resources'
			},
			delBusiness(i) {
				this.model = true
				this.businessIdx = i
				this.delName = 'business'
			},
			submitDialog() {
				this.isSave = false
				if (this.delName == 'resources') {
					this.singlenessBusiness[this.businessIdx].dataResources.splice(this.resourcesIdx, 1);

					if (this.singlenessBusiness[this.businessIdx].dataResources.length == 0) {
						this.singlenessBusiness[this.businessIdx].isShowRedact = true
						this.emptyData(this.businessIdx)
					}

				} else {
					this.singlenessBusiness.splice(this.businessIdx, 1)
				}

				this.summation()
				this.saveInterface()
			},
			setEditBoxShow(i, index) {
				$('.resourceList').eq(index).find('.listEditing').eq(i).css('display', 'block')
				$('.resourceList').eq(index).find('.detailedDisplay').eq(i).css('display', 'none')
				$('.resourceList').eq(index).find('.addBtn').css('display', 'none')

			},
			setEditBoxHide(i, index) {
				$('.resourceList').eq(index).find('.listEditing').eq(i).css('display', 'none')
				$('.resourceList').eq(index).find('.detailedDisplay').eq(i).css('display', 'block')

			},
			edit(i, index) { //编辑
				this.singlenessBusiness[index].isShowRedact = false
				this.singlenessBusiness[index].redact = this.convertData(this.singlenessBusiness[index].dataResources[i])
				this.setEditBoxShow(i, index)

			},
			convertData(obj) {
				const newData = JSON.parse(JSON.stringify(obj));;
				return newData
			},
			emptyData(index) {
				var obj = {
					resourceName: '',
					originalValue: '', //原值
					netValue: '', //净值
					resDescribe: '', //数据资源介绍
					dataResourceType: [], //数据类型
					newType: []
				}
				this.singlenessBusiness[index].redact = this.convertData(obj)
			},
			addSet(index) {
				this.singlenessBusiness[index].isShowRedact = true //显示初始的编辑框
				this.emptyData(index)
			},
			getDataResources() {
				this.$post(this.url + 'dataResources/getDataResources', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						resourceType: this.resourceType
					}))
					.then(res => {
						if (!res.data.content.netWorth && sessionStorage.netWorth) {
							this.netWorth = sessionStorage.netWorth
						} else {
							this.netWorth = this.method.numRound(res.data.content.netWorth)
							sessionStorage.netWorth = this.netWorth
						}

						this.iaBookValue = res.data.content.iaBookValue ? res.data.content.iaBookValue : ''
						this.createTime = res.data.content.createTime


						if (res.data.content.multiple) {

							this.singlenessBusiness = []
							res.data.content.multiple.map(el => {
								if (el.businessName == 'productSum') {
									this.productSum = JSON.parse(el.dataResources)
								} else {
									el.dataResources = JSON.parse(el.dataResources)
									if (el.dataResources.length > 0) {
										el.isShowRedact = false

										el.dataResources.map(item => {
											item.dataResourceType = item.dataResourceType ? item.dataResourceType : []
										})

									} else {
										el.isShowRedact = true
									}

									el.redact = {
										resourceName: '',
										originalValue: '', //原值
										netValue: '', //净值
										resDescribe: '', //数据资源介绍
										dataResourceType: [], //数据类型
										newType: []
									}

									this.singlenessBusiness.push(el)
								}
							})

						}


						console.log('多种数据-------', this.singlenessBusiness)


					})

			},
			save() {
				var this_ = this
				var shouldBreak = false; // 标志变量  

				for (var i = 0; i < this.singlenessBusiness.length && !shouldBreak; i++) {
					this.singlenessBusiness[i].seq = i + 1
					for (var j = 0; j < this.singlenessBusiness[i].dataResources.length && !shouldBreak; j++) {
						if (!this.singlenessBusiness[i].businessName) {
							this.showDialogBar(true, 'notEmpty', '请输入业务名称');
							shouldBreak = true; // 设置标志变量为true，以跳出循环  
							break; // 跳出内层循环  
						} else if (!this.singlenessBusiness[i].dataResources[j].name) {
							this.showDialogBar(true, 'notEmpty', '请输入数据资源名称');
							shouldBreak = true; // 设置标志变量为true，以跳出循环  
							break; // 跳出内层循环  
						} else if (this.singlenessBusiness[i].dataResources[j].netValue == '' || this.singlenessBusiness[i]
							.dataResources[j].netValue < 0) {
							this.showDialogBar(true, 'notEmpty', '账面净值不能小于0');
							shouldBreak = true;
							break;
						} else if (this.singlenessBusiness[i].serviceLife > 10 || this.singlenessBusiness[i].serviceLife < 2) {
							this.showDialogBar(true, 'notEmpty', '年限不正确');
							shouldBreak = true;
							break;
						}
					}

					if (shouldBreak) {
						break; // 如果需要，这里可以再加一个break来确保跳出外层循环  
					}
				}

				// 这里的代码会在满足条件并跳出循环后执行  
				if (shouldBreak) {
					return false
				}

				if (this.method.numRound(this.productSum[2].value) < 0) {
					this.showDialogBar(true, 'notEmpty-info', '账面净值不能小于0');
					return false
				}

				if (this.productSum[1].value && this.method.numRound(this.productSum[2].value) > this.method.numRound(this
						.productSum[1].value)) {
					this.showDialogBar(true, 'notEmpty-info', '数据资源账面净值应小于或等于数据资源账面原值，请核实数据准确性。');
					return false
				}

				// if (this.method.numRound(this.productSum[2].value) > this.method.numRound(this.netWorth)) {
				// 	this.hint = true
				// 	this.showDialogBar(true, 'notEmpty-info', '数据资源账面净值应小于或等于无形资产账面净值，请核实数据准确性。');
				// 	return false
				// }



				sessionStorage.netWorth = this.method.numRound(this.netWorth)
				console.log('可以保存----', this.singlenessBusiness)


				this.saveInterface()


			},
			saveInterface(goUrl) {
				sessionStorage.netWorth = this.method.numRound(this.netWorth)
				var this_ = this
				if (!this.isSave) {
					this.isSave = true
					this.$post(this.url + 'dataResources/saveDataResources', qs.stringify({
							reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
							dataResources: JSON.stringify(this.singlenessBusiness),
							productSum: JSON.stringify(this.productSum),
							netWorth: this.netWorth,
							reportType: this.type,
							resourceType: this.resourceType
						}))
						.then(res => {
							this.isSave = false
							this.pageModify('false');
							if (res.data.code == 200) {
								if (goUrl) {
									setTimeout(function() {
										this_.$router.push({
											name: this_.jumpAddress,
											query: {
												reportId: res.data.content
											}
										})
									}, 500)
								} else {
									if (!this.$route.query.reportId) {
										this.$router.push({
											query: merge({}, {
												'reportId': res.data.content
											})
										})
									}

								}

							}

						})
				}
			},
			cancelRedact(i, index) {
				this.emptyData(index)

				if (i === '') {
					if (this.singlenessBusiness[index].dataResources.length > 0) {
						this.singlenessBusiness[index].isShowRedact = false
					}
				} else {
					this.singlenessBusiness[index].isShowRedact = false
					$('.resourceList').eq(index).find('.addBtn').css('display', 'block')
					this.setEditBoxHide(i, index)
				}

			},
			addSave(i, index) {

				if (!this.singlenessBusiness[index].businessName) {
					this.showDialogBar(true, 'notEmpty-info', '请输入业务名称');
					return false
				}

				if (Number(this.singlenessBusiness[index].serviceLife) > 10 || Number(this.singlenessBusiness[index]
						.serviceLife) < 2) {
					this.showDialogBar(true, 'notEmpty', '年限不正确');
					return false
				}

				if (!this.singlenessBusiness[index].redact.resourceName) {
					this.showDialogBar(true, 'notEmpty-info', '请输入数据资源名称');
					return false
				}

				if (!this.singlenessBusiness[index].redact.netValue) {
					this.showDialogBar(true, 'notEmpty-info', '请输入数据资源账面净值');
					return false
				} else if (this.method.numRound(this.singlenessBusiness[index].redact.netValue) < 0) {
					this.showDialogBar(true, 'notEmpty-info', '数据资源账面净值不能小于0');
					return false
				}

				if (!this.singlenessBusiness[index].redact.resDescribe) {
					this.showDialogBar(true, 'notEmpty-info', '请输入数据资源介绍');
					return false
				}

				if (this.singlenessBusiness[index].redact.dataResourceType.length == 0) {
					this.showDialogBar(true, 'notEmpty-info', '请选择数据类型');
					return false
				}

				if (this.singlenessBusiness[index].redact.netValue && this.singlenessBusiness[index].redact.originalValue && this
					.method.numRound(this.singlenessBusiness[index].redact.netValue) > this.method.numRound(this
						.singlenessBusiness[index].redact.originalValue)) {
					this.showDialogBar(true, 'notEmpty-info', '数据资源账面净值应小于或等于数据资源账面原值，请核实数据准确性。');
					return false
				}

				// if (this.method.numRound(this.productSum[2].value) > this.method.numRound(this.netWorth)) {
				// 	this.showDialogBar(true, 'notEmpty-info', '数据资源账面净值应小于或等于无形资产账面净值，请核实数据准确性。');
				// 	return false
				// }


				if (i === '') {
					this.singlenessBusiness[index].dataResources.unshift(this.singlenessBusiness[index].redact)
				} else {
					console.log('列表中的编辑---------')
					this.setEditBoxHide(i, index)
					$('.resourceList').eq(index).find('.addBtn').css('display', 'block')

					this.$set(this.singlenessBusiness[index].dataResources, i, this.convertData(this.singlenessBusiness[index]
						.redact))
				}
				this.singlenessBusiness[index].isShowRedact = false


				this.summation() //计算合计
				this.saveInterface()
				console.log('点击了确定', this.singlenessBusiness)
				console.log('合计------', this.productSum)
			},
			nextStep() {
				var this_ = this

				this.saveInterface(true)

			},


			//是  载入缓存
			clickCacheTrueMaskJump() {
				this.hideDialogBar()
			},

			//否  载入缓存
			clickCacheMaskJump() {
				this.hideDialogBar()
			},
			closeMaskInfoJump() {
				this.hideDialogBar()
				if (this.hint) {
					this.saveInterface()
				}
			}


		}



	}
</script>

<style scoped lang="less">
	.serviceLine:first-child {
		border-top: none;
	}
	
	.serviceLine {
		width: 100%;
		border-top: 3px dotted #fff;
		padding-top: 30px;
		margin-bottom: 40px;
		position: relative;

		.close {
			width: 26px;
			height: 26px;
			position: absolute;
			right: 0;
		}
	}

	.line {
		border-bottom: 3px dotted #fff;
	}


	.addBusinessType {
		width: 480px;
		height: 36px;
		line-height: 36px;
		background: #29FFFC;
		border-radius: 6px;
		font-size: 14px;
		color: #1237C6;
		text-align: center;
		margin: 25px auto;
		cursor: pointer;
	}

	.addBusinessType:hover {
		background: #00d5d2;
	}

	.yearLimit {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 10px 0;

		>p {
			font-size: 14px;
			color: #fff;
		}

		label {
			width: 168px;
			font-weight: bold;
			font-size: 16px;
			color: #fff;
		}

		input,
		.baseRight,
		.showInput {
			width: calc(100% - 293px);
		}

		input {
			width: 88%;
			height: 30px;
			background: #1237C6;
			border: 1px solid #29FFFC;
			font-size: 14px;
			color: #fff;
			padding: 0 26px 0 10px;
		}

		input:focus {
			border-color: #fff;
		}

		.showInput {
			width: 80%;
			height: 27px;
			line-height: 28px;
			background: #1237C6;
			padding: 0 6px;
			color: #fff;
			font-size: 14px;
			position: absolute;
			top: 2px;
			left: 2px;
			overflow: hidden;
		}

		.measure {
			width: 25px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			position: absolute;
			top: 0;
			right: 11px;
			color: #fff;
			font-size: 14px;
		}

		.baseRight {
			height: 30px;
			position: relative;
		}
	}
</style>