<template>
	<div>
		<table class="table liaTableInfo" border="0" style="border-collapse: collapse;">
			<thead>
				<tr class="noInput">
					<th style="width: 31%;">项目</th>
					<th style="width: 23%;" v-for="(item,index) in year" :key="index - 1">
						{{type==7?item: item.substring(0,4)+'-12-31'}}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="trOne">流动资产：</td>
					<td v-for="(item,index) in year" :key="index + 1"></td>
				</tr>
				<tr>
					<td class="trTwo">货币资金</td>
					<td v-for="(item,index) in year" :key="index + 2">
						{{judgingCondition('monetaryCapital',index)?method.formateNum(liaTableInfo.monetaryCapital[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">交易性金融资产</td>
					<td v-for="(item,index) in year" :key="index + 3">
						{{judgingCondition('tradingAssets',index)?method.formateNum(liaTableInfo.tradingAssets[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">衍生金融资产</td>
					<td v-for="(item,index) in year" :key="index + 4">
						{{judgingCondition('derivativeAssets',index)?method.formateNum(liaTableInfo.derivativeAssets[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">应收票据</td>
					<td v-for="(item,index) in year" :key="index + 1">
						{{judgingCondition('billReceivable',index)?method.formateNum(liaTableInfo.billReceivable[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">应收账款</td>
					<td v-for="(item,index) in year" :key="index + 5">
						{{judgingCondition('receivables',index)?method.formateNum(liaTableInfo.receivables[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">应收账款融资</td>
					<td v-for="(item,index) in year" :key="index + 6">
						{{judgingCondition('accountReceivable',index)?method.formateNum(liaTableInfo.accountReceivable[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">预付款项</td>
					<td v-for="(item,index) in year" :key="index + 7">
						{{judgingCondition('prepayment',index)?method.formateNum(liaTableInfo.prepayment[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">其他应收款</td>
					<td v-for="(item,index) in year" :key="index + 8">
						{{judgingCondition('otherReceivables',index)?method.formateNum(liaTableInfo.otherReceivables[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">存货</td>
					<td v-for="(item,index) in year" :key="index + 9">
						{{judgingCondition('inventory',index)?method.formateNum(liaTableInfo.inventory[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">合同资产</td>
					<td v-for="(item,index) in year" :key="index + 10">
						{{judgingCondition('contractualAssets',index)?method.formateNum(liaTableInfo.contractualAssets[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">持有待售资产</td>
					<td v-for="(item,index) in year" :key="index + 11">
						{{judgingCondition('saleForAssets',index)?method.formateNum(liaTableInfo.saleForAssets[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">一年内到期的非流动资产</td>
					<td v-for="(item,index) in year" :key="index + 12">
						{{judgingCondition('illiquidAssetsOne',index)?method.formateNum(liaTableInfo.illiquidAssetsOne[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">其他流动资产</td>
					<td v-for="(item,index) in year" :key="index + 13">
						{{judgingCondition('otherCurrentAssets',index)?method.formateNum(liaTableInfo.otherCurrentAssets[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trOne">流动资产合计</td>
					<td v-for="(item,index) in year" :key="index + 14">
						{{judgingCondition('totalCurrentAssets',index)?method.formateNum(liaTableInfo.totalCurrentAssets[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trOne">非流动资产：</td>
					<td v-for="(item,index) in year" :key="index + 15"></td>
				</tr>
				<tr>
					<td class="trTwo">债权投资</td>
					<td v-for="(item,index) in year" :key="index + 16">
						{{judgingCondition('debtInvestment',index)?method.formateNum(liaTableInfo.debtInvestment[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">其他债权投资</td>
					<td v-for="(item,index) in year" :key="index + 17">
						{{judgingCondition('otherDebtInvestment',index)?method.formateNum(liaTableInfo.otherDebtInvestment[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">长期应收款</td>
					<td v-for="(item,index) in year" :key="index + 18">
						{{judgingCondition('longAeceivables',index)?method.formateNum(liaTableInfo.longAeceivables[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">长期股权投资</td>
					<td v-for="(item,index) in year" :key="index + 19">
						{{judgingCondition('longInvestment',index)?method.formateNum(liaTableInfo.longInvestment[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">其他权益工具投资</td>
					<td v-for="(item,index) in year" :key="index + 20">
						{{judgingCondition('otherEquityInvestment',index)?method.formateNum(liaTableInfo.otherEquityInvestment[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">其他非流动金融资产</td>
					<td v-for="(item,index) in year" :key="index + 21">
						{{judgingCondition('otherIlliquidAssets',index)?method.formateNum(liaTableInfo.otherIlliquidAssets[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">投资性房地产</td>
					<td v-for="(item,index) in year" :key="index + 22">
						{{judgingCondition('investmentProperty',index)?method.formateNum(liaTableInfo.investmentProperty[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">固定资产</td>
					<td v-for="(item,index) in year" :key="index + 23">
						{{judgingCondition('fixedAssets',index)?method.formateNum(liaTableInfo.fixedAssets[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">在建工程</td>
					<td v-for="(item,index) in year" :key="index + 24">
						{{judgingCondition('constructionProjects',index)?method.formateNum(liaTableInfo.constructionProjects[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">生产性生物资产</td>
					<td v-for="(item,index) in year" :key="index + 25">
						{{judgingCondition('productiveAsset',index)?method.formateNum(liaTableInfo.productiveAsset[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">油气资产</td>
					<td v-for="(item,index) in year" :key="index + 26">
						{{judgingCondition('oilAndGasAssets',index)?method.formateNum(liaTableInfo.oilAndGasAssets[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">使用权资产</td>
					<td v-for="(item,index) in year" :key="index + 27">
						{{judgingCondition('rightAssets',index)?method.formateNum(liaTableInfo.rightAssets[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">无形资产</td>
					<td v-for="(item,index) in year" :key="index + 28">
						{{judgingCondition('netWorth',index)?method.formateNum(liaTableInfo.netWorth[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">开发支出</td>
					<td v-for="(item,index) in year" :key="index + 29">
						{{judgingCondition('developExpense',index)?method.formateNum(liaTableInfo.developExpense[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">商誉</td>
					<td v-for="(item,index) in year" :key="index + 30">
						{{judgingCondition('goodwill',index)?method.formateNum(liaTableInfo.goodwill[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">长期待摊费用</td>
					<td v-for="(item,index) in year" :key="index + 31">
						{{judgingCondition('longExpenses',index)?method.formateNum(liaTableInfo.longExpenses[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">递延所得税资产</td>
					<td v-for="(item,index) in year" :key="index + 32">
						{{judgingCondition('deferredAssets',index)?method.formateNum(liaTableInfo.deferredAssets[index],2):'-'}}
					</td>
				</tr>
				<tr>
					<td class="trTwo">其他非流动资产</td>
					<td v-for="(item,index) in year" :key="index + 33">
						{{judgingCondition('otherNonCurrentAssets',index)?method.formateNum(liaTableInfo.otherNonCurrentAssets[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trOne">非流动资产合计</td>
					<td v-for="(item,index) in year" :key="index + 34">
						{{judgingCondition('totalNonCurrentAssets',index)?method.formateNum(liaTableInfo.totalNonCurrentAssets[index],2):'-'}}
					</td>
				</tr>
				<tr class="total">
					<td class="trOne">资产总计</td>
					<td v-for="(item,index) in year" :key="index + 35">
						{{judgingCondition('totalAssets',index)?method.formateNum(liaTableInfo.totalAssets[index],2):'-'}}
					</td>
				</tr>

			</tbody>
		</table>
	</div>
</template>
<script>
	export default ({
		props: {
			liaTableInfo: Object,
			year: Array,
			versions: [String, Number],
			stage: {
				type: [String, Number],
				required: false
			},
			type: {
				type: [String, Number],
				required: false
			}

		},
		data() {
			return {
				
				
			}
		},
		mounted() {
			

		},
		methods: {
			judgingCondition: function(key, i, index) {
				if (this.liaTableInfo[key][i] === 0) {
					return true
				} else if (this.liaTableInfo[key][i] != 0 && this.liaTableInfo[key][i] != '-' && this.liaTableInfo[key][i]) {
					return true
				} else {
					return false
				}
			},


		}
	})
</script>
<style scoped lang="less">
	.titleBox {
		margin: 130px 0 0 0;
		padding: 0px 110px;
		display: flex;
		justify-content: space-between;
	}

	.titleBox span {
		line-height: 100%;
		font-size: 20px;
	}

	.titleBox .title {
		text-align: center;
		font-size: 24px;
		font-weight: 400;
		color: #282828;
		margin: 130px 0 0 0;
		line-height: 100%;
	}

	table {
		width: 100%;
		text-align: center;
		border-collapse: collapse;
		margin: 28px auto 0;
		border: 1px solid #000;
	}

	table tr th {
		height: 50px;
		border: 1px solid #000;
		background: #0478fc;
		font-size: 18px;
		color: #fff;
	}

	table tr td {
		height: 33px;
		border: 1px solid #000;
		font-size: 14px;
		color: #282828;
	}

	table>tbody>tr:first-of-type>td {
		border-top: none;
	}

	table>tbody>tr>td:first-child {
		text-align: left;
	}

	.total {
		// background: rgba(20, 22, 244, 0.1);
		font-weight: bold;
	}

	.trOne {
		padding-left: 40px;
		padding-right: 10px;
		font-weight: bold;
	}

	.trTwo {
		padding-left: 57px;
	}

	.trThree {
		padding-left: 84px;
	}

	.trFour {
		padding-left: 125px;
	}

	.title {
		margin: 0 !important;
	}

	table>tbody>tr:not(:first-child) {
		td:not(:first-child) {
			text-align: right;
			padding-right: 38px;
		}
	}
</style>