<template>
	<div id="main">
		<Header />
		<Banner />

		<div class="mainContent">
			<MyCenterMenu ref="menu" />

			<div class="myCenterInfo">
				<div class="userinfo">
					<div>
						<div>
							<span class="name">{{name}}</span>
							<button
								:class="auditStatus=='1'?'authenticated':'unverified'">{{auditStatus=='1'?'已实名认证':'未实名认证'}}</button>
						</div>
						<button class="immediateAuthentication buttonFill" v-if="auditStatus!=1&&auditStatus!=2"
							@click="immediateAuthentication">立即认证</button>
					</div>
				</div>
				<ul class="statisticalData">
					<li>
						<span>
							<i>数据产品</i>
							<i>{{data.product}}</i>
						</span>
						<img src="../../assets/img/myCenter/icon1.png" />
					</li>
					<li>
						<span>
							<i>数据产品交易</i>
							<i>{{data.order}}</i>
						</span>
						<img src="../../assets/img/myCenter/icon2.png" />
					</li>
					<li>
						<span>
							<i>数据测评</i>
							<i>{{data.evaluation}}</i>
						</span>
						<img src="../../assets/img/myCenter/icon3.png" />
					</li>
					<li>
						<span>
							<i>数据估值</i>
							<i>{{data.valuation}}</i>
						</span>
						<img src="../../assets/img/myCenter/icon4.png" />
					</li>
				</ul>
			</div>
		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '../../common/header/flow_header.vue'
	import Footer from '../../common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import MyCenterMenu from '@/components/myCenterMenu/index.vue'
	import Banner from './banner.vue'
	import qs from 'qs'
	import axios from 'axios'
	import './personalCenter.less'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			MyCenterMenu,
			Banner,

		},
		data() {
			return {
				data: {
					product: 0,
					order: 0,
					evaluation: 0,
					valuation: 0
				},
				name: this.$store.state.user.customerInfo.name,
				auditStatus: ''


			}
		},
		watch: {
			name: {
				handler: function(newValue, oldValue) {
					console.log(newValue)
					this.$set(this, 'name', newValue)
				},
				deep: true
			}
		},
		mounted() {

			this.getDataStatistics()
			this.getCustomerInfo()

		},
		methods: {
			getDataStatistics() {
				this.$post(this.domain.dataRealization + 'dataCustomer/getDataStatistics', qs.stringify({}))
					.then((res) => {
						this.data = res.data.content
					})

			},
			getCustomerInfo() { //查用户个人信息
				this.$post(this.domain.dataRealization + 'dataCustomer/getCustomerInfo', qs.stringify({}))
					.then((res) => {
						const data = res.data.content
						this.name = data.name
						this.auditStatus = data.auditStatus

					});
			},
			immediateAuthentication() {
				this.$router.push({
					path: 'certification'
				})
			}


		}
	}
</script>

<style scoped lang="less">
	body[theme='#d9001b'] {
		.userinfo {
			background: url(../../assets/img/myCenter/pictureRed.jpg) center center no-repeat !important;
		}
	}

	.mainContent {
		margin-top: 75px;
		display: flex;
		justify-content: space-between;

		>.myCenterInfo {

			.userinfo {
				width: 100%;
				height: 260px;
				background: url(../../assets/img/myCenter/picture.jpg) center center no-repeat;
				background-size: cover;
				box-shadow: 0px 0px 13px 0px rgba(98, 136, 200, 0.23);
				padding: 30px;
				box-sizing: border-box;

				.immediateAuthentication {
					width: 100px;
					height: 30px;
					font-size: 14px;
					margin-top: 20px;
				}

				>div {
					position: relative;
					top: 50%;
					transform: translateY(-50%);

					>div {
						display: flex;
						align-items: center;
					}


					.name {
						max-width: 400px;
						font-size: 24px;
						font-weight: bold;
						color: #282828;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin-right: 20px;
					}

					.name+button {
						width: 94px;
						height: 28px;
						border-radius: 19px;
						font-size: 14px;
						background: transparent;
						border: 1px solid red;
						color: red;
					}

					button.authenticated {
						border-color: #1459F4;
						color: #1459F4;
					}

					button.unverified {
						border-color: #a5a5a5;
						color: #a5a5a5;
					}

				}

			}

			.statisticalData {
				display: flex;
				justify-content: space-between;
				margin-top: 28px;

				li {
					width: 210px;
					height: 110px;
					background: #FFFFFF;
					display: flex;
					align-items: center;
					padding: 0 14px 0 13px;
					position: relative;
					overflow: hidden;

					span {
						z-index: 2;

						i {
							font-weight: bold;
							color: #333333;
							display: block;
						}

						i:nth-of-type(1) {
							font-size: 20px;
							margin-bottom: 15px;
						}

						i:nth-of-type(2) {
							font-size: 24px;
						}
					}

					img {
						vertical-align: middle;
						position: absolute;
						right: 10px;
					}
				}

				li:first-child {
					box-shadow: 0px 0px 13px 0px rgba(98, 136, 200, 0.23);
					border: 1px solid #ABC5FF;
					background: url(../../assets/img/myCenter/dataproduct.png) center center no-repeat;
					background-size: cover;
				}

				li:nth-of-type(2) {
					box-shadow: 0px 0px 13px 0px rgba(98, 136, 200, 0.23);
					border: 1px solid #A0A1FF;
					background: url(../../assets/img/myCenter/NumberTransactions.png) center center no-repeat;
					background-size: cover;
				}

				li:nth-of-type(3) {
					box-shadow: 0px 0px 13px 0px rgba(98, 136, 200, 0.23);
					border: 1px solid #C3FFED;
					background: url(../../assets/img/myCenter/turnover.png) center center no-repeat;
					background-size: cover;
				}

				li:nth-of-type(4) {
					box-shadow: 0px 0px 13px 0px rgba(98, 136, 200, 0.23);
					border: 1px solid #FFDEC4;
					background: url(../../assets/img/myCenter/dataReport.png) center center no-repeat;
					background-size: cover;
				}
			}

		}
	}
</style>