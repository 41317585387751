<template>
	<div :id="id"></div>
</template>

<script>
	import * as echarts from 'echarts'

	export default {
		props: [
			'chartData', 'id', 'yAxisName', 'chartColor'
		],
		data() {
			return {



			}
		},
		mounted() {
			this.initChart()

		},
		methods: {
			initChart() {
				var this_ = this;
				var barWidth = 50;
				var width=50
				var datayAxis = this.chartData.netArr
				var dataxAxis = this.chartData.yearArray
				var chartGrid = {
					left: '8%',
					right: '12%',
					bottom: '10%',
					top: '14%',
					containLabel: true
				}
				
				this_.method.registerShape(width)

				var	yAxisParams = this_.method.setHasBackgroundData(datayAxis);
				var barData = []
				

				datayAxis.forEach(function(item, i) {
					barData.push(item > 0 ? yAxisParams.max : item == 0 ? 0 : yAxisParams.min)
				})

				var option = {
					tooltip: {
						trigger: 'none'
					},
					animation: false, // 禁用动画
					grid: chartGrid,
					xAxis: [{
						type: "category",
						data: dataxAxis,
						splitLine: {
							show: false
						},
						axisTick: {
							show: false,
							alignWithLabel: true,
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#1459F4"
							}
						},
						axisLabel: {
							color: "#282828",
							fontSize: 16,
							lineHeight: 20,
							margin: 20
						}
					}],
					yAxis: {
						type: "value",
						name: this.yAxisName,
						nameTextStyle: { // 设置名称样式
							color: '#282828', // 文字颜色
							fontSize: 16, // 字体大小
							padding: [0, 0, 0, -72]
						},
						axisLine: {
							show: true, //轴线
							lineStyle: {
								color: "#1459F4"
							}
						},
						axisLabel: {
							fontSize: 16,
							color: "#282828"
						},
						splitLine: {
							show: false //网格线
						}
					},
					series: [{
							name: '预测',
							data: datayAxis,
							type: 'bar',
							barMaxWidth: barWidth,
							itemStyle: {
								color(params) {
									var index = params.dataIndex;
									return params.color = {
										type: "linear",
										x: 0,
										x2: 1,
										y: 0,
										y2: 0,
										colorStops: [{
											offset: 0,
											color: this_.chartColor.leftColorEight[index],
										}, {
											offset: 0.5,
											color: this_.chartColor.leftColorEight[index],
										}, {
											offset: 0.5,
											color: this_.chartColor.rightColorEight[index],
										}, {
											offset: 1,
											color: this_.chartColor.rightColorEight[index],
										}]
									}
								}
							}
						},
						{
							name: "展示数据文本",
							type: 'pictorialBar',
							symbol: "diamond",
							symbolSize: [barWidth, 12],
							z: 10,
							label: {
								show: true,
								position: 'top',
								fontSize: 16,
								color: '#282828',
								padding: [0, 0, 7, 0],
								formatter: function(params, index) {
									return this_.method.formateNum(datayAxis[params.dataIndex].toString());
								}
							},
							itemStyle: {
								color(params) {
									var index = params.dataIndex;
									return params.color = this_.chartColor.cubeTopEight[index]
								}
							},
							emphasis: {
								disabled: true
							},
							data: this_.method.pictorialBarTop(datayAxis, 6)
						}
					]
				};
				var myChart = echarts.init(document.getElementById(this.id));
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);

			}


		}

	}
</script>

<style>
</style>