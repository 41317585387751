<template>
	<div v-if="dupontData">
		<!--构成表-各种率-->
		<div v-if="!paging||(paging&&whichPage==1)">
			<div class="return-on-assets">
				<p>单位：万元、%</p>
				<!--第一行 资产收益率-->
				<div :class="['box', 'dubangNetAssetsRatio',dubangNetAssetsRatioStatus?'decline':'']">
					<p>净资产收益率（ROE）</p>
					<p v-text="dubangNetAssetsRatio + '%'">XX%</p>
				</div>
				<div class="link-line first-link-line"></div>
				<div class="link-box first-link-box"></div>
				<!--第二行-->
				<div class="second-line">
					<div :class="['box', 'totalNetProfitRatio',totalNetProfitRatioStatus?'decline':'']">
						<p>总资产净利率</p>
						<p v-text="totalNetProfitRatio + '%'">XX%</p>
						<!-- <span v-show="totalNetProfitRatioStatus">↓</span> -->
					</div>
					<span>X</span>
					<div :class="['box', 'rightsMul',rightsMulStatus?'decline':'']">
						<p>权益乘数</p>
						<p v-text="Number(rightsMul).toFixed(2)">XX</p>
						<!-- <span v-show="rightsMulStatus">↓</span> -->
					</div>
				</div>
				<div class="link-line second-link-line"></div>
				<div class="link-box second-link-box"></div>
				<div class="link-line third-link-line"></div>
				<!--第三行-->
				<div class="third-line">
					<div :class="['box', 'netProfitRatio', netProfitRatioStatus?'decline':'']">
						<p>净利润率</p>
						<p v-text="netProfitRatio + '%'">XX%</p>
						<span v-show="netProfitRatioStatus">↓</span>
					</div>
					<div :class="['box', 'totalAssetsRatio', totalAssetsRatioStatus?'decline':'']">
						<p>总资产周转率</p>
						<p v-text="totalAssetsRatio + '%'">XX</p>
						<span v-show="totalAssetsRatioStatus">↓</span>
					</div>
					<div :class="['box', 'liabilitiesRatio', liabilitiesRatioStatus?'decline':'']">
						<p>平均资产负债率</p>
						<p v-text="liabilitiesRatio + '%'">XX%</p>
						<span v-show="liabilitiesRatioStatus">↓</span>
					</div>
					<span>
						1&nbsp; ÷&nbsp; (&nbsp; 1&nbsp;-
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
					</span>
				</div>
				<div class="line-box-container">
					<div>
						<div class="link-line fourth-link-line"></div>
						<div class="link-box fourth-link-box"></div>
					</div>
					<div>
						<div class="link-line fourth-link-line"></div>
						<div class="link-box fourth-link-box"></div>
					</div>
					<div>
						<div class="link-line fourth-link-line"></div>
						<div class="link-box fourth-link-box"></div>
					</div>
				</div>
				<!--第四行-->
				<div class="fourth-line">
					<div>
						<div class="box netProfit">
							<p>净利润</p>
							<p v-text="netProfit.toFixed(2)">XX</p>
						</div>
						<span>÷</span>
						<div class="box income">
							<p>营业收入</p>
							<p v-text="income.toFixed(2)">XX</p>
						</div>
					</div>
					<div>
						<div class="box income">
							<p>营业收入</p>
							<p v-text="income.toFixed(2)">XX</p>
						</div>
						<span>÷</span>
						<div class="box avgTotalAssets">
							<p>平均资产总计</p>
							<p v-text="avgTotalAssets.toFixed(2)">XX</p>
						</div>
					</div>
					<div>
						<div class="box totalLiab">
							<p>平均负债合计</p>
							<p v-text="totalLiab.toFixed(2)">XX</p>
						</div>
						<span>÷</span>
						<div class="box avgTotalAssets">
							<p>平均资产总计</p>
							<p v-text="avgTotalAssets.toFixed(2)">XX</p>
						</div>
					</div>
				</div>
			</div>
			<!--杜邦分析解释-->
			<div class="dupon-explain">
				杜邦分析的核心是净资产收益率(ROE)，它反映了所有者投入资本的获利能力和企业 筹资、投资、资产运营等活动的效率。ROE 的高低取决于总资产利润率和权益总资产率的水平。主要因素有三个方面:销售净利率、总资产周转率和权益乘数。
			</div>

		</div>

		<div v-if="!paging||(paging&&whichPage==2)">
			<!--表格-->
			<table border="1" cellspacing="0" cellpadding="0">
				<tbody v-if="type==5">
					<tr>
						<th :style="{'width':(100/(dupontData.years.length+2))+'% !important'}">杜邦分析体系指标</th>
						<th v-for="item in dupontData.years" :style="{'width':(100/(dupontData.years.length+2))+'% !important'}">
							{{item}}
						</th>
						<th :style="{'width':(100/(dupontData.years.length+2))+'% !important'}">
							{{dupontData.years[dupontData.years.length-1]+'行业平均'}}
						</th>
					</tr>
					<tr>
						<td
							:style="{'color':netProfitRatioStatus?'#f00':'#282828','width':(100/(dupontData.years.length+2))+'% !important'}">
							净利润率（%）
						</td>
						<td v-for="(item,i) in dupontData.netProfitRatioYears"
							:style="{'width':(100/(dupontData.years.length+2))+'% !important'}">
							{{i!=dupontData.netProfitRatioYears.length-1? (item*100).toFixed(2):item}}
						</td>
					</tr>
					<tr>
						<td
							:style="{'color':totalAssetsRatioStatus?'#f00':'#282828','width':(100/(dupontData.years.length+2))+'% !important'}">
							总资产周转率（%）
						</td>
						<td v-for="(item,i) in dupontData.totalAssetsRatioYears"
							:style="{'width':(100/(dupontData.years.length+2))+'% !important'}">
							{{i!=dupontData.totalAssetsRatioYears.length-1? (item*100).toFixed(2):item}}
						</td>
					</tr>
					<tr>
						<td
							:style="{'color':rightsMulStatus?'#f00':'#282828','width':(100/(dupontData.years.length+2))+'% !important'}">
							权益乘数
						</td>
						<td v-for="(item,i) in dupontData.rightsMulYears"
							:style="{'width':(100/(dupontData.years.length+2))+'% !important'}">
							{{Number(item).toFixed(2)}}
						</td>
					</tr>
					<tr>
						<td :style="{'width':(100/(dupontData.years.length+2))+'% !important'}">
							净资产收益率（%）
						</td>
						<td v-for="(item,i) in dupontData.netAssetsRatio"
							:style="{'width':(100/(dupontData.years.length+2))+'% !important'}">
							{{i!=dupontData.netAssetsRatio.length-1? (item*100).toFixed(2):item}}
						</td>
					</tr>
				</tbody>
				<tbody v-else>
					<tr>
						<th>杜邦分析体系指标</th>
						<th v-text="year + '年'"></th>
						<th>行业平均</th>
					</tr>
					<tr>
						<td :style="netProfitRatioStatus?'color:#f00;':''">净利润率（%）</td>
						<td v-text="netProfitRatio"></td>
						<td v-text="dupontData.industryNetProfitRatio">Data</td>
					</tr>
					<tr>
						<td :style="totalAssetsRatioStatus?'color:#f00;':''">总资产周转率（%）</td>
						<td v-text="totalAssetsRatio">Data</td>
						<td v-text="dupontData.industryTotalAssetsRatio">Data</td>
					</tr>
					<tr>
						<td :style="rightsMulStatus?'color:#f00;':''">权益乘数</td>
						<td v-text="Number(rightsMul).toFixed(2)">Data</td>
						<td v-text="Number(dupontData.industryRightsMul).toFixed(2)">Data</td>
					</tr>
					<tr>
						<td :style="dubangNetAssetsRatioStatus?'color:#f00;':''">净资产收益率（%）</td>
						<td v-text="dubangNetAssetsRatio">Data</td>
						<td v-text="dupontData.industryNetAssetsRatio">Data</td>
					</tr>
				</tbody>
			</table>
			<!--净利润率、总资产周转率、权益乘数-->
			<div class="rate-container">
				<p>1. 净利润率</p>
				<span v-text="netProfitRatioDes">2017 年公司净利润率为 XX%，低于行业平均水平。</span>
				<span>净利润率可以直观的反映企业的盈利能力，提高净利润率能够提升净资产收益率，是公司最优运营模式。提高净利润率的途径有两种:提高公司议价权或降低成本费用。</span>
			</div>
			<div class="rate-container">
				<p>2. 总资产周转率</p>
				<span v-text="totalAssetsRatioDes">2017年公司总资产周转率为XX%， 高于行业平均水平。</span>
				<span>总资产周转率体现了公司对总资产的利用效率。在不能有效提高毛利率的情况下，企业可通过提高总资产周转速度实现较好的净资产收益率。</span>
			</div>
			<div class="rate-container">
				<p>3. 权益乘数</p>
				<span v-text="rightsMulDes">2017年公司权益乘数为XX，处于行业平均水平。</span>
				<span>权益乘数主要受资产负债率的影响。资产负债率越大，权益乘数越高，企业的杠杆利益越高，但同时也应考虑到相关风险因素，如：偿债风险、资金周转风险等。</span>
			</div>

		</div>

	</div>
</template>
<script>
	export default ({
		props: [
			'dupontData'
		],
		props: {
			dupontData: Object,
			type: {
				type: [String, Number],
				required: false
			},
			versions: {
				type: [String, Number],
				required: false
			},
			paging: {
				type: Boolean,
				required: false
			},
			whichPage: {
				type: [String, Number],
				required: false
			},

		},
		mounted() {
			this.initPage();
		},
		data() {
			return {
				// 净资产收益率
				dubangNetAssetsRatio: "",
				// 总资产净利率
				totalNetProfitRatio: "",
				// 权益乘数
				rightsMul: 0,
				// 净利润率
				netProfitRatio: "",
				// 总资产周转率
				totalAssetsRatio: "",
				// 平均资产负债率
				liabilitiesRatio: "",
				// 净利润
				netProfit: 0,
				// 营业收入
				income: 0,
				// 平均资产总计
				avgTotalAssets: 0,
				// 平均负债合计
				totalLiab: 0,
				year: "",
			}
		},
		methods: {
			initPage: function() {
				var that = this;
				that.dubangNetAssetsRatio = that.ratioFormate(that.dupontData.dubangNetAssetsRatio);
				that.totalNetProfitRatio = that.ratioFormate(that.dupontData.totalNetProfitRatio);
				that.rightsMul = that.dupontData.rightsMul;
				that.netProfitRatio = that.ratioFormate(that.dupontData.netProfitRatio);
				that.totalAssetsRatio = that.ratioFormate(that.dupontData.totalAssetsRatio);
				that.liabilitiesRatio = that.ratioFormate(that.dupontData.liabilitiesRatio);
				that.netProfit = that.dupontData.netProfit;
				that.income = that.dupontData.income;
				that.avgTotalAssets = that.dupontData.avgTotalAssets;
				that.totalLiab = that.dupontData.totalLiab;
				that.year = that.dupontData.year;
			},
			ratioFormate: function(val) {
				return (Number(val) * 100).toFixed(2);
			}
		},
		computed: {
			// 权益乘数  
			rightsMulStatus: function() {
				if (this.type == 5) {
					return (this.dupontData.rightsMulYears[this.dupontData.rightsMulYears.length - 2] * 100 - this.dupontData
						.rightsMulYears[this.dupontData.rightsMulYears.length - 1]) / Math.abs(this.dupontData
						.rightsMulYears[this.dupontData.rightsMulYears.length - 1]) < -0.6
				} else {
					return (this.rightsMul - this.dupontData.industryRightsMul) / Math.abs(this.dupontData.industryRightsMul) <
						-
						0.6
				}

			},
			// 总资产净利率
			totalNetProfitRatioStatus: function() {
				return this.totalNetProfitRatio - this.dupontData.industryTotalNetProfitRatio < 0
			},
			// 净利润率
			netProfitRatioStatus: function() {
				if (this.type == 5) {
					return (this.dupontData.netProfitRatioYears[this.dupontData.netProfitRatioYears.length - 2] * 100 - this
						.dupontData.netProfitRatioYears[this.dupontData.netProfitRatioYears.length - 1]) / Math.abs(this
						.dupontData.netProfitRatioYears[this.dupontData.netProfitRatioYears.length - 1]) < -0.6
				} else {
					return (this.netProfitRatio - this.dupontData.industryNetProfitRatio) / Math.abs(this.dupontData
						.industryNetProfitRatio) < -0.6
				}

			},
			// 总资产周转率
			totalAssetsRatioStatus: function() {
				if (this.type == 5) {
					return (this.dupontData.totalAssetsRatioYears[this.dupontData.totalAssetsRatioYears.length - 2] * 100 -
						this.dupontData.totalAssetsRatioYears[this.dupontData.totalAssetsRatioYears.length - 1]) / Math.abs(
						this.dupontData.totalAssetsRatioYears[this.dupontData.totalAssetsRatioYears.length - 1]) < -0.6
				} else {
					return (this.totalAssetsRatio - this.dupontData.industryTotalAssetsRatio) / Math.abs(this.dupontData
						.industryTotalAssetsRatio) < -0.6
				}

			},
			// 平均资产负债率
			liabilitiesRatioStatus: function() {
				return this.liabilitiesRatio - this.dupontData.industryLiabilitiesRatio < 0
			},
			// 净资产收益率
			dubangNetAssetsRatioStatus: function() {
				return (this.dubangNetAssetsRatio - this.dupontData.industryNetAssetsRatio) / Math.abs(this.dupontData
					.industryNetAssetsRatio) < -0.6
			},
			// 净利润率语句
			netProfitRatioDes: function() {
				if (this.type == 5) {
					return this.dupontData.netProfitTitleDesc
				} else {
					return this.year + '年公司净利润率为' + this.netProfitRatio + '%' + this.dupontData.netProfitDesc + '。'
				}
			},
			// 总资产周转率语句
			totalAssetsRatioDes: function() {
				if (this.type == 5) {
					return this.dupontData.totalAssetsTitleDesc
				} else {
					return this.year + '年公司总资产周转率为' + this.totalAssetsRatio + '%' + this.dupontData.totalAssetsDesc + '。'
				}
			},
			// 权益乘数语句
			rightsMulDes: function() {
				if (this.type == 5) {
					return this.dupontData.rightsMulTitleDesc
				} else {
					return this.year + '年公司权益乘数为' + this.rightsMul.toFixed(2) + '%' + this.dupontData.rightsMulDesc + '。'
				}
			},
		}

	})
</script>


<style scoped lang="less">
	/* 杜邦分析--------------- */

	.page-dupontAnalyzeNew .return-on-assets {
		position: relative;
		width: 100%;
		margin: 0 auto;
		margin-top: 8px;
		margin-bottom: 28px;
	}

	.page-dupontAnalyzeNew .return-on-assets>p {
		position: absolute;
		right: 0;
		top: 0;
		font-size: 16px;
		font-weight: 400;
		color: rgba(40, 40, 40, 1);
		line-height: 30px;
	}

	.page-dupontAnalyzeNew .return-on-assets .box {
		background-size: 100% 100% !important;
	}

	.page-dupontAnalyzeNew .dubangNetAssetsRatio {
		width: 165px;
		height: 128px;
	}

	.page-dupontAnalyzeNew.pvda .dubangNetAssetsRatio {
		width: 169px;
		height: 111px;
	}

	.page-dupontAnalyzeNew .return-on-assets .box:not(.decline) {
		background: url(../../assets/img/professionalReport/dupontAnalyze.png) center center no-repeat;
	}

	.page-dupontAnalyzeNew .return-on-assets .box.decline {
		background: url(../../assets/img/professionalReport/decline.png) center center no-repeat;
	}

	.page-dupontAnalyzeNew.pvda .return-on-assets .box:not(.decline) {
		background: url(../../assets/img/professionalReport/dupontAnalyze.jpg) center center no-repeat;
	}

	.page-dupontAnalyzeNew.pvda .return-on-assets .box.decline {
		background: url(../../assets/img/professionalReport/decline.jpg) center center no-repeat;
	}

	.page-dupontAnalyzeNew .return-on-assets .box {
		position: relative;
		box-sizing: border-box;
	}

	.page-dupontAnalyzeNew .return-on-assets .box>p {
		width: 100%;
		font-size: 18px;
		text-align: center;
	}

	.page-dupontAnalyzeNew .return-on-assets .box>p:first-child {
		margin-top: 3%;
	}


	.page-dupontAnalyzeNew .second-line .box>p:nth-of-type(2) {
		margin-top: 3%;
	}

	.page-dupontAnalyzeNew.pvda .second-line .box>p:nth-of-type(2) {
		margin-top: 0%;
	}

	.page-dupontAnalyzeNew .dubangNetAssetsRatio>p:nth-of-type(2) {
		margin-top: 7%;
	}

	.page-dupontAnalyzeNew.pvda .dubangNetAssetsRatio>p:nth-of-type(2) {
		margin-top: 1%;
	}

	.page-dupontAnalyzeNew .return-on-assets .box>span {
		position: absolute;
		right: 5px;
		top: 7px;
		font-size: 30px;
		text-align: center;
		color: #fff;
	}

	.page-dupontAnalyzeNew .box.dubangNetAssetsRatio {
		margin-left: 444px;
	}

	.page-dupontAnalyzeNew .second-line {
		width: 562px;
		margin: 0 auto;
		margin-left: 260px;
		overflow: hidden;
	}

	.page-dupontAnalyzeNew .second-line>.box {
		width: 150px;
		height: 116px;
		float: left;
	}

	.page-dupontAnalyzeNew.pvda .second-line>.box {
		width: 152px;
		height: 110px;
		float: left;
	}

	.page-dupontAnalyzeNew .second-line>span {
		font-size: 14px;
		font-weight: bold;
		color: #1459F4;
		margin-left: 112px;
		margin-top: 20px;
		text-align: center;
		vertical-align: middle;
	}

	.page-dupontAnalyzeNew .second-line>.box:last-child {
		float: right;
	}

	.page-dupontAnalyzeNew .third-line {
		position: relative;
		width: 100%;
		overflow: hidden;
	}

	.page-dupontAnalyzeNew .third-line>.box {
		width: 133px;
		height: 104px;
		float: left;
	}

	.page-dupontAnalyzeNew .third-line>.box:first-of-type {
		margin-right: 215px;
		margin-left: 92px;
	}

	.page-dupontAnalyzeNew .third-line>.box:last-of-type {
		margin-left: 224px;
	}

	.page-dupontAnalyzeNew .third-line>span {
		position: absolute;
		right: 89px;
		top: 19px;
		font-size: 16px;
		color: #1459F4;
		line-height: 22px;
	}

	.page-dupontAnalyzeNew .fourth-line {
		overflow: hidden;
	}

	.page-dupontAnalyzeNew .fourth-line>div {
		float: left;
		overflow: hidden;
	}

	.page-dupontAnalyzeNew .fourth-line>div:not(:last-child) {
		margin-right: 45px;
	}

	.page-dupontAnalyzeNew .fourth-line>div>.box {
		width: 133px;
		height: 104px;
		float: left;
	}

	.page-dupontAnalyzeNew .fourth-line>div>span {
		float: left;
		width: 24px;
		height: 30px;
		margin-left: 8px;
		margin-right: 8px;
		margin-top: 15px;
		font-size: 22px;
		text-align: center;
	}

	.page-dupontAnalyzeNew .link-line {
		width: 2px;
		background: #1459F4;
	}

	.page-dupontAnalyzeNew .first-link-line {
		height: 11px;
		margin-left: 526px;
	}

	.page-dupontAnalyzeNew .second-link-line {
		height: 11px;
		margin-left: 332px;
	}

	.page-dupontAnalyzeNew .third-link-line {
		width: 119px;
		height: 27px;
		background: url(../../assets/img/professionalReport/thirdLinkLine.png) center center no-repeat;
		background-size: contain;
		margin-left: 748px;
		margin-top: -24px;
	}

	.page-dupontAnalyzeNew .line-box-container {
		margin-left: 77px;
		overflow: hidden;
	}

	.page-dupontAnalyzeNew .line-box-container>div {
		float: left;
	}

	.page-dupontAnalyzeNew .line-box-container>div:first-of-type {
		margin-right: 176px;
	}

	.page-dupontAnalyzeNew .line-box-container>div:last-of-type {
		margin-left: 183px;
	}

	.page-dupontAnalyzeNew .line-box-container>div>.link-line {
		height: 29px;
	}

	.page-dupontAnalyzeNew .fourth-link-line {
		margin-left: 82px;
	}

	.page-dupontAnalyzeNew .link-box {
		width: 160px;
		box-sizing: border-box;
		border: 2px solid #1459F4;
		border-bottom: none;
	}

	.page-dupontAnalyzeNew .first-link-box {
		width: 415px;
		height: 14px;
		margin-left: 332px;
	}

	.page-dupontAnalyzeNew .second-link-box {
		width: 353px;
		height: 11px;
		margin-left: 155px;
	}

	.page-dupontAnalyzeNew .line-box-container>div>.link-box {
		width: 173px;
		height: 11px;
	}

	.page-dupontAnalyzeNew .dupon-explain {
		padding: 12px 20px;
		background: rgba(20, 89, 244, 0.13);
		margin: 0 auto 20px;
		font-size: 20px;
		font-weight: 400;
		color: #282828;
		line-height: 34px;
		text-indent: 2em;
		text-align: justify;
	}

	.page-dupontAnalyzeNew .rate-container {
		width: 100%;
		margin: 20px auto 0;
	}

	.page-dupontAnalyzeNew .rate-container>p {
		font-size: 20px;
		font-weight: bold;
		color: rgba(40, 40, 40, 1);
		line-height: 20px;
		margin-bottom: 8px;
	}

	.page-dupontAnalyzeNew .rate-container>span {
		padding: 0 24px;
		font-size: 20px;
		font-weight: 400;
		color: rgba(40, 40, 40, 1);
		line-height: 32px;
		text-indent: 2em;
	}

	.page-dupontAnalyzeNew table {
		font-size: 16px;
		width: 1008px;
		margin: 0 auto;
	}

	.page-dupontAnalyzeNew table th {
		width: 25%;
		font-size: 20px;
	}

	.page-dupontAnalyzeNew table tr {
		height: 42px;
		font-size: 20px;
	}

	.page-dupontAnalyzeNew table>tbody>tr>td:first-of-type {
		padding-left: 30px;
		text-align: left;
	}

	.page-dupontAnalyzeNew table>tbody>tr>td:nth-child(n+2) {
		padding-right: 50px;
	}

	.page-dupontAnalyzeNew table>tbody>tr>td:nth-of-type(2) {
		padding-left: 48px;
		border-color: #666;
		text-align: center;
	}

	.page-dupontAnalyzeNew table>tbody>tr>td:nth-of-type(3) {
		padding-left: 48px;
		border-color: #666;
		text-align: center;
	}


	/* 杜邦分析--------------- */
</style>