<template>
	<div :id="id"></div>
</template>
<script>
	import * as echarts from 'echarts/lib/echarts.js'

	export default ({
		props: {
			chartData: Array,
			id: String,
			title:String

		},
		data() {
			return {
				radarStyle: {
					fontSize: 20,
					radius: "60%",
					lineHeight: 34,
					nameGap: 5
				}
			}
		},
		mounted() {

			this.initChart();
		},
		methods: {
			//App.vue
			_isMobile: function() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag;
			},
			initChart: function() {
				this.chart = echarts.init(document.getElementById(this.id));
				this.setOptions(this.id, this.chartData, this.radarStyle)
			},
			setOptions: function(id, data, style) {
				var indicatorArr = [],
					valueArr = [];
				for (var i = 0; i < data.length; i++) {
					var obj = {};
					obj.max = 100;
					obj.name = data[i].name;
					indicatorArr.push(obj);
					valueArr.push(data[i].value);
				}
				
				
				var drawOption = {
					tooltip: {
						trigger: 'axis',
					},
					radar: {
						axisName: {
							color: '#000',
							fontSize: style.fontSize,
							lineHeight: style.lineHeight,
							fontWeight: 'bold',
							formatter: function(name, value) {
								let nameResult = "";
								for (var i = 0; i < data.length; i++) {
									if (name == data[i].name) {
										nameResult = data[i].name + data[i].value + '分'
									}
								}
								return nameResult;
							}
						},
						center: ['50%', '50%'],
						radius: style.radius,
						axisNameGap: style.nameGap, // 名称和轴的距离
						fontSize: 16,
						shape: 'polygon', // 雷达图绘制类型:circle,polygon
						axisLine: { // 坐标轴线相关位置
							show: true, // 坐标轴线的显示与否，默认显示，属性show控制显示与否
							symbol: 'none', // 轴线两边的箭头 ["none","none"]
							fontSize: 16,
							color: "#000",
							lineStyle: {
								color: '#d6d6d6',
							}
						},
						indicator: indicatorArr,
						splitArea: {
							show: true,
							areaStyle: {
								color: ["#2867F4", "#5A8BF6", "#89ACF8", "#BCD0FB", "#E7EEFD", ]
							}
						},
						splitLine: {
							lineStyle: {
								color: "#0752F8"
							}
						}
					},
					series: [{
						type: 'radar',
						tooltip: {
							trigger: 'item'
						},
						itemStyle: { //  折线拐点标志的样式
							color: "#fff",
							borderColor: "#0478fc",
							borderWidth: 0.3
						},
						symbol: 'none',
						data: [{
							name: this.title,
							value: valueArr, // 单个数据的值
							label: {
								show: false, // 标签是否显示
								//                  formatter: '{c}',
								formatter: function(p) {
									return formateNum(p.value.toString());
								},
								color: '#000',
								fontSize: 16,
							},
							lineStyle: { // 单项线条样式
								color: '#FFDE00',
								type: 'solid',
							},
							areaStyle: { // 单项区域填充样式
								color: 'rgba(255, 82, 0, 0.26)',
							}
						}],
					}]
				}
				this.chart.setOption(drawOption);
			}
		}
	})
</script>
<style scoped>
	#financialAnalysisPic,
	#flowDiagnosePic {
		width: 560px;
		height: 400px;
		margin: 60px auto;
	}

	@media screen and (max-width: 765px) {

		#financialAnalysisPic,
		#flowDiagnosePic {
			width: 100%;
			height: auto;
			margin: 60px auto;
		}

		.financialAnalysis,
		.flowDiagnose {
			float: inherit;
			margin: 0 0 20px 0;
		}
	}
</style>