import { render, staticRenderFns } from "./examineInformation.vue?vue&type=template&id=298bd390&scoped=true"
import script from "./examineInformation.vue?vue&type=script&lang=js"
export * from "./examineInformation.vue?vue&type=script&lang=js"
import style0 from "./examineInformation.vue?vue&type=style&index=0&id=298bd390&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "298bd390",
  null
  
)

export default component.exports