<template>
	<div>
		<div class="tools">
			<button @click="pdf()">导出PDF</button>
		</div>
		<div id="pageContent">
			<!-- 封面 -->
			<div class="homePage page">
				<div class="content" id="homePage">
					<div class="logoBox">
						<img v-if="pageInfo.logo" id="logo" :src="pageInfo.logo" alt="公司logo" />
					</div>
					<p class="companyName" v-text="pageInfo.baseInfo.companyName"></p>
					<div class="homePageLine"></div>
					<div class="reportType">数据价值测评报告</div>
					<h4>报告编号：{{pageInfo.reportNumber}}</h4>
					<div class="page-one-footer">
						<div style="width:94px;"></div>
						<p>
							<span>{{pageInfo.channelName}}保留对此报告的最终解释权</span>
						</p>
						<div class="date" v-text="pageInfo.createTime ? replaceAll(pageInfo.createTime) : ''"></div>
					</div>
				</div>
			</div>

			<!--目录页-->
			<div class="page">
				<div class="content page-content page-catalogue" id="page-catalogue">
					<h2>目录</h2>
					<div class="generalize">
						<div>
							<span class="serialNumber">1</span>
							<div class="catalogue-category">
								<span class="spreadhead">数据价值测评等级</span>
								<span class="catalogue-pagination">01</span>
							</div>
						</div>
						<div>
							<span class="serialNumber">2</span>
							<div class="catalogue-category">
								<span class="spreadhead">基本信息</span>
								<span class="catalogue-pagination">02</span>
							</div>
						</div>
						<div class="haveTwoLevels">
							<span class="serialNumber">3</span>
							<div class="catalogue-category">
								<span class="spreadhead">软性指标</span>
								<span class="catalogue-pagination">03</span>
							</div>
						</div>
						<ul>
							<li>
								<div>
									<span>基础指标</span>
									<span>03</span>
								</div>
							</li>
							<li>
								<div>
									<span>风险指标</span>
									<span>04</span>
								</div>
							</li>
							<li>
								<div>
									<span>质量指标</span>
									<span>05</span>
								</div>
							</li>
							<li>
								<div>
									<span>流通指标</span>
									<span>06</span>
								</div>
							</li>
							<li>
								<div>
									<span>经济指标</span>
									<span>07</span>
								</div>
							</li>
						</ul>
						<div>
							<span class="serialNumber">4</span>
							<div class="catalogue-category">
								<span class="spreadhead">免责声明</span>
								<span class="catalogue-pagination">08</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 数据价值测评等级 -->
			<div class="page">
				<div class="content page-content" id="page-three">
					<div class="valueResultTitle">
						<img src="../../../assets/img/report/icon25.png" class="icon_">
						<span>数据价值测评等级</span>
					</div>
					<p class="scoreDescription">
						数据价值评价的结果是通过综合评价得出最终的评价得分，并对应不同的测评等级，分为五个等级，分别为A、AA、AAA、AAAA、AAAAA。等级越高，代表数据价值越高。
					</p>
					<p>{{pageInfo.baseInfo.companyName}}数据价值测评等级为：</p>
					<div class="opinionRating">
						<p class="grade">{{pageInfo.evaluationLevel}}</p>
						<p class="softTitle">软性指标</p>
						<ul>
							<li>
								<span>基本指标</span>
								<span>{{question[0].value}}分</span>
							</li>
							<li>
								<span>风险指标</span>
								<span>{{question[1].value}}分</span>
							</li>
							<li>
								<span>质量指标</span>
								<span>{{question[2].value}}分</span>
							</li>
							<li>
								<span>流通指标</span>
								<span>{{question[3].value}}分</span>
							</li>
							<li>
								<span>经济指标</span>
								<span>{{question[4].value}}分</span>
							</li>
						</ul>
					</div>
					<div class="pagination">01</div>
				</div>
			</div>
			<!-- 基本信息 -->
			<div class="page">
				<div class="content page-content" id="page-two">
					<div class="baseInfo">
						<div class="valueResultTitle">
							<img src="../../../assets/img/dataEvaluation/report/icon1.png" class="icon_">
							<span>基本信息</span>
						</div>
						<div class="baseInfoCont">
							<span id="companyName" v-text="pageInfo.baseInfo.companyName"></span>
							<ul>
								<li>
									<span style="vertical-align: top;">所属行业：</span>
									<span id="industry"
										v-text="pageInfo.baseInfo.industryName + '-' + pageInfo.baseInfo.industryChildName"></span>
								</li>
								<li>
									<span>详细地址：</span>
									<span id="companyLocation"
										v-text="pageInfo.baseInfo.address ? pageInfo.baseInfo.address : '暂无数据'"></span>
								</li>
								<li>
									<span>统一社会信用代码：</span>
									<span id="socialCreditCode" v-text="pageInfo.baseInfo.creditCode"></span>
								</li>
								<li>
									<span>法定代表人：</span>
									<span id="corporateRepresentative"
										v-text="pageInfo.baseInfo.legalPeople ? pageInfo.baseInfo.legalPeople : '暂无数据'"></span>
								</li>
								<li>
									<span>成立日期：</span>
									<span id="establishmentDate" v-text="dateFormate(pageInfo.baseInfo.establishDate)"></span>
								</li>
								<li>
									<span>注册资本：</span>
									<span id="establishmentDate"
										v-text="pageInfo.baseInfo.regCapital ? method.formateNum(pageInfo.baseInfo.regCapital,2)+'万元' : '暂无数据'"></span>
								</li>
								<li>
									<span>数据类型：</span>
									<span id="dataResourceType" v-text="dataType ? dataType : '暂无数据'"></span>
								</li>
								<li>
									<span>数据量级：</span>
									<span v-text="pageInfo.dataLevel?pageInfo.dataLevel.optionName.substring(2):'暂无数据'"></span>
								</li>
								<li>
									<span>数据资源所处周期：</span>
									<span v-text="pageInfo.dataPeriod?pageInfo.dataPeriod.optionName.substring(2):'暂无数据'"></span>
								</li>
								<li>
									<span>权属情况：</span>
									<span v-text="pageInfo.dataOwnership?pageInfo.dataOwnership.optionName.substring(2):'暂无数据'"></span>
								</li>
							</ul>
						</div>
						<div class="mainBusiness">
							<div class="valueResultTitle">
								<img src="../../../assets/img/dataEvaluation/report/icon2.png" class="icon_">
								<span>数据资源介绍</span>
							</div>
							<div class="mainBusinessCont">
								<p class="bussinessDesc"
									v-text="!pageInfo.baseInfo.dataDesc ?'暂无数据':pageInfo.baseInfo.dataDesc.length>207? pageInfo.baseInfo.dataDesc.substring(0, 207)+'...' :pageInfo.baseInfo.dataDesc ">
								</p>
							</div>
						</div>
						<div class="equityStructure">
							<div class="valueResultTitle">
								<img src="../../../assets/img/dataEvaluation/report/icon3.png" class="icon_">
								<span>股权结构</span>
							</div>
							<div v-if="pageInfo.baseInfo.holderRation">
								<img src="../../../assets/img/dataEvaluation/report/equityStructureBase.png" class="equityStructureBase"
									alt="饼图">
								<Draw3DPieCanvas :id="'stockStructurePie'" :chartData="pageInfo.baseInfo.holderRation" type="14"
									v-if="initStatus" style="top: 7px;margin-left:-17px;" />
							</div>
							<div v-else style="padding: 20px 0 0 74px;font-size: 20px;color:#000;">暂无数据</div>

						</div>
					</div>
					<div class="pagination">02</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-four">
					<div class="valueResultTitle">
						<img src="../../../assets/img/dataEvaluation/report/icon6.png" class="icon_">
						<span>{{question[0].name}} &nbsp;{{question[0].value}}分</span>
					</div>
					<div class="indicator" v-if="pageInfo.industry">
						<img src="../../../assets/img/dataEvaluation/report/softIndicatorBase.png">
						<HistogramHasBackground :id="'basicIndex'" :chartData="basicIndex" v-if="initStatus" />
					</div>
					<div class="guidelines">
						<p v-for="(item,index) in pageInfo.industry[0]">{{item.description}}</p>
					</div>
					<div class="pagination">03</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-five">
					<div class="valueResultTitle">
						<img src="../../../assets/img/dataEvaluation/report/icon7.png" class="icon_">
						<span>{{question[1].name}} &nbsp;{{question[1].value}}分</span>
					</div>
					<div class="indicator" v-if="pageInfo.industry">
						<img src="../../../assets/img/dataEvaluation/report/softIndicatorBase.png">
						<StereodiamondHasBackground :id="'riskIndicator'" :chartData="riskIndicator" :width="35"
							v-if="initStatus" />
					</div>
					<div class="guidelines">
						<p v-for="(item,index) in pageInfo.industry[1]">{{item.description}}</p>
					</div>
					<div class="pagination">04</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-six">
					<div class="valueResultTitle">
						<img src="../../../assets/img/dataEvaluation/report/icon8.png" class="icon_">
						<span>{{question[2].name}} &nbsp;{{question[2].value}}分</span>
					</div>
					<div class="indicator" v-if="pageInfo.industry">
						<img src="../../../assets/img/dataEvaluation/report/softIndicatorBase.png">
						<StereoBarDiagram :id="'qualityIndex'" :chartData="qualityIndex" v-if="initStatus" />
					</div>
					<div class="guidelines">
						<p v-for="(item,index) in pageInfo.industry[2]">{{item.description}}</p>
					</div>
					<div class="pagination">05</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-seven">
					<div class="valueResultTitle">
						<img src="../../../assets/img/dataEvaluation/report/icon9.png" class="icon_">
						<span>{{question[3].name}} &nbsp;{{question[3].value}}分</span>
					</div>
					<div class="indicator" v-if="pageInfo.industry">
						<img src="../../../assets/img/dataEvaluation/report/softIndicatorBase.png">
						<Stereodiamond :id="'circulationIndex'" :chartData="circulationIndex" :width="25" v-if="initStatus" />
					</div>
					<div class="guidelines" style="margin-top: 28px;">
						<p v-for="(item,index) in pageInfo.industry[3]">{{item.description}}</p>
					</div>

					<div class="pagination">06</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-eight">
					<div class="valueResultTitle" style="margin-top: 41px;">
						<img src="../../../assets/img/dataEvaluation/report/icon10.png" class="icon_">
						<span>{{question[4].name}} &nbsp;{{question[4].value}}分</span>
					</div>
					<div class="indicator" v-if="pageInfo.industry">
						<img src="../../../assets/img/dataEvaluation/report/softIndicatorBase.png">
						<Histogram2 :id="'economicIndicators'" :chartData="economicIndicators" v-if="initStatus" />
					</div>
					<div class="guidelines" style="margin-top: 28px;">
						<p v-for="(item,index) in pageInfo.industry[4]">{{item.description}}</p>
					</div>

					<div class="pagination">07</div>
				</div>
			</div>


			<div class="page">
				<div class="content page-content" id="disclaimer">
					<div class="page-last">
						<img src="../../../assets/img/report/map@2x.png" alt="线上估值" />
						<div class="flow-statement">
							<img :src="pageInfo.logo" alt="线上估值" />
							<ul>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<p class="dian"><img src="../../../assets/img/dataEvaluation/report/statement-01@2x.png" alt="icon">
										</p>
										<p>测评声明</p>
										<p class="dian dian_"><img src="../../../assets/img/dataEvaluation/report/statement-01@2x.png"
												alt="icon"></p>
										<span class="xian"></span>
									</div>
									<div class="statement-main">
										本报告由系统自动生成，系统对数据资源的整体基础指标、质量指标、经济指标、风险指标、成本指标、流通指标等有关的元素进行定性和定量分析，通过逐层分析，确定每个元素的权重；通过对数据多个变量的综合考虑和分析，来研究各个变量之间的相互关系和影响；利用计算机技术、数学和统计学方法对数据进行处理和分析建模，以解释和预测数据各个因子对数据价值变化的影响，选择不同的分析方法和模型，以得出更加准确和客观的评价结果。请本报告使用者知悉。
									</div>
								</li>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<p class="dian"><img src="../../../assets/img/dataEvaluation/report/statement-01@2x.png" alt="icon">
										</p>
										<p>免责声明</p>
										<p class="dian dian_"><img src="../../../assets/img/dataEvaluation/report/statement-01@2x.png"
												alt="icon"></p>
										<span class="xian"></span>
									</div>
									<div class="statement-main">
										系统所采用的数据信息来源于企业自行提供的资料和公开渠道可获取的行业信息和数据，并基于前述资料属实、合法的假设出具本报告，网站亦不对前述资料的准确性、完整性负有实质审核的义务，本报告仅作为对数据资源价值衡量的参考，非抵押、转让等交易的直接证据，网站及其运营方不承担因信赖本报告信息而采取行动所导致的任何损失及一切法律责任。
									</div>
								</li>
							</ul>
						</div>
					</div>

					<div class="pagination">{{channel?'09':'08'}}</div>
				</div>
			</div>



		</div>


		<div id="loading" style="display: none;">
			<div class='base'>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
			</div>
		</div>
	</div>
</template>

<script>
	import qs from 'qs'
	import '@/assets/css/loading.css'
	import PdfOnload from '@/utils/pdf'
	import * as echarts from 'echarts'
	import Draw3DPieCanvas from '@/common/echarts/draw3DPieCanvas.vue'
	import Histogram from '@/common/echarts/histogram.vue'
	import Histogram2 from '@/common/echarts/histogram2.vue'
	import HistogramHasBackground from '@/common/echarts/histogramHasBackground.vue'
	import StereoBarDiagram from '@/common/echarts/stereoBarDiagram.vue'
	import StereodiamondHasBackground from '@/common/echarts/stereodiamondHasBackground.vue'
	import Stereodiamond from '@/common/echarts/stereodiamond.vue'



	export default ({
		components: {
			Draw3DPieCanvas,
			Histogram,
			Histogram2,
			HistogramHasBackground,
			StereoBarDiagram,
			StereodiamondHasBackground,
			Stereodiamond



		},
		data() {
			return {
				reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
				channel: this.$route.query.channel ? this.$route.query.channel : '',
				pdfArr: [],
				pdfIndex: 0,
				pageInfo: {
					createTime: 'xxxx.xx.xx',
					baseInfo: {
						companyName: '',
						establishDate: '',
					},
					reportNumber: '',
					year: [],
					absoluteValuation: {
						ggm: ''
					},
					industry: '',
					firstPageImage: '', //合作公司的logo
					introImage: '', //合作公司的简介
					evaluationLevel: ''
				},
				valueResultNumber: [], //估值结果的数字
				question: [{
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}],
				boxhead: ['项目', 'xxxx年预测'],
				income: ['公司营业收入', 0],
				netAsset: ['公司净资产', 0],
				netProfit: ['公司净利润', 0],
				amortizeProfit: ['公司息税折旧摊销前利润', 0],
				profitDataYearX: ['125', '260'],
				profitDataIncomeX: ['130', '305'],
				profitDataNetAssetX: ['120', '302'],
				profitDataNetAssetY: ['184', '182'],
				profitDataNetProfitX: ['110', '295'],
				machineLearningValuationResults: [], //机器学习估值法结果特殊数字(ML估值法)
				revenueContributionResults: [], //收入贡献法估值
				incomeForecast: {
					data: [],
					year: []
				},
				dataType: '', //数据类型
				portfolio: [],
				netBookValue: [], //账面净值
				summation: 0, //账面净值合计
				netBookValuePaging: [],
				pagesNumber: [],
				// 基础指标
				basicIndex: {
					year: [],
					data: [],
					backgroundColor: ['#D6E3FF', '#D0EDFF'],
					maxColor: ['#AEC8FF', '#B8E4FF'],
					topColor: ['#6F9CFF', '#94D6FE'],
					bottomColor: ['#1459F4', '#008AF3'],
					dataColor: [new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [{
						offset: 0,
						color: "#84AAFF"
					}, {
						offset: 1,
						color: "#2164FC"
					}]), new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [{
						offset: 0,
						color: "#B9E4FF"
					}, {
						offset: 1,
						color: "#0091FF"
					}])]
				},
				// 风险指标
				riskIndicator: {
					xdata: [],
					data: [],
					leftColor: ['#2D6CFA', '#29A3FF'],
					rightColor: ['#1459F4', '#0091FF'],
					topColor: ['#3E79FF', '#4BB1FE'],
					maxLeftColor: ['#C4D6FE', '#C3E6FF'],
					maxRightColor: ['#BDD1FC', '#B7E0FF'],
					maxTopColor: ['#C9DAFF', '#CDE9FF']
				},
				// 质量指标
				qualityIndex: {
					year: [],
					data: [],
					topColor: ['#6F9CFF', '#94D6FE', '#C9AEFF', '#FF8FEF', '#91F9FF'],
					bottomColor: ['#1459F4', '#008AF3', '#8848FF', '#FF4CE6', '#00F1FF'],
					dataColor: [new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [{
						offset: 0,
						color: "#84AAFF"
					}, {
						offset: 1,
						color: "#2164FC"
					}]), new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [{
						offset: 0,
						color: "#B9E4FF"
					}, {
						offset: 1,
						color: "#0091FF"
					}]), new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [{
						offset: 0,
						color: "#D3BEFF"
					}, {
						offset: 1,
						color: "#9157FF"
					}]), new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [{
						offset: 0,
						color: "#FFA8F3"
					}, {
						offset: 1,
						color: "#FF64E9"
					}]), new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [{
						offset: 0,
						color: "#BBFCFF"
					}, {
						offset: 1,
						color: "#4AF5FF"
					}])]
				},
				// 流通指标
				circulationIndex: {
					xdata: [],
					data: [],
					leftColor: ['#2D6CFA', '#29A3FF', '#9863FE'],
					rightColor: ['#1459F4', '#0091FF', '#8848FF'],
					topColor: ['#3E79FF', '#4BB1FE', '#B089FF']
				},
				// 经济指标
				economicIndicators: {
					xdata: [],
					data: [],
					dataColor: ['#1459F4', '#0091FF', '#8848FF', '#FF64E9'],
					backgroundColor: ['#E9EFFE', '#D6EDFF', '#ECE2FF', '#FFE6FB'],
					topColor: ['#9EBCFF', '#6ABFFF', '#B790FF', '#FFB8F5']
				}


			}
		},
		computed: {
			initStatus: function() {
				if (this.pageInfo.baseInfo.companyName) {
					return true;
				} else {
					return false;
				}
			},
			charts() {
				if (this.incomeForecast.data.length > 0) {
					return true;
				} else {
					return false;
				}
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				var that = this;
				this.$post(this.domain.valuationProject + 'valuationReport/getDataValuationReport', qs.stringify({
						reportId: this.reportId,
						channel: this.channel
					}))
					.then((res) => {
						var data = res.data;
						console.log(data)
						if (data.code == 200 && data.content) {
							that.pageInfo = data.content;

							// 数据类型
							if (data.content.baseInfo.dataResourceType) {
								JSON.parse(data.content.baseInfo.dataResourceType).map((el, i) => {
									if (i == JSON.parse(data.content.baseInfo.dataResourceType).length - 1) {
										this.dataType += el
									} else {
										this.dataType += el + '、'
									}
								})
							}


							// 设置估值结果页特殊数字
							var minArr = this.method.switchNum(that.pageInfo.minValue, that.pageInfo.maxValue)
								.min.split('');
							var maxArr = this.method.switchNum(that.pageInfo.minValue, that.pageInfo.maxValue)
								.max.split('');
							minArr.push('12')
							var newArr = minArr.concat(maxArr);
							this.specialNumeric(newArr, "valueResultNumber")

							// 股权结构
							if (that.pageInfo.baseInfo.holderRation) {
								var holderArr = [];
								var newHolderRation = JSON.parse(that.pageInfo.baseInfo.holderRation);
								var deep = [50, 70];
								for (var i = 0; i < newHolderRation.length; i++) {
									holderArr.push({
										name: newHolderRation[i].name,
										y: Number(newHolderRation[i].value),
										sliced: true,
										selected: true
									})
								}
								that.pageInfo.baseInfo.holderRation = holderArr;
							}


							// 软性指标
							this.question = JSON.parse(that.pageInfo.question)
							that.pageInfo.industry = JSON.parse(that.pageInfo.industry)

							this.income[1] = that.pageInfo.financialForecast.income // 营业收入
							this.netAsset[1] = that.pageInfo.financialForecast.netAsset //净资产预测
							this.netProfit[1] = that.pageInfo.financialForecast.netProfit //净利润预测
							this.amortizeProfit[1] = that.pageInfo.financialForecast.amortizeProfit //折旧摊销预测

							console.log('软性指标--', this.question)
							console.log(this.pageInfo.industry)

							// 基础指标 
							this.pageInfo.industry[0].map(item => {
								this.basicIndex.year.push(item.name)
								this.basicIndex.data.push(item.value)
							})

							// 风险指标
							this.pageInfo.industry[1].map(item => {
								this.riskIndicator.xdata.push(item.name)
								this.riskIndicator.data.push(item.value)
							})

							// 质量指标
							this.pageInfo.industry[2].map(item => {
								this.qualityIndex.year.push(item.name)
								this.qualityIndex.data.push(item.value)
							})

							// 流通指标  
							this.pageInfo.industry[3].map(item => {
								this.circulationIndex.xdata.push(item.name)
								this.circulationIndex.data.push(item.value)
							})

							// 经济指标
							this.pageInfo.industry[4].map(item => {
								this.economicIndicators.xdata.push(item.name)
								this.economicIndicators.data.push(item.value)
							})




						}

					})
			},
			// 特殊数字的转换
			specialNumeric: function(data, key, minus) { //minus==1 代表正数   minus==2代表负数
				if (minus == 2) {
					this[key].push({
						val: '12',
						top: "0px"
					})
				}
				data.forEach((val, i) => {
					if (val == ".") {
						this[key].push({
							val: "11",
							top: "62px",
						})
					} else {
						this[key].push({
							val: val,
							top: "0px"
						})
					}
				})
			},
			// 日期格式化
			dateFormate: function(date) {
				if (date) {
					var dataStr = date.split("-");
					var newDataStr = dataStr[0] + "年" + dataStr[1] + "月" + dataStr[2] + "日";
					return newDataStr;
				}
			},
			// 得分校对
			scoreFormate: function(score) {
				if (this.pageInfo.baseInfo.companyName) {
					for (var i = 0; i < this.pageInfo.question.length; i++) {
						if (this.pageInfo.question[i].name == score) {
							return this.pageInfo.question[i].value + '分';
						}
					};
				}
			},
			// 估值结果格式化
			resultFormate: function(min, max) {
				var val = '';
				if (min) {
					return this.method.valuationResultConversion(min, max)
				} else {
					return '无数据'
				}
			},
			valueFormate: function(val) {
				if (val) {
					return this.method.resultConversion(val)
				} else {
					return '无数据'
				}
			},
			/*页脚日期格式转换*/
			replaceAll: function(val) {
				if (val) {
					return val.replace(new RegExp("-", "gm"), ".");
				}

			},
			pdf: function() {
				document.getElementById("loading").style.display = "block";
				var title = this.pageInfo.baseInfo.companyName + '数据价值测评报告.pdf'
				var pageArr = document.getElementsByClassName("content");
				this.pdfArr = [];
				for (var i = 0; i < pageArr.length; i++) {
					this.pdfArr.push(pageArr[i].id)
				}
				console.log(this.pdfArr)
				PdfOnload.getPdf(this.pdfArr, this.pdfIndex, title);
			}
		}


	})
</script>

<style scoped lang="less">
	.tools {
		width: 1160px;
		height: 60px;
		margin: 0 auto;
		padding-top: 10px;
		box-sizing: border-box;
	}

	.tools button {
		float: right;
		width: 120px;
		padding: 8px;
		border: 1px #aaa solid;
		border-radius: 4px;
		outline: none;
		font-size: 16px;
		transition: background-color 0.3s;
		background: #B1781E;
		color: #fff;
		margin-right: 20px;
		cursor: pointer;
	}

	.page {
		width: 100%;
		height: 1754px;
		position: relative;
		line-height: normal;
		vertical-align: middle;
	}

	.content {
		width: 1240px;
		height: 100%;
		background: #fff;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		border-top: 1px solid #ccc;
	}

	.page-content {
		padding-top: 114px;
		padding-left: 110px;
		padding-right: 110px;
	}

	.pagination {
		width: 120px;
		height: 22px;
		line-height: 22px;
		font-size: 24px;
		color: #282828;
		text-align: center;
		position: absolute;
		bottom: 37px;
		left: 50%;
		transform: translateX(-50%);
		background: url(../../../assets/img/dataEvaluation/report/pageNumber.png) bottom center no-repeat;
		background-size: 100% 100%;
	}

	/* 封面 */

	#homePage {
		overflow: hidden;
		background: url(../../../assets/img/dataEvaluation/report/pageIndex_bg.jpg) no-repeat center center;
	}

	.homePage h1 {
		width: 293px;
		height: 99px;
		background: url(../../../assets/img/report/flowData.png)no-repeat center center;
		background-size: 100%;
		margin: 81px 0 0 75px;
		float: left;
	}

	#logo {
		width: 293px;
		margin: 81px 0 0 75px;
	}

	.stock-logo {
		margin: 81px 0 0 75px;
		display: flex;
		align-items: center;
	}

	.stock-logo .logoXian {
		width: 2px;
		height: 99px;
		background: #7F7F7F;
		margin: 0 20px;
	}

	.stock-logo img {
		height: 80px;
	}

	#page-stock {
		padding: 0;
	}

	#page-stock>.introduce-img {
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
	}

	.homePage h4 {
		font-size: 24px;
		font-weight: 400;
		line-height: 100%;
		color: #000;
		text-align: center;
		margin: 42px auto 0;
		text-align: center;
	}

	.homePage .content {
		border: none;
	}

	.companyName {
		font-size: 50px;
		font-weight: bold;
		color: #000;
		text-align: center;
		margin: 118px 0 40px 0;
	}

	.homePage>div>div.homePageLine {
		width: 796px;
		height: 6px;
		background: #1459F4;
		margin: 0 auto;
	}

	.homePage .reportType {
		font-size: 122px;
		font-weight: bold;
		color: #1459F4;
		margin: 60px 0 10px 0;
		text-align: center;
	}

	.homePage h3 {
		font-size: 34px;
		font-weight: bold;
		color: #388BFF;
		text-align: center;
		margin-bottom: 22px;
	}

	.page-one-footer {
		position: absolute;
		bottom: 50px;
		height: 21px;
		width: 1100px;
		margin: 0 34px;
		display: flex;
		justify-content: space-between;
	}

	.page-one-footer>p {
		font-size: 18px;
		color: #282828;
		line-height: 1;
	}

	.page-one-footer>p>span:last-of-type {
		color: #0D0D0D;
	}

	.page-one-footer>div.date {
		font-size: 18px;
		line-height: 1;
		color: #0D0D0D;
	}

	/* 新目录 */

	.page-catalogue h2 {
		font-size: 48px;
		font-weight: bold;
		color: #0F060B;
		margin: 14px auto 69px;
		text-align: center;
		line-height: 1;
	}

	.page-catalogue .generalize {
		position: relative;
	}

	.page-catalogue .generalize::before {
		display: block;
		content: '';
		width: 1px;
		height: 580px;
		border-left: 3px dotted #1416F4;
		position: absolute;
		left: 42px;
		top: 15px;
		z-index: 2;
	}

	.page-catalogue .generalize>div {
		padding-bottom: 62px;
		display: flex;
		align-items: center;
		position: relative;

		.serialNumber {
			font-size: 34px;
			color: #282828;
			font-weight: bold;
			padding-right: 14px;
			position: relative;
		}

		.serialNumber::after {
			display: block;
			content: '';
			width: 18px;
			height: 18px;
			background: #1459F4;
			position: absolute;
			top: 50%;
			right: -18px;
			transform: translateY(-50%);
			border-radius: 50%;
			z-index: 3;
		}

		.catalogue-category {
			width: calc(100% - 30px);
			height: 2px;
			background: url(../../../assets/img/report/catalogue_.png) left center repeat;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.spreadhead {
			font-size: 28px;
			color: #282828;
			font-weight: bold;
			padding-left: 41px;
			padding-right: 20px;
			background: #fff;
		}

		.catalogue-pagination {
			font-size: 20px;
			color: #282828;
			background: #fff;
			padding-left: 20px;
		}

		.dottedLine {
			height: 2px;
			width: 748px;
			margin: 0 20px 0;
			background: url(../../../assets/img/report/catalogue_.png) left center repeat;
		}

	}

	.page-catalogue .generalize .haveTwoLevels {
		padding-bottom: 50px;
	}

	.page-catalogue .generalize>ul {
		padding-left: 75px;

		li {
			height: 54px;
			padding-bottom: 20px;
			position: relative;

			>div {
				width: 100%;
				height: 2px;
				background: url(../../../assets/img/report/catalogue_.png) left center repeat;
				display: flex;
				align-items: center;
				justify-content: space-between;

				span:first-child {
					font-size: 22px;
					color: #282828;
					padding-right: 20px;
					background: #fff;
				}

				span:last-child {
					font-size: 20px;
					color: #282828;
					background: #fff;
					padding-left: 20px;
				}
			}

		}
	}

	// 基本信息
	.valueResultTitle {
		display: flex;
		align-items: center;
	}

	.icon_ {
		height: 52px;
		margin-right: 25px;
		vertical-align: middle;
	}

	.icon_+span {
		font-size: 30px;
		font-weight: bold;
		color: #282828;
		line-height: 52px;
	}

	#companyName {
		font-size: 24px;
		font-weight: bold;
		color: #000000;
		margin-bottom: 20px;
	}

	.baseInfoCont,
	.productCont,
	.groupCont,
	.mainBusinessCont,
	.situationCont {
		width: 100%;
		overflow: hidden;
		padding: 20px 0 67px;
		margin-left: 74px;
	}

	.situationCont ul li span,
	.baseInfoCont ul li span,
	.productCont ul li span,
	.groupCont ul li span {
		font-size: 20px;
		font-weight: 400;
		color: #010000;
		line-height: 38px;
	}

	.mainBusinessCont>p {
		font-size: 20px;
		color: #282828;
		line-height: 40px;
		text-align: justify;
	}

	.equityStructure {
		position: relative;
	}

	#stockStructurePie {
		top: -36px !important;
		margin-left: -1px !important;
	}

	.equityStructureBase {
		position: absolute;
		top: 352px;
		left: 50%;
		transform: translateX(-50%);
	}

	// 估值结果
	.stamp {
		width: 880px;
		font-size: 20px;
		color: #282828;
		line-height: 42px;
		text-align: justify;
		margin: 50px auto 78px;
	}

	.detail_result {
		width: 955px;
		height: 415px;
		margin: 0 auto;
		background: url(../../../assets/img/report/result.png) center center no-repeat;
		position: relative;
	}

	.detail_result .numberBox {
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-49%, -50%);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.locationUnit {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.numberBox img {
		margin-right: 4px;
	}

	.numberBox img:last-child {
		// margin-right: 0;
	}

	.numberBox p.unit {
		width: 56px;
		font-size: 28px;
		font-weight: bold;
		color: #1416F4;
		position: relative;
		top: 20px;
	}

	.numberBox p.unit+img {
		margin-right: 17px !important;
	}

	.detail_result+img {
		margin: 10px auto 0;
		display: block;
	}

	// 软性指标
	.softIndexScore {
		width: 1023px;
		height: 816px;
		background-size: contain;
		margin: 0 auto;
		position: relative;

		>img {
			position: absolute;
			left: 0;
			top: 0;
		}

		div {
			width: 378px;
			height: 378px;
			margin: 0 auto;
			transform: translateY(44%);
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			span {
				width: 100%;
				color: #1416F4;
				text-align: center;
				font-weight: bold;
			}

			span:first-child {
				font-size: 53px;
				margin: 18px 0 10px;
			}

			span:last-child {
				font-size: 28px;
			}
		}
	}

	.scoreDescription {
		font-size: 20px;
		color: #282828;
		line-height: 36px;
		text-align: justify;
		margin: 20px auto 49px;
		padding-left: 78px;
	}

	.scoreDescription+p {
		font-size: 28px;
		color: #282828;
		font-weight: bold;
		padding-left: 78px;
	}

	.opinionRating {
		width: 1100px;
		height: 1185px;
		background: url(../../../assets/img/dataEvaluation/report/opinionRating.png) center center no-repeat;
		margin: 50px auto 0;

		.grade {
			height: 482px;
			line-height: 482px;
			font-size: 60px;
			color: #1459F4;
			font-weight: bold;
			text-align: center;
		}

		.softTitle {
			font-size: 28px;
			color: #fff;
			font-weight: bold;
			text-align: center;
			margin-top: 206px;
		}

		ul {
			width: 862px;
			margin: 77px auto 0;

			li {
				height: 48px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 19px;

				span {
					font-size: 26px;
					color: #fff;
					font-weight: bold;
					display: block;
					text-align: center;
				}

				span:first-child {
					width: 57%;
				}

				span:last-child {
					width: 22%;
					padding-right: 20px;
				}
			}
		}

	}

	.guidelines {
		p {
			width: 880px;
			font-size: 20px;
			color: #282828;
			line-height: 42px;
			margin: 0 auto;
			text-indent: 2em;
			text-align: justify;
		}
	}

	.indicator {
		display: inline-block;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		margin-top: 16px;
		margin-bottom: 55px;

		>img {
			position: absolute;
			left: -68px;
			bottom: -31px;
		}

		>ul {
			width: 100%;
			position: absolute;
		}

		>div {
			width: 700px !important;
			height: 450px !important;
		}
	}

	.basicIndexScore+div {
		width: 100%;
		top: 60px;
		left: 0;
		text-align: center;

		p {
			font-size: 30px;
			font-weight: bold;
			color: #1416F4;
			margin-top: 11px;
		}
	}

	.basicIndexScore {
		top: 0;

		li {
			width: 221px;
			height: 256px;
			background: url(../../../assets/img/report/basicIndexScore.png) center center no-repeat;
			position: absolute;
			top: 115px;

			p {
				width: 100%;
				text-align: center;
			}

			p:first-child {
				font-size: 24px;
				font-weight: bold;
				color: #1416F4;
				margin-top: 59px;
			}

			p:last-child {
				font-size: 20px;
				color: #fff;
				position: absolute;
				bottom: 26.5%;
			}
		}
	}

	.riskIndicatorScore+div {
		top: -38px;
	}

	.riskIndicatorScore {
		li {
			width: 262px;
			height: 155px;
			background: url(../../../assets/img/report/riskIndicatorScore.png) center center no-repeat;
			top: 30px;

			p {
				color: #000 !important;
			}

			p:first-child {
				margin-top: -27px;
			}

			p:last-child {
				bottom: 77%;
			}
		}
	}

	.qualityIndexScore+div {
		width: 100%;
		text-align: center;
		position: absolute;
		top: 49px;

		p {
			font-size: 30px;
			font-weight: bold;
			color: #1416F4;
		}
	}

	.qualityIndexScore {
		top: -103px;
		left: -138px;

		li {
			position: absolute;

			>div {
				width: 158px;
				height: 86px;
				background: url(../../../assets/img/report/riskIndicatorGrade.png) center center no-repeat;
				margin: 0 auto 6px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;

				p {
					text-align: center;
					font-size: 18px;
					margin-top: 5px;
					color: #000;
				}

				p:last-child {
					font-size: 20px;
					font-weight: bold;
				}
			}
		}
	}

	.economicScore+div {
		width: 100%;
		text-align: center;
		position: absolute;
		top: -45px;

		p {
			font-size: 30px;
			font-weight: bold;
			color: #1416F4;
		}
	}

	.economicScore {
		top: -22px;

		li {
			width: 250px;
			height: 70px;
			background: url(../../../assets/img/report/economicIcon.png) center center no-repeat;
			position: absolute;
			text-align: center;

			span {
				line-height: 70px;
				color: #1416F4;
			}

			span:first-child {
				font-size: 20px;
			}

			span:last-child {
				font-size: 24px;
				font-weight: bold;
				margin-left: 10px;
			}
		}
	}

	.circulationScore+div {
		width: 100%;
		text-align: center;
		position: absolute;
		top: 7px;

		p {
			font-size: 30px;
			font-weight: bold;
			color: #1416F4;
		}
	}

	.circulationScore {
		top: 28px;

		li {
			width: 228px;
			height: 254px;
			background: url(../../../assets/img/report/circulationIcon.png) center center no-repeat;
			position: absolute;
			text-align: center;

			p:first-child {
				font-size: 24px;
				color: #1416F4;
				margin-top: 71px;
				font-weight: bold;
			}

			p:last-child {
				width: 100%;
				font-size: 20px;
				color: #fff;
				position: absolute;
				bottom: 19%;
			}
		}
	}

	.costIndexScore+div {
		width: 288px;
		height: 288px;
		text-align: center;
		position: absolute;
		top: 18px;
		left: 296px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		p {
			width: 100%;
			font-size: 30px;
			font-weight: bold;
			color: #fff;
			margin-top: 27px;
		}
	}

	.costIndexScore {
		top: 189px;

		li {
			width: 160px;
			height: 160px;
			background: url(../../../assets/img/report/costIndexIcon.png) center center no-repeat;
			position: absolute;
			text-align: center;

			p:first-child {
				font-size: 24px;
				font-weight: bold;
				color: #fff;
				margin-top: 56px;
			}

			p:last-child {
				font-size: 20px;
				color: #fff;
				margin-top: 8px;
			}
		}
	}

	.interpretation {
		width: 850px;
		margin: -15px 0 0 96px;

		li {
			font-size: 16px;
			color: #282828;
			line-height: 34px;
			text-indent: -1.6em;
			text-align: justify;
		}
	}

	.profitData {
		width: 511px;
		height: 402px;
		background: url(../../../assets/img/report/profit.png)center center repeat;
		background-size: 100% 100%;
		margin: 0 auto;
		position: relative;
		top: -26px;
	}

	.profitData svg:nth-of-type(1) {
		position: relative;
		// top: -14px;
	}

	.arrows {
		width: 60px;
		height: 75px;
		margin: 0 auto;
		display: block;
		position: relative;
		top: -17px;
	}

	.arrows+img {
		height: 388px;
		margin: 0 auto;
		display: block;
		position: relative;
		top: -40px;
	}

	.rotateYear {
		font-size: 18px;
		fill: #fff;
		font-weight: bold;
	}

	.rotateData {
		width: 50px;
		font-size: 14px;
		fill: #fff;
		text-anchor: middle;
	}

	.rotateDataBlue {
		width: 50px;
		font-size: 14px;
		fill: #1416F4;
		text-anchor: middle;
	}

	.rotateData:nth-of-type(3),
	.rotateDataBlue:nth-of-type(5),
	.rotateData:nth-of-type(7) {
		font-weight: bold;
	}

	.specialNumeric>img {
		display: block;
		margin: 0 auto;
	}

	.machineLearningValuationResults {
		width: 880px;
		height: 443px;
		padding-top: 30px;
		position: relative;
		top: -26px;
		background: url(../../../assets/img/report/machineLearningValuationResults.png) center center repeat;
		background-size: 100% 100%;
		margin: 37px auto 0;
	}

	.machineLearningValuationResults .numberBox {
		width: 636px;
		height: 135px;
		position: absolute;
		top: 30px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.valuationMethod {
		font-size: 22px;
		font-weight: bold;
		color: #000;
	}

	.revenueContributionInterpretation {
		margin-top: 60px;
		margin-left: 74px;

		li {
			font-size: 20px;
			color: #282828;
			line-height: 42px;
			text-align: justify;
		}
	}

	.revenueContributionInterpretation+.numberBox {
		width: 520px;
		height: 119px;
		background: url(../../../assets/img/report/absoluteValuationResult.png) center center no-repeat;
		margin: 75px auto 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.contribution1 {
		width: 242px;
		height: 196px;
		margin: 45px auto;
		position: relative;
	}

	.contribution1::before {
		content: '';
		display: block;
		width: 3px;
		height: 47px;
		background: #1416F4;
		position: absolute;
		left: 50%;
		top: -46px;
		transform: translateX(-50%);
	}

	.contribution1::after {
		content: '';
		display: block;
		width: 3px;
		height: 47px;
		background: #1416F4;
		position: absolute;
		left: 50%;
		bottom: -45px;
		transform: translateX(-50%);
	}

	.contribution2 {
		width: 693px;
		height: 82px;
		border-top: 3px solid #1416F4;
		margin: 0 auto;
		position: relative;

		ul {
			display: flex;
			position: relative;

			li {
				position: relative;

				>div {
					width: 196px;
					height: 201px;
					background: url(../../../assets/img/report/contributionIcon.png) center center no-repeat;
					margin-top: 82px;
					margin-bottom: 45px;
					position: relative;
				}

				p {
					width: 180px;
					height: 66px;
					line-height: 66px;
					background: #EEEEFF;
					border: 2px solid #1416F4;
					font-size: 20px;
					color: #282828;
					text-align: center;
				}

				>img {
					width: 29px;
					transform: rotate(-90deg);
					position: absolute;
					right: -24px;
					bottom: 7px;
				}

				>div::before {
					content: '';
					display: block;
					width: 47px;
					height: 85px;
					background: url(../../../assets/img/report/contribution2.png) center center no-repeat;
					background-size: 100% 100%;
					position: absolute;
					left: 50%;
					top: -85px;
					transform: translateX(-50%);
				}

				>div::after {
					content: '';
					display: block;
					width: 3px;
					height: 47px;
					background: #1416F4;
					position: absolute;
					left: 50%;
					bottom: -45px;
					transform: translateX(-50%);
				}

			}

			>li:first-child {
				left: -96px;
			}

			>li:last-child {
				right: -8px;
			}
		}
	}

	#incomeStatement {
		width: 100%;
		height: 550px;
		margin: 50px auto 0;
	}

	.revenueForecastingBase {
		position: absolute;
		bottom: 0;
	}

	.valuationResultDV {
		width: 890px;
		height: 294px;
		background: url(../../../assets/img/report/valuationResultDVBox.png) center center no-repeat;
		margin: 111px auto 0;

		.numberBox {
			width: 817px;
			height: 151px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			position: relative;
			top: 106px;

			>div {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					margin-right: 5px;
				}

				span {
					font-size: 28px;
					font-weight: bold;
					color: #1416F4;
					margin-top: 40px;
				}
			}

			>p {
				width: 100%;
				font-size: 32px;
				font-weight: bold;
				color: #000000;
				text-align: center;
			}
		}
	}

	.valuationResultDV+img {
		margin-top: -20px;
	}


	.netBookValue {
		width: 950px;
		height: 608px;
		margin: 0 auto;
		position: relative;

		thead {
			th {
				width: 474px;
				height: 71px;
				line-height: 71px;
				text-align: center;
				font-size: 24px;
				font-weight: bold;
				color: #fff;
			}

			>th:first-child {
				background: url(../../../assets/img/report/dataAssetName.png) center center no-repeat;
				margin-right: 3px;
			}

			>th:last-child {
				background: url(../../../assets/img/report/bookValue.png) center center no-repeat;
			}

		}

		tbody {
			border: 2px solid #2426F4;
			position: absolute;
			top: 79px;

			tr {
				td {
					width: 474px;
					height: 54px;
					line-height: 54px;
					text-align: center;
					font-size: 20px;
					color: #000000;
					border-bottom: 2px solid #2426F4;
				}

				>td:first-child {
					padding-right: 3px;
				}
			}

			>tr:last-child {
				td {
					border: none;
				}
			}

			tr.summation {
				td {
					font-weight: bold;
				}
			}

		}
	}


	// 免责声明
	.page-last>img {
		width: 900px;
		height: 376px;
		margin: 0px auto 50px;
		display: block;
	}

	.flow-statement {
		width: 1008px;
		margin: 0 auto;
	}

	.flow-statement>img {
		display: block;
		width: 351px;
		margin: 0 auto;
	}

	.flow-statement>ul>li>div.statement-tit {
		height: 20px;
		width: 428px;
		margin: 50px auto 30px;
		font-size: 0;
		position: relative;
	}

	.statement-tit>span.xian {
		width: 135px;
		height: 1px;
		display: block;
		position: absolute;
		top: -1px;
		margin-top: 10px;
		background: #1459F4;
	}

	.statement-tit>span.xian:first-of-type {
		left: 2px;
	}

	.statement-tit>span.xian:last-of-type {
		right: 0;
	}

	.statement-tit>img {
		width: 12px;
		height: 12px;
		float: left;
		margin-top: 3px;
	}

	.statement-tit>img:first-of-type {
		margin-left: 135px;
	}

	.statement-tit>p {
		width: 136px;
		height: 20px;
		line-height: 20px;
		font-size: 22px;
		color: #282828;
		float: left;
		background: #FFF;
		text-align: center;
		font-weight: bold;
	}

	.statement-tit>p.dian {
		width: 11px;
		height: 11px;
		float: left;
		margin: -2px 0 0 136px;
	}

	.statement-tit>p.dian img {
		width: 100%;
		position: relative;
		top: -2px;
	}

	.statement-tit>p.dian_ {
		margin: -2px 0 0 0;
	}

	.statement-main {
		width: 977px;
		padding: 10px 20px;
		background: #E3EBFF;
		font-size: 18px;
		color: #282828;
		line-height: 32px;
		text-indent: 2em;
		text-align: justify;
		border-radius: 10px;
	}

	.statement-main div {
		margin: 24px 0 0 118px;
		overflow: hidden;
	}

	.statement-main div img {
		float: left;
		width: 138px;
		height: 138px;
		margin: 0 0 20px 0;
	}

	.statement-main div ul {
		margin: 4px 0 0 0;
	}

	.statement-main div ul li {
		font-size: 18px;
		font-weight: 400;
		color: #282828;
		line-height: 30px;
	}

	.statement-main div ul li span {
		width: 72px;
		text-indent: 0;
		// text-align-last: justify;
		// text-align: justify;
		// text-justify: distribute-all-lines;
	}
</style>