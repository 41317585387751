import { render, staticRenderFns } from "./profitTableComponent1.vue?vue&type=template&id=3f853603&scoped=true"
import script from "./profitTableComponent1.vue?vue&type=script&lang=js"
export * from "./profitTableComponent1.vue?vue&type=script&lang=js"
import style0 from "./profitTableComponent1.vue?vue&type=style&index=0&id=3f853603&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f853603",
  null
  
)

export default component.exports