<template>
	<div>
		<div class="newCategory">资产负债表</div>

		<div class="financeTabulation">
			<p class="unit">单位：元</p>
			<div>
				<ul>
					<i></i>
					<li>
						<span>项目</span>
						<span v-for="(item,i) in yearList">{{item}}</span>
					</li>
					<li>
						<span>应收账款</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="liabilities.receivables[i]" @change="change"
								@blur="((ev)=>{blur(ev,liabilities,'receivables',i)})" @focus="focus($event)"
								@wheel="method.disableWheel($event)">
							<span class="showInput" @click="inputFocus($event)">
								{{judgingCondition('receivables',i)?method.formateNum(liabilities.receivables[i],2):'-'}}
							</span>
						</span>
					</li>
					<li>
						<span>存货</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="liabilities.inventory[i]" @change="change"
								@blur="((ev)=>{blur(ev,liabilities,'inventory',i)})" @focus="focus($event)"
								@wheel="method.disableWheel($event)">
							<span class="showInput" @click="inputFocus($event)">
								{{judgingCondition('inventory',i)?method.formateNum(liabilities.inventory[i],2):'-'}}
							</span>
						</span>
					</li>
					<li class="required">
						<span>流动资产合计</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="liabilities.totalCurrentAssets[i]" @change="change"
								@blur="((ev)=>{blur(ev,liabilities,'totalCurrentAssets',i)})" @focus="focus($event)"
								@wheel="method.disableWheel($event)">
							<span class="showInput" @click="inputFocus($event)">
								{{judgingCondition('totalCurrentAssets',i)?method.formateNum(liabilities.totalCurrentAssets[i],2):'-'}}
							</span>
						</span>
					</li>
					<li class="required">
						<span>无形资产（不含土地使用权、矿业权、水域使用权）</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="liabilities.netWorthCombination[i]" @change="change"
								@blur="((ev)=>{blur(ev,liabilities,'netWorthCombination',i)})" @focus="focus($event)"
								@wheel="method.disableWheel($event)">
							<span class="showInput" @click="inputFocus($event)">
								{{judgingCondition('netWorthCombination',i)?method.formateNum(liabilities.netWorthCombination[i],2):'-'}}
							</span>
						</span>
					</li>
					<li class="required">
						<span>非流动资产合计</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="liabilities.totalNonCurrentAssets[i]" @change="change"
								@blur="((ev)=>{blur(ev,liabilities,'totalNonCurrentAssets',i)})" @focus="focus($event)"
								@wheel="method.disableWheel($event)">
							<span class="showInput" @click="inputFocus($event)">
								{{judgingCondition('totalNonCurrentAssets',i)?method.formateNum(liabilities.totalNonCurrentAssets[i],2):'-'}}
							</span>
						</span>
					</li>
					<li>
						<span>资产总计</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="liabilities.totalAssets[i]" disabled>
							<span class="showInput">
								{{judgingCondition('totalAssets',i)?method.formateNum(liabilities.totalAssets[i],2):'-'}}
							</span>
						</span>
					</li>
					<li>
						<span>流动负债合计</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="liabilities.totalNonLiabilities[i]" @change="change"
								@blur="((ev)=>{blur(ev,liabilities,'totalNonLiabilities',i)})" @focus="focus($event)"
								@wheel="method.disableWheel($event)">
							<span class="showInput" @click="inputFocus($event)">
								{{judgingCondition('totalNonLiabilities',i)?method.formateNum(liabilities.totalNonLiabilities[i],2):'-'}}
							</span>
						</span>
					</li>
					<li>
						<span>非流动负债合计</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="liabilities.totalFixedLiabilities[i]" @change="change"
								@blur="((ev)=>{blur(ev,liabilities,'totalFixedLiabilities',i)})" @focus="focus($event)"
								@wheel="method.disableWheel($event)">
							<span class="showInput" @click="inputFocus($event)">
								{{judgingCondition('totalFixedLiabilities',i)?method.formateNum(liabilities.totalFixedLiabilities[i],2):'-'}}
							</span>
						</span>
					</li>
					<li>
						<span>负债合计</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="liabilities.totalLiabilities[i]" disabled>
							<span class="showInput">
								{{judgingCondition('totalLiabilities',i)?method.formateNum(liabilities.totalLiabilities[i],2):'-'}}
							</span>
						</span>
					</li>
					<li>
						<span>所有者权益(或股东权益)合计</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="liabilities.totalOwnershipInterest[i]" disabled>
							<span class="showInput">
								{{judgingCondition('totalOwnershipInterest',i)?method.formateNum(liabilities.totalOwnershipInterest[i],2):'-'}}
							</span>
						</span>
					</li>

					<i></i>
				</ul>
			</div>

		</div>




	</div>
</template>

<script>
	import common from '@/views/dataValuation/common'
	import qs from 'qs'
	import axios from 'axios';

	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		mixins: [common],
		props: {
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},
			url: String,
			jumpAddress: String //保存之后的跳转地址


		},
		data() {
			return {
				yearList: ['', '', ''],
				id: '',
				isCacheValue: '',
				liabilities: {
					receivables: [],
					inventory: [],
					totalCurrentAssets: [],
					totalNonCurrentAssets: [],
					totalAssets: [],
					totalNonLiabilities: [],
					totalFixedLiabilities: [],
					totalLiabilities: [],
					totalOwnershipInterest: [],
					netWorthCombination: [], //无形资产
					dataResources: [] //数据资源
				},
				isSave: false

			}
		},
		mounted() {
			this.getBalanceSheet()
			this.pageModify('false');

		},
		methods: {
			...mapMutations(['pageModify']),
			change() {
				this.pageModify('true');
				if (!this.$route.query.reportId || (this.$route.query.reportId && this.isCacheValue == '0') || (this.$route
						.query.reportId && !this.id)) {
					this.method.caching("liabilitiesInfo", JSON.stringify(this.liabilities), this.type, this.stage, this)
				}
			},
			inputFocus: function(e) {
				this.isSave = false
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'block';
				e.currentTarget.previousSibling.style.borderColor = '#fff';
				e.currentTarget.previousSibling.focus();
			},
			focus(e) {
				e.currentTarget.setAttribute("placeholder", "")

			},
			judgingCondition: function(key, i, index) {
				if (this.liabilities[key][i] === 0) {
					return true
				} else if (this.liabilities[key][i] != 0 && this.liabilities[key][i] != '-') {
					return true
				} else {
					return false
				}
			},
			blur: function(e, data, name, num) { //data：资产负债表的字段   name：具体的科目名称   num：科目的具体下标
				this.isSave = false
				e.currentTarget.nextSibling.style.display = 'block';
				e.currentTarget.style.borderColor = '#D5D5D5';
				var y = String(data[name][num]).indexOf("."); //获取小数点的位置
				var count = String(data[name][num]).length - y; //获取小数点后的个数
				this.pageModify('true');

				if (data[name][num] == '') {
					this.$set(this.liabilities[name], num, '0')
				}

				if (data[name][num]) {
					if (String(data[name][num]).indexOf(".") != -1) {
						this.$set(this.liabilities[name], num, Math.round(Number(data[name][num]) * 100) / 100) //保留两位
					} else {
						if (data[name][num].length > 20) { //判断数据的合理性
							this.$Message.error({
								background: true,
								content: '请输入合理数据'
							});
							this.$set(this.liabilities[name], num, '')
						} else {
							this.$set(this.liabilities[name], num, Number(data[name][num]))
						}
					}
				} else {
					this.$set(this.liabilities[name], num, '')
				}

				for (var i = 0; i < this.yearList.length; i++) {
					// 资产总计
					this.liabilities.totalAssets[i] = Number(this.liabilities.totalCurrentAssets[i]) + Number(this.liabilities
						.totalNonCurrentAssets[i])

					// 负债合计=流动负债合计+非流动负债合计
					this.liabilities.totalLiabilities[i] = Number(this.liabilities.totalNonLiabilities[i]) + Number(this
						.liabilities.totalFixedLiabilities[i])

					// 所有者权益(或股东权益)合计=资产总计-负债合计
					this.liabilities.totalOwnershipInterest[i] = Number(this.liabilities.totalAssets[i]) - Number(this
						.liabilities.totalLiabilities[i])

				}

				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && this.isCacheValue == '0') || (this.$route
						.query.reportId && !this.id)) {
					this.method.caching("liabilitiesInfo", JSON.stringify(this.liabilities), this.type, this.stage, this)
				}


			},
			getBalanceSheet() {
				var this_ = this
				this.$post(this.domain.valuationProject + 'financialLiabilitiesDetail/getLiabilitesInfo', qs.stringify({
						stage: this.stage,
						reportType: this.type,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.$Spin.hide();
						var liabilitiesKey = Object.keys(this.liabilities); //获取资产负债表对象的 key
						this.yearList = res.data.content.year;

						this.id = res.data.content.id;
						this.isCacheValue = res.data.content.isCache

						if (res.data.content.liabilities) {
							this.liabilities = JSON.parse(res.data.content.liabilities)
							if (res.data.content.isCache == "0" && !sessionStorage.isHistoryCache) {
								this.showDialogBar(true, 'cacheType', '您上次填写过估值报告，是否载入缓存信息？')


								// 清除 0.00
								var dataKey = Object.keys(this.liabilities); //获取对象的 key
								dataKey.forEach((key, idx) => {
									this.yearList.forEach((item, i) => {
										this.liabilities[key].push("")
									})
								})

							} else { //正式数据
								var returnliabilitiesKey = Object.keys(JSON.parse(res.data.content
									.liabilities)); //后台数据返回的资产负债表对象的 key
								liabilitiesKey.forEach((elem, index) => {
									returnliabilitiesKey.forEach((item, i) => {
										this_.yearList.forEach((yearItem, y) => {
											if (elem == item) {
												this_.liabilities[elem][y] = JSON.parse(res.data.content.liabilities)[elem][y] ?
													JSON.parse(res.data.content.liabilities)[elem][y] : '';
											} else if (!this_.liabilities[elem]) {
												if (this_.yearList.length == 2) {
													this_.liabilities[elem] = ['', '']
												} else if (this_.yearList.length == 3) {
													this_.liabilities[elem] = ['', '', '']
												}
											}
										})
									})
								})
							}
							console.log(this.liabilities)
						} else {
							var dataKey = Object.keys(this.liabilities); //获取对象的 key
							dataKey.forEach((key, idx) => {
								this.yearList.forEach((item, i) => {
									this.liabilities[key].push("")
								})
							})


						}


					})
			},
			save() {
				var this_ = this

				if (!this.liabilities.totalCurrentAssets[this.liabilities.totalCurrentAssets.length - 1]) {
					this.showDialogBar(true, 'notEmpty', this.yearList[this.yearList.length - 1] + '流动资产合计不能为零')
					return false;
				}
				if (!this.liabilities.totalNonCurrentAssets[this.liabilities.totalNonCurrentAssets.length - 1]) {
					this.showDialogBar(true, 'notEmpty', this.yearList[this.yearList.length - 1] + '非流动资产合计不能为零')
					return false;
				}

				if (this.liabilities.receivables[0] && this.liabilities.inventory[0] && this.liabilities.totalCurrentAssets[0] &&
					this.method.numRound(this.liabilities.receivables[0]) + this.method.numRound(this.liabilities.inventory[0]) >
					this.method.numRound(this.liabilities.totalCurrentAssets[0])) {
					this.showDialogBar(true, 'notEmpty', this.yearList[0] + '应收账款+存货≤流动资产合计')
					return false;
				}

				if (this.liabilities.receivables[1] && this.liabilities.inventory[1] && this.liabilities.totalCurrentAssets[1] &&
					this.method.numRound(this.liabilities.receivables[1]) + this.method.numRound(this.liabilities.inventory[1]) >
					this.method.numRound(this.liabilities.totalCurrentAssets[1])) {
					this.showDialogBar(true, 'notEmpty', this.yearList[1] + '应收账款+存货≤流动资产合计')
					return false;
				}

				if (this.method.numRound(this.liabilities.receivables[this.yearList.length - 1]) + this.method.numRound(this
						.liabilities.inventory[this.yearList.length - 1]) > this.method.numRound(this.liabilities.totalCurrentAssets[
						this.yearList.length - 1])) {
					this.showDialogBar(true, 'notEmpty', this.yearList[this.yearList.length - 1] + '应收账款+存货≤流动资产合计')
					return false;
				}


				// if (!this.liabilities.netWorthCombination[this.liabilities.netWorthCombination.length - 1]) {
				// 	this.showDialogBar(true, 'notEmpty', this.yearList[this.yearList.length - 1] + '无形资产不能为零')
				// 	return false;
				// }

				if (this.liabilities.netWorthCombination[0] && this.liabilities.totalNonCurrentAssets[0] && this.method.numRound(
						this.liabilities.netWorthCombination[0]) > this.method.numRound(this.liabilities.totalNonCurrentAssets[0])) {
					this.showDialogBar(true, 'notEmpty', this.yearList[0] + '无形资产≤非流动资产')
					return false;
				}
				if (this.liabilities.netWorthCombination[1] && this.liabilities.totalNonCurrentAssets[1] && this.method.numRound(
						this.liabilities.netWorthCombination[1]) > this.method.numRound(this.liabilities.totalNonCurrentAssets[1])) {
					this.showDialogBar(true, 'notEmpty', this.yearList[1] + '无形资产≤非流动资产')
					return false;
				}
				if (this.method.numRound(this.liabilities.netWorthCombination[this.yearList.length - 1]) > this.method.numRound(
						this.liabilities.totalNonCurrentAssets[this.yearList.length - 1])) {
					this.showDialogBar(true, 'notEmpty', this.yearList[this.yearList.length - 1] + '无形资产≤非流动资产')
					return false;
				}




				this.liabilities.year = this.yearList

				if (!this.isSave) {
					this.isSave = true
					this.$post(this.url + 'financialLiabilitiesDetail/save', qs.stringify({
							stage: this.stage,
							reportType: this.type,
							id: this.id ? this.id : '',
							reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
							liabilitiesInfo: JSON.stringify(this.liabilities),
						}))
						.then((res) => {
							if (res.data.code == 200) {
								setTimeout(function() {
									this_.$router.push({
										name: this_.jumpAddress,
										query: {
											reportId: res.data.content.valuationReportId
										}
									})
								}, 500)

							}
						})
				}



			},
			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.getBalanceSheet();
				this.hideDialogBar()
			},

			//否  载入缓存
			clickCacheMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.method.clearCaching(this.type, this.stage, this, 'getBalanceSheet')
				this.getBalanceSheet();
				this.hideDialogBar()
			},
			closeMaskInfoJump() {
				this.hideDialogBar()
			}



		}


	}
</script>

<style>
</style>