<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="newViewPage"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<examineTitle />
			<div class="examineContent">
				<div>
					<examineNav :type="type" category='营业收入预测' />
				</div>
				<div class="newViewInfo">
					<div class="fillinMain">
						<img class="leftTop" :src="require('@/assets/img/dataValutaion/leftTop.png')" />
						<img class="leftBottom" :src="require('@/assets/img/dataValutaion/leftBottom.png')" />
						<img class="rightTop" :src="require('@/assets/img/dataValutaion/rightTop.png')" />
						<img class="rightBottom" :src="require('@/assets/img/dataValutaion/rightBottom.png')" />
						<div class="fillinContent">
							<p class="examineTitle">营业收入预测</p>
							<div :class="['forecast',years.length==3?'threeYears':'twoYears']">
								<p class="unit">单位：元</p>
								<div v-if="data.products">
									<ul class="forms" v-for="(yearData,f) in bisectionForecast" :key="'forecast'+f">
										<i></i>
										<li>
											<span>业务分类</span>
											<span v-for="(item,i) in yearData.years">{{item}}<span v-if="i==0&&f==0"
													class="reRed">*</span></span>
										</li>
										<li v-for="(operation,i) in yearData[key].products">
											<span>{{operation.businessName}}</span>
											<span v-for="(item,j) in operation.prediction">
												<input v-if="item.value!='-'" type="number" v-model="item.value">
												<input v-else type="number" disabled>

												<span v-if="item.value!='-'" class="showInput">
													{{item.value?method.formateNum(item.value,2):''}}
												</span>
												<span v-else class="showInput">-</span>
											</span>
										</li>
										<li>
											<span>合计</span>
											<span v-for="(item,i) in yearData[key].productSum.prediction">
												<input type="number" v-model="item.value" disabled>
												<span class="showInput">
													{{item.value?method.formateNum(item.value,2):''}}
												</span>
											</span>
										</li>
										<i></i>
									</ul>
								</div>

								<div v-else>
									<ul class="forms" v-for="(yearData,f) in bisectionForecast" :key="'forecast'+f">
										<i></i>
										<li>
											<span>项目</span>
											<span v-for="(item,i) in yearData.years">{{item}}<span v-if="i==0&&f==0"
													class="reRed">*</span></span>
										</li>
										<li>
											<span>营业收入</span>
											<span v-for="(item,i) in yearData[key].productSum.prediction">
												<input type="number" v-model="item.value">
												<span class="showInput">
													{{item.value?method.formateNum(item.value,2):''}}
												</span>
											</span>
										</li>
										<i></i>
									</ul>
								</div>

								<div class="basisDescription">
									<label for="">预测依据描述</label>
									<div class="forecastingBasis">
										<textarea cols="20" rows="4" maxlength="500" v-model="predict"
											placeholder="请根据公司当前业务类型、经营状况和未来发展规划，结合公司目前已签约订单（含未执行完部分）、已签订的意向/框架协议、正在接洽中的合作伙伴的情况，对未来数据进行预测，并对预测依据进行简要描述。"></textarea>
										<p class="storyDescLength">{{predict?predict.length:0}}/500</p>
									</div>
								</div>


							</div>

						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import examineTitle from '@/common/fillin/examineTitle.vue'
	import examineNav from '../../nav/examineNav.vue'
	import qs from 'qs'
	import axios from 'axios'

	export default {
		components: {
			Header,
			examineTitle,
			examineNav
		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				type: '13',
				data: {
					productSum: {
						name: '',
						prediction: [{
							value: ''
						}, {
							value: ''
						}, {
							value: ''
						}]
					}
				},
				years: [],
				key: 'income',
				predict: '',
				bisectionForecast: {}

			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				var this_ = this
				this.$post(this.domain.valuationProject + 'auditInfoShow/getDataFinancialFutureValue', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.$Spin.hide();

						this.data = JSON.parse(res.data.content.income.income)
						this.years = res.data.content.years
						this.predict = this.data.predict

						var num = this.years.length > 5 ? 2 : 1
						var bisectingData = this.method.paginateData(this.years, num)
						const data = []
						var forecast = this.data

						if (this.data.products) {
							bisectingData.map((item, i) => {
								data.push({
									[this.key]: {
										productSum: {
											businessName: 'sum',
											prediction: (i == 0 ? forecast.productSum.prediction.slice(0, bisectingData[0].length) :
												forecast.productSum.prediction.slice(bisectingData[0].length))
										},
										products: []
									},
									years: item
								})

								forecast.products.map(product => {
									data[i][this.key].products.push({
										businessName: product.businessName,
										id: product.id,
										prediction: (i == 0 ? product.prediction.slice(0, bisectingData[0].length) : product
											.prediction.slice(bisectingData[0].length))
									})
								})

							})

						} else {
							bisectingData.map((item, i) => {
								data.push({
									[this.key]: {
										productSum: {
											businessName: 'sum',
											prediction: (i == 0 ? forecast.productSum.prediction.slice(0, bisectingData[0].length) :
												forecast.productSum.prediction.slice(bisectingData[0].length))
										}
									},
									years: item
								})
							})

						}

						this.$set(this, 'bisectionForecast', data)


					})
			}

		}
	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";

	input,
	textarea {
		pointer-events: none;
	}
</style>