<template>
	<div :id="id"></div>
</template>

<script>
	import * as echarts from 'echarts'

	export default {
		props: [
			'chartData', 'id', 'yAxisName', 'chartColor', 'titleText','borderRadius'
		],
		data() {
			return {



			}
		},
		mounted() {
			this.initChart()

		},
		methods: {
			initChart() {
				var this_ = this;
				var fontSize12 = 14;
				var chartRotate = 30;
				var dataxAxis = this.chartData.name;
				var datayAxis = this.chartData.value;
				var chartGrid = {
					left: '8%',
					right: '12%',
					bottom: '10%',
					top: '14%',
					containLabel: true
				}

				var difference = this_.method.setFinancialAnalysisChartPoor(dataxAxis, datayAxis),
					barWidth = 40;

				var newData = []
				var colorList = this_.method.setGradientColor(datayAxis, this_.chartColor.darkColor, this_.chartColor.lightColor)


				datayAxis.forEach(function(item, i) {
					newData.push({
						value: item,
						itemStyle: {
							color: colorList[i],
							borderRadius: this_.borderRadius ? (item > 0 ? [40, 40, 0, 0] : [0, 0, 40, 40]) : 0
						},
					})
				})


				var option = {
					tooltip: {
						trigger: 'none'
					},
					animation: false, // 禁用动画
					grid: chartGrid,
					title: {
						show: true,
						text: this.titleText,
						textStyle: {
							color: '#282828',
							align: 'center',
							fontSize: 16
						},
						left: 'center',
						top: 'top'
					},
					xAxis: [{
						type: "category",
						data: dataxAxis,
						splitLine: {
							show: false
						},
						axisTick: {
							show: false,
							alignWithLabel: true,
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#1459F4"
							}
						},
						axisLabel: {
							color: "#282828",
							fontSize: fontSize12,
							lineHeight: 15,
							interval: 0,
							rotate: chartRotate,
							margin: 20
						}
					}],
					yAxis: {
						type: "value",
						name: this.yAxisName,
						nameTextStyle: { // 设置名称样式
							color: '#282828', // 文字颜色
							fontSize: fontSize12, // 字体大小
							padding: [0, 0, 10, 0]
						},
						axisLine: {
							show: true, //轴线
							lineStyle: {
								color: "#1459F4"
							}
						},
						axisLabel: {
							fontSize: fontSize12,
							color: "#282828"
						},
						splitLine: {
							show: false //网格线
						}
					},
					series: [{
						name: '',
						type: 'bar',
						barWidth: barWidth,
						tooltip: {
							show: true,
						},
						label: {
							show: true,
							position: 'top',
							fontSize: fontSize12,
							color: '#282828',
							formatter: function(params, index) {
								return this_.method.formateNum(datayAxis[params.dataIndex].toString());
							}
						},
						data: newData,
						markLine: {
							lineStyle: {
								color: '#282828',
							},
							label: {
								show: false,
							},
							data: [
								[{
										coord: difference.coordOne,
										lineStyle: {
											color: '#282828',
											type: 'solid'
										},
										symbolSize: [0, 0],
									},
									{
										coord: difference.coordTwo,
										lineStyle: {
											color: '#282828',
											type: 'solid'
										},
										label: {
											show: true,
											position: 'middle',
											color: '#282828', //设置了文本的颜色会把文本周围的白边去掉
											formatter: function(params) {
												var value = difference.markLineValue.toFixed(2);
												return value;
											}
										},
									}
								]
							]
						}
					}, {
						type: 'line',
						markLine: {
							symbolSize: [0, 0],
							silent: true,
							lineStyle: {
								color: '#282828',
							},
							label: {
								show: false
							},
							data: [
								difference.axisOne,
								difference.axisTwo,
							]
						}
					}]
				};
				var myChart = echarts.init(document.getElementById(this.id));
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);


			}

		}

	}
</script>

<style>
</style>