<template>
	<div :id="id"></div>
</template>

<script>
	import * as echarts from 'echarts'

	export default {
		props: [
			'chartData', 'id', 'yAxisName', 'chartColor'
		],
		data() {
			return {



			}
		},
		mounted() {
			this.initChart()

		},
		methods: {
			initChart() {
				var this_ = this;
				var barWidth = 50;
				var datayAxis = this.chartData.netProfitArr
				var dataxAxis = this.chartData.yearArray
				var yAxisParams = this_.method.setHasBackgroundData(datayAxis);
				var barData = []

				var chartGrid = {
					left: '8%',
					right: '12%',
					bottom: '10%',
					top: '14%',
					containLabel: true
				}


				datayAxis.forEach(function(item, i) {
					barData.push(item > 0 ? yAxisParams.max : item == 0 ? 0 : yAxisParams.min)
				})

				var option = {
					tooltip: {
						trigger: 'none'
					},
					grid: chartGrid,
					animation: false,
					xAxis: [{
						type: "category",
						data: dataxAxis,
						axisTick: {
							alignWithLabel: true,
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#1459F4"
							}
						},
						axisLabel: {
							color: "#282828",
							fontSize: 16,
							lineHeight: 20,
							margin: 20
						}
					}],
					yAxis: {
						type: "value",
						name: this.yAxisName,
						nameTextStyle: { // 设置名称样式
							color: '#282828', // 文字颜色
							fontSize: 16, // 字体大小
							padding: [0, 0, 0, -72]
						},
						axisLine: {
							show: true, //轴线
							lineStyle: {
								color: "#1459F4"
							}
						},
						axisLabel: {
							fontSize: 16,
							color: "#282828"
						},
						splitLine: {
							show: false //网格线
						}
					},
					series: [{
						name: "数据上椭圆",
						type: 'pictorialBar',
						symbolSize: [barWidth, 16],
						symbolPosition: 'end',
						z: 12,
						label: {
							show: true,
							position: 'top',
							fontSize: 16,
							color: '#282828',
							padding: [0, 0, 7, 0],
							formatter: function(params, index) {
								return this_.method.formateNum(datayAxis[params.dataIndex].toString());
							}
						},
						itemStyle: {
							color(params) {
								var colorList = this_.chartColor.topColorEight
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: this_.method.pictorialBarTop(datayAxis, 8)
					}, {
						name: '下椭圆',
						type: 'pictorialBar',
						symbolSize: [barWidth, 16],
						z: 12,
						itemStyle: {
							color(params) {
								var colorList = this_.chartColor.bottomColorEight
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: this_.method.pictorialBarBottom(datayAxis, 8)
					}, {
						name: '真实数据',
						type: 'bar',
						barWidth: barWidth,
						z: 5,
						// barGap:'30%',
						barCategoryGap: 10,
						itemStyle: {
							color(params) {
								var colorList = this_.method.setGradientColor(datayAxis, this_.chartColor.lightColorEight, this_
									.chartColor.darkColorEight)
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: datayAxis
					}]
				};
				var myChart = echarts.init(document.getElementById(this.id));
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option, false);

			}

		}


	}
</script>

<style>
</style>