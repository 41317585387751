<template>
	<div class="reportPage webReport">
		
		<initialValuationReport :stage="stage" :type="type" :url="url" :typeName="typeName" />

	</div>
</template>

<script>
	import initialValuationReport from '@/components/reportPage/initialValuation.vue'
	import '@/assets/css/reportCommon.css'

	export default {
		components: {
			initialValuationReport
		},
		data() {
			return {
				url: this.domain.ajaxUrl + this.domain.systemName + this.domain.projectValuationName,
				stage: '1',
				type: '',
				typeName: '初创期估值'

			}
		},
		mounted() {

		},
		methods: {
			

		}


	}
</script>

<style>
	
</style>