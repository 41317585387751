import { render, staticRenderFns } from "./commoditiesTrading.vue?vue&type=template&id=4b1a5b01&scoped=true"
import script from "./commoditiesTrading.vue?vue&type=script&lang=js"
export * from "./commoditiesTrading.vue?vue&type=script&lang=js"
import style0 from "./commoditiesTrading.vue?vue&type=style&index=0&id=4b1a5b01&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b1a5b01",
  null
  
)

export default component.exports