<template>

	<div class="table_explain">
		<div class="grade" v-if="gradeStatus">
			<img class="battery" :src="chartSrc" alt="诊断报告中的偿债分析之资产负债表" />
			<p v-text="chartData.batteryLevel" :style="chartData.batteryLevel=='20%'?'color:#FF383D;':''">0.00%</p>
		</div>
		<h4 v-text="chartData.title"></h4>
		<div :id="id" style="width:100%;height:340px;"></div>
		<div class="explainText">
			<p v-for="(item,index) in chartDescription" :key="index">{{item}}</p>
		</div>
	</div>

</template>
<script>
	import * as echarts from 'echarts/lib/echarts.js'


	export default ({
		props: [
			'chartData', 'id', 'gradeStatus'
		],
		data() {
			return {
				chartName: [],
				chartValue: [],
				chartDescription: [],
				chartSrc: require("../../assets/img/reportValuation/dianchi-100.svg")
			}
		},
		mounted() {
			var that = this;
			// console.log(that.chartData)
			if (that.chartData) {
				that.chartName = that.chartData.name;
				that.chartName.push("");
				that.chartValue = that.chartData.value;
				that.chartDescription = that.chartData.description.split("|");
				that.chartSrc = that.srcFormate(that.chartData.batteryLevel)
				that.initChart()
			}
		},
		methods: {
			initChart: function() {
				this.chart = echarts.init(document.getElementById(this.id));
				this.setOptions(this.id, this.chartName, this.chartValue)
			},
			setOptions: function(id, dataxAxis, datayAxis) {
				var this_ = this;
				var companyValue = datayAxis[0];
				var averageIndex;
				var datayAxis_ = [];
				for (var i = 0; i < dataxAxis.length; i++) {
					if (dataxAxis[i] == "行业均值") {
						averageIndex = i
					};
					datayAxis_.push({
						value: datayAxis[i],
						itemStyle: {
							borderRadius: datayAxis[i] > 0 ? [24, 24, 0, 0] : [0, 0, 24, 24]
						}
					})
				}
				var markLineValue = companyValue - datayAxis[averageIndex],
					labelObj = {},
					normalObj = {
						fontSize: 16
					};
				switch (id) {
					case "totalAssetsReturnOn":
						var titleText = "总资产收益率";
						var yAxisName = "%";
						break;
					case "saleRatio":
						var titleText = "销售费用率";
						var yAxisName = "%";
						break;
					case "manageRatio":
						var titleText = "管理费用率";
						var yAxisName = "%";
						break;
					case "researchRatio":
						var titleText = "研发费用率";
						var yAxisName = "%";
						break;
					case "grossProfitRatio":
						var titleText = "毛利率";
						var yAxisName = "%";
						break;
					case "netProfiltRatio":
						var titleText = "净利润率";
						var yAxisName = "%";
						break;
					case "mainIncomeRatio":
						var titleText = "主营业务收入增长率";
						var yAxisName = "%";
						break;
					case "totalProfitRatio":
						var titleText = "利润总额增长率";
						var yAxisName = "%";
						break;
					case "netAssetRatio":
						var titleText = "净资产收益率";
						var yAxisName = "%";
						break;
					case "liabilitiesRatio":
						var titleText = "资产负债率";
						var yAxisName = "%";
						break;
					case "flowRation":
						var titleText = "流动比率";
						var yAxisName = "";
						break;
					case "quickRatio":
						var titleText = "速动比率";
						var yAxisName = "";
						break;
					case "ebitMultiple":
						var titleText = "EBIT利息保障倍数";
						var yAxisName = "";
						break;


					case "receivablesTurnoverRatio":
						var titleText = "应收账款周转率";
						//			var yAxisName = "%";
						break;
					case "inventoryTurnoverRatio":
						var titleText = "存货周转率";
						var yAxisName = "";
						break;
				}

				var xAxis = [],
					yAxis = [],
					position;
				var xObj = {},
					yObj = {
						axisLabel: {
							fontSize: 16,
							color: "#282828",
							margin: 12
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#282828"
							}
						}

					},
					splitLine = {},
					axisTick = {
						alignWithLabel: true
					},
					data = [],
					axisOne = {},
					axisTwo = {},
					coordOne = [],
					coordTwo = [],
					axisLabel = {
						interval: 0,
						margin: 10,
						fontSize: 16,
						color: "#282828",
						lineHeight: 24
					};
				xObj.type = "category";
				xObj.data = dataxAxis;
				xObj.axisTick = axisTick;
				xObj.axisLabel = axisLabel;
				xObj.axisLine = {
					show: true,
					lineStyle: {
						color: "#282828"
					}
				}
				axisTick.show = false;
				axisLabel.formatter = this_.method.formatter;
				xAxis.push(xObj);

				yObj.type = "value";
				yObj.name = yAxisName;
				yObj.nameTextStyle = {
					color: "#282828",
					fontSize: 16
				}
				yObj.splitLine = splitLine;
				splitLine.show = false;
				yObj.axisTick = {
					show: true
				}
				yAxis.push(yObj);

				position = "top";
				axisOne.yAxis = datayAxis[0];
				axisOne.symbolSize = [0, 0];
				axisTwo.yAxis = datayAxis[averageIndex];
				axisTwo.symbolSize = [0, 0];
				coordOne = [6, companyValue];
				coordTwo = [6, datayAxis[averageIndex]];
				normalObj.show = true;
				normalObj.position = position;
				var textObj = {
					color: "#282828",
					fontSize: 16
				};
				normalObj.name = textObj;
				normalObj.formatter = '{c}';
				labelObj = normalObj;
				var option = {
					title: {
						show: true,
						text: titleText,
						textStyle: {
							color: '#282828',
							align: 'center',
							fontSize: 16
						},
						left: 'center',
						top: 'top'
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
						}
					},
					grid: { // 控制图的大小，调整下面这些值就可以，
						left: '2%',
						right: '8%',
						bottom: '10%',
						top: '12%',
						containLabel: true
					},
					xAxis: xAxis,
					yAxis: yAxis,

					series: [{
						data: datayAxis_,
						type: 'bar',
						barMaxWidth: 48,
						itemStyle: {
							color: function(params) {
								var colorList = ['#3684F7', '#FE6F83', '#FA6600', '#5DDBB7', '#BD76F9', '#3684F7',
									'rgba(0,0,0,0)'
								];
								var colorList_ = ['#57C9F1', '#FD57A5', '#FDAD00', '#ADE9C4', '#F99EC9', '#57C9F1',
									'rgba(0,0,0,0)'
								];
								if (params.dataIndex == 6) {
									return colorList_[params.dataIndex];
								} else {
									return new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: colorList_[params.dataIndex]
									}, {
										offset: 1,
										color: colorList[params.dataIndex]
									}]);
								}
							},

						},
						markLine: {
							lineStyle: {
								color: '#282828',
							},
							label: {
								show: false,
							},
							data: [
								[{
										coord: coordOne,
										lineStyle: {
											color: '#282828',
											type: 'solid'
										},
										symbolSize: [0, 0],
									},
									{
										coord: coordTwo,
										lineStyle: {
											color: '#282828',
											type: 'solid'
										},
										// 箭头标识
										label: {
											show: true,
											position: 'middle',
											formatter: function(params) {
												var value = markLineValue.toFixed(2);
												return value;
											},
											fontSize: 16,
											color: "#282828"

										},
									},
								],

							],
						},
						label: labelObj,

					}, {
						type: 'line',
						markLine: {
							symbolSize: [0, 0],
							silent: true,
							lineStyle: {
								color: '#282828',
							},
							label: {
								show: false
							},
							data: [
								axisOne,
								axisTwo,
							]
						}
					}]
				};

				var myChart = echarts.getInstanceByDom(document.getElementById(id));
				if (myChart == undefined) {
					myChart = echarts.init(document.getElementById(id));
				}
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
			},
			// 电池图片格式化
			srcFormate: function(val) {
				if (val == "20%") {
					return require("../../assets/img/reportValuation/dianchi-20.svg")
				} else if (val == "40%") {
					return require("../../assets/img/reportValuation/dianchi-40.svg")
				} else if (val == "60%") {
					return require("../../assets/img/reportValuation/dianchi-60.svg")
				} else if (val == "80%") {
					return require("../../assets/img/reportValuation/dianchi-80.svg")
				} else if (val == "100%") {
					return require("../../assets/img/reportValuation/dianchi-100.svg")
				}
			}
		}
	})
</script>
<style scoped>
	#totalAssetsReturnOn,
	#saleRatio,
	#manageRatio,
	#researchRatio,
	#grossProfitRatio,
	#netProfiltRatio,
	#mainIncomeRatio,
	#totalProfitRatio,
	#netAssetRatio,
	#flowRation,
	#quickRatio,
	#ebitMultiple,
	#receivablesTurnoverRatio,
	#inventoryTurnoverRatio,
	#totalAssetsTurnoverRatio,
	#liabilitiesRatio {
		height: 340px;
		width: 820px;
		padding: 0 100px;
	}

	.grade img.battery {
		margin-right: 6px;
		height: 34px;
		width: 70px;
	}

	.grade p {
		font-size: 20px;
		font-weight: bold;
		color: #282828;
		line-height: 34px;
		float: right;
	}

	.grade {
		position: absolute;
		top: 0;
		right: 0;
		width: 132px;
	}

	.table_explain {
		margin: 20px 0 60px;
		position: relative;
		overflow: hidden;
	}

	.table_explain .explainText p {
		font-size: 18px;
		font-weight: 400;
		color: #333333;
		line-height: 34px;
		text-indent: 36px;
		text-align: justify;
	}

	.table_explain h4 {
		font-size: 20px;
		font-weight: bold;
		color: #282828;
		margin: 16px 0 30px 0;
	}
</style>