// 配置全局接口域名
const domain = 'http://test.flcccc.com/';
// const domain = 'http://192.168.12.95:8089/';
const ajaxUrl='flow/';
const systemName=''


const dataRealization ='flow/dataAsset/' 
const evaluation ='flow/dataAsset/dataAsset/' //数据测评项目
const valuationProject = evaluation; //数据估值项目

// 估值具体项目名称
const projectValuationName='valuation/'

// 财务预警
const earlyWarningAjaxUrl='earlyWarning/'


export default {
	testUrl: domain,
	dataRealization,
	valuationProject,
	ajaxUrl,
	evaluation,
	systemName,
	projectValuationName,
	earlyWarningAjaxUrl
}
