<template>
	<div>
		<img :src="this.$store.state.user.generalSetting.footerLogo" alt="公司logo" style="width: 285px;margin-top: 377px;">
		<p class="ourCompany">{{channelName}}</p>
		<p>联系方式</p>
		<ul class="contact">
			<li> <span>公司地址：</span> <span>{{channelInfo.address}}</span> </li>
			<li> <span>联系电话：</span> <span>{{channelInfo.serviceTel}}</span> </li>
		</ul>
		<ul class="flowQRCode">
			<li v-for="(item,index) in channelInfo.footerQRCode" :key="index">
				<div> <img :src="item.imgPath"> </div>
				<p>{{item.describe}}</p>
			</li>
		</ul>

	</div>
</template>

<script>
	import navFooterInfo from '@/common/navFooterInfo'
	
	
	export default {
		mixins: [navFooterInfo],
		props: {
			channelName: {
				type: String,
				required: false
			},
			
		},
		data() {
			return {
				channelInfo: {},


			}
		},
		computed:{
			generalSetting() {
				return this.$store.state.user.generalSetting;
			}
		},
		watch: {
			generalSetting: {
				handler: function(newValue, oldValue) {
					this.channelInfo = newValue

					console.log(newValue)
				},
				deep: true
			}

		},
		mounted() {
			this.getLogo()
		},
		methods: {


		}


	}
</script>

<style>
</style>